import { HamburgerIcon, Icon } from "@chakra-ui/icons";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link as LinkDom } from "react-router-dom";
import { auth } from "../api/firebaseApi";
import { activeClientSelectors } from "../redux/client-case/selectors";
import { individualSelectors } from "../redux/individual/selectors";
import { lawyerSelectors } from "../redux/lawyer/selectors";
import styles from "../styles/hamburger-menu.module.css";
import { Sidebar } from "./Sidebar/Sidebar";
import { SvgLogoIcon } from "./svgLogoItem";
import { NavBarLogo } from "./NavbarLogo";

interface HamburgerMenuProps {
  link?: string;
}
const HamburgerMenu = ({ link }: HamburgerMenuProps) => {
  const [showSidebar, setShowSidebar] = useState(false);

  const lawyerId = useSelector(lawyerSelectors.selectUid);
  const clientId = useSelector(activeClientSelectors.selectId);
  const individualId = useSelector(individualSelectors.selectUid);

  const logoLink = useMemo(() => {
    if (link) {
      return link;
    }
    if (lawyerId) {
      return "/cases";
    }
    if (clientId) {
      return "/client";
    }
    if (individualId) {
      return "/individual";
    }
    return "/";
  }, [auth, lawyerId, clientId, individualId]);

  return (
    <div className={styles.hamburger_menu}>
      <span onClick={() => setShowSidebar(true)}>
        <Icon as={HamburgerIcon} boxSize={8} />
      </span>
      <Flex alignItems="center" gap="14px" ml="15px">
        <Text fontSize="30px" fontWeight={500}>
          <LinkDom to={logoLink} />
        </Text>
        <Box width="100%">
          <NavBarLogo />
        </Box>
      </Flex>
      <Sidebar
        toggleSidebar={() => setShowSidebar(!showSidebar)}
        showSidebar={showSidebar}
      />
    </div>
  );
};

export default HamburgerMenu;
