import React, { ChangeEventHandler } from "react";
import { useSelector } from "react-redux";
import { FormControl, FormLabel, Input, Box, Flex, Text } from "./_index";
import styles from "../styles/extracted-info.module.css";
import Thumbnail from "../components/individualTabs/individualDocuments/Thumbnail";
import { DocUrl } from "../types/tables-data";
import { DocumentReducer } from "../redux/documents/documentSlice";
import { documentSelectors } from "../redux/documents/selectors";

export type PasseportFormProps = {
  i94DocumentUrl: DocUrl | null;
  values: {
    mostRecentDateOfEntry: string;
    recordNumber: string;
    authorizedStayExpirationDate: string;
    classOfAdmission: string;
  };
  handleChange: ChangeEventHandler<any>;
};
export const I94Form = ({
  values,
  handleChange,
  i94DocumentUrl
}: PasseportFormProps) => {
  const i94Document = useSelector((state: DocumentReducer) =>
    documentSelectors.selectDocumentByType(state, "immigration")
  );

  return (
    <Box>
      <Text fontWeight={550} fontSize={20} color="primary.darkBlue">
        I-94
      </Text>
      <Flex flexDirection="column" mt={8}>
        <Flex flexDirection="column">
          <Flex className={styles.document_section}>
            <Flex flexDirection="column">
              <Box className="flex gap-4">
                <FormControl variant="floating" id="recordNumber" my={4}>
                  <Input
                    placeholder=" "
                    name="recordNumber"
                    value={values.recordNumber}
                    onChange={handleChange}
                  />
                  <FormLabel>I-94 number</FormLabel>
                </FormControl>
                <FormControl
                  variant="floating"
                  id="mostRecentDateOfEntry"
                  my={4}
                >
                  <Input
                    placeholder=" "
                    type="date"
                    min="1900-01-01"
                    max="2100-01-01"
                    name="mostRecentDateOfEntry"
                    value={values.mostRecentDateOfEntry}
                    onChange={handleChange}
                  />
                  <FormLabel>Last entry in US</FormLabel>
                </FormControl>
              </Box>

              <Box className="flex gap-4 ">
                <FormControl
                  variant="floating"
                  id="authorizedStayExpirationDate"
                  my={4}
                >
                  <Input
                    placeholder=" "
                    type="date"
                    min="1900-01-01"
                    max="2100-01-01"
                    name="authorizedStayExpirationDate"
                    value={values.authorizedStayExpirationDate}
                    onChange={handleChange}
                  />
                  <FormLabel>Authorized stay expiration date</FormLabel>
                </FormControl>
                <FormControl variant="floating" id="classOfAdmission" my={4}>
                  <Input
                    placeholder=" "
                    name="classOfAdmission"
                    value={values.classOfAdmission}
                    onChange={handleChange}
                  />
                  <FormLabel>Class of admission</FormLabel>
                </FormControl>
              </Box>
            </Flex>
            <Flex>
              {i94Document?.filePath && (
                <Thumbnail filePath={i94Document?.filePath} />
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
