import { useState } from "react";
import { VISAVALUE } from "../types/tables-data";
import { openai } from "../api/OpenAIApi";

interface DraftLetter {
  letterDate: string;
  visaType: VISAVALUE;
  expertName: string;
  recommendedPerson: string;
  recommendedPersonRole: string;
  institution: string;
  relationship: string;
  expertRelationshipRole: string;
  expertCurrentRole: string;
  expertCurrentInstitution: string;
  startDate: string;
  endDate: string;
  contributionsToEmphasize: string;
  nationalImportance: string;
}

const createMessage = (params: DraftLetter) => {
  return `You are writing a recommendation letter and adressing an immigration officer ("dear officer").\
    Make sure to write a compelling letter using appropriate language, making it personal.\
    The letter should be dated ${params.letterDate}.\
    Your name is: ${params.expertName}.\
    You are recommending: ${params.recommendedPerson} for an ${params.visaType}.\
    ${params.recommendedPerson} is a ${params.recommendedPersonRole}\
    You worked together at ${params.institution}  while you were a ${params.expertRelationshipRole}, and you acted as ${params.relationship}.\
    This was from ${params.startDate} to ${params.endDate}.\
    You are currently ${params.expertCurrentRole} at ${params.expertCurrentInstitution}.\
    Make sure to emphasize these contributions: ${params.contributionsToEmphasize}.\
    Make sure to emphasize the following national importance items: ${params.nationalImportance}.\
    Start by introducing yourself, then how you and ${params.recommendedPerson} worked together,\
    then emphasize ${params.recommendedPerson} contributions and their importance to the US economy.\
    Only use this input information and general knowledge to generate the letter.\
    Heres an example letter:\
    March 30, 2023\
    Dear Officer,\
    I am writing to provide a strong endorsement for Khalil Zlaouis immigration application. I had the honor of working closely with Khalil while leading clinical analytics at CVS Health, and I can attest to his exceptional contributions and their critical impact on healthcare in the United States. As the Medicaid Director for the state of Connecticut with a PhD in Economics from Stanford University and extensive experience at top organizations such as CVS/Aetna, Nuna Health, and the White House Council of Economic Advisers, I have had the privilege of collaborating with some of the most talented professionals in the industry.\
    In my current role as the Connecticut State director of Medicaid, I have a significant impact on the health and welfare of millions of program participants. My work affects both state and federal tax burdens, carrying spillover benefits to other states and having the potential to lead to multibillion-dollar shifts in the flow of federal tax dollars to states. Medicaid leaders such as myself do not work alone, and external stakeholders, including providers, enrollees, health plans, and their proxies can also influence policy making. Research on healthcare policy such as the work conducted by Khalil at CVS Health positively influences the choices made regarding Medicare and Medicaid, leading to improved health and welfare for their participants.\
    While at CVS Health/Aetna, Khalil applied advanced biostatistics modeling techniques to measure the effects of care management and member outreach. His work helped prioritize programs that were effective, resulting in millions of dollars saved in healthcare spending. His expertise in predictive modeling also enabled him to allocate resources to members who required the most attention, ultimately leading to better health outcomes for members of CVS/Aetna across the country, and thousands of inpatients readmissions avoided. \
    In addition, Khalil developed software to ensure the implementation of sound hypothesis testing, establishing a culture of statistical excellence and rigor across the entire Data Science organization, influencing the work of over 200 data scientists. His exceptional skills led to two promotions in less than three years, demonstrating his expertise in the field. His communication skills and leadership made him one of the top-notch data scientists in the company, granting him unique opportunities, such as working on high-impact projects for the office of the chief medical officer, Dr. Troyen Brennan.\
    Khalils work in data science is of immense importance to the healthcare industry, benefiting not only CVS and its patients and members across the United States, but also the broader healthcare ecosystem. Data science has the potential to revolutionize healthcare by leveraging data to improve patient outcomes and reduce costs. Analyzing clinical data is complex and requires advanced machine learning and predictive modeling techniques, all of which Khalil possesses. He can help healthcare providers make more informed decisions and improve outcomes by identifying patients at risk and prioritizing preventive measures, leading to more personalized care.\
    Khalils expertise in causal inference, artificial intelligence, and predictive modeling is paramount to the success and technological evolution of the healthcare industry. I am confident that he will continue to make significant contributions to data science and the healthcare industry, making him an invaluable asset to the progress of the US economy. Thank you for your consideration.\
    Sincerely,\
    William Gui Woolston\
`;
};

export const useDraftExtertLetter = () => {
  const [isLoadingDraftExtertLetter, setIsLoadingDraftExtertLetter] =
    useState(false);

  const [apiResponse, setApiResponse] = useState<string | null>(null);

  const onSubmitDraftExtertLetter = async (params: DraftLetter) => {
    const content = createMessage(params);
    setIsLoadingDraftExtertLetter(true);
    try {
      const result = await openai.createChatCompletion({
        model: "gpt-4",
        messages: [{ role: "user", content }],
        temperature: 0.3
      });
      setApiResponse(result.data.choices[0].message?.content || "");
    } catch (e) {
      setApiResponse("Something went wrong, Please try again.");
    } finally {
      setIsLoadingDraftExtertLetter(false);
    }
  };

  return {
    isLoadingDraftExtertLetter,
    onSubmitDraftExtertLetter,
    apiResponse
  };
};
