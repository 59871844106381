import { Field } from "formik";
import { AllCountriesOptions } from "../../components/common/AllCountriesOption";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Stack
} from "../_index";

export const AddressFields = ({
  name,
  errors,
  touched,
  usBased = false
}: any) => {
  return (
    <Box>
      <Stack spacing={4}>
        <Field name={`${name}.street`}>
          {({ field }: any) => (
            <FormControl
              isInvalid={errors[name]?.street && touched[name]?.street}
            >
              <FormLabel htmlFor={`${name}.street`}>Street</FormLabel>
              <Input {...field} id={`${name}.street`} placeholder="Street" />
              <FormErrorMessage>{errors[name]?.street}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <div className="flex gap-4">
          <Field name={`${name}.city`}>
            {({ field }: any) => (
              <FormControl
                isInvalid={errors[name]?.city && touched[name]?.city}
              >
                <FormLabel htmlFor={`${name}.city`}>City</FormLabel>
                <Input {...field} id={`${name}.city`} placeholder="City" />
                <FormErrorMessage>{errors[name]?.city}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name={`${name}.state`}>
            {({ field }: any) => (
              <FormControl
                isInvalid={errors[name]?.state && touched[name]?.state}
              >
                <FormLabel htmlFor={`${name}.state`}>State</FormLabel>
                <Input {...field} id={`${name}.state`} placeholder="State" />
                <FormErrorMessage>{errors[name]?.state}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name={`${name}.zipCode`}>
            {({ field }: any) => (
              <FormControl
                isInvalid={errors[name]?.zipCode && touched[name]?.zipCode}
              >
                <FormLabel htmlFor={`${name}.zipCode`}>Zip Code</FormLabel>
                <Input
                  {...field}
                  id={`${name}.zipCode`}
                  placeholder="Zip Code"
                />
                <FormErrorMessage>{errors[name]?.zipCode}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </div>

        {!usBased && (
          <FormControl variant="fixed">
            <FormLabel htmlFor={`${name}.country`}>Country</FormLabel>
            <Field name={`${name}.country`} as={Select}>
              <AllCountriesOptions />
              <FormErrorMessage>{errors[name]?.country}</FormErrorMessage>
            </Field>
          </FormControl>
        )}
      </Stack>
    </Box>
  );
};
