import { DataDocs } from "../../types/tables-data";
import { DocumentReducer } from "./documentSlice";

const selectAll = (state: DocumentReducer) => state.document;

const standardDocs = (state: DocumentReducer) => selectAll(state).standardDocs;

const newStandardDocs = (state: DocumentReducer) =>
  selectAll(state).newStandardDocs;

const selectStandardDocById = (state: DocumentReducer, id: string) =>
  selectAll(state).newStandardDocs.find((doc) => doc.id === id);

const selectDocumentById = (state: DocumentReducer, id: string) => {
  const {
    standardDocs,
    evidenceDocs,
    newEvidenceDocs,
    newStandardDocs,
    signedExpertLetters
  } = state.document;
  const mergedDocuments = [
    ...standardDocs,
    ...evidenceDocs,
    ...newEvidenceDocs,
    ...newStandardDocs,
    ...signedExpertLetters
  ];
  const document = mergedDocuments.find((document) => document.id === id);
  return document;
};

const selectBundledDocById = (
  state: DocumentReducer,
  id: string,
  documentType: string
) => {
  let docsArray;

  switch (documentType) {
    case "evidence-documents":
      docsArray = selectAll(state).evidenceDocs;
      break;
    case "signed-expert-letters":
      docsArray = selectAll(state).signedExpertLetters;
      break;
    default:
      return undefined;
  }
  return docsArray.find((doc) => doc.id === id) || undefined;
};

const selectDocumentByType = (
  state: DocumentReducer,
  type: string
): DataDocs | undefined => {
  const document = newStandardDocs(state)
    .filter((doc) => doc.deleted === false)
    .find((element) => {
      if (typeof element.type === "object") {
        return (element?.type as any).title?.toLowerCase().includes(type);
      }

      return element?.type?.toLowerCase().includes(type);
    });
  return document;
};

const getDocumentUrl = (
  state: DocumentReducer,
  docType: "I94" | "Passeport" | "Visa"
) => {
  const docs = state.document.standardDocs;
  console.log(docs);
};

const evidenceDocs = (state: DocumentReducer) => selectAll(state).evidenceDocs;
const documentTypes = (state: DocumentReducer) =>
  selectAll(state).documentTypes;
const signedLetters = (state: DocumentReducer) =>
  selectAll(state).signedExpertLetters;
const extractedData = (state: DocumentReducer) =>
  selectAll(state).extractedData;
const selectedEvidenceDoc = (state: DocumentReducer) =>
  selectAll(state).selectedEvidenceDoc;
const evidenceExhibitMap = (state: DocumentReducer) =>
  selectAll(state).evidenceExhibitMap;
const standardExhibitMap = (state: DocumentReducer) =>
  selectAll(state).standardExhibitMap;
const isLoadingGetEvidence = (state: DocumentReducer) =>
  selectAll(state).isLoadingGetEvidence;

export const documentSelectors = {
  selectAll,
  standardDocs,
  evidenceDocs,
  signedLetters,
  selectedEvidenceDoc,
  extractedData,
  newStandardDocs,
  getDocumentUrl,
  selectBundledDocById,
  selectStandardDocById,
  selectDocumentByType,
  selectDocumentById,
  evidenceExhibitMap,
  standardExhibitMap,
  isLoadingGetEvidence,
  documentTypes
};
