import React, { useState } from "react";
import { Box, Button, Flex } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { createNewFirebaseUser } from "../../../api/OpenAIApi";
import HamburgerMenu from "../../../components/HamburgerMenu";
import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar/Sidebar";
import { SpecialHeading } from "../../../components/individualTabs/PanelLayout";

function AdminNewUser() {
  const navigate = useNavigate();
  const location = useLocation();

  function useQuery() {
    return new URLSearchParams(location.search);
  }

  const query = useQuery();
  const roleQueryParam = query.get("role");
  const practiceNameQueryParam = query.get("practiceName");

  const [user, setUser] = useState({
    email: "",
    firstName: "",
    lastName: "",
    disabled: false,
    emailVerified: false,
    newPassword: "",
    role: roleQueryParam || "",
    practiceName: practiceNameQueryParam || "",
    visaType: "",
    subscriptionStatus: "",
    lawyerRole: ""
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showSidebar, setShowSidebar] = useState(false);

  const handleChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    setUser((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setIsLoading(true);
    let newUser = null;
    try {
      newUser = await createNewFirebaseUser(
        user.email,
        user.newPassword,
        false,
        user.role,
        user.firstName,
        user.lastName,
        user.practiceName,
        user.visaType,
        user.subscriptionStatus,
        user.lawyerRole
      );
      alert("User created successfully!");
      setUser({
        email: "",
        firstName: "",
        lastName: "",
        disabled: false,
        emailVerified: false,
        newPassword: "",
        role: "",
        practiceName: "",
        visaType: "",
        subscriptionStatus: "",
        lawyerRole: ""
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      navigate(`/admin/users/${newUser.uid}/edit`);
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Flex flexDirection="column" flex="1" className="p-4 overflow-scroll">
      <Box py="15px" width="100%">
        <SpecialHeading title="Create New User" />
      </Box>

      <form onSubmit={handleSubmit}>
        <Box py="15px" width="100%">
          <Flex justifyContent="center" flexDirection="row">
            <label htmlFor="firstName">
              First Name:
              <input
                style={{ marginLeft: "10px" }}
                type="text"
                id="firstName"
                name="firstName"
                value={user.firstName}
                onChange={handleChange}
              />
            </label>
          </Flex>
        </Box>
        <Box py="15px" width="100%">
          <Flex justifyContent="center" flexDirection="row">
            <label htmlFor="lastName">
              Last Name:
              <input
                style={{ marginLeft: "10px" }}
                type="text"
                id="lastName"
                name="lastName"
                value={user.lastName}
                onChange={handleChange}
              />
            </label>
          </Flex>
        </Box>
        <Box py="15px" width="100%">
          <Flex justifyContent="center" flexDirection="row">
            <label htmlFor="email">
              Email:
              <input
                style={{ marginLeft: "10px" }}
                type="email"
                id="email"
                name="email"
                value={user.email}
                autoComplete="new-email"
                onChange={handleChange}
              />
            </label>
          </Flex>
        </Box>
        <Box py="15px" width="100%">
          <Flex justifyContent="center" flexDirection="row">
            <label htmlFor="disabled">
              Disabled:
              <input
                style={{ marginLeft: "10px" }}
                type="checkbox"
                id="disabled"
                name="disabled"
                checked={user.disabled}
                onChange={handleChange}
              />
            </label>
          </Flex>
        </Box>
        <Box py="15px" width="100%">
          <Flex justifyContent="center" flexDirection="row">
            <label htmlFor="newPassword">
              New Password:
              <input
                style={{ marginLeft: "10px" }}
                type="password"
                id="newPassword"
                name="newPassword"
                value={user.newPassword}
                onChange={handleChange}
              />
            </label>
          </Flex>
        </Box>

        <Box py="15px">
          <Flex justifyContent="center" flexDirection="row">
            <label htmlFor="role">
              Role:
              <select
                name="role"
                id="role"
                value={user.role}
                onChange={handleChange}
                style={{ marginLeft: "10px" }}
              >
                <option value="">Select a role</option>
                <option value="lawyer">Lawyer</option>
                <option value="individual">Individual</option>
              </select>
            </label>
          </Flex>
        </Box>

        {user.role === "lawyer" && (
          <>
            <Box py="15px">
              <Flex justifyContent="center" flexDirection="row">
                <label htmlFor="practiceName">
                  Practice Name:
                  <input
                    style={{ marginLeft: "10px" }}
                    type="text"
                    id="practiceName"
                    name="practiceName"
                    value={user.practiceName}
                    onChange={handleChange}
                  />
                </label>
              </Flex>
            </Box>
            <Box py="15px">
              <Flex justifyContent="center" flexDirection="row">
                <label htmlFor="subscriptionStatus">
                  Subscription Type:
                  <select
                    name="subscriptionStatus"
                    id="subscriptionStatus"
                    value={user.subscriptionStatus}
                    onChange={handleChange}
                    style={{ marginLeft: "10px" }}
                  >
                    <option value="">Select a subscription type</option>
                    <option value="freemium">Freemium</option>
                    <option value="paid">Paid</option>
                  </select>
                </label>
              </Flex>
            </Box>
            <Box py="15px">
              <Flex justifyContent="center" flexDirection="row">
                <label htmlFor="lawyerRole">
                  Account Type:
                  <select
                    name="lawyerRole"
                    id="lawyerRole"
                    value={user.lawyerRole}
                    onChange={handleChange}
                    style={{ marginLeft: "10px" }}
                  >
                    <option value="">Select an account type</option>
                    <option value="standard">Standard</option>
                    <option value="full">Full</option>
                  </select>
                </label>
              </Flex>
            </Box>
          </>
        )}

        {user.role === "individual" && (
          <Box py="15px">
            <Flex justifyContent="center" flexDirection="row">
              <label htmlFor="visaType">
                Visa Type:
                <select
                  name="visaType"
                  id="visaType"
                  value={user.visaType}
                  onChange={handleChange}
                  style={{ marginLeft: "10px" }}
                >
                  <option value="">Select a visatype</option>
                  <option value="EB-2 NIW">EB-2 NIW</option>
                  <option value="O-1 A">O-1 A</option>
                </select>
              </label>
            </Flex>
          </Box>
        )}

        <Button
          bg="bgColor.300"
          color="text.100"
          type="submit"
          isLoading={isLoading}
        >
          Create User
        </Button>
      </form>
    </Flex>
  );
}

export default AdminNewUser;
