import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import Dropdown from "../../common/Dropdown";
import { DataDocs } from "../../../types/tables-data";
import DropdownMulti from "../../common/DropdownMulti";
import { documentSelectors } from "../../../redux/documents/selectors";

type Props = {
  onConfirm: () => void;
  selectedExhibits: number[];
  setExhibits: (exhibits: number[]) => void;
  evidenceDocs: DataDocs[];
  closeDialog: () => void;
};

export const SelectExhibitDialog = ({
  selectedExhibits,
  setExhibits,
  evidenceDocs,
  onConfirm,
  closeDialog
}: Props) => {
  const { isOpen, onOpen } = useDisclosure();
  const evidenceExhibitMap = useSelector(documentSelectors.evidenceExhibitMap);
  const exhibitNumbers = Object.keys(evidenceExhibitMap!);

  return (
    <Modal closeOnOverlayClick={false} isOpen onClose={closeDialog}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select evidence to summarize</ModalHeader>
        <ModalCloseButton
          onClick={() => {
            closeDialog();
          }}
        />
        <ModalBody pb={6}>
          <DropdownMulti
            selectedValues={selectedExhibits}
            title="Documents"
            onItemSelect={setExhibits}
            list={exhibitNumbers}
          />
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={() => {
              closeDialog();
            }}
          >
            Cancel
          </Button>
          <Button
            colorScheme="red"
            ml={3}
            onClick={() => {
              onConfirm();
            }}
          >
            Generate
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
