import { Link } from "@chakra-ui/react";
import { Link as ReactRouterLink, useLocation } from "react-router-dom";
import { SidebarLink } from "./types";

type Props = {
  link: SidebarLink;
  onClick?: () => void;
};
export const NavigationLink = ({ link, onClick }: Props) => {
  const location = useLocation();

  const isActive = location.pathname === link.url;

  return (
    <Link
      as={ReactRouterLink}
      to={link.url}
      color={isActive ? "#00008b" : "gray.600"}
      fontWeight={isActive ? 500 : 400}
      textDecoration={isActive ? "underline" : ""}
      onClick={onClick}
    >
      {link.label}
    </Link>
  );
};
