import {
  Heading,
  Card,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Box,
  Flex,
  Image,
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";

import { useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { clickedStyles } from "../styles/common-styles";
import images from "../assets/image-export-assets";
import { useDraftExtertLetter } from "../hooks/useDraftExtertLetter";
import { clientsSelectors } from "../redux/clients/selectors";
import { DataDocs } from "../types/tables-data";
import { documentsTypes, formatPassport } from "../helpers/helpers";
import { lawyerSelectors } from "../redux/lawyer/selectors";
import { useAddExtractInfo } from "../hooks/useAddExtractInfo";
import { useDeleteExtractedInfo } from "../hooks/useDeleteExtractedInfo";
import { useExtractData } from "../hooks/useExtractData";
import { SpecialHeading } from "./individualTabs/PanelLayout";

type CaseIdPacketProps = {
  index: number;
  data: DataDocs[];
};

export const CaseIdPacket = ({ index, data }: CaseIdPacketProps) => {
  const { onSubmitDraftExtertLetter, apiResponse, isLoadingDraftExtertLetter } =
    useDraftExtertLetter();
  const questionnaire = useSelector(clientsSelectors.selectAll)[index]
    .questionnaireLetter;

  const { firstName, lastName, visa, employer } = useSelector(
    clientsSelectors.selectAll
  )[index];

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [documentToDelete, setDocumentToDelete] = useState("");

  const dataForDraftLetter = {
    letterDate: dayjs().format("MMMM D, YYYY"),
    visaType: visa,
    expertName: questionnaire?.expertName || "",
    recommendedPerson: `${firstName} ${lastName}` || "",
    recommendedPersonRole: employer || "",
    institution: questionnaire?.institution || "",
    relationship: questionnaire?.relationship || "",
    expertRelationshipRole: questionnaire?.expertRole || "",
    expertCurrentRole: questionnaire?.expertCurrent || "",
    expertCurrentInstitution: questionnaire?.expertCurrent || "",
    startDate: dayjs(questionnaire?.relationshipStartDate).format("MMMM YYYY"),
    endDate: dayjs(questionnaire?.relationshipEndDate).format("MMMM YYYY"),
    contributionsToEmphasize: questionnaire?.specificRoleList?.join(" ") || "",
    nationalImportance: questionnaire?.roleImportanceList?.join(" ") || ""
  };
  const handleDraftLetter = () => {
    onSubmitDraftExtertLetter(dataForDraftLetter);
  };

  const navigate = useNavigate();
  const params = useParams();
  const lawyerFirstName = useSelector(lawyerSelectors.selectFirstName);
  const lawyerLastName = useSelector(lawyerSelectors.selectLastName);

  const {
    onSubmitAddPassExtractInfo,
    onSubmitAddVisaExtractInfo,
    onSubmitAddI94ExtractInfo,
    onSubmitAddResumeExtractInfo,
    extractedData,
    onSubmitSaveExpertLetter,
    isLoadingAddPassExtractInfo,
    isLoadingAddVisaExtractInfo,
    isLoadingAddI94ExtractInfo,
    isLoadingAddResumeExtractInfo
  } = useAddExtractInfo();

  const { onDeleteExtractedInfo, isLoadingDeleteExtractedInfo } =
    useDeleteExtractedInfo();

  const isShouldUpdateExtraData =
    !isLoadingAddPassExtractInfo &&
    !isLoadingAddVisaExtractInfo &&
    !isLoadingAddI94ExtractInfo &&
    !isLoadingAddResumeExtractInfo &&
    !isLoadingDeleteExtractedInfo;

  // useEffect(() => {
  //   if (isShouldUpdateExtraData) {
  //     onSubmitGetExtractedData();
  //   }
  // }, [
  //   isLoadingAddPassExtractInfo,
  //   isLoadingAddVisaExtractInfo,
  //   isLoadingAddI94ExtractInfo,
  //   isLoadingAddResumeExtractInfo,
  //   isLoadingDeleteExtractedInfo
  // ]);

  // Passport
  const {
    isLoadingExtractData: isLoadingExtractPassportInfo,
    onSubmitExtractData: onSubmitExtractPassportInfo,
    apiResponseExtractData: apiResponseExtractPassportInfo
  } = useExtractData(documentsTypes.passport);

  // Resume
  const {
    isLoadingExtractData: isLoadingExtractResumeInfo,
    onSubmitExtractData: onSubmitExtractResumeInfo,
    apiResponseExtractData: apiResponseExtractResumeInfo
  } = useExtractData(documentsTypes.resume);

  // Visa
  const {
    isLoadingExtractData: isLoadingExtractVisaInfo,
    onSubmitExtractData: onSubmitExtractVisaInfo,
    apiResponseExtractData: apiResponseExtractVisaInfo
  } = useExtractData(documentsTypes.visa);

  // I94
  const {
    isLoadingExtractData: isLoadingExtractI94Info,
    onSubmitExtractData: onSubmitExtractI94Info,
    apiResponseExtractData: apiResponseExtractI94Info
  } = useExtractData(documentsTypes.i94);

  const handleExtract = () => {
    data.forEach((doc) => {
      if (
        doc?.documentTitle ??
        ("".toLocaleLowerCase().includes("passport") && doc.autoOCRText)
      ) {
        onSubmitExtractPassportInfo(doc.autoOCRText ?? "");
      }
      if (
        doc?.documentTitle ??
        ("".toLocaleLowerCase().includes("resume") && doc.autoOCRText)
      ) {
        onSubmitExtractResumeInfo(doc.autoOCRText ?? "");
      }
      if (
        doc?.documentTitle ??
        ("".toLocaleLowerCase().includes("visa") && doc.autoOCRText)
      ) {
        onSubmitExtractVisaInfo(doc.autoOCRText ?? "");
      }
      if (
        doc?.documentTitle ??
        ("".toLocaleLowerCase().includes("i-94") && doc.autoOCRText)
      ) {
        onSubmitExtractI94Info(doc.autoOCRText ?? "");
      }
    });
  };

  useEffect(() => {
    if (apiResponseExtractPassportInfo) {
      onSubmitAddPassExtractInfo({
        firstName: lawyerFirstName,
        lastName: lawyerLastName,
        passport: formatPassport(apiResponseExtractPassportInfo)
      });
    }
  }, [apiResponseExtractPassportInfo]);

  useEffect(() => {
    if (apiResponseExtractResumeInfo) {
      onSubmitAddResumeExtractInfo({
        firstName: lawyerFirstName,
        lastName: lawyerLastName,
        resume: {
          email: apiResponseExtractResumeInfo.email_address,
          lastRole: apiResponseExtractResumeInfo.last_role,
          lastEmployer: apiResponseExtractResumeInfo.last_employer,
          lastRoleStartDate: apiResponseExtractResumeInfo.last_role_start_date,
          lastRoleEndDate: apiResponseExtractResumeInfo.last_role_end_date || ""
        }
      });
    }
  }, [apiResponseExtractResumeInfo]);

  useEffect(() => {
    if (apiResponseExtractI94Info) {
      onSubmitAddI94ExtractInfo({
        firstName: lawyerFirstName,
        lastName: lawyerLastName,
        i94: {
          authorizedStayExpirationDate:
            apiResponseExtractI94Info.authorized_stay_expiration_date,
          classOfAdmission: apiResponseExtractI94Info.class_of_admission,
          i94RecordNumber: apiResponseExtractI94Info.i94_record_number,
          mostRecentDateOfEntry:
            apiResponseExtractI94Info.most_recent_date_of_entry
        }
      });
    }
  }, [apiResponseExtractI94Info]);

  useEffect(() => {
    if (apiResponseExtractVisaInfo) {
      onSubmitAddVisaExtractInfo({
        firstName: lawyerFirstName,
        lastName: lawyerLastName,
        visa: {
          controlNumber: apiResponseExtractVisaInfo.control_number,
          expirationDate: apiResponseExtractVisaInfo.expiration_date,
          firstName: apiResponseExtractVisaInfo.first_name,
          issueDate: apiResponseExtractVisaInfo.issue_date,
          lastName: apiResponseExtractVisaInfo.last_name,
          passportNumber: apiResponseExtractVisaInfo.passport_number,
          visaNumber: apiResponseExtractVisaInfo.visa_number || "",
          visaType: apiResponseExtractVisaInfo.visa_type
        }
      });
    }
  }, [apiResponseExtractVisaInfo]);

  const loading =
    isLoadingExtractPassportInfo ||
    isLoadingExtractResumeInfo ||
    isLoadingExtractVisaInfo ||
    isLoadingExtractI94Info;

  const statusMsg = useMemo(() => {
    return extractedData?.data.i94 &&
      extractedData?.data.passport &&
      extractedData?.data.resume &&
      extractedData?.data.visa
      ? "Finalized"
      : "In progress";
  }, [extractedData]);

  const handleSaveExpertLetter = async () => {
    if (apiResponse) {
      await onSubmitSaveExpertLetter({
        firstName: lawyerFirstName,
        lastName: lawyerLastName,
        expertLetter: apiResponse
      });
    }
  };

  useEffect(() => {
    if (apiResponse) {
      handleSaveExpertLetter();
    }
  }, [apiResponse]);

  const deleteExtractedInfo = async () => {
    await onDeleteExtractedInfo({
      firstName: lawyerFirstName,
      lastName: lawyerLastName
    });
  };

  const [isShowExpertLetter, setIsShowExpertLetter] = useState(false);

  const showExpertLetter = () => {
    setIsShowExpertLetter(!isShowExpertLetter);
  };

  const deleteExpertLetter = async () => {
    await onSubmitSaveExpertLetter({
      firstName: lawyerFirstName,
      lastName: lawyerLastName,
      expertLetter: ""
    });
  };

  const handleDeleteDocConfirm = () => {
    if (documentToDelete.includes("A.I")) {
      deleteExtractedInfo();
    }
    if (documentToDelete.includes("letter")) {
      deleteExpertLetter();
    }
    onClose();
    setDocumentToDelete("");
  };

  const handleEditExpertLetter = () => {
    if (extractedData?.expertLetter) {
      navigate(`/cases/${params.caseID}/expert-letter`);
    }
    showExpertLetter();
  };

  const handleDeleteExpertLetter = () => {
    if (extractedData?.expertLetter) {
      setDocumentToDelete("Expert letter");
      onOpen();
    }
  };

  return (
    <div>
      <Card mb="15px">
        <Box py="15px" sx={clickedStyles}>
          <Heading size="md">Extract all client information</Heading>
        </Box>

        <Card p="15px" m="15px">
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th />
                  <Th>Document</Th>
                  <Th>Date</Th>
                  <Th>Status</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>
                    <Checkbox isChecked={statusMsg === "Finalized"} />
                  </Td>
                  <Td>A.I extracted information</Td>
                  <Td>
                    {extractedData?.uploadedDate === 0
                      ? ""
                      : dayjs(extractedData?.uploadedDate).format("MM/DD/YYYY")}
                  </Td>
                  <Td>{statusMsg}</Td>
                  <Td>
                    <EditIcon
                      cursor="pointer"
                      onClick={() =>
                        navigate(
                          `/cases/${params.caseID}/extracted-information`
                        )
                      }
                    />
                    <DeleteIcon
                      cursor="pointer"
                      ml="15px"
                      onClick={() => {
                        setDocumentToDelete("A.I extracted information");
                        onOpen();
                      }}
                    />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Card>

        <Flex justifyContent="center" gap="25px" pb="15px">
          <Flex
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            gap="5px"
            mt="15px"
          >
            <Image
              boxSize="30px"
              objectFit="contain"
              src={images.generateCaseSummary}
              alt="Extract information"
            />
            <Button
              onClick={handleExtract}
              isLoading={loading || isLoadingDeleteExtractedInfo}
            >
              Extract
            </Button>
          </Flex>
        </Flex>
      </Card>

      <Card mb="15px">
        <Box py="15px" sx={clickedStyles}>
          {/* <Heading size="md"></Heading> */}
          <SpecialHeading title="Draft expert letter" />
        </Box>

        <Card p="15px" m="15px">
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th />
                  <Th>Document</Th>
                  <Th>uploaded by</Th>
                  <Th>Date</Th>
                  <Th>Status</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>
                    <Checkbox isChecked={!!extractedData?.expertLetter} />
                  </Td>
                  <Td>Expert letter</Td>
                  <Td>
                    {extractedData?.expertLetter
                      ? extractedData?.uploadedBy
                      : ""}
                  </Td>
                  <Td>
                    {extractedData?.expertLetter
                      ? dayjs(extractedData.uploadedDate).format("MM/DD/YYYY")
                      : ""}
                  </Td>
                  <Td>
                    {extractedData?.expertLetter
                      ? "Generated"
                      : "Need to generate"}
                  </Td>
                  <Td>
                    <EditIcon
                      onClick={handleEditExpertLetter}
                      cursor={extractedData?.expertLetter ? "pointer" : ""}
                    />
                    <DeleteIcon
                      cursor={extractedData?.expertLetter ? "pointer" : ""}
                      ml="15px"
                      onClick={handleDeleteExpertLetter}
                    />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Card>

        <Flex justifyContent="center" gap="25px" pb="15px">
          <Flex
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            gap="5px"
            mt="15px"
          >
            <Image
              boxSize="30px"
              objectFit="contain"
              src={images.draftLetters}
              alt="Draft letter"
            />
            <Button
              onClick={handleDraftLetter}
              isLoading={isLoadingDraftExtertLetter}
              disabled={!questionnaire}
            >
              Draft an expert letter
            </Button>
          </Flex>
        </Flex>
        <Box textAlign="left" p="15px" fontStyle="italic">
          Expert letter intakes must be completed by the client or yourself,
          before you can AI generate expert letters. Once generated, you and the
          client can edit the document.
        </Box>

        {extractedData?.expertLetter && isShowExpertLetter && (
          <Card m="15px" p="15px">
            <Box p="15px" textAlign="left">
              <Heading size="sm" mb="15px">
                Expert Letter:
              </Heading>
              {extractedData?.expertLetter.split("\n").map((p: string, i) => (
                <Box py="5px" key={i}>
                  {p}
                </Box>
              ))}
            </Box>
          </Card>
        )}
      </Card>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalCloseButton
            onClick={() => {
              onClose();
              setDocumentToDelete("");
            }}
          />
          <ModalBody pb={6}>
            Would you like to delete the extracted information?
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={() => {
                onClose();
                setDocumentToDelete("");
              }}
            >
              Cancel
            </Button>
            <Button colorScheme="red" ml={3} onClick={handleDeleteDocConfirm}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};
