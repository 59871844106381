import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react";

type AlreadyExtractedDialogProps = {
  onConfirm: () => void;
  type: string;
  isOpen: boolean;
  onClose: () => void;
  exhibits?: number[];
};

export const AlreadyExtractedDialog = ({
  exhibits,
  onConfirm,
  type,
  isOpen,
  onClose
}: AlreadyExtractedDialogProps) => {
  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Warning !</ModalHeader>
        <ModalCloseButton onClick={onClose} />
        <ModalBody pb={6}>
          {type === "standard" ? (
            <span>
              You already extracted information from documents. Would you like
              to replace the existing fields?
            </span>
          ) : (
            <span>
              You already extracted information for exhibit
              {exhibits!.length > 1 ? "s" : ""}
              {exhibits!.join(", ")}. Would you like to replace the existing
              fields?
            </span>
          )}
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button bg="primary.red" color="white" ml={3} onClick={onConfirm}>
            Start over
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
