import {
  Button,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from "@chakra-ui/react";
import { useState } from "react";
import { MdAddCircle } from "react-icons/md";

interface Props {
  title: string;
  onItemSelect: (value: any) => void;
  list: any[];
  selectedValue: any;
  isDisabled?: boolean;
}

const Dropdown = ({
  title,
  onItemSelect,
  list,
  selectedValue,
  isDisabled
}: Props) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredList = list.filter((item) => {
    if (title === "Expert" && item.expertName) {
      return item.expertName.toLowerCase().includes(searchTerm.toLowerCase());
    }
    if (typeof item === "object" && item.title) {
      return item.title.toLowerCase().includes(searchTerm.toLowerCase());
    }
    if (typeof item === "string") {
      return item.toLowerCase().includes(searchTerm.toLowerCase());
    }
    if (typeof item === "number") {
      return item.toString().includes(searchTerm);
    }
    return false;
  });

  const renderTitle = () => {
    if (typeof selectedValue === "object" && selectedValue !== null) {
      if (title === "Expert" && selectedValue.expertName) {
        return selectedValue.expertName;
      }
      return selectedValue.title || title;
    }
    return selectedValue || title;
  };

  const renderMenuItem = (item: any) => {
    if (typeof item === "object" && item !== null) {
      if (title === "Expert" && item.expertName) {
        return item.expertName;
      }
      return item.title;
    }
    return item;
  };

  return (
    <Menu>
      <MenuButton
        bg="primary.darkBlue"
        color="white"
        isDisabled={isDisabled}
        as={Button}
        leftIcon={<MdAddCircle />}
        variant="outline"
        className="w-fit overflow-auto"
      >
        {renderTitle()}
      </MenuButton>
      <MenuList
        px={2}
        maxHeight={300}
        overflowY="auto"
        overflowX="scroll"
        sx={{
          "&::-webkit-scrollbar": {
            width: "10px"
          },
          "&::-webkit-scrollbar-track": {
            background: "white"
          },
          "&::-webkit-scrollbar-thumb": {
            background: "gray",
            borderRadius: "8px"
          }
        }}
      >
        <Input
          autoFocus
          placeholder="Search..."
          variant="filled"
          onChange={(e) => setSearchTerm(e.target.value)}
          my={2}
        />
        {filteredList.map((item, index) => (
          <MenuItem
            key={index}
            autoFocus={false}
            onClick={() => onItemSelect(item)}
          >
            {renderMenuItem(item)}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default Dropdown;
