import { CheckCircleIcon } from "@chakra-ui/icons";
import { Box, Divider, List, ListItem, ListIcon, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { lawyerSelectors } from "../../redux/lawyer/selectors";

export const PaygoCard = () => {
  const { subscription } = useSelector(lawyerSelectors.selectAll);
  const isFreemium = subscription?.status === "freemium";
  return (
    <Box
      background="white"
      boxShadow="md"
      borderRadius="md"
      padding={8}
      width="full"
      maxWidth="md"
      textColor="#4D5962"
      fontWeight={500}
    >
      <Text
        color="primary.red"
        fontWeight="bold"
        textTransform="uppercase"
        className="text-sm"
        visibility={isFreemium ? "hidden" : "visible"}
      >
        Current Plan
      </Text>
      <Text
        fontSize="2xl"
        fontWeight="bold"
        marginBottom={4}
        color="primary.darkBlue"
      >
        Subscription
      </Text>
      <Divider my={2} />

      <List
        whiteSpace="nowrap"
        spacing={3}
        display="flex"
        flexDirection="column"
        justifyContent="start"
        alignItems="start"
      >
        <ListItem>
          <ListIcon as={CheckCircleIcon} color="primary.red" />
          <b>3 cases:</b> $500 per case
        </ListItem>
        <ListItem>
          <ListIcon as={CheckCircleIcon} color="primary.red" />
          <b>10 cases:</b> $400 per case
        </ListItem>
        <ListItem>
          <ListIcon as={CheckCircleIcon} color="primary.red" />
          <b>20 cases:</b> $300 per case
        </ListItem>
      </List>
    </Box>
  );
};
