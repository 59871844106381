import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Card,
  Flex,
  ListItem,
  Textarea,
  UnorderedList
} from "@chakra-ui/react";
import { KeyboardEventHandler, useEffect, useRef, useState } from "react";

export interface ExplanationForm {
  blockTitle: string;
  list: string[];
  setList: (v: string[]) => void;
  isActive: boolean;
  index: number;
  changeActiveBlock: (v: number) => void;
  visaTypes?: string[];
}

export const ExplanationFormBlock = ({
  blockTitle,
  list,
  setList,
  isActive,
  index,
  changeActiveBlock,
  visaTypes
}: ExplanationForm) => {
  const [listValue, setListValue] = useState<string>("");
  const [changingDataIndex, setChangingDataIndex] = useState<number | null>(
    null
  );
  const [changingListValue, setChangingListValue] = useState<string>("");

  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  // useEffect(() => {
  //   if (changingDataIndex === null && isActive) {
  //     inputRef.current?.focus();
  //   }
  // }, [changingDataIndex, isActive]);

  const addToList = (v: string) => setList([...list, v]);
  const changeListValue = (i: number, v: string) => {
    const newList = [...list];
    newList[i] = v;
    setList(newList);
  };
  const deleteItem = (i: number) => {
    const newList = [...list];
    newList.splice(i, 1);
    setList(newList);
    setChangingDataIndex(null);
  };

  const handleClickEnter: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    if (e.key === "Enter" && !!listValue.trim() && changingDataIndex === null) {
      addToList(listValue);
      setListValue("");
    }
    if (
      e.key === "Enter" &&
      !!changingListValue.trim() &&
      changingDataIndex !== null
    ) {
      changeListValue(changingDataIndex, changingListValue);
      setChangingDataIndex(null);
      setChangingListValue("");
    }
  };

  const handleOnBlur = () => {
    if (!!listValue.trim() && changingDataIndex === null) {
      addToList(listValue);
      setListValue("");
    }
    if (!!changingListValue.trim() && changingDataIndex !== null) {
      changeListValue(changingDataIndex, changingListValue);
      setChangingDataIndex(null);
      setChangingListValue("");
    }
  };

  return (
    <Card
      borderRadius={0}
      className="border-2"
      onClick={() => {
        if (!isActive) {
          changeActiveBlock(index);
        }
      }}
    >
      <Box
        color="primary.darkBlue"
        p="15px"
        width="100%"
        fontWeight="700"
        textAlign="left"
      >
        {blockTitle}
      </Box>
      <UnorderedList listStyleType="none" textAlign="left">
        {list.map((role, i) => (
          <ListItem key={i} my={15}>
            {changingDataIndex === i ? (
              <Flex>
                <Textarea
                  size="sm"
                  value={changingListValue}
                  onChange={(e) => setChangingListValue(e.target.value)}
                  onKeyDown={handleClickEnter}
                />
                <DeleteIcon
                  ml="5px"
                  cursor="pointer"
                  onClick={() => {
                    deleteItem(i);
                  }}
                />
              </Flex>
            ) : (
              <Box>
                <div
                  onDoubleClick={() => {
                    setChangingListValue(role);
                    setChangingDataIndex(i);
                  }}
                >
                  <span>{role}</span>
                </div>
              </Box>
            )}
          </ListItem>
        ))}
        {changingDataIndex === null && isActive && (
          <ListItem my={15}>
            <Textarea
              fontWeight={400}
              ref={inputRef}
              size="sm"
              value={listValue.trimStart()}
              onChange={(e) => setListValue(e.target.value)}
              onKeyDown={handleClickEnter}
              onBlur={handleOnBlur}
            />
          </ListItem>
        )}
      </UnorderedList>
    </Card>
  );
};
