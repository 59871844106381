import { Menu, MenuButton } from "@chakra-ui/react";
import { UserAvatar } from "./UserAvatar";
import { UserMenuItems } from "./UserMenuItems";

export const UserMenu = () => {
  return (
    <Menu>
      <MenuButton as={undefined}>
        <UserAvatar />
      </MenuButton>
      <UserMenuItems />
    </Menu>
  );
};
