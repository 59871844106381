import { useState } from "react";
import { reader } from "../helpers/file_helpers";

export const useConvertFileTo64String = () => {
  const [file64string, setFile64string] = useState<string | null>(null);

  const logImagesData = async (file: File) => {
    const frPromises = [reader(file)];
    try {
      const fileResults = await Promise.all(frPromises);

      fileResults.forEach((fr: any) => setFile64string(fr.result));
    } catch (err) {
      console.error(err);
    }
  };

  return { logImagesData, file64string };
};
