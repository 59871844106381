import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  useToast
} from "@chakra-ui/react";
import { debounce } from "lodash"; // Import lodash's debounce function
import { useCallback, useEffect, useRef, useState } from "react";
import { defaultStyles, FileIcon } from "react-file-icon";
import { FaSave } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { convertFromHtml } from "../../api/draftsApi";
import { triggerDownload } from "../../helpers/file_helpers";
import { useNavbar } from "../../hooks/useNavbarContext";
import "../../styles/reactquill.css";

type RichTextProps = {
  text: string;
  saveText: null | ((html: string, plainText: string) => void);
  updateText?: (html: string, plainText?: string) => void;
  shouldShowSaveBtn?: boolean | null;
  shouldPreserveWhitespace?: boolean;
  placeholder?: string | null;
  customHeight?: string;
};

export const RichTextEditor = ({
  text,
  saveText,
  updateText = () => {},
  shouldShowSaveBtn = true,
  shouldPreserveWhitespace = false,
  placeholder,
  customHeight = "80vh"
}: RichTextProps) => {
  const [parsedText, setParsedText] = useState<string>(text || "");
  const [parsedTextCopy, setParsedTextCopy] = useState<string>("");
  const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const quillRef = useRef<ReactQuill>(null);
  const { setPos } = useNavbar();
  const toast = useToast();

  useEffect(() => {
    setPos("relative");
    return () => {
      setPos("sticky");
    };
  }, []);

  const getText = () => {
    const editor = quillRef?.current?.getEditor(); // access the Quill editor
    const text = editor?.getText(); // get plain text, without HTML
    return text;
  };

  useEffect(() => {
    if (parsedTextCopy === "") {
      setParsedTextCopy(parsedText);
    } else if (parsedTextCopy !== parsedText) {
      setShouldUpdate(true);
      updateText(parsedText, getText() ?? "");
    } else {
      setShouldUpdate(false);
    }
  }, [parsedText]);

  // Applied a fix for cases where the user pastes text, as Quill's onChange event wasn't triggered.
  // Debounce the text change handler
  const handleTextChange = useCallback(
    debounce(() => {
      const editor = quillRef?.current?.getEditor();
      if (editor) {
        const text = editor.getText();
        setParsedText(editor.root.innerHTML);
        updateText(editor.root.innerHTML, text);
      }
    }, 300), // Delay of 300ms after user stops typing
    []
  );

  useEffect(() => {
    const editor = quillRef?.current?.getEditor();
    if (editor) {
      editor.on("text-change", handleTextChange);
    }

    return () => {
      if (editor) {
        editor.off("text-change", handleTextChange);
      }
    };
  }, [handleTextChange]);

  const handleDownload = async (format: string) => {
    setIsDownloading(true);
    try {
      const { data, filename } = await convertFromHtml(parsedText, format);
      triggerDownload(data, filename, format);
    } catch (error) {
      console.error(`Error generating ${format} file:`, error);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleSave = () => {
    const plainText = getText() ?? "";
    if (saveText !== null) {
      saveText(parsedText, plainText);
      setShouldUpdate(false);
      toast({
        title: "Text saved.",
        description: "Your changes have been saved successfully.",
        status: "info",
        duration: 3000,
        isClosable: true,
        position: "bottom-right"
      });
    }
  };

  useEffect(() => {
    setParsedText(text ?? "");
  }, [text]);

  return (
    <>
      <Box position="relative" height="100%">
        <ReactQuill
          placeholder={placeholder ?? ""}
          ref={quillRef}
          theme="snow"
          preserveWhitespace={shouldPreserveWhitespace}
          value={parsedText}
          onChange={(value) => setParsedText(value)}
          style={{
            height: customHeight,
            paddingBottom: "10px"
          }}
        />
        <Box
          position="absolute"
          top="4px"
          right="10px"
          zIndex="10"
          height="10px"
        >
          {isDownloading ? (
            <Spinner size="md" color="primary.darkBlue" />
          ) : (
            <Menu>
              <MenuButton
                as={Button}
                leftIcon={<FaSave />}
                backgroundColor="transparent"
                _hover={{ backgroundColor: "transparent" }}
                _active={{ backgroundColor: "transparent", boxShadow: "none" }}
                _focus={{ backgroundColor: "transparent", boxShadow: "none" }}
              >
                <Text fontSize={14}>Export and Save</Text>
              </MenuButton>
              <MenuList>
                <MenuItem
                  icon={
                    <Box boxSize="20px">
                      <FileIcon extension="docx" {...defaultStyles.docx} />
                    </Box>
                  }
                  onClick={() => handleDownload("docx")}
                >
                  DOCX
                </MenuItem>
                <MenuItem
                  icon={
                    <Box boxSize="20px">
                      <FileIcon extension="pdf" {...defaultStyles.pdf} />
                    </Box>
                  }
                  onClick={() => handleDownload("pdf")}
                >
                  PDF
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </Box>
      </Box>

      {shouldUpdate && shouldShowSaveBtn && (
        <Flex justifyContent="center" gap={8} mt="8">
          <Button
            className="btn"
            onClick={() => {
              setParsedText(parsedTextCopy);
            }}
          >
            Reset
          </Button>
          <Button className="btn secondary-btn" mr="2" onClick={handleSave}>
            Save
          </Button>
        </Flex>
      )}
    </>
  );
};
