// this code is following chakra styling conventions
// make sure to read the documents to understand
// the anatomy approach
// https://chakra-ui.com/docs/styled-system/component-style

import { formAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  formAnatomy.keys
);

const activeLabelStyles = {
  transform: "scale(1) translateY(-24px)",
  borderColor: "primary.darkBlue"
};

export const formTheme = defineMultiStyleConfig({
  // define the part you're going to style

  baseStyle: {
    container: {
      label: {
        color: "primary.darkBlue"
      }
    }
  },
  variants: {
    fixed: {
      container: {
        label: {
          color: "primary.darkBlue",
          borderColor: "primary.darkBlue"
        }
      }
    },
    floating: {
      container: {
        _focusWithin: {
          label: {
            ...activeLabelStyles,
            top: "-12px",
            left: 0
          }
        },
        "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
          {
            ...activeLabelStyles,
            top: "-12px",
            left: 0
          },
        label: {
          color: "primary.darkBlue",
          top: 0,
          left: 4,
          bottom: 2,
          zIndex: 2,
          position: "absolute",
          backgroundColor: "transparent",
          pointerEvents: "none",
          mx: 0,
          px: 0,
          my: 2,
          transformOrigin: "left top"
        }
      }
    }
  }
});
