import { Search2Icon, TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  chakra
} from "@chakra-ui/react";
import { UniqueIdentifier } from "@dnd-kit/core";
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ClientData, visaTypeToPath } from "../types/tables-data";

export type DataTableProps<Data extends object> = {
  data: Data[];
  columns: ColumnDef<Data, any>[];
  isSortable?: boolean;
  sortBy?: string;
};

export function DataTable<Data extends ClientData>({
  data,
  columns,
  isSortable = false,
  sortBy
}: DataTableProps<Data>) {
  const params = useParams();
  const dispatch = useDispatch();
  const [sorting, setSorting] = useState<SortingState>([
    { id: sortBy ?? "createdDate", desc: true }
  ]);
  const [data2, setData2] = useState(() => data);
  const [filter, setFilter] = useState("");
  const table = useReactTable({
    columns,
    data: data2,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getRowId: (row) => row.id,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      ...(isSortable && { sorting }),
      globalFilter: filter,
      columnVisibility: {
        id: false
      }
    },
    onGlobalFilterChange: setFilter
  });
  const navigate = useNavigate();
  const location = useLocation();
  const dataIds = useMemo<UniqueIdentifier[]>(
    () => data2?.map(({ id }) => id),
    [data2]
  );

  // Reset sorting and filter when navigating back from a detail page
  useEffect(() => {
    if (location.state?.from === "/cases") {
      setSorting([]);
      setFilter("");
    }
  }, [location.state]);

  return (
    <Box>
      <InputGroup my="15px" bg="white">
        <InputLeftElement pointerEvents="none">
          <Search2Icon color="gray.300" />
        </InputLeftElement>
        <Input
          type="search"
          size="md"
          placeholder="Search a case"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </InputGroup>
      <Table
        variant="striped"
        className="bg-white border border-white overflow-scroll"
      >
        <Thead bg="white" className="bg-white">
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const { meta } = header.column.columnDef;
                return (
                  <Th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    isNumeric={meta?.isNumeric}
                    textAlign="center"
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    <chakra.span pl={header.column.getIsSorted() ? "1px" : "0"}>
                      {header.column.getIsSorted() &&
                        (header.column.getIsSorted() === "desc" ? (
                          <TriangleDownIcon aria-label="sorted descending" />
                        ) : (
                          <TriangleUpIcon aria-label="sorted ascending" />
                        ))}
                    </chakra.span>
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr
              key={row.id}
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (!params.caseID && location.pathname === "/cases") {
                  const visaPath = row.original.visa
                    ? visaTypeToPath[row.original.visa] || row.original.visa
                    : "";
                  const path = visaPath
                    ? `/individual/${row.original.id}/${visaPath}/documents/standard-documents`
                    : `/individual/${row.original.id}`;
                  // Ensure we're setting the state to track where we navigated from
                  navigate(path, { state: { from: "/cases" } });
                }
              }}
            >
              {row.getVisibleCells().map((cell) => {
                const { meta } = cell.column.columnDef;
                return (
                  <Td
                    key={cell.id}
                    isNumeric={meta?.isNumeric}
                    textAlign="center"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                );
              })}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}
