import { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Text
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "../components/Header";
import { Sidebar } from "../components/Sidebar/Sidebar";
import { clickedStyles } from "../styles/common-styles";
import { addQuestionnaireSecond } from "../redux/client-case/activeClientSlice";
import { activeClientSelectors } from "../redux/client-case/selectors";
import { useUpdateFormsData } from "../hooks/useUpdateFormsData";

export const ClientFormStepTwo = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const secondStepData = useSelector(
    activeClientSelectors.selectQuestionnaireSecond
  );
  const clientId = useSelector(activeClientSelectors.selectId);
  const clientFirstName = useSelector(activeClientSelectors.selectFirstName);
  const clientLastName = useSelector(activeClientSelectors.selectLastName);

  const { onSubmitUpdateFormsData } = useUpdateFormsData();

  const formik = useFormik({
    initialValues: {
      cityOfBirth: secondStepData?.cityOfBirth || "",
      stateOfBirth: secondStepData?.stateOfBirth || "",
      countryOfBirth: secondStepData?.countryOfBirth || "",
      nationality: secondStepData?.nationality || "",
      alienNumber: secondStepData?.alienNumber || "",
      ssn: secondStepData?.ssn || ""
    },

    onSubmit: (values) => {
      const secondStepValues = {
        ...values,
        uploadDate: Date.now(),
        uploadedByUid: clientId,
        uploadedByName: `${clientFirstName} ${clientLastName}`
      };
      dispatch(addQuestionnaireSecond(secondStepValues));
      onSubmitUpdateFormsData({
        "questionnaires.secondStep": secondStepValues
      });
      navigate(`/questionnaire/${clientId}/forms-step-3`);
    }
  });

  return (
    <>
      <Header />
      <Flex justifyContent="space-between" flexDirection="row" mt="30px">
        <Sidebar
          closeSidebar={() => setShowSidebar(false)}
          showSidebar={showSidebar}
        />

        <Flex alignItems="center" flexDirection="column" flex="1">
          <Card align="center" width="90%">
            <CardHeader sx={clickedStyles} width="100%">
              <Flex alignItems="center">
                <Button
                  type="button"
                  w="40px"
                  h="40px"
                  borderRadius="50%"
                  bgColor="transparent"
                  border="2px solid #000"
                  _hover={{
                    bgColor: "blue.500",
                    border: "2px solid transparent",
                    color: "#fff"
                  }}
                  onClick={() => navigate(-1)}
                  mx="15px"
                >
                  <ArrowBackIcon w={6} h={6} />
                </Button>
                <Heading size="md" flex="1">
                  Forms questionnaire - Step 2
                </Heading>
              </Flex>
            </CardHeader>

            <Box p="15px" gap="50px" w="60%">
              <Text fontSize="22px" fontWeight="700">
                Other information
              </Text>
            </Box>

            <Box p="15px" gap="50px" w="60%">
              <form onSubmit={formik.handleSubmit}>
                <FormControl my="30px" variant="floating" id="cityOfBirth">
                  <Input
                    placeholder=" "
                    name="cityOfBirth"
                    type="text"
                    value={formik.values.cityOfBirth}
                    onChange={formik.handleChange}
                  />
                  <FormLabel>City/town of birth</FormLabel>
                </FormControl>
                <FormControl my="30px" variant="floating" id="stateOfBirth">
                  <Input
                    placeholder=" "
                    name="stateOfBirth"
                    type="text"
                    value={formik.values.stateOfBirth}
                    onChange={formik.handleChange}
                  />
                  <FormLabel>State / province of birth</FormLabel>
                </FormControl>

                <FormControl my="30px" variant="floating" id="countryOfBirth">
                  <Input
                    placeholder=" "
                    name="countryOfBirth"
                    type="text"
                    value={formik.values.countryOfBirth}
                    onChange={formik.handleChange}
                  />
                  <FormLabel>Country of birth</FormLabel>
                </FormControl>
                <FormControl my="30px" variant="floating" id="nationality">
                  <Input
                    placeholder=" "
                    name="nationality"
                    type="text"
                    value={formik.values.nationality}
                    onChange={formik.handleChange}
                  />
                  <FormLabel>Country of citizenship or nationality</FormLabel>
                </FormControl>
                <FormControl my="30px" id="alienNumber">
                  <FormLabel pl="16px" fontSize="14px" mb="0">
                    Alien registration number if any
                  </FormLabel>
                  <InputGroup>
                    <InputLeftAddon>A -</InputLeftAddon>
                    <Input
                      placeholder=" "
                      name="alienNumber"
                      type="text"
                      value={formik.values.alienNumber}
                      onChange={formik.handleChange}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl my="30px" variant="floating" id="ssn">
                  <Input
                    placeholder=" "
                    name="ssn"
                    type="text"
                    value={formik.values.ssn}
                    onChange={formik.handleChange}
                  />
                  <FormLabel>US SSN if any</FormLabel>
                </FormControl>

                <Flex m="30px">
                  <Button
                    type="button"
                    onClick={() => navigate(-1)}
                    mx="15px"
                    flex="1"
                  >
                    Cancel
                  </Button>
                  <Button
                    bg="bgColor.300"
                    color="text.100"
                    mx="15px"
                    type="submit"
                    flex="1"
                  >
                    Next
                  </Button>
                </Flex>
              </form>
            </Box>
          </Card>
        </Flex>
      </Flex>
    </>
  );
};
