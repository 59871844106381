import { useState, useEffect } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { db } from "../api/firebaseApi";
import { DATABASE } from "../types/tables-data";
import {
  ExpertLetter,
  FirstForm,
  SecondForm
} from "../redux/clients/clientsSlice";

export interface FormsData {
  expertLetter: ExpertLetter | null;
  questionnaires: {
    firstStep?: FirstForm;
    secondStep?: SecondForm;
  } | null;
}

export const useGetFormsData = () => {
  const [isLoadingGetFormsData, setIsLoadingGetFormsData] = useState(true);
  const [formsData, setFormsData] = useState<FormsData | null>(null);
  const [isFormsNotFound, setIsFormsNotFound] = useState(false);
  const params = useParams();

  useEffect(() => {
    if (!params.caseID) {
      return () => {}; // Return a no-op cleanup function
    }
  
    const ref = doc(db, DATABASE.FORMS, `${params.id ?? params.caseID}`);
    const unsubscribe = onSnapshot(
      ref,
      (docSnap) => {
        if (docSnap.exists()) {
          setFormsData({
            expertLetter: docSnap.data().expertLetter || null,
            questionnaires: docSnap.data().questionnaires || null
          });
          setIsFormsNotFound(false);
        } else {
          setIsFormsNotFound(true);
          setFormsData(null);
        }
        setIsLoadingGetFormsData(false);
      },
      (error) => {
        console.error("Error fetching forms data: ", error);
        setIsLoadingGetFormsData(false);
      }
    );
  
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [params.caseID, params.id]); // Include dependencies in the dependency array
  

  return {
    isLoadingGetFormsData,
    formsData,
    isFormsNotFound
  };
};
