import {
  Text,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from "@chakra-ui/react";

type Props = {
  isOpen: boolean;
  onConfirm: () => void;
  setShowConfirmationDialog: (value: boolean) => void;
  isLoading: boolean;
  jobType: "extracted" | "generated";
  jobName: "Legal Brief" | "Case Research";
};

export const ReplaceDialog = ({
  isOpen,
  onConfirm,
  setShowConfirmationDialog,
  isLoading,
  jobType,
  jobName
}: Props) => {
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={() => setShowConfirmationDialog(false)}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Warning !</ModalHeader>
        <ModalCloseButton
          onClick={() => {
            setShowConfirmationDialog(false);
          }}
        />
        <ModalBody pb={6}>
          <Text>
            You already {jobType} a <b>{jobName}</b>. Would you like to replace
            it?
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={() => {
              setShowConfirmationDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button
            colorScheme="red"
            ml={3}
            onClick={() => {
              onConfirm();
            }}
            isLoading={isLoading}
          >
            Replace
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
