import { useState } from "react";
import { extractFromText, summarizeResume } from "../api/OpenAIApi";
import { ExtractedPassport } from "../types/extracted-passport";

export const useExtractData = (documentType?: string, ocrData?: string) => {
  const [isLoadingExtractData, setIsLoadingExtractData] = useState(false);
  const [apiResponseExtractData, setApiResponseExtractData] = useState<
    any | null | ExtractedPassport
  >(null);
  const [summarizedResumeResponse, setSummarizedResumeResponse] = useState<
    any | null | any
  >(null);

  const onSubmitExtractData = async (ocrData: string): Promise<any> => {
    console.log("Extracting data from useExtractData");

    setIsLoadingExtractData(true);

    if (documentType && ocrData)
      try {
        const extractedInfo = await extractFromText(ocrData, documentType);
        if (extractedInfo) {
          setApiResponseExtractData(extractedInfo);
          return extractedInfo;
        }
      } catch (error) {
        console.error("Something went wrong:", error);
      } finally {
        setIsLoadingExtractData(false);
      }
    return null;
  };
  return {
    isLoadingExtractData,
    onSubmitExtractData,
    summarizedResumeResponse,
    apiResponseExtractData
  };
};
