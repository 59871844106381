import { Flex, Box, Text, Spinner, Heading } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Header } from "../components/Header";
import { Sidebar } from "../components/Sidebar/Sidebar";
import { DataTable } from "../components/DataTable";
import { ClientData } from "../types/tables-data";
import { clickedStyles } from "../styles/common-styles";
import { useGetClientsData } from "../hooks/useGetClientsData";
import HamburgerMenu from "../components/HamburgerMenu";
import { ClientState } from "../redux/clients/clientsSlice";

export const ArchivedCases = () => {
  const [showSidebar, setShowSidebar] = useState(false);

  const { onSubmitGetClientsData, isLoadingGetClientsData, clientsData } =
    useGetClientsData();

  const getClientsData = async () => {
    try {
      await onSubmitGetClientsData(false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getClientsData();
  }, []);

  const columnHelper = createColumnHelper<ClientState>();
  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: "Name"
    }),
    columnHelper.accessor("birthDay", {
      cell: (info) => dayjs(info.getValue()).format("MM/DD/YYYY"),
      header: "DoB",
      meta: {
        isNumeric: true
      }
    }),
    columnHelper.accessor("visa", {
      cell: (info) => info.getValue(),
      header: "Visa"
    }),
    columnHelper.accessor("citizenship", {
      cell: (info) => info.getValue() || "-",
      header: "Citizenship"
    }),
    columnHelper.accessor("createdDate", {
      cell: (info) => dayjs(info.getValue()).format("MM/DD/YYYY"),
      header: "Creation Date",
      meta: {
        isNumeric: true
      }
    }),
    columnHelper.accessor("status", {
      cell: (info) => `Step ${info.getValue()}`,
      header: "Status"
    }),
    columnHelper.accessor("caseId", {
      cell: (info) => info.getValue(),
      header: "Case ID",
      meta: {
        isNumeric: true
      }
    }),
    columnHelper.accessor("priority", {
      cell: (info) => info.getValue(),
      header: "Priority"
    }),
    columnHelper.accessor("actionNeeded", {
      cell: (info) => (info.getValue() ? "Yes" : "No"),
      header: "Action needed"
    })
  ];

  return (
    <>
      <Header />
      <Flex justifyContent="space-between" flexDirection="row" mt="30px">
        <HamburgerMenu />
        <Sidebar
          closeSidebar={() => setShowSidebar(false)}
          showSidebar={showSidebar}
        />
        <Flex alignItems="center" flexDirection="column" flex="1" mx="15px">
          <Box py="15px" width="100%" sx={clickedStyles}>
            <Text fontSize="xl" width="100%" fontWeight={700}>
              Archived cases
            </Text>
          </Box>

          {clientsData.length === 0 && isLoadingGetClientsData && (
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          )}
          {clientsData.length !== 0 && !isLoadingGetClientsData && (
            <DataTable columns={columns} data={clientsData} />
          )}
          {clientsData.length === 0 && !isLoadingGetClientsData && (
            <Heading size="sm" my="15px">
              No archived cases found
            </Heading>
          )}
        </Flex>
      </Flex>
    </>
  );
};
