import { useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { db } from "../api/firebaseApi";
import { DATABASE, DataDocs } from "../types/tables-data";

type DBData = {
  id: string;
  documents: DataDocs;
};

export const useGetClientDocuments = () => {
  const [isLoadingGetClientDocs, setIsLoadingGetClientDocs] = useState(false);
  const [dbDocuments, setDBDocuments] = useState<DBData[]>();

  const params = useParams();

  const onSubmitGetClientDocs = async () => {
    const documentsRef = collection(
      db,
      DATABASE.DOCUMENTS,
      `${params.caseID}`,
      "docs"
    );
    setIsLoadingGetClientDocs(true);
    try {
      const querySnapshot = await getDocs(documentsRef);
      const docsData: DBData[] = [];

      querySnapshot.forEach((doc) => {
        docsData.push({
          id: doc.id,
          documents: {
            documentTitle: doc.data().documentTitle,
            uploadDate: doc.data().uploadDate,
            uploadBy: doc.data().uploadBy,
            isRequireForForms: doc.data().isRequireForForms,
            isRequireForDraft: doc.data().isRequireForDraft,
            docNames: doc.data().docNames || "",
            docUrls: doc.data().docUrls || "",
            text: doc.data().text || ""
          }
        });
      });
      setDBDocuments(docsData);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingGetClientDocs(false);
    }
  };

  return {
    isLoadingGetClientDocs,
    dbDocuments,
    onSubmitGetClientDocs
  };
};
