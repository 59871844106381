import {
  Box,
  Button,
  Card,
  Flex,
  Heading,
  Image,
  Text
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { clickedStyles } from "../../styles/common-styles";
import { activeClientSelectors } from "../../redux/client-case/selectors";
import images from "../../assets/image-export-assets";

export const ClientOverview = () => {
  const { steps } = useSelector(activeClientSelectors.selectOverview);
  const lawyerId = useSelector(activeClientSelectors.selectLawyerId);
  const lawyerEmail = useSelector(activeClientSelectors.selectLawyerEmail);
  return (
    <>
      <Card align="center" ml="0" mb="55px">
        <Box py="15px" sx={clickedStyles} width="100%">
          <Heading size="md" textAlign="center">
            Welcome!
          </Heading>
        </Box>

        <Text textAlign="left" p="15px" w="100%">
          You can use this overview page to track your progress.
        </Text>
      </Card>
      <Card align="center" ml="0">
        <Box py="15px" width="100%" sx={clickedStyles}>
          <Heading size="md" textAlign="center">
            Case steps
          </Heading>
        </Box>
        <Flex gap="15px" p="15px" flexWrap="wrap">
          {steps.length ? (
            steps.map((step, i) => (
              <Card key={i} p="15px" alignItems="center" w="200px" gap="10px">
                {step.isComplete ? (
                  <CheckCircleIcon color="blue.500" w="40px" h="40px" />
                ) : (
                  <Flex
                    w="40px"
                    h="40px"
                    borderRadius="50%"
                    bgColor="#fff"
                    border="2px solid"
                    borderColor="blue.500"
                    justifyContent="center"
                    alignItems="center"
                    fontSize="20px"
                    color="blue.500"
                    fontWeight="700"
                  >
                    {i + 1}
                  </Flex>
                )}
                <Text>Step {i + 1}</Text>
                <Text>{step.description}</Text>
              </Card>
            ))
          ) : (
            <Text>No steps</Text>
          )}
        </Flex>

        {lawyerId && (
          <Flex justifyContent="center" gap="25px" pb="15px">
            <Flex
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              gap="5px"
              mt="15px"
            >
              <Image
                boxSize="30px"
                objectFit="contain"
                src={images.emailClient}
                alt="Email lawyer"
              />
              <Button>
                <a href={`mailto:${lawyerEmail}`}>Email lawyer</a>
              </Button>
            </Flex>
          </Flex>
        )}
      </Card>
    </>
  );
};
