import { Flex, IconButton, Link, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { FunctionComponent } from "react";
import { CaseResearchTopic } from "../../../redux/case-research/caseResearchSlice";


interface Props {
    topics: CaseResearchTopic[];
    onDelete: (topicTitle: string, topicId: string) => void;
}

const CaseResearchDatatable: FunctionComponent<Props> = ({ topics, onDelete }) => {
    return (
        <TableContainer className="w-full">
            <Table variant="simple" __css={{ wordWrap: "break-word", whiteSpace: "normal", width: "100%" }}>
                <Thead>
                    <Tr>
                        <Th>Research topic</Th>
                        <Th>Source</Th>
                        {/* <Th>Originating reference</Th> */}
                        <Th />
                    </Tr>
                </Thead>
                <Tbody>
                    {topics && topics.map(topic => (
                        <Tr key={topic.id}>
                            <Td>{topic.topicTitle}</Td>
                            <Td>
                                <ul>
                                    {topic.sources && topic.sources.map((source, index) => (
                                        <li key={index}>
                                            <Link href={source} target="_blank" rel="noopener noreferrer" textDecoration="underline" cursor="pointer">
                                                Source {index + 1}
                                            </Link>
                                            { 
                                                (index < topic.sources.length - 1 ) && ","
                                            }
                                        </li>
                                    ))}
                                </ul>
                            </Td>
                            {/* <Td>
                            { topic?.reference?.type && 
                                <Link href="unkown" target="_blank" rel="noopener noreferrer" textDecoration="underline" cursor="pointer">
                                    {topic?.reference?.type} ({topic.reference?.uid})
                                </Link>
                            }
                            </Td> */}
                            <Td>
                                <Flex justifyContent="flex-end">
                                    <IconButton aria-label="Delete" icon={<DeleteIcon cursor="pointer" />} 
                                    onClick={() => onDelete(topic.topicTitle, topic.id)}
                                    />
                                </Flex>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </TableContainer>
    );
}

export default CaseResearchDatatable;
