import { ActiveClientReducer } from "./activeClientSlice";

const selectActiveClient = (state: ActiveClientReducer) => state.activeClient;
const selectFirstName = (state: ActiveClientReducer) =>
  selectActiveClient(state).firstName;
const selectLastName = (state: ActiveClientReducer) =>
  selectActiveClient(state).lastName;
const selectEmail = (state: ActiveClientReducer) =>
  selectActiveClient(state).email;
const selectLawyerId = (state: ActiveClientReducer) =>
  selectActiveClient(state).lawyerId;
const selectVisa = (state: ActiveClientReducer) =>
  selectActiveClient(state).visa;
const selectAddress = (state: ActiveClientReducer) =>
  selectActiveClient(state).address;
const selectBirthDay = (state: ActiveClientReducer) =>
  selectActiveClient(state).birthDay;
const selectCitizenship = (state: ActiveClientReducer) =>
  selectActiveClient(state).citizenship;
const selectEmployer = (state: ActiveClientReducer) =>
  selectActiveClient(state).employer;
const selectId = (state: ActiveClientReducer) => selectActiveClient(state).id;
const selectOverview = (state: ActiveClientReducer) =>
  selectActiveClient(state).overview;
const selectDocuments = (state: ActiveClientReducer) =>
  selectActiveClient(state).documents;
const selectLawyerEmail = (state: ActiveClientReducer) =>
  selectActiveClient(state).lawyerEmail;
const selectQuestionnaireFirst = (state: ActiveClientReducer) =>
  selectActiveClient(state).questionnaireFirst;
const selectQuestionnaireSecond = (state: ActiveClientReducer) =>
  selectActiveClient(state).questionnaireSecond;
const selectQuestionnaireExpertLetter = (state: ActiveClientReducer) =>
  selectActiveClient(state).questionnaireLetter;
const selectAvatarSrc = (state: ActiveClientReducer) =>
  selectActiveClient(state).avatarSrc;
const selectAvatarName = (state: ActiveClientReducer) =>
  selectActiveClient(state).avatarName;

export const activeClientSelectors = {
  selectActiveClient,
  selectFirstName,
  selectLastName,
  selectEmail,
  selectLawyerId,
  selectVisa,
  selectAddress,
  selectBirthDay,
  selectCitizenship,
  selectEmployer,
  selectId,
  selectOverview,
  selectDocuments,
  selectLawyerEmail,
  selectQuestionnaireFirst,
  selectQuestionnaireSecond,
  selectQuestionnaireExpertLetter,
  selectAvatarSrc,
  selectAvatarName
};
