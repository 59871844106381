import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  Text
} from "@chakra-ui/react";
import { getDownloadURL } from "firebase/storage";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as LinkDom, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import YupPassword from "yup-password";
import { auth } from "../api/firebaseApi";
import images from "../assets/image-export-assets";
import { DomainFilter } from "../components/DomainFilter";
import { Header } from "../components/Header";
import { URLS } from "../constans";
import { getSubdomain, setupIntercom } from "../helpers/helpers";
import { useGetFilesFromDB } from "../hooks/useGetFilesFromDB";
import { useGetIndividualData } from "../hooks/useGetIndividualData";
import { useGetLawyerData } from "../hooks/useGetLawyerData";
import { useGoogleSignIn } from "../hooks/useGoogleSignIn";
import { useLogOut } from "../hooks/useLogOut";
import { useLogin } from "../hooks/useLogin";
import { updateActiveClientEmail } from "../redux/client-case/activeClientSlice";
import {
  setIndividualData,
  updateIndividualAvatar
} from "../redux/individual/individualSlice";
import { individualSelectors } from "../redux/individual/selectors";
import { setLawyerData, updateLawyerAvatar } from "../redux/lawyer/lawyerSlice";
import { lawyerSelectors } from "../redux/lawyer/selectors";
import { clickedStyles } from "../styles/common-styles";
import { DATABASE, visaTypeToPath } from "../types/tables-data";

export const Login = () => {
  const [userRole, setUserRole] = useState("lawyer"); // Default to lawyer login
  const [isWrongPassword, setIsWrongPassword] = useState(false);
  const [isManyRequests, setIsManyRequests] = useState(false);
  const [isNoUserFound, setIsNoUserFound] = useState(false);
  const [sentPassword, setSentPassword] = useState("");
  const [signupPath, setSignupPath] = useState("/signup"); // Default to lawyer signup
  const dispatch = useDispatch();
  const location = useLocation();
  const previousPage = location.state?.from || null;
  const { customToken, customRole } = location.state || {};
  const subdomain = getSubdomain();
  const navigate = useNavigate();
  const visaType = useSelector(individualSelectors.selectVisaType);
  const email =
    userRole === "lawyer"
      ? useSelector(lawyerSelectors.selectEmail)
      : useSelector(individualSelectors.selectEmail);
  const uid =
    userRole === "lawyer"
      ? useSelector(lawyerSelectors.selectUid)
      : useSelector(individualSelectors.selectUid);
  const avatarSrc =
    userRole === "lawyer"
      ? useSelector(lawyerSelectors.selectAvatarSrc)
      : useSelector(individualSelectors.selectAvatarSrc);
  const hasAcceptedTerms =
    userRole === "lawyer"
      ? useSelector(lawyerSelectors.selectHasAcceptedTerms)
      : useSelector(individualSelectors.selectHasAcceptedTerms);
  const { onSubmitGetFiles, storageFiles, isLoadingGetFiles } =
    useGetFilesFromDB();

  const { onSubmitLogin, user, errorLogin, isLoadingLogin, loginAs } =
    useLogin();
  const { onSubmitGoogleSignIn, userGoogle } = useGoogleSignIn();

  const { onSubmitLogOut } = useLogOut();

  const { onSubmitGetLawyerData, fullLawyerData, isNoLawyerData } =
    useGetLawyerData();

  const { onSubmitGetIndividualData, individualData, isNoIndividualData } =
    useGetIndividualData();

  let errorMessage = null;

  if (isWrongPassword) {
    errorMessage = "Incorrect password";
  } else if (isNoUserFound) {
    errorMessage = "There is no user with this e-mail.";
  } else if (isManyRequests) {
    errorMessage =
      "Access to this account has been temporarily disabled due to many failed login attempts. Please restore it by resetting your password or try again later.";
  } else if (isNoLawyerData && userRole === "lawyer") {
    errorMessage = "There is no lawyer data. Please try another login type.";
  } else if (isNoIndividualData && userRole === "individual") {
    errorMessage =
      "There is no individual data. Please try another login type.";
  }

  useEffect(() => {
    const signIn = async () => {
      if (customToken && !loginAs) {
        try {
          onSubmitLogin("blah", "blah", customToken, customRole);
        } catch (error) {
          console.error("Error signing in with custom token:", error);
        }
      }
    };

    signIn();
  }, [customToken]);

  useEffect(() => {
    if (
      !isLoadingLogin &&
      !isLoadingGetFiles &&
      storageFiles.length &&
      uid &&
      avatarSrc === ""
    ) {
      storageFiles.forEach((item) => {
        getDownloadURL(item).then((url) => {
          dispatch(
            userRole === "lawyer"
              ? updateLawyerAvatar({ avatarSrc: url, avatarName: item.name })
              : updateIndividualAvatar({
                  avatarSrc: url,
                  avatarName: item.name
                })
          );
        });
      });
    } else if (
      !isLoadingLogin &&
      !isLoadingGetFiles &&
      storageFiles.length === 0 &&
      uid &&
      avatarSrc === ""
    ) {
      dispatch(
        userRole === "lawyer"
          ? updateLawyerAvatar({ avatarSrc: "/", avatarName: "" })
          : updateIndividualAvatar({ avatarSrc: "/", avatarName: "" })
      );
    }
  }, [storageFiles, uid, isLoadingLogin, isLoadingGetFiles]);

  const [currentUser, setCurrentUser] = useState(null);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user: any) => {
      if (user) {
        // User is signed in.
        setCurrentUser(user);
      } else {
        // No user is signed in.
        setCurrentUser(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (avatarSrc && previousPage) {
      navigate(`${previousPage}`);
    } else if (user && email && avatarSrc && userRole === "lawyer") {
      setupIntercom(email, uid);

      if (hasAcceptedTerms) {
        navigate("/cases");
      } else {
        navigate("/accept-agreement");
      }
    } else if (
      user?.uid &&
      currentUser &&
      email &&
      avatarSrc &&
      visaType &&
      userRole === "individual"
    ) {
      setupIntercom(email, user?.uid);
      navigate(`/individual/${user?.uid}/${visaTypeToPath[visaType]}`);
    }
  }, [email, avatarSrc, visaType]);

  const getAllData = async () => {
    console.log("running getallData");
    console.log(user);
    if (user && user.uid && userRole === "lawyer") {
      console.log("getting data for lawyer");
      console.log(user.uid);
      await onSubmitGetLawyerData(user.uid);
      await onSubmitGetFiles(`${DATABASE.LAWYERS}/avatars/${user.uid}/`);
    }
    if (userGoogle && userGoogle.uid && userRole === "lawyer") {
      await onSubmitGetLawyerData(userGoogle.uid);
      await onSubmitGetFiles(`${DATABASE.LAWYERS}/avatars/${userGoogle.uid}/`);
    }
    if (user && user.uid && userRole === "individual") {
      await onSubmitGetIndividualData(user.uid);
      await onSubmitGetFiles(`${DATABASE.INDIVIDUALS}/avatars/${user.uid}/`);
    }
    if (userGoogle && userGoogle.uid && userRole === "individual") {
      await onSubmitGetIndividualData(userGoogle.uid);
      await onSubmitGetFiles(
        `${DATABASE.INDIVIDUALS}/avatars/${userGoogle.uid}/`
      );
    }
  };

  useEffect(() => {
    if (fullLawyerData && userRole === "lawyer") {
      dispatch(setLawyerData(fullLawyerData));
    }
  }, [fullLawyerData]);

  useEffect(() => {
    if (individualData && userRole === "individual") {
      dispatch(setIndividualData(individualData));
    }
  }, [individualData]);

  useEffect(() => {
    // Check if there is no current user or if there's missing data for the specific role
    if (
      !currentUser ||
      (isNoLawyerData && userRole === "lawyer") ||
      (isNoIndividualData && userRole === "individual")
    ) {
      onSubmitLogOut();
    }
  }, [currentUser, isNoLawyerData, isNoIndividualData, userRole]);

  const handlerLogIn = async (email: string, password: string) => {
    setSentPassword(password);
    await onSubmitLogin(email, password);
  };

  YupPassword(Yup);

  const { values, handleSubmit, touched, errors, handleChange, handleBlur } =
    useFormik({
      initialValues: {
        email: "",
        password: ""
      },
      validationSchema: Yup.object({
        email: Yup.string()
          .email("Invalid email address")
          .required("Email is required"),
        password: Yup.string()
          .required("Password is required")
          .min(6, "Password must be at least 6 characters")
      }),
      onSubmit: ({ email, password }) => handlerLogIn(email, password),
      validateOnChange: false, // Disable validation on change
      validateOnBlur: false // Disable validation on blur
    });

  useEffect(() => {
    if (
      errorLogin === "auth/wrong-password" ||
      errorLogin === "auth/invalid-login-credentials"
    ) {
      setIsWrongPassword(true);
    }
    if (errorLogin === "auth/too-many-requests") {
      setIsWrongPassword(false);
      setIsManyRequests(true);
    }
    if (errorLogin === "auth/user-not-found") {
      setIsNoUserFound(true);
    }
  }, [errorLogin]);

  /*
  useEffect(() => {
    async function fetchData() {
      if (
        user &&
        user.uid &&
        (userRole === "lawyer" || userRole === "individual")
      ) {
        getAllData();
      }
      if (
        userGoogle &&
        userGoogle.uid &&
        (userRole === "lawyer" || userRole === "individual")
      ) {
        getAllData();
      }
      if (user && user.uid && userRole === "client") {
        dispatch(updateActiveClientEmail(values.email));
        await onSubmitGetClientsData(values.email);
        if (isNoClientData)
          errorMessage =
            "There is no client data. Please try another login type.";
        else navigate("/client", { state: "login" });
      }
      if (
        userGoogle &&
        userGoogle.uid &&
        userRole === "lawyer" &&
        userGoogle.email
      ) {
        dispatch(updateActiveClientEmail(userGoogle.email));
        navigate("/client");
      }
    }
    fetchData();
  }, [user, userGoogle]);
*/

  useEffect(() => {
    console.log("user change");
    console.log(user);
    if (user) {
      console.log(user.uid);
    }
    if (user && user.uid) {
      localStorage.setItem("userId", user.uid);
    }
    if (
      user &&
      user.uid &&
      (userRole === "lawyer" || userRole === "individual")
    ) {
      console.log("running get all data");
      getAllData();
    }
    if (
      userGoogle &&
      userGoogle.uid &&
      (userRole === "lawyer" || userRole === "individual")
    ) {
      getAllData();
    }
    if (user && user.uid && userRole === "client") {
      dispatch(updateActiveClientEmail(values.email));
      navigate("/client", { state: "login" });
    }
    if (
      userGoogle &&
      userGoogle.uid &&
      userRole === "lawyer" &&
      userGoogle.email
    ) {
      dispatch(updateActiveClientEmail(userGoogle.email));
      navigate("/client");
    }
  }, [user, userGoogle]);

  const mailRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!values.email && mailRef.current) {
      mailRef.current.focus();
    }
  }, [userRole]);

  useEffect(() => {
    if (isWrongPassword && sentPassword !== values.password) {
      setIsWrongPassword(false);
      setSentPassword("");
    }
  }, [isWrongPassword, values.password]);

  const handleTabChange = (role: string) => {
    setUserRole(role);
    localStorage.setItem("loginType", role);
    let path;
    if (role === "lawyer") {
      path = "/signup";
    } else if (role === "individual") {
      path = "/individual-signup  ";
    } else {
      path = "/client-signup";
    }
    setSignupPath(path);
  };

  // by default set it to lawyer
  useEffect(() => {
    if (customRole) {
      handleTabChange(customRole);
    } else {
      handleTabChange("lawyer");
    }
  }, []);

  return (
    <>
      <Header />
      <Box className="w-full flex flex-grow justify-center gap-0 h-full">
        <DomainFilter>
          <Box
            className="hidden md:visible relative md:flex flex-1 flex-shrink flex-grow-0 
          items-center justify-center md:basis-[40%] bg-gradient-to-tr from-[darkBlue] to-[red] "
          >
            <Box className="flex flex-col items-center justify-center">
              <Image src={images.caseBlinkWhiteIcon} />
              <Text className="text-white " fontSize="x-large">
                Automated Immigration Applications
              </Text>
            </Box>
            <Image
              className="absolute top-0 left-0"
              src="https://44110916.fs1.hubspotusercontent-na1.net/hubfs/44110916/Theme%20Assets/Image/cta-left-shape.svg"
            />
            <Image
              className="absolute bottom-0 right-0"
              src="https://44110916.fs1.hubspotusercontent-na1.net/hubfs/44110916/Theme%20Assets/Image/cta-left-shape.svg"
            />
          </Box>
        </DomainFilter>
        <Box className="flex flex-col flex-1 flex-grow  mx-8 md:mx-auto ">
          <Flex
            alignItems="center"
            flexDirection="column"
            marginTop="50px"
            style={{
              height: "calc(100vh - 125px)"
            }}
            width="100%"
          >
            <Box
              borderRadius="16px"
              width={["100%", "100%", "80%", "50%"]}
              border="1px"
              borderColor="rgba(0, 0, 0, 0.2)"
              bgColor="#fff"
              maxWidth="30rem"
            >
              <Flex alignItems="center" m="-1px -1px 64px">
                <Box
                  flex={1}
                  onClick={() => handleTabChange("lawyer")}
                  py="15px"
                  borderTopLeftRadius="16px"
                  sx={
                    userRole === "lawyer"
                      ? {
                          fontWeight: "bold",
                          bg: "darkBlue",
                          color: "white"
                        }
                      : clickedStyles
                  }
                  cursor="pointer"
                >
                  Lawyer
                </Box>

                <Box
                  flex={1}
                  onClick={() => handleTabChange("individual")}
                  py="15px"
                  borderTopRightRadius="16px"
                  sx={
                    userRole === "individual"
                      ? {
                          fontWeight: "bold",
                          // bg: "primary.lightRed",
                          bg: "darkBlue",
                          color: "white"
                        }
                      : clickedStyles
                  }
                  cursor="pointer"
                >
                  Individual
                </Box>
              </Flex>
              <form autoComplete="off" onSubmit={handleSubmit}>
                <Box p="0 24px 15px">
                  <FormControl style={{ display: "none" }}>
                    <Flex style={{ display: "none" }}>
                      <FormLabel
                        htmlFor="fake-input"
                        style={{ display: "none" }}
                      >
                        fake-input
                      </FormLabel>
                      <Input
                        id="fake-input"
                        type="text"
                        autoComplete="off"
                        style={{ display: "none" }}
                      />
                    </Flex>
                  </FormControl>
                  <FormControl
                    my="25px"
                    variant="floating"
                    id="email"
                    isInvalid={touched.email && !!errors.email}
                  >
                    <Input
                      ref={mailRef}
                      placeholder=" "
                      name="email"
                      type="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="new-email"
                    />
                    <FormLabel>Email</FormLabel>
                    <FormErrorMessage>{errors.email}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    my="20px"
                    variant="floating"
                    id="password"
                    isInvalid={touched.password && !!errors.password}
                  >
                    <Input
                      placeholder=" "
                      name="password"
                      type="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="new-password"
                    />
                    <FormLabel>Password</FormLabel>
                    <FormErrorMessage>{errorMessage}</FormErrorMessage>
                    {errorMessage && (
                      <Box color="red.500" fontSize="sm">
                        {errorMessage}
                      </Box>
                    )}
                  </FormControl>
                </Box>
                <Text mb="14px" fontSize="small" color="tertiary.blue">
                  Forgot your password?{" "}
                  <LinkDom to="/reset-password">
                    <strong style={{ cursor: "pointer" }}>Click here!</strong>
                  </LinkDom>
                </Text>
                <Text mb="14px" fontSize="small" color="tertiary.blue">
                  Don't have an account?{" "}
                  <LinkDom to="https://share.hsforms.com/17k5kCX6ES0eZaKDyGewkDAq9g78">
                    <strong style={{ cursor: "pointer" }}>Sign up here!</strong>
                  </LinkDom>
                </Text>
                <Divider className="my-4" />
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  gap="12px"
                  mb="12px"
                >
                  <Button
                    className="w-full mx-6"
                    bg="primary.red"
                    color="text.100"
                    type="submit"
                    isLoading={isLoadingLogin || isLoadingGetFiles}
                  >
                    Log in
                  </Button>
                </Flex>
              </form>

              <Divider my={6} />
              <div className="text-sm text-center mb-6">
                <p className="text-xs sm:mx-auto sm:max-w-sm">
                  By continuing, you agree to{" "}
                  <DomainFilter inline>CaseBlink's</DomainFilter>{" "}
                  <a
                    target="_blank"
                    className="underline "
                    href={URLS.tos}
                    rel="noreferrer"
                  >
                    Terms of Service
                  </a>{" "}
                  and{" "}
                  <a
                    target="_blank"
                    className="underline "
                    href={URLS.privacy}
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </p>
              </div>
              {/* <Flex
            justifyContent="center"
            alignItems="center"
            mb="14px"
            p="15px"
            cursor="pointer"
            onClick={handleGoogleSignIn}
          >
            <Image src={images.gmail} alt="google" w="30px" h="20px" />
            <Text alignSelf="center">Sign in with Google? </Text>
          </Flex> */}
            </Box>
          </Flex>
        </Box>
      </Box>
      {/* <JoinPageWawes /> */}
      {/* <JoinPageFooter /> */}
    </>
  );
};
