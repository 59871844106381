import {
  Box,
  Button,
  Card,
  Checkbox,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { useMemo, useState } from "react";
import { clickedStyles } from "../../styles/common-styles";
import images from "../../assets/image-export-assets";
import { activeClientSelectors } from "../../redux/client-case/selectors";
import {
  deleteExpertLetter,
  deleteQuestionnairesForms
} from "../../redux/client-case/activeClientSlice";
import { useUpdateFormsData } from "../../hooks/useUpdateFormsData";
import { FORMDATA } from "../../types/tables-data";

export const ClientQuestionnaires = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { onSubmitUpdateFormsData } = useUpdateFormsData();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formsToDelete, setFormsToDelete] = useState("");

  const secondStepData = useSelector(
    activeClientSelectors.selectQuestionnaireSecond
  );

  const questionnaireLetter = useSelector(
    activeClientSelectors.selectQuestionnaireExpertLetter
  );
  const questionnairesData = useMemo(() => {
    return [
      {
        title: FORMDATA.LETTER,
        date: questionnaireLetter?.uploadDate,
        name: questionnaireLetter?.uploadedByName,
        expertName: questionnaireLetter?.expertName
      }
    ];
  }, [secondStepData, questionnaireLetter]);

  const handleDeleteConfirm = () => {
    if (formsToDelete === FORMDATA.FORMS) {
      dispatch(deleteQuestionnairesForms());
      onSubmitUpdateFormsData({
        questionnaires: null
      });
    }
    if (formsToDelete === FORMDATA.LETTER) {
      dispatch(deleteExpertLetter());
      onSubmitUpdateFormsData({
        expertLetter: null
      });
    }
    setFormsToDelete("");
    onClose();
  };

  const handleEditExpertForms = () =>
    navigate(`/questionnaire/${params.caseID}/expert-letter`);

  return (
    <>
      <Card align="center" ml="0">
        <Box py="15px" sx={clickedStyles} width="100%">
          <Heading size="md">Questionnaires</Heading>
        </Box>

        <Box p="15px" width="100%">
          <Box
            p="15px"
            width="100%"
            fontWeight="400"
            textAlign="left"
            bgColor="rgba(255, 251, 229, 0.67)"
            mb="10px"
            borderRadius="5px"
            border="1px solid #ECE4E4"
          >
            <Text textAlign="left" w="100%" fontSize="18px" fontStyle="italic">
              Questionnaires must be completed and uploaded for the lawyer to
              generate immigration forms and draft letters, memos, and briefs.
            </Text>
          </Box>
        </Box>

        <Card p="15px" m="15px">
          <TableContainer>
            <Table
              variant="simple"
              p="15px"
              __css={{
                wordWrap: "break-word",
                whiteSpace: "normal"
              }}
            >
              <Thead>
                <Tr>
                  <Th />
                  <Th textAlign="center">Document</Th>
                  <Th textAlign="center">Upload date</Th>
                  <Th textAlign="center">Uploaded by</Th>
                  <Th textAlign="center">Required for forms</Th>
                  <Th textAlign="center">Required for drafting</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {questionnairesData.map((doc, i) => (
                  <Tr key={i}>
                    <Td>
                      <Checkbox isChecked={!!doc.date} />
                    </Td>
                    <Td>{`${doc.title} questionnaire ${
                      doc.expertName || ""
                    }`}</Td>
                    <Td>
                      {doc.date ? dayjs(doc.date).format("MM/DD/YYYY") : ""}
                    </Td>
                    <Td color="#000">{doc.name ? `${doc.name}` : ""}</Td>
                    <Td textAlign="center">Y</Td>
                    <Td textAlign="center">Y</Td>
                    <Td>
                      <Flex flexGrow="0" gap="15px">
                        <EditIcon
                          cursor="pointer"
                          onClick={handleEditExpertForms}
                        />
                        <DeleteIcon
                          cursor="pointer"
                          onClick={() => {
                            if (doc.date) {
                              setFormsToDelete(doc.title);
                              onOpen();
                            }
                          }}
                        />
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Card>

        <Flex gap="25px" justifyContent="center" my="40px">
          <Flex
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            gap="5px"
          >
            <Image
              boxSize="30px"
              objectFit="cover"
              src={images.uploadDocument}
              alt="questionnaire"
            />
            <Link to={`/questionnaire/${params.caseID}`}>
              <Button>Start questionnaire</Button>
            </Link>
          </Flex>
        </Flex>
      </Card>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalCloseButton
            onClick={() => {
              onClose();
              setFormsToDelete("");
            }}
          />
          <ModalBody pb={6}>
            Would you like to delete this questionnaire:{" "}
            <strong>{formsToDelete}</strong>?
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={() => {
                onClose();
                setFormsToDelete("");
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={() => {
                handleDeleteConfirm();
              }}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
