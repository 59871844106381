import React from "react";
import {
  ChakraProvider,
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Heading,
  VStack,
  Divider
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AddressFields } from "../common/AddressFields";
import { employerInfoSchema } from "./Client.schema";
import useFirestoreDocument from "../../hooks/useFirestoreDocument";
import { DATABASE } from "../../types/tables-data";
import {
  ClientEmployerInfo,
  ClientsReducer,
  defaultEmployerInfo
} from "../../redux/clients/clientsSlice";
import { clientsSelectors } from "../../redux/clients/selectors";
import { ClientEmployerForm } from "./ClientEmployerFields";

export const ClientEmployerInformation = () => {
  const params = useParams();
  const client = useSelector((state: ClientsReducer) =>
    clientsSelectors.selectClientById(state, params.caseID ?? "")
  );
  const navigate = useNavigate();
  const { updateDocument, loading, document } =
    useFirestoreDocument<ClientEmployerInfo>(
      DATABASE.INDIVIDUALS,
      params.caseID ?? ""
    );

  return (
    <Box p={5} className="max-w-3xl">
      <Formik
        initialValues={client}
        validationSchema={employerInfoSchema}
        onSubmit={(values, actions) => {
          updateDocument({ employerInfo: values.employerInfo, id: client.id });
          actions.setSubmitting(false);
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <ClientEmployerForm />
            <div className="flex gap-4 my-8 justify-end">
              <Button
                variant="outline"
                color="primary.darkBlue"
                onClick={() => navigate(-1)}
                type="button"
              >
                Cancel
              </Button>
              <Button
                bg="primary.darkBlue"
                color="white"
                isLoading={loading}
                type="submit"
              >
                Update Personal Information
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
