import { useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../api/firebaseApi";
import { DATABASE } from "../types/tables-data";
import { ClientState } from "../redux/clients/clientsSlice";
import { getStatusValue } from "../helpers/helpers";

export const useGetActiveClientData = () => {
  const [isLoadingGetActiveClient, setIsLoadingGetActiveClient] =
    useState(false);
  const [isNoClientData, setIsNoClientData] = useState(false);
  const [clientData, setClientData] = useState<ClientState | null>(null);

  const onSubmitGetClientsData = async (clientEmail: string) => {
    setIsLoadingGetActiveClient(true);
    try {
      const clientsRef = collection(db, DATABASE.CLIENTS);
      const q = query(clientsRef, where("email", "==", clientEmail));
      const querySnapshot = await getDocs(q);

      const clientsData: ClientState[] = [];

      querySnapshot.forEach((doc) => {
        clientsData.push({
          id: doc.id,
          firstName: doc.data().firstName,
          lastName: doc.data().lastName,
          name: `${doc.data().firstName} ${doc.data().lastName}`,
          birthDay: doc.data().birthDay,
          createdDate: doc.data().createdDate,
          visa: doc.data().visa,
          email: doc.data().email || "",
          isActive: doc.data().isActive,
          address: doc.data().address,
          practiceName: doc.data().practiceName,
          actionNeeded: doc.data().actionNeeded,
          caseId: doc.data().caseId,
          citizenship: doc.data().citizenship,
          employer: doc.data().employer,
          priority: doc.data().priority,
          status:
            doc.data().overview && doc.data().overview.steps
              ? getStatusValue(doc.data().overview.steps)
              : 1,
          notes: doc.data().notes || [],
          documents: [],
          overview: {
            steps:
              doc.data().overview && doc.data().overview.steps
                ? doc.data().overview.steps
                : []
          },
          lawyerId: doc.data().lawyerId || "",
          avatarName: doc.data().avatarName || "",
          avatarSrc: doc.data().avatarSrc || ""
        });
      });
      if (clientsData.length === 1) {
        setClientData(clientsData[0]);
      } else {
        setIsNoClientData(true);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingGetActiveClient(false);
    }
  };

  return {
    clientData,
    isLoadingGetActiveClient,
    onSubmitGetClientsData,
    isNoClientData
  };
};
