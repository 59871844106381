import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
  Container,
  Heading,
  Box
} from "@chakra-ui/react";
import { useParams, Link } from "react-router-dom";
import { useLayoutEffect } from "react";
import { Header } from "../components/Header";
import { Sidebar } from "../components/Sidebar/Sidebar";
import { LawyerProfile } from "./LawyerProfile";
import { PlanPage } from "./PlanPage";

const LawyerAccountPage = () => {
  const { tabIndex } = useParams();
  const currentTabIndex = tabIndex !== undefined ? Number(tabIndex) : 0;
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box>
      <Box bg="transparent">
        <Heading
          bg="transparent"
          fontSize="28px"
          mt={4}
          textAlign="left"
          color="primary.darkBlue"
        >
          Account Settings
        </Heading>
      </Box>
      <Tabs variant="sub" index={Number(currentTabIndex)}>
        <TabList>
          <Tab as={Link} to="/account">
            Manage Profile
          </Tab>
          <Tab as={Link} to="/account/1">
            Payment & Subscription
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <LawyerProfile />
          </TabPanel>
          <TabPanel>
            <PlanPage />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default LawyerAccountPage;
