import {
  useDisclosure,
  Alert,
  AlertIcon,
  Box,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Button
} from "@chakra-ui/react";

export const UpgradeRequestCard = () => {
  const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen: true });

  return isVisible ? (
    <Alert status="success" my={4} className="rounded-md">
      <AlertIcon />
      <Box className="text-left">
        <AlertTitle className="text-black font-bold">Success!</AlertTitle>
        <AlertDescription className="text-black font-normal">
          Your request to upgrade has been received. We will review your request
          and respond within the next 48 hours
        </AlertDescription>
      </Box>
      <CloseButton
        alignSelf="flex-start"
        position="relative"
        right={-1}
        top={-1}
        onClick={onClose}
      />
    </Alert>
  ) : null;
};
