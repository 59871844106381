import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useSelector } from "react-redux";

import { deleteDoc, doc, doc as gdoc, updateDoc } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { TargetBox } from "./inputDnD/TargetBox";

import { db } from "../api/firebaseApi";
import { useAddExtractInfo } from "../hooks/useAddExtractInfo";
import { useDeleteExtractedInfo } from "../hooks/useDeleteExtractedInfo";
import useFileActions from "../hooks/useFileActions";
import { useGetStanadardDocs } from "../hooks/useGetStandardDocs";
import { useSubscribeToJob } from "../hooks/useSubscribeToJob";
import { documentSelectors } from "../redux/documents/selectors";
import { VisaDocumentType } from "../redux/documents/types";
import { DATABASE, DataDocs } from "../types/tables-data";
import { generatedColumnsWithActions } from "./StandardColumns";
import { UploadFilesFooterLink } from "./UploadFilesFooterLink";
import { UploadFilesPlaceholder } from "./UploadFilesPlaceholder";
import { TableGroupedData } from "./common/TableGroupedData";
import EditMainDocModal from "./individualTabs/individualDocuments/EditMainDocModal";
import { DocumentReducer } from "../redux/documents/documentSlice";

export const StandardDocumentsTable = () => {
  const { id: uid, visaType } = useParams();
  const { deleteJob } = useSubscribeToJob();
  const navigate = useNavigate();
  const { deleteExtractionByDocType } = useDeleteExtractedInfo(uid);
  const { resetSummaryPoints } = useAddExtractInfo(uid);
  const newStandardDocs = useSelector(documentSelectors.newStandardDocs).filter(
    (doc) => doc.deleted === false
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDeleting, setIsDeleting] = useState(false);

  const [selectedDoc, setSelectedDoc] = useState<DataDocs | null>(null);
  const [selectedDocId, setSelectedDocId] = useState<string | null>(null);
  const [document, setDocument] = useState<DataDocs | undefined>();

  const {
    isOpen: isEditOpen,
    onClose: onEditClose,
    onOpen: onEditOpen
  } = useDisclosure();

  const { standardDocumentsColumns } = generatedColumnsWithActions({
    onDelete: (docId: string) => {
      const foundDocument = newStandardDocs.find((el) => el.id === docId);

      if (foundDocument) {
        setSelectedDoc(foundDocument);
        onOpen();
      }
    },
    onEdit: (docId: string) => {
      const foundDocument = newStandardDocs.find((el) => el.id === docId);

      if (foundDocument) {
        setDocument(foundDocument);
        onEditOpen();
      }
    }
  });

  const standardDocumentsCollectionPath = `documents/${uid}/docs/`;
  const standardDocumentsStoragePath = `${DATABASE.INDIVIDUALS}/documents/${uid}`;

  useGetStanadardDocs(standardDocumentsCollectionPath);

  const { handleFileActions } = useFileActions();

  const handleDelete = async () => {
    setIsDeleting(true);

    if (uid && selectedDoc?.documentTitle) {
      deleteExtractionByDocType(selectedDoc.documentTitle);
    }

    if (selectedDoc?.documentTitle?.toLowerCase().includes("resume")) {
      resetSummaryPoints(uid!);
      // Hacky approach to prevent adding standard documents job to "In Progress" after uploading a new resume
      deleteJob("Standard Documents");
    }

    try {
      setIsDeleting(true);
      const docRef = doc(db, selectedDoc?.docRef ?? "");
      await updateDoc(docRef, { deleted: true });
      setIsDeleting(false);
      onClose();
    } catch (e) {
      console.error(
        `Error deleting document with id: ${selectedDocId} for user with uid: ${uid}`,
        e
      );
    }

    setIsDeleting(false);
    onClose();
  };

  return (
    <div>
      <DndProvider backend={HTML5Backend} key={4} context={window}>
        <TargetBox
          onDrop={(item) => {
            const { files } = item;
            handleFileActions({
              files: Array.from(files),
              filePath: standardDocumentsStoragePath,
              uid: uid!
            });
          }}
        >
          {newStandardDocs.length === 0 && (
            <UploadFilesPlaceholder
              title="Drag and drop your files here"
              subTitle="Click Here to Upload"
              withImg
              onUpload={(files) => {
                if (files)
                  handleFileActions({
                    files: Array.from(files),
                    filePath: standardDocumentsStoragePath,
                    uid: uid!
                  });
              }}
            />
          )}

          {newStandardDocs.length > 0 && (
            <TableGroupedData
              type={VisaDocumentType.Standard}
              columns={standardDocumentsColumns}
              data={newStandardDocs}
              enableGrouping
              includeExhibits
            />
          )}

          <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Confirmation</ModalHeader>
              <ModalCloseButton
                onClick={() => {
                  onClose();
                }}
              />
              <ModalBody pb={6}>
                Are you sure you want to delete the document?
              </ModalBody>

              <ModalFooter>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                  colorScheme="red"
                  ml={3}
                  isLoading={isDeleting}
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </TargetBox>
      </DndProvider>

      <EditMainDocModal
        headerText="Edit Main Document"
        isDeleting={false}
        document={document}
        isOpen={isEditOpen}
        onClose={onEditClose}
        visaDocumentType={VisaDocumentType.Standard}
      />

      {newStandardDocs.length > 0 && (
        <UploadFilesFooterLink
          onUpload={(files) => {
            if (files)
              handleFileActions({
                files: Array.from(files),
                filePath: standardDocumentsStoragePath,
                uid: uid!
              });
          }}
        />
      )}
    </div>
  );
};
