import { Box, Button, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { getFirebaseUsers, getLoginAsToken, updateFirebaseUser } from "../../../api/OpenAIApi";
import { SpecialHeading } from "../../../components/individualTabs/PanelLayout";


function AdminEditUser() {
  const { uid } = useParams<{ uid: string }>(); // TypeScript for useParams
  const [updateList, setUpdateList] = useState({});
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const navigate = useNavigate();
  
  const [user, setUser] = useState({
    email: "",
    firstName: "",
    lastName: "",
    disabled: false,
    newPassword: "",
    role: "",
    practiceName: "",
    visaType: "",
    subscriptionStatus: "",
    lawyerRole: ""
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showSidebar, setShowSidebar] = useState(false);

  const handleCheckboxChange = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    setShowPasswordInput(event.target.checked);
  };

  async function viewAsUser(targetUserId: any, customRole:string) {
    console.log(customRole);
    const requestData = await getLoginAsToken(targetUserId);

    if (requestData) {
      const customToken = requestData.token;
      navigate('/login', {
        state: { customToken, customRole }
      });
    } else {
      console.error('Error fetching custom token:', requestData.error);
    }
  }
  
  useEffect(() => {
    const fetchUser = async () => {
      setIsLoading(true);
      try {
        const usersData = await getFirebaseUsers("email", uid);
        if (Array.isArray(usersData.users) && usersData.users.length > 0) {
          const userData = usersData.users[0];
          setUser({
            email: userData.email || "",
            firstName: userData.firstName || "",
            lastName: userData.lastName || "",
            disabled: userData.disabled || false,
            newPassword: "",
            role: userData.role,
            practiceName: userData.practiceName || "",
            visaType: userData.visaType || "",
            subscriptionStatus: userData.subscriptionStatus || "",
            lawyerRole: userData.userRole || ""
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    };

    if (uid) fetchUser();
  }, [uid]);

  const handleChange = (event: any) => {
    const { name, value, type, checked } = event.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: type === "checkbox" ? checked : value
    }));
    setUpdateList((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value
    }));
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    try {
      if (Object.keys(updateList).length) {
        if (uid) {
          await updateFirebaseUser(uid, updateList);
          alert("User updated successfully!");
        }
      } else {
        alert("No changes to update.");
      }
    } catch (error) {
      alert(`Failed to update user: ${JSON.stringify(error)}`);
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
      <Flex flexDirection="column" flex="1" className="p-4 overflow-scroll" >
        <Box py="15px" width="100%">
          <SpecialHeading title="Update User" />
        </Box>

        {user.role === "lawyer" && (
          <Box py="15px" width="100%">
            <Link
              to={`/admin/users/new?role=lawyer&practiceName=${user.practiceName}`}
              key={user.practiceName}
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              Create new user with access to the same cases
            </Link>
          </Box>
        )}

        { (user.role === "lawyer" || user.role === "individual") && <Box py="15px" width="100%">
            <Button
              onClick={() => viewAsUser(uid, user.role)}
              key={uid}
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              View the portal as this user.
            </Button>
          </Box> }
          
        <form onSubmit={handleSubmit}>
          <Box py="15px" width="100%">
            <h3>role:</h3>
            {user.role ? (
              user.role
            ) : (
              <h4 style={{ color: "red" }}>
                Warning: There's no individual or lawyer object associated -
                some updates will not work
              </h4>
            )}
          </Box>
          <Box py="15px" width="100%">
            <Flex justifyContent="center" flexDirection="row">
              <label htmlFor="firstName">
                First name:
                <input
                  style={{ marginLeft: "10px" }}
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={user.firstName}
                  onChange={handleChange}
                />
              </label>
            </Flex>
          </Box>
          <Box py="15px" width="100%">
            <Flex justifyContent="center" flexDirection="row">
              <label htmlFor="lastName">
                Last name:
                <input
                  style={{ marginLeft: "10px" }}
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={user.lastName}
                  onChange={handleChange}
                />
              </label>
            </Flex>
          </Box>
          <Box py="15px" width="100%">
            <Flex justifyContent="center" flexDirection="row">
              <label htmlFor="email">
                Email:
                <input
                  style={{ marginLeft: "10px" }}
                  type="email"
                  id="email"
                  name="email"
                  value={user.email}
                  onChange={handleChange}
                />
              </label>
            </Flex>
          </Box>
          <Box py="15px" width="100%">
            <Flex justifyContent="center" flexDirection="row">
              <label htmlFor="disabled">
                Disabled:
                <input
                  style={{ marginLeft: "10px" }}
                  type="checkbox"
                  id="disabled"
                  name="disabled"
                  checked={user.disabled}
                  onChange={handleChange}
                />
              </label>
            </Flex>
          </Box>
          {user.role === "lawyer" && (
            <Box py="15px" width="100%">
              <Flex justifyContent="center" flexDirection="row">
                <label htmlFor="practiceName">
                  PracticeName:
                  <input
                    style={{ marginLeft: "10px" }}
                    type="text"
                    id="practiceName"
                    name="practiceName"
                    value={user.practiceName}
                    onChange={handleChange}
                  />
                </label>
              </Flex>
              <Flex justifyContent="center" flexDirection="row">
                <label htmlFor="subscriptionStatus">
                  Subscription Type:
                  <select
                    name="subscriptionStatus"
                    id="subscriptionStatus"
                    value={user.subscriptionStatus}
                    onChange={handleChange}
                    style={{ marginLeft: "10px" }}
                  >
                    <option value="">Select a subscription type</option>
                    <option value="freemium">Freemium</option>
                    <option value="paid">Paid</option>
                  </select>
                </label>
              </Flex>
              <Flex justifyContent="center" flexDirection="row">
                <label htmlFor="lawyerRole">
                  Account Type:
                  <select
                    name="lawyerRole"
                    id="lawyerRole"
                    value={user.lawyerRole}
                    onChange={handleChange}
                    style={{ marginLeft: "10px" }}
                  >
                    <option value="">Select an Account type</option>
                    <option value="standard">Standard</option>
                    <option value="full">Full</option>
                  </select>
                </label>
              </Flex>
            </Box>
          )}

          {user.role === "individual" && (
            <Box py="15px" width="100%">
              <Flex justifyContent="center" flexDirection="row">
                <label htmlFor="visaType">
                  VisaType:
                  <select
                    name="visaType"
                    id="visaType"
                    value={user.visaType}
                    style={{ marginLeft: "10px" }}
                    onChange={handleChange}
                  >
                    <option value="">Select a visatype</option>
                    <option value="EB-2 NIW">EB-2 NIW</option>
                    <option value="O-1 A">O-1 A</option>
                  </select>
                </label>
              </Flex>
            </Box>
          )}

          <div>
            <label htmlFor="showPassword">
              <input
                id="showPassword"
                type="checkbox"
                checked={showPasswordInput}
                onChange={handleCheckboxChange}
              />{" "}
              Manually set the user's password
            </label>
          </div>

          {showPasswordInput && (
            <Box py="15px" width="100%">
              <Flex justifyContent="center" flexDirection="row">
                <label htmlFor="newPassword">
                  New Password:
                  <input
                    style={{ marginLeft: "10px" }}
                    placeholder="Manually set password"
                    type="password"
                    id="newPassword"
                    name="newPassword"
                    value={user.newPassword}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </label>
              </Flex>
            </Box>
          )}

          <Button
            bg="bgColor.300"
            color="text.100"
            type="submit"
            isLoading={isLoading}
          >
            Update User
          </Button>
        </form>
      </Flex>
  );
}

export default AdminEditUser;
