// this code is following chakra styling conventions
// make sure to read the documents to understand
// the anatomy approach
// https://chakra-ui.com/docs/styled-system/component-style

import { tableAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tableAnatomy.keys
);

export const tablesTheme = defineMultiStyleConfig({
  // define the part you're going to style
  baseStyle: {
    thead: {
      bg: "secondary.offWhite"
    },
    tr: {
      color: "primary.darkBlue",
      fontWeight: "500"
    }
  }
});
