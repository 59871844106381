import {
  Box,
  Button,
  Card,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Header } from "../components/Header";
import { Sidebar } from "../components/Sidebar/Sidebar";
import { clickedStyles } from "../styles/common-styles";
import { addQuestionnaireFirst } from "../redux/client-case/activeClientSlice";
import {
  AddressAptSteFloor,
  GeneralAddress
} from "../redux/clients/clientsSlice";
import { activeClientSelectors } from "../redux/client-case/selectors";
import { useUpdateFormsData } from "../hooks/useUpdateFormsData";

export const ClientFormStepOne = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const firstStepData = useSelector(
    activeClientSelectors.selectQuestionnaireFirst
  );
  const clientId = useSelector(activeClientSelectors.selectId);

  const { onSubmitUpdateFormsData } = useUpdateFormsData();

  const formik = useFormik({
    initialValues: {
      addressGeneral: firstStepData?.addressGeneral || GeneralAddress.US,
      careOf: firstStepData?.careOf || "",
      streetNumberName: firstStepData?.streetNumberName || "",
      addressAptSteFloor:
        firstStepData?.addressAptSteFloor || AddressAptSteFloor.EMPTY,
      addressAptSteFloorInput: firstStepData?.addressAptSteFloorInput || "",
      cityOrTown: firstStepData?.cityOrTown || "",
      state: firstStepData?.state || "",
      province: firstStepData?.province || "",
      postalCode: firstStepData?.postalCode || "",
      country: firstStepData?.country || ""
    },

    onSubmit: (values) => {
      dispatch(
        addQuestionnaireFirst({
          ...values
        })
      );
      onSubmitUpdateFormsData({
        "questionnaires.firstStep": values
      });
      navigate(`/questionnaire/${clientId}/forms-step-2`);
    }
  });

  return (
    <>
      <Header />
      <Flex justifyContent="space-between" flexDirection="row" mt="30px">
        <Sidebar
          closeSidebar={() => setShowSidebar(false)}
          showSidebar={showSidebar}
        />

        <Flex alignItems="center" flexDirection="column" flex="1">
          <Card align="center" width="90%">
            <CardHeader sx={clickedStyles} width="100%">
              <Heading size="md">Forms questionnaire - Step 1</Heading>
            </CardHeader>

            <Box p="15px" w="60%">
              <Text fontSize="22px" fontWeight="700">
                Mailing address
              </Text>
            </Box>

            <Box p="15px" w="60%">
              <form onSubmit={formik.handleSubmit}>
                <RadioGroup
                  onChange={(val) =>
                    formik.setFieldValue("addressGeneral", val, false)
                  }
                  value={formik.values.addressGeneral}
                  name="addressGeneral"
                  mb="30px"
                >
                  <Stack direction="row">
                    <Radio value={GeneralAddress.US}>US address</Radio>
                    <Radio value={GeneralAddress.FOREIGN}>
                      Foreign address
                    </Radio>
                  </Stack>
                </RadioGroup>

                <FormControl my="30px" variant="floating" id="careOf">
                  <Input
                    placeholder=" "
                    name="careOf"
                    type="text"
                    value={formik.values.careOf}
                    onChange={formik.handleChange}
                  />
                  <FormLabel>In care of</FormLabel>
                </FormControl>
                <FormControl my="30px" variant="floating" id="streetNumberName">
                  <Input
                    placeholder=" "
                    name="streetNumberName"
                    type="text"
                    value={formik.values.streetNumberName}
                    onChange={formik.handleChange}
                  />
                  <FormLabel>Street number and name</FormLabel>
                </FormControl>

                <Flex justifyContent="center" gap="15px">
                  <RadioGroup
                    onChange={(val) =>
                      formik.setFieldValue("addressAptSteFloor", val, false)
                    }
                    value={formik.values.addressAptSteFloor}
                    name="addressAptSteFloor"
                    display="flex"
                    my="auto"
                  >
                    <Stack direction="row">
                      <Radio value={AddressAptSteFloor.APT}>Apt</Radio>
                      <Radio value={AddressAptSteFloor.STE}>Ste</Radio>
                      <Radio value={AddressAptSteFloor.FLOOR}>Floor</Radio>
                    </Stack>
                  </RadioGroup>

                  <Input
                    placeholder=" "
                    _placeholder={{ color: "grey" }}
                    name="addressAptSteFloorInput"
                    type="text"
                    value={formik.values.addressAptSteFloorInput}
                    onChange={formik.handleChange}
                  />
                </Flex>

                <FormControl my="30px" variant="floating" id="cityOrTown">
                  <Input
                    placeholder=" "
                    name="cityOrTown"
                    type="text"
                    value={formik.values.cityOrTown}
                    onChange={formik.handleChange}
                  />
                  <FormLabel>City or town</FormLabel>
                </FormControl>
                <FormControl my="30px" variant="floating" id="state">
                  <Input
                    placeholder=" "
                    name="state"
                    type="text"
                    value={formik.values.state}
                    onChange={formik.handleChange}
                  />
                  <FormLabel>State</FormLabel>
                </FormControl>
                <FormControl my="30px" variant="floating" id="province">
                  <Input
                    placeholder=" "
                    name="province"
                    type="text"
                    value={formik.values.province}
                    onChange={formik.handleChange}
                  />
                  <FormLabel>Province</FormLabel>
                </FormControl>
                <FormControl my="30px" variant="floating" id="postalCode">
                  <Input
                    placeholder=" "
                    name="postalCode"
                    type="text"
                    value={formik.values.postalCode}
                    onChange={formik.handleChange}
                  />
                  <FormLabel>Postal code</FormLabel>
                </FormControl>
                <FormControl my="30px" variant="floating" id="country">
                  <Input
                    placeholder=" "
                    name="country"
                    type="text"
                    value={formik.values.country}
                    onChange={formik.handleChange}
                  />
                  <FormLabel>Country</FormLabel>
                </FormControl>
                <Flex m="30px">
                  <Button
                    type="button"
                    onClick={() => navigate(-1)}
                    mx="15px"
                    flex="1"
                  >
                    Cancel
                  </Button>
                  <Button
                    bg="bgColor.300"
                    color="text.100"
                    mx="15px"
                    type="submit"
                    flex="1"
                  >
                    Next
                  </Button>
                </Flex>
              </form>
            </Box>
          </Card>
        </Flex>
      </Flex>
    </>
  );
};
