import {
  deleteField,
  doc,
  getDoc,
  onSnapshot,
  updateDoc
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../api/firebaseApi";
import { DATABASE } from "../types/tables-data";

import { individualSelectors } from "../redux/individual/selectors";
import { setLegalBrief } from "../redux/drafts/draftsSlice";

export const useIndivSop = (indivId: string) => {
  const dispatch = useDispatch();
  const email = useSelector(individualSelectors.selectEmail);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingSop, setIsLoadingSop] = useState(false);
  const [deleteError, setDeleteError] = useState<any>(null);
  const [getError, setGetError] = useState<any>(null);
  const [updateError, setUpdateError] = useState<any>(null);

  const getSop = async () => {
    if (!indivId) {
      console.log("SOP_FETCH: No individual ID provided, exiting SOP fetch.");
      return;
    }

    const documentReference = doc(db, `${DATABASE.DRAFTS}/${indivId}`);
    setIsLoadingSop(true);

    try {
      console.log(`SOP_FETCH: Fetching SOP for individual ID: ${indivId}`);
      const documentSnapshot = await getDoc(documentReference);

      if (!documentSnapshot.exists()) {
        console.log("SOP_FETCH: No such document!");
        setGetError(new Error("No such document"));
        return;
      }

      const sopData = documentSnapshot.data().sop;
      if (sopData && "text" in sopData) {
        console.log("SOP_FETCH: SOP document found and processed.");
        const sopDraft = {
          value: {
            text: sopData.text ?? "",
            html: sopData?.html ?? "",
            accessEnabled: sopData.accessEnabled ?? false
          }
        };
        dispatch(setLegalBrief(sopDraft));
      } else {
        console.log("SOP_FETCH: No SOP data found.");
        dispatch(setLegalBrief(null));
      }
    } catch (error) {
      console.error("SOP_FETCH: Error fetching SOP: ", error);
      setGetError(error);
    } finally {
      setIsLoadingSop(false);
      console.log("SOP_FETCH: SOP fetch process completed.");
    }
  };

  const deleteSop = async () => {
    if (!indivId) return; // Ensure the ID is present

    const docRef = doc(db, `${DATABASE.DRAFTS}/${indivId}`);

    setIsLoadingDelete(true); // If you have a loading state for deletion

    try {
      await updateDoc(docRef, {
        sop: deleteField(),
        last_updated_by: email
      });
    } catch (e) {
      setDeleteError(e); // Use an appropriate error handling state
    } finally {
      setIsLoadingDelete(false); // Reset the loading state
    }
  };

  const updateSop = async (newData: any) => {
    if (!indivId) return;

    const docRef = doc(db, `${DATABASE.DRAFTS}/${indivId}`);

    setIsLoadingUpdate(true);

    try {
      await updateDoc(docRef, {
        sop: newData,
        last_updated_by: email
      });
    } catch (e) {
      console.log("update Error: ", e);
      setUpdateError(e);
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  useEffect(() => {
    if (!indivId) return;

    const documentReference = doc(db, `${DATABASE.DRAFTS}/${indivId}`);
    setIsLoadingSop(true);

    const unsubscribe = onSnapshot(
      documentReference,
      (documentSnapshot) => {
        if (!documentSnapshot.exists()) {
          console.log("No such document!");
          setGetError(new Error("No such document"));
        } else {
          const sopData = documentSnapshot.data().sop;
          if (sopData && "text" in sopData) {
            const sopDraft = {
              value: {
                text: sopData.text,
                html: sopData?.html ?? "",
                accessEnabled: sopData.accessEnabled ?? false
              }
            };
            dispatch(setLegalBrief(sopDraft));
          } else {
            dispatch(setLegalBrief(null));
          }
        }
        setIsLoadingSop(false);
      },
      (error: any) => {
        console.error("Error fetching SOP: ", error);
        setGetError(error);
        setIsLoadingSop(false);
      }
    );
  }, [indivId]);

  return {
    getSop,
    getError,
    isLoadingSop,
    updateError,
    isLoadingUpdate,
    updateSop,
    deleteSop,
    isLoadingDelete,
    deleteError
  };
};
