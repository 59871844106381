import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from "@chakra-ui/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Header } from "../components/Header";
import { Sidebar } from "../components/Sidebar/Sidebar";
import { selectedTab } from "../styles/common-styles";
import { ClientOverview } from "../components/clientTabs/ClientOverview";
import { ClientDocuments } from "../components/clientTabs/ClientDocuments";
import { useGetClientDocuments } from "../hooks/useGetClientDocuments";
import { DataDocs } from "../types/tables-data";
import { eb2Docs } from "../testTableData";
import { ClientQuestionnaires } from "../components/clientTabs/ClientQuestionnaires";
import { useGetFormsData } from "../hooks/useGetFormsData";
import { useAddEmptyForms } from "../hooks/useAddEmptyForms";
import {
  addQuestionnaireFirst,
  addQuestionnaireSecond,
  addQuestionnaireLetter
} from "../redux/client-case/activeClientSlice";
import { ClientPacket } from "../components/clientTabs/ClientPacket";
import HamburgerMenu from "../components/HamburgerMenu";

export const ClientCase = () => {
  const [showSidebar, setShowSidebar] = useState(false);

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { onSubmitGetClientDocs, dbDocuments } = useGetClientDocuments();
  const [docsTableData, setDocsTableData] = useState<DataDocs[]>([]);

  const { formsData, isFormsNotFound } = useGetFormsData();

  const { onSubmitAddEmptyForms } = useAddEmptyForms();

  const updateDocsTableData = () => {
    const docs: DataDocs[] = [];
    const titles: string[] = [];
    dbDocuments?.forEach((data) => {
      docs.push(data.documents);
      titles.push(data.documents.documentTitle!);
    });
    const docsToUpload = eb2Docs.filter(
      (doc) => !titles.includes(doc?.documentTitle ?? "")
    );
    docs.push(...docsToUpload);
    setDocsTableData(docs);
  };

  const getClientData = async () => {
    if (params.caseID) {
      await onSubmitGetClientDocs();
      // await onSubmitGetFormsData();
    }
  };

  useEffect(() => {
    getClientData();
  }, [params.caseID]);

  useEffect(() => {
    updateDocsTableData();
  }, [dbDocuments]);

  const createFormsData = async () => {
    if (params.caseID) {
      try {
        await onSubmitAddEmptyForms();
        // await onSubmitGetFormsData();
      } catch (e) {
        console.log(e);
      }
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (isFormsNotFound && !formsData) {
      createFormsData();
    }
  }, [isFormsNotFound]);

  useEffect(() => {
    if (formsData && formsData.questionnaires?.firstStep) {
      dispatch(addQuestionnaireFirst(formsData.questionnaires.firstStep));
    }
    if (formsData && formsData.questionnaires?.secondStep) {
      dispatch(addQuestionnaireSecond(formsData.questionnaires.secondStep));
    }
    if (formsData && formsData.expertLetter) {
      dispatch(addQuestionnaireLetter(formsData.expertLetter));
    }
  }, [formsData]);

  const getDefaultTabIndex = (pathname: string) => {
    let index = 0;

    const tabIndexesMap = {
      "/overview": 0,
      "/documents": 1,
      "/questionnaires": 2,
      "/packet": 3
    };

    Object.entries(tabIndexesMap).forEach(([path, value]) => {
      if (pathname.includes(path)) {
        index = value;
      }
    });

    return index;
  };

  return (
    <>
      <Header />
      <Flex justifyContent="space-between" flexDirection="row" mt="30px">
        <HamburgerMenu />
        <Sidebar
          closeSidebar={() => setShowSidebar(false)}
          showSidebar={showSidebar}
        />

        <Flex
          alignItems="center"
          flexDirection="column"
          flex="1"
          mx="15px"
          width="40wh"
        >
          <Tabs
            isFitted
            variant="enclosed"
            m="15px"
            width="100%"
            defaultIndex={getDefaultTabIndex(location.pathname)}
          >
            <TabList mb="1em">
              <Tab
                _selected={selectedTab}
                onClick={() =>
                  navigate(`/client-case/${params.caseID}/overview`)
                }
              >
                Overview
              </Tab>
              <Tab
                _selected={selectedTab}
                onClick={() =>
                  navigate(`/client-case/${params.caseID}/documents`)
                }
              >
                Documents
              </Tab>
              <Tab
                _selected={selectedTab}
                onClick={() =>
                  navigate(`/client-case/${params.caseID}/questionnaires`)
                }
              >
                Forms and Questionnaires
              </Tab>
              <Tab
                _selected={selectedTab}
                onClick={() => navigate(`/client-case/${params.caseID}/packet`)}
              >
                Final packet
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <ClientOverview />
              </TabPanel>
              <TabPanel>
                <ClientDocuments
                  data={docsTableData}
                  onSubmitGetClientDocs={onSubmitGetClientDocs}
                />
              </TabPanel>
              <TabPanel>
                <ClientQuestionnaires />
              </TabPanel>
              <TabPanel>
                <ClientPacket />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </Flex>
    </>
  );
};
