import { DeleteIcon, EditIcon, ViewIcon } from "@chakra-ui/icons";
import {
  Box,
  Fade,
  Flex,
  IconButton,
  Image,
  Skeleton,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { Timestamp } from "firebase/firestore";
import _ from "lodash";
import { MdLock } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import images from "../../assets/image-export-assets";
import { isFullUser, isSuperAdmin } from "../../helpers/helpers";
import { ExtractedResearchData } from "../../redux/case-research/caseResearchSlice";
import { ExpertLetter } from "../../redux/clients/clientsSlice";
import { DraftType } from "../../redux/drafts/draftsSlice";
import { individualSelectors } from "../../redux/individual/selectors";
import { lawyerSelectors } from "../../redux/lawyer/selectors";
import { RootState } from "../../redux/store";
import { DraftDocument } from "../../types/drafts.type";
import { ExpertLetterDraft } from "../../types/expert-letter-draft";
import { EXTRACTIONSTATES } from "../../redux/extraction-jobs/extractionJobsSlice";
import { extractionSelectors } from "../../redux/extraction-jobs/selectors";
import { getShortString } from "../../helpers/string_helpers";

type ExtractionType =
  | "documents/standard"
  | "documents/evidence"
  | "questionnaires/resume-highlights"
  | "drafts/sop"
  | "drafts/expert-letter"
  | "drafts/exhibit-list"
  | "drafts/cover-letter"
  | "case-research";

type ExtractedDataType =
  | {
      uploadedDate: number | string;
      created_at?: number | string;
    }
  | Partial<DraftDocument>
  | DraftType
  | ExpertLetter
  | ExtractedResearchData
  | ExpertLetterDraft
  | null;

type ExtractionCardProps = {
  type: ExtractionType;
  id: string | undefined;
  statusMsg?: string | undefined;
  extractedData: ExtractedDataType;
  onExtractionDelete: VoidFunction;
  onEdit?: VoidFunction;
  onView?: VoidFunction;
  expertLetterId?: string | undefined;
  locked?: boolean;
  disableIcons?: boolean;
  showIcons?: boolean;
};

export const ExtractionCard = ({
  type,
  id,
  onExtractionDelete,
  onEdit,
  onView,
  extractedData,
  statusMsg,
  expertLetterId,
  locked,
  showIcons = false,
  disableIcons = false
}: ExtractionCardProps) => {
  const role = useSelector(lawyerSelectors.selectRole);
  const { subscription, email } = useSelector(lawyerSelectors.selectAll);
  const navigate = useNavigate();
  const { visaType } = useParams();
  const indivId = useSelector((state: RootState) =>
    individualSelectors.selectUid(state)
  );
  const activeJobs = useSelector(extractionSelectors.selectActiveJobs);
  const reducedActiveJobs = activeJobs.map((job) => job.id);

  const isIndividual = indivId ?? false;

  let title = "";
  let date = "";
  let link = "";

  extractInfo();
  mapLinkToDocumentLocation();

  const islocked = isLocked();

  const bg = useColorModeValue("secondary.offWhite", "primary.backgroundDark");
  const primaryColor = useColorModeValue("primary.darkBlue", "text.100");
  const statusBgColor = useColorModeValue("primary.red", "primary.lightRed");
  const hoverBg = useColorModeValue(
    "secondary.lightGray",
    "primary.backgroundDark"
  );
  const borderColor = useColorModeValue(
    "secondary.lightGray",
    "secondary.offWhiteDark"
  );

  return (
    <Fade in>
      <Box
        title={title}
        textAlign="left"
        color={primaryColor}
        display="flex"
        justifyContent="space-between"
        position="relative"
        p={4}
        bg={bg}
        zIndex="0"
        cursor="pointer"
        borderWidth="1px"
        borderStyle="solid"
        borderRadius="8px"
        borderColor={borderColor}
        _hover={{ bg: hoverBg }}
        onClick={(_) => {
          if (!islocked) navigate(link);
        }}
      >
        {islocked && (
          <div className="backdrop-blur-sm absolute top-0 z-30  w-full h-full">
            <div className="flex flex-grow items-center justify-center w-full h-full">
              <MdLock className="h-8 w-8" />
            </div>
          </div>
        )}

        <Box
          bg="primary.red"
          width={2}
          height="100%"
          className="absolute top-0 left-0 z-10 rounded-l"
        />
        <Box className="flex   basis-80 ml-2">
          <Image src={images.uploadDocument} />{" "}
          <Box className="flex flex-col  whitespace-nowrap" maxWidth="32vh">
            <Text className="overflow-hidden text-ellipsis">
              <Text className="overflow-hidden text-ellipsis">
                {title && title.trim() !== "" && title.trim() !== "N/A" ? (
                  title
                ) : (
                  <Skeleton height="20px" width="100px" />
                )}
              </Text>
            </Text>
            <Text fontWeight={400} fontSize="small">
              Date: {date}
            </Text>
          </Box>
        </Box>
        {statusMsg && (
          <Box className="flex flex-col justify-center" alignItems="flex-start">
            <Text>STATUS</Text>
            <Text
              fontWeight={400}
              fontSize="small"
              minWidth="150px"
              textAlign="left"
            >
              {statusMsg ?? ""}
            </Text>
          </Box>
        )}
        <Flex alignItems="center">
          {showIcons && (
            <>
              <Box title="View draft">
                <IconButton
                  variant="transparent"
                  icon={<ViewIcon />}
                  cursor="pointer"
                  mr={2}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (onView) onView();
                  }}
                  aria-label="View Extracted Information"
                />
              </Box>
              <Box title="Edit draft">
                <IconButton
                  variant="transparent"
                  icon={<EditIcon />}
                  cursor="pointer"
                  mr={1}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (onEdit) onEdit();
                  }}
                  aria-label="Edit Information"
                  isDisabled={disableIcons}
                />
              </Box>
            </>
          )}
          <Box title="Delete draft">
            <IconButton
              variant="transparent"
              aria-label="Delete Extraction"
              icon={<DeleteIcon />}
              onClick={(e) => {
                e.stopPropagation();
                onExtractionDelete();
              }}
              isDisabled={disableIcons}
            />
          </Box>
        </Flex>
      </Box>
    </Fade>
  );

  function mapLinkToDocumentLocation() {
    switch (type) {
      case "drafts/exhibit-list":
        title = "Exhibit List";
        link = `/individual/${id}/${visaType}/drafts/exhibit-list/edit`;
        break;
      case "documents/evidence":
        title = "Summaries";
        link = `/individual/${id}/${visaType}/documents/evidence-documents/extracted-information`;
        break;
      case "documents/standard":
        title = "Summaries";
        link = `/individual/${id}/${visaType}/documents/standard-documents/extracted-information`;
        break;
      case "questionnaires/resume-highlights": {
        const data = extractedData as ExpertLetter;
        title = `Summaries`;
        link = `questionnaires/expert-letters/extracted-resume-highlights`;
        break;
      }
      case "drafts/expert-letter": {
        if (
          extractedData &&
          "extracted_argument" in extractedData &&
          extractedData.extracted_argument &&
          Object.keys(extractedData.extracted_argument).length > 0
        ) {
          // Handle ExpertLetterDraft
          const data = extractedData as ExpertLetterDraft;
          title = `Expert: ${_.startCase(
            data.extracted_argument?.letter_author || ""
          )}`;
          link = `/individual/${id}/${visaType}/drafts/expert-letters/${expertLetterId}/edit`;
        } else if (extractedData && "expertName" in extractedData) {
          // Handle ExpertLetter
          const data = extractedData as ExpertLetter;
          title = `Expert: ${_.startCase(data.expertName ?? "")}`;
          link = `/individual/${id}/${visaType}/drafts/expert-letters/expert-letter-inputs/${expertLetterId}/`;
          date =
            extractedData?.created_at === 0
              ? "n/a"
              : dayjs(extractedData?.created_at).format("MM/DD/YYYY");
        } else {
          // Handle null or unexpected data case
          title = "No Data Available";
          link = "#";
        }
        break;
      }

      case "drafts/cover-letter":
        link = `/individual/${id}/${visaType}/drafts/cover-letter/edit`;
        title = "Cover letter";
        break;
      case "drafts/sop":
        link = `/individual/${id}/${visaType}/drafts/statement-of-purpose/edit`;
        title = "Legal brief";
        break;
      case "case-research":
        link = `/individual/${id}/${visaType}/case-research/extracted-information`;
        title = "Summaries";
        break;
      default:
        link = "";
    }
  }

  // hacky approach to differentiate between documents
  // would consider to refactor this in the near future
  // using more consistent types
  function extractInfo() {
    if (extractedData != null) {
      if ("date" in extractedData) {
        date =
          extractedData?.date === 0
            ? "n/a"
            : dayjs(extractedData?.date).format("MM/DD/YYYY");
      }
      if ("uploadedDate" in extractedData) {
        date =
          extractedData?.uploadedDate === 0
            ? "n/a"
            : dayjs(extractedData?.uploadedDate).format("MM/DD/YYYY");
      }
      if ("created_at" in extractedData) {
        const createdAt = extractedData?.created_at;
        date =
          createdAt === 0
            ? "n/a"
            : dayjs(
                createdAt instanceof Timestamp ? createdAt.toDate() : createdAt
              ).format("MM/DD/YYYY");
      }
    } else {
      date = "";
    }
  }

  // function that checks the status of locked
  function isLocked() {
    if (locked !== undefined) {
      return locked;
    }

    if (isSuperAdmin() === true || isFullUser() === true) return false;

    if (subscription?.status === "paid") return false;

    if (isIndividual) return false;

    if (
      subscription?.status === "freemium" &&
      (type === "drafts/expert-letter" ||
        type === "documents/standard" ||
        type === "documents/evidence" ||
        type === "questionnaires/resume-highlights")
    )
      return false;

    return true;
  }
};
