import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { Divider, HStack, IconButton, Stack, VStack } from "@chakra-ui/react";
import { FieldArray, useFormikContext } from "formik";
import { useParams } from "react-router-dom";
import { countryListAllIsoDataArray } from "../../data/countries";
import { ClientState } from "../../redux/clients/clientsSlice";
import { InputField } from "../InputField";
import { SelectField } from "../SelectField";

export const ClientPersonalForm = () => {
  const { values } = useFormikContext<ClientState>();
  const params = useParams();
  const newCase = !params.caseID;

  return (
    <Stack alignItems="flex-start" spacing={4}>
      <HStack className="flex gap-2">
        {/* firstName */}

        <InputField label="First Name" name="firstName" isRequired />

        {/* middleName */}

        <InputField label="Middle Name" name="middleName" />

        {/* lastName */}
        <InputField label="Last Name" name="lastName" isRequired />
      </HStack>

      <div className="flex gap-4 ">
        {/* title */}
        <SelectField
          label="Title"
          name="title"
          options={["Mr.", "Ms.", "Dr."]}
          placeHolder="Select title"
          isRequired
        />

        {/* pronouns */}

        <SelectField
          label="Pronouns"
          name="pronouns"
          options={["He/Him/His", "She/Her/Hers", "They/Them/Theirs", "Other"]}
          placeHolder="Select pronouns"
          isRequired
        />

        {/* other pronouns */}
        {values.pronouns === "Other" && (
          <InputField label="Other Pronouns" name="otherPronouns" />
        )}
      </div>

      {newCase ? null : (
        <VStack spacing={4} alignItems="flex-start">
          <div className="flex gap-4">
            <InputField type="date" label="Date of Birth" name="birthDay" />
            <SelectField
              label="Country of Birth"
              name="countryob"
              options={countryListAllIsoDataArray}
              placeHolder="Select country"
            />
          </div>
          <div className="flex flex-col  gap-4 w-fit items-end ">
            <FieldArray name="citizenships">
              {({ insert, remove, push }) => (
                <>
                  <div className="flex items-end gap-2 ">
                    <SelectField
                      label="Country of Citizenship"
                      name="citizenship"
                      options={countryListAllIsoDataArray}
                      placeHolder="Select country"
                    />
                    <IconButton
                      size="sm"
                      icon={<AddIcon />}
                      aria-label="add country"
                      onClick={() => {
                        push("");
                      }}
                    />
                  </div>
                  {values.citizenships?.map((citizenship, indx) => (
                    <div className="flex items-end gap-2" key={`#${indx + 2}`}>
                      <SelectField
                        label={`Country of Citizenship #${indx + 2}`}
                        name={`citizenships[${indx}]`}
                        options={countryListAllIsoDataArray}
                        placeHolder="Select country"
                      />
                      <IconButton
                        size="sm"
                        bg="primary.red"
                        color="white"
                        icon={<MinusIcon />}
                        aria-label="add country"
                        onClick={() => {
                          remove(indx);
                        }}
                      />
                    </div>
                  ))}
                </>
              )}
            </FieldArray>
          </div>
        </VStack>
      )}
      <Divider />
    </Stack>
  );
};
