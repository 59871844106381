import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Divider,
  Flex,
  Text,
  useToast
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CustomBox } from "../components/common/CustomBox";
import { SpecialHeading } from "../components/individualTabs/PanelLayout";
import { I94Form } from "../forms/I94Form";
import { PasseportForm } from "../forms/PasseportForm";
import { ResumeForm } from "../forms/ResumeForm";
import { VisaForm } from "../forms/VisaForm";
import { useAddExtractInfo } from "../hooks/useAddExtractInfo";
import {
  ExtractionReducer,
  EXTRACTIONSTATES
} from "../redux/extraction-jobs/extractionJobsSlice";
import { extractionSelectors } from "../redux/extraction-jobs/selectors";
import { individualSelectors } from "../redux/individual/selectors";
import styles from "../styles/extracted-info.module.css";
import { DocUrl } from "../types/tables-data";
import { documentSelectors } from "../redux/documents/selectors";

export const IndividualExtractedInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const { id, visaType } = useParams();

  const indivFirstName = useSelector(individualSelectors.selectFirstName);
  const indivLastName = useSelector(individualSelectors.selectLastName);
  const indivId = id ?? useSelector(individualSelectors.selectUid);

  const standardDocJob = useSelector((state: ExtractionReducer) =>
    extractionSelectors.selectJobById(state, "Standard Documents")
  );

  const {
    onSaveSummaryPoints,
    onSubmitAddI94ExtractInfo,
    onSubmitAddVisaExtractInfo,
    onSubmitAddResumeExtractInfo,
    onSubmitAddPassExtractInfo,
    isLoadingGetExtractedData,
    isLoadingSaveSummaryPoints,
    isLoadingSummaryPoints,
    onSubmitAddEmptyData,
    isExtractadDataNotFound
  } = useAddExtractInfo(indivId);

  const [showSidebar, setShowSidebar] = useState(false);

  const extractedData = useSelector(documentSelectors.extractedData);

  const shouldSubmitData = (values: any, fields: string[]) => {
    return !fields.every((field) => values[field] === "");
  };

  const shouldSubmitPassportData = (values: any) => {
    const fields = [
      "passportFirstName",
      "passportMiddleName",
      "passportLastName",
      "passportDoB",
      "passportNumber",
      "passportDateOfIssue",
      "passportExpirationDate",
      "passportCountry"
    ];
    return shouldSubmitData(values, fields);
  };

  const shouldSubmitResumeData = (values: any) => {
    const fields = [
      "resumeFirstName",
      "resumeMiddleName",
      "resumeLastName",
      "resumeEmailAddress",
      "resumeLastRole",
      "resumeLastEmployer",
      "resumeLastRoleStartDate",
      "resumeLastRoleEndDate",
      "resumeFirstRoleBeforeLast",
      "resumeFirstEmployerBeforeLast",
      "resumeFirstBeforeLastRoleStartDate",
      "resumeFirstBeforeLastRoleEndDate",
      "resumeSecondRoleBeforeLast",
      "resumeSecondEmployerBeforeLast",
      "resumeSecondBeforeLastRoleStartDate",
      "resumeSecondBeforeLastRoleEndDate",
      "resumeLastEducationInstitution",
      "resumeLastEducationDegree",
      "resumeLastEducationDateObtained",
      "resumeFirstBeforeLastEducationInstitution",
      "resumeFirstBeforeLastEducationDegree",
      "resumeFirstBeforeLastEducationDateObtained",
      "resumeSecondBeforeLastEducationInstitution",
      "resumeSecondBeforeLastEducationDegree",
      "resumeSecondBeforeLastEducationDateObtained"
    ];
    return shouldSubmitData(values, fields);
  };

  const shouldSubmitVisaData = (values: any) => {
    const fields = [
      "visaFirstName",
      "visaMiddleName",
      "visaLastName",
      "visaPassportNumber",
      "visaControlNumber",
      "visaNumber",
      "visaType",
      "visaIssueDate",
      "visaExpirationDate"
    ];
    return shouldSubmitData(values, fields);
  };

  const shouldSubmitI94Data = (values: any) => {
    const fields = [
      "mostRecentDateOfEntry",
      "recordNumber",
      "authorizedStayExpirationDate",
      "classOfAdmission"
    ];
    return shouldSubmitData(values, fields);
  };

  const shouldSubmitSummaryPoints = (values: any) => {
    if (extractedData?.summaryPointsHtml || extractedData?.summaryPoints) {
      return (
        extractedData.summaryPointsHtml !== values.summaryPointsHtml ||
        extractedData.summaryPoints !== values.summaryPoints
      );
    }
    return true;
  };

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      // Passport
      passportFirstName: "",
      passportMiddleName: "",
      passportLastName: "",
      passportDoB: "",
      passportNumber: "",
      passportDateOfIssue: "",
      passportExpirationDate: "",
      passportCountry: "",

      // Resume
      resumeFirstName: "",
      resumeMiddleName: "",
      resumeLastName: "",
      resumeEmailAddress: "",
      resumeLastRole: "",
      resumeLastEmployer: "",
      resumeLastRoleStartDate: "",
      resumeLastRoleEndDate: "",
      resumeFirstRoleBeforeLast: "",
      resumeFirstEmployerBeforeLast: "",
      resumeFirstBeforeLastRoleStartDate: "",
      resumeFirstBeforeLastRoleEndDate: "",
      resumeSecondRoleBeforeLast: "",
      resumeSecondEmployerBeforeLast: "",
      resumeSecondBeforeLastRoleStartDate: "",
      resumeSecondBeforeLastRoleEndDate: "",
      resumeLastEducationInstitution: "",
      resumeLastEducationDegree: "",
      resumeLastEducationDateObtained: "",
      resumeFirstBeforeLastEducationInstitution: "",
      resumeFirstBeforeLastEducationDegree: "",
      resumeFirstBeforeLastEducationDateObtained: "",
      resumeSecondBeforeLastEducationInstitution: "",
      resumeSecondBeforeLastEducationDegree: "",
      resumeSecondBeforeLastEducationDateObtained: "",

      // I94 record if any
      mostRecentDateOfEntry: "",
      recordNumber: "",
      authorizedStayExpirationDate: "",
      classOfAdmission: "",

      // Visa
      visaFirstName: "",
      visaMiddleName: "",
      visaLastName: "",
      visaPassportNumber: "",
      visaControlNumber: "",
      visaNumber: "",
      visaType: "",
      visaIssueDate: "",
      visaExpirationDate: "",

      // Summary Points
      summaryPoints: "",
      summaryPointsHtml: ""
    },
    onSubmit: async (values) => {
      if (shouldSubmitPassportData(values)) {
        const {
          passportFirstName,
          passportMiddleName,
          passportLastName,
          passportDoB,
          passportNumber,
          passportDateOfIssue,
          passportExpirationDate,
          passportCountry
        } = values;
        const passport = {
          passportFirstName,
          passportMiddleName,
          passportLastName,
          passportDoB,
          passportNumber,
          passportDateOfIssue,
          passportExpirationDate,
          passportCountry
        };
        await onSubmitAddPassExtractInfo({
          firstName: indivFirstName,
          lastName: indivLastName,
          passport
        });
      }

      if (shouldSubmitVisaData(values)) {
        await onSubmitAddVisaExtractInfo({
          firstName: indivFirstName,
          lastName: indivLastName,
          visa: {
            visaFirstName: values.visaFirstName,
            visaMiddleName: values.visaMiddleName,
            visaLastName: values.visaLastName,
            visaPassportNumber: values.visaPassportNumber,
            visaControlNumber: values.visaControlNumber,
            visaNumber: values.visaNumber,
            visaType: values.visaType,
            visaIssueDate: values.visaIssueDate,
            visaExpirationDate: values.visaExpirationDate
          }
        });
      }
      if (shouldSubmitResumeData(values)) {
        const {
          resumeFirstName,
          resumeMiddleName,
          resumeLastName,
          resumeEmailAddress,
          resumeLastRole,
          resumeLastEmployer,
          resumeLastRoleStartDate,
          resumeLastRoleEndDate,
          resumeFirstRoleBeforeLast,
          resumeFirstEmployerBeforeLast,
          resumeFirstBeforeLastRoleStartDate,
          resumeFirstBeforeLastRoleEndDate,
          resumeSecondRoleBeforeLast,
          resumeSecondEmployerBeforeLast,
          resumeSecondBeforeLastRoleStartDate,
          resumeSecondBeforeLastRoleEndDate,
          resumeLastEducationInstitution,
          resumeLastEducationDegree,
          resumeLastEducationDateObtained,
          resumeFirstBeforeLastEducationInstitution,
          resumeFirstBeforeLastEducationDegree,
          resumeFirstBeforeLastEducationDateObtained,
          resumeSecondBeforeLastEducationInstitution,
          resumeSecondBeforeLastEducationDegree,
          resumeSecondBeforeLastEducationDateObtained
        } = values;

        const resume = {
          resumeFirstName,
          resumeMiddleName,
          resumeLastName,
          resumeEmailAddress,
          resumeLastRole,
          resumeLastEmployer,
          resumeLastRoleStartDate,
          resumeLastRoleEndDate,
          resumeFirstRoleBeforeLast,
          resumeFirstEmployerBeforeLast,
          resumeFirstBeforeLastRoleStartDate,
          resumeFirstBeforeLastRoleEndDate,
          resumeSecondRoleBeforeLast,
          resumeSecondEmployerBeforeLast,
          resumeSecondBeforeLastRoleStartDate,
          resumeSecondBeforeLastRoleEndDate,
          resumeLastEducationInstitution,
          resumeLastEducationDegree,
          resumeLastEducationDateObtained,
          resumeFirstBeforeLastEducationInstitution,
          resumeFirstBeforeLastEducationDegree,
          resumeFirstBeforeLastEducationDateObtained,
          resumeSecondBeforeLastEducationInstitution,
          resumeSecondBeforeLastEducationDegree,
          resumeSecondBeforeLastEducationDateObtained
        };
        await onSubmitAddResumeExtractInfo({
          firstName: indivFirstName,
          lastName: indivLastName,
          resume
        });
      }
      if (shouldSubmitI94Data(values)) {
        const {
          mostRecentDateOfEntry,
          recordNumber,
          authorizedStayExpirationDate,
          classOfAdmission
        } = values;
        await onSubmitAddI94ExtractInfo({
          firstName: indivFirstName,
          lastName: indivLastName,
          i94: {
            mostRecentDateOfEntry,
            recordNumber,
            authorizedStayExpirationDate,
            classOfAdmission
          }
        });
      }
      if (shouldSubmitSummaryPoints(values)) {
        await onSaveSummaryPoints({
          firstName: indivFirstName,
          lastName: indivLastName,
          summaryPoints: values.summaryPoints || "",
          summaryPointsHtml: values.summaryPointsHtml || ""
        });
      }

      toast({
        description: "Information updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right"
      });
      navigate(`/individual/${id}/${visaType}/documents/standard-documents`, {
        replace: true
      });
    }
  });

  useEffect(() => {
    if (isExtractadDataNotFound) {
      onSubmitAddEmptyData();
    }
  }, [isExtractadDataNotFound]);

  useEffect(() => {
    if (!extractedData?.data) return;
    // PASSPORT
    if (extractedData && extractedData.data.passport) {
      formik.setFieldValue(
        "passportFirstName",
        extractedData.data.passport.passportFirstName || "",
        false
      );
      formik.setFieldValue(
        "passportMiddleName",
        extractedData.data.passport.passportMiddleName || "",
        false
      );
      formik.setFieldValue(
        "passportLastName",
        extractedData.data.passport.passportLastName || "",
        false
      );

      formik.setFieldValue(
        "passportDoB",
        dayjs(extractedData.data.passport.passportDoB).format("YYYY-MM-DD"),
        false
      );

      formik.setFieldValue(
        "passportNumber",
        extractedData.data.passport.passportNumber || "",
        false
      );

      formik.setFieldValue(
        "passportDateOfIssue",
        dayjs(extractedData.data.passport.passportDateOfIssue).format(
          "YYYY-MM-DD"
        ) || "",
        false
      );

      formik.setFieldValue(
        "passportExpirationDate",
        dayjs(extractedData.data.passport.passportExpirationDate).format(
          "YYYY-MM-DD"
        ) || "",
        false
      );

      formik.setFieldValue(
        "passportCountry",
        extractedData.data.passport.passportCountry || "",
        false
      );
    }

    // RESUME
    if (extractedData && extractedData.data.resume) {
      formik.setFieldValue(
        "resumeFirstName",
        extractedData.data.resume.resumeFirstName || "",
        false
      );
      formik.setFieldValue(
        "resumeMiddleName",
        extractedData.data.resume.resumeMiddleName || "",
        false
      );
      formik.setFieldValue(
        "resumeLastName",
        extractedData.data.resume.resumeLastName || "",
        false
      );
      formik.setFieldValue(
        "resumeEmailAddress",
        extractedData.data.resume.resumeEmailAddress || "",
        false
      );
      formik.setFieldValue(
        "resumeLastRole",
        extractedData.data.resume.resumeLastRole || "",
        false
      );
      formik.setFieldValue(
        "resumeLastEmployer",
        extractedData.data.resume.resumeLastEmployer || "",
        false
      );
      formik.setFieldValue(
        "resumeLastRoleStartDate",
        extractedData.data.resume.resumeLastRoleStartDate || "",
        false
      );
      formik.setFieldValue(
        "resumeLastRoleEndDate",
        extractedData.data.resume.resumeLastRoleEndDate || "",
        false
      );
      formik.setFieldValue(
        "resumeFirstRoleBeforeLast",
        extractedData.data.resume.resumeFirstRoleBeforeLast || "",
        false
      );
      formik.setFieldValue(
        "resumeFirstEmployerBeforeLast",
        extractedData.data.resume.resumeFirstEmployerBeforeLast || "",
        false
      );
      formik.setFieldValue(
        "resumeFirstBeforeLastRoleStartDate",
        extractedData.data.resume.resumeFirstBeforeLastRoleStartDate || "",
        false
      );
      formik.setFieldValue(
        "resumeFirstBeforeLastRoleEndDate",
        extractedData.data.resume.resumeFirstBeforeLastRoleEndDate || "",
        false
      );
      formik.setFieldValue(
        "resumeSecondRoleBeforeLast",
        extractedData.data.resume.resumeSecondRoleBeforeLast || "",
        false
      );
      formik.setFieldValue(
        "resumeSecondEmployerBeforeLast",
        extractedData.data.resume.resumeSecondEmployerBeforeLast || "",
        false
      );
      formik.setFieldValue(
        "resumeSecondBeforeLastRoleStartDate",
        extractedData.data.resume.resumeSecondBeforeLastRoleStartDate || "",
        false
      );
      formik.setFieldValue(
        "resumeSecondBeforeLastRoleEndDate",
        extractedData.data.resume.resumeSecondBeforeLastRoleEndDate || "",
        false
      );
      formik.setFieldValue(
        "resumeLastEducationInstitution",
        extractedData.data.resume.resumeLastEducationInstitution || "",
        false
      );
      formik.setFieldValue(
        "resumeLastEducationDegree",
        extractedData.data.resume.resumeLastEducationDegree || "",
        false
      );
      formik.setFieldValue(
        "resumeLastEducationDateObtained",
        extractedData.data.resume.resumeLastEducationDateObtained || "",
        false
      );
      formik.setFieldValue(
        "resumeFirstBeforeLastEducationInstitution",
        extractedData.data.resume.resumeFirstBeforeLastEducationInstitution ||
          "",
        false
      );
      formik.setFieldValue(
        "resumeFirstBeforeLastEducationDegree",
        extractedData.data.resume.resumeFirstBeforeLastEducationDegree || "",
        false
      );
      formik.setFieldValue(
        "resumeFirstBeforeLastEducationDateObtained",
        extractedData.data.resume.resumeFirstBeforeLastEducationDateObtained ||
          "",
        false
      );
      formik.setFieldValue(
        "resumeSecondBeforeLastEducationInstitution",
        extractedData.data.resume.resumeSecondBeforeLastEducationInstitution ||
          "",
        false
      );
      formik.setFieldValue(
        "resumeSecondBeforeLastEducationDegree",
        extractedData.data.resume.resumeSecondBeforeLastEducationDegree || "",
        false
      );
      formik.setFieldValue(
        "resumeSecondBeforeLastEducationDateObtained",
        extractedData.data.resume.resumeSecondBeforeLastEducationDateObtained ||
          "",
        false
      );
    }

    if (extractedData && extractedData.data.visa) {
      formik.setFieldValue(
        "visaFirstName",
        extractedData.data.visa.visaFirstName || "",
        false
      );
      formik.setFieldValue(
        "visaMiddleName",
        extractedData.data.visa.visaMiddleName || "",
        false
      );
      formik.setFieldValue(
        "visaLastName",
        extractedData.data.visa.visaLastName || "",
        false
      );
      formik.setFieldValue(
        "visaPassportNumber",
        extractedData.data.visa.visaPassportNumber || "",
        false
      );
      formik.setFieldValue(
        "visaControlNumber",
        extractedData.data.visa.visaControlNumber || "",
        false
      );
      formik.setFieldValue(
        "visaNumber",
        extractedData.data.visa.visaNumber || "",
        false
      );
      formik.setFieldValue(
        "visaType",
        extractedData.data.visa.visaType || "",
        false
      );
      formik.setFieldValue(
        "visaIssueDate",
        dayjs(extractedData.data.visa.visaIssueDate).format("YYYY-MM-DD") || "",
        false
      );
      formik.setFieldValue(
        "visaExpirationDate",
        dayjs(extractedData.data.visa.visaExpirationDate).format(
          "YYYY-MM-DD"
        ) || "",
        false
      );
    }
    if (extractedData && extractedData.data.i94) {
      formik.setFieldValue(
        "recordNumber",
        extractedData.data.i94.recordNumber || "",
        false
      );
      formik.setFieldValue(
        "mostRecentDateOfEntry",
        dayjs(extractedData.data.i94.mostRecentDateOfEntry).format(
          "YYYY-MM-DD"
        ) || "",
        false
      );
      formik.setFieldValue(
        "authorizedStayExpirationDate",
        dayjs(extractedData.data.i94.authorizedStayExpirationDate).format(
          "YYYY-MM-DD" || ""
        ),
        false
      );
      formik.setFieldValue(
        "classOfAdmission",
        extractedData.data.i94.classOfAdmission || "",
        false
      );
    }
    if (extractedData && extractedData.summaryPoints) {
      formik.setFieldValue("summaryPoints", extractedData.summaryPoints, false);
    }
    if (extractedData && extractedData.summaryPointsHtml) {
      formik.setFieldValue(
        "summaryPointsHtml",
        extractedData.summaryPointsHtml,
        false
      );
    }
  }, [extractedData]);

  useEffect(() => {
    console.log("summaryPointsHtml", formik.values.summaryPointsHtml);
    console.log("summaryPoints", formik.values.summaryPoints);
  }, [formik.values.summaryPointsHtml, formik.values.summaryPoints]);

  const [passportUrl, setPassportUrl] = useState<DocUrl | null>(null);
  const [i94Url, setI94Url] = useState<DocUrl | null>(null);
  const [resumeUrl, setResumeUrl] = useState<DocUrl | null>(null);
  const [visaUrl, setVisaUrl] = useState<DocUrl | null>(null);

  return (
    <Container>
      <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
        <SpecialHeading
          backNav
          title="Extracted information from standard documents"
        />
        <CustomBox type="info">
          <Text fontSize="18px">
            The following data was extracted from the documents you uploaded.
            Make edits as needed.
          </Text>
        </CustomBox>
        <Box
          border="1px"
          borderRadius={4}
          borderColor="secondary.lightGray"
          padding={4}
          className="flex flex-col gap-4"
        >
          <ResumeForm
            formik={formik}
            values={formik.values}
            handleChange={formik.handleChange}
            resumeUrl={resumeUrl}
          />

          <Divider />

          <PasseportForm
            values={formik.values}
            passportUrl={passportUrl}
            handleChange={formik.handleChange}
          />

          <Divider />

          <VisaForm
            handleChange={formik.handleChange}
            values={formik.values}
            visaUrl={visaUrl}
          />
          <Divider />
          <I94Form
            handleChange={formik.handleChange}
            values={formik.values}
            i94DocumentUrl={i94Url}
          />

          <Divider />
        </Box>

        <div className="flex justify-center">
          <Button
            my={4}
            className="btn tertiary-btn"
            type="button"
            onClick={() => {
              if (location.key === "default") {
                navigate(
                  `/individual/${id}/${visaType}/documents/standard-documents`
                );
              } else {
                navigate(-1);
              }
            }}
            mx="15px"
          >
            Cancel
          </Button>

          <Button
            my={4}
            className="btn secondary-btn"
            mx="15px"
            type="submit"
            isLoading={
              isLoadingSummaryPoints ||
              isLoadingSaveSummaryPoints ||
              isLoadingGetExtractedData
            }
          >
            Save
          </Button>
        </div>
      </form>
    </Container>
  );
};
