import { ChangeEventHandler } from "react";
import { useSelector } from "react-redux";
import { Flex, FormControl, Input, FormLabel, Box, Text } from "./_index";

import styles from "../styles/extracted-info.module.css";
import Thumbnail from "../components/individualTabs/individualDocuments/Thumbnail";
import { DocUrl } from "../types/tables-data";
import { DocumentReducer } from "../redux/documents/documentSlice";
import { documentSelectors } from "../redux/documents/selectors";

export type VisaFormProps = {
  visaUrl: DocUrl | null;
  values: {
    visaFirstName: string;
    visaMiddleName: string;
    visaLastName: string;
    visaPassportNumber: string;
    visaControlNumber: string;
    visaNumber: string;
    visaType: string;
    visaIssueDate: string;
    visaExpirationDate: string;
  };
  handleChange: ChangeEventHandler<any>;
};
export const VisaForm = ({ handleChange, values, visaUrl }: VisaFormProps) => {
  const visaDocument = useSelector((state: DocumentReducer) =>
    documentSelectors.selectDocumentByType(state, "visa")
  );
  return (
    <Box>
      <Text fontWeight={550} fontSize={20} color="primary.darkBlue">
        Visa
      </Text>
      <Flex flexDirection="column" mt={8}>
        <Flex flexDirection="column">
          <Flex className={styles.document_section}>
            <Flex flexDirection="column">
              {/* check with team if we need passport info with visa */}
              {/* <FormControl variant="floating" id="visaFirstName" my={4}>
              <Input
                placeholder=" "
                name="visaFirstName"
                value={values.visaFirstName}
                onChange={handleChange}
              />
              <FormLabel>First Name</FormLabel>
            </FormControl>

            <FormControl variant="floating" id="visaMiddleName" my={4}>
              <Input
                placeholder=" "
                name="visaMiddleName"
                value={values.visaMiddleName}
                onChange={handleChange}
              />
              <FormLabel>Middle Name</FormLabel>
            </FormControl>

            <FormControl variant="floating" id="visaLastName" my={4}>
              <Input
                placeholder=" "
                name="visaLastName"
                value={values.visaLastName}
                onChange={handleChange}
              />
              <FormLabel>Last Name</FormLabel>
            </FormControl> */}

              {/* <FormControl variant="floating" id="visaPassportNumber" my={4}>
              <Input
                placeholder=" "
                name="visaPassportNumber"
                value={values.visaPassportNumber}
                onChange={handleChange}
              />
              <FormLabel>Passport Number</FormLabel>
            </FormControl>

            <FormControl variant="floating" id="visaControlNumber" my={4}>
              <Input
                placeholder=" "
                name="visaControlNumber"
                value={values.visaControlNumber}
                onChange={handleChange}
              />
              <FormLabel>Control Number</FormLabel>
            </FormControl> */}

              <Box className="flex gap-4">
                <FormControl variant="floating" id="visaNumber" my={4}>
                  <Input
                    placeholder=" "
                    name="visaNumber"
                    value={values.visaNumber}
                    onChange={handleChange}
                  />
                  <FormLabel>Visa Number</FormLabel>
                </FormControl>

                <FormControl variant="floating" id="visaType" my={4}>
                  <Input
                    placeholder=" "
                    name="visaType"
                    value={values.visaType}
                    onChange={handleChange}
                  />
                  <FormLabel>Visa Type</FormLabel>
                </FormControl>
              </Box>

              <Box className="flex gap-4">
                <FormControl variant="floating" id="visaIssueDate" my={4}>
                  <Input
                    placeholder=" "
                    name="visaIssueDate"
                    type="date"
                    min="1900-01-01"
                    max="2100-01-01"
                    value={values.visaIssueDate}
                    onChange={handleChange}
                  />
                  <FormLabel>Issue Date</FormLabel>
                </FormControl>

                <FormControl variant="floating" id="visaExpirationDate" my={4}>
                  <Input
                    placeholder=" "
                    name="visaExpirationDate"
                    type="date"
                    min="1900-01-01"
                    max="2100-01-01"
                    value={values.visaExpirationDate}
                    onChange={handleChange}
                  />
                  <FormLabel>Expiration Date</FormLabel>
                </FormControl>
              </Box>
            </Flex>
            <Flex>
              {" "}
              {visaDocument?.filePath && (
                <Thumbnail filePath={visaDocument.filePath} />
              )}
            </Flex>
          </Flex>
        </Flex>

        {/* )} */}
      </Flex>
    </Box>
  );
};
