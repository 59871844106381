import { useDispatch } from "react-redux";
import { fetchDocumentCategoriesAndTypes } from "../api/OpenAIApi";
import { setDocumentTypes } from "../redux/documents/documentSlice";

export const useGetDocumentTypes = () => {
  const dispatch = useDispatch();

  const fetchDocumentTypesAndUpdate = async () => {
    const data = await fetchDocumentCategoriesAndTypes();
    dispatch(setDocumentTypes(data));
  };

  return { fetchDocumentTypesAndUpdate };
};
