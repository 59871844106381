import { ClientState, ClientsReducer } from "./clientsSlice";

const selectAll = (state: ClientsReducer) => state.clients;

const selectClientById = (state: ClientsReducer, id: string) =>
  state.clients.find((el) => el.id === id) as ClientState;
export const clientsSelectors = {
  selectAll,
  selectClientById
};
