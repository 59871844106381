import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

export const tabsTheme = defineMultiStyleConfig({
  baseStyle: {
    tabpanel: {
      padding: 0,
    },
    root: {
      width: "100%",
      textColor: "secondary.softGray",
      fontWeight: "700",
      _selected: { textColor: "primary.darkBlue" },
    },
    tab: {
      _selected: { textColor: "primary.darkBlue" },
      textTransform: "uppercase",
    },
    indicator: {
      _selected: { borderColor: "red" },
      _active: { borderColor: "red" },
      color: "red",
      bg: "red",
      borderColor: "red",
      padding: 0,
    },
  },
  variants: {
    sub: {
      tab: {
        mt: 6,
        bg: "secondary.lightGray",
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        color: "secondary.softGray",
        _selected: {
          color: "white",
          bg: "primary.red",
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
        },
        textTransform: "capitalize",
      },
    },
  },
});
