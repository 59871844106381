/* eslint-disable no-nested-ternary */
import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Flex,
  Heading,
  Select,
  Text
} from "@chakra-ui/react";
import { useState } from "react";
import { SpecialHeading } from "../components/individualTabs/PanelLayout";
import useGroupedEvidenceDocuments from "../hooks/useGroupedEvidenceDocuments";
import { DocumentsSlider } from "./DocumentSlider";

export interface TextChange {
  current: string;
  original: string;
}

const LoadingState = () => (
  <Flex justify="center" align="center" h="100%">
    <CircularProgress isIndeterminate />
  </Flex>
);

const NoDocumentsState = () => (
  <Flex direction="column" align="center" justify="center" h="50%">
    <Heading size="md" textColor="primary.darkBlue" mb={2}>
      No Documents Available
    </Heading>
    <Text textAlign="center" fontSize="sm">
      It looks like there are no evidence documents uploaded at the moment.
      <br />
      Please upload documents to start reviewing and extracting information.
    </Text>
  </Flex>
);

export const IndividualExtractedEvidence = () => {
  const { groupedDocuments, isLoadingGetEvidence } =
    useGroupedEvidenceDocuments();
  const [categoryIndex, setCategoryIndex] = useState(0);

  return (
    <Container>
      <SpecialHeading
        title="Extracted Information from evidence document"
        ml={2}
        backNav
      />

      {isLoadingGetEvidence || groupedDocuments === null ? (
        <LoadingState />
      ) : groupedDocuments.length > 0 ? (
        <div className="flex flex-col justify-center">
          <Select
            textColor="white"
            color="white"
            className="!bg-[#00004d] "
            onChange={(e) => {
              const indx = Number(e.target.value);
              setCategoryIndex(indx);
            }}
            value={categoryIndex}
          >
            {groupedDocuments.map((group, indx) => (
              <option className="!bg-[#00004d]" key={indx} value={indx}>
                {indx + 1}. {group.type}
              </option>
            ))}
          </Select>

          <Divider my={2} />
          <DocumentsSlider
            categoryIndex={categoryIndex}
            documents={groupedDocuments[categoryIndex]?.subrows ?? []}
          />
        </div>
      ) : (
        <NoDocumentsState />
      )}
    </Container>
  );
};
