import { createSelector } from "@reduxjs/toolkit";
import { ExtractionJob, ExtractionReducer, isActiveStatus } from "./extractionJobsSlice";

const selectAll = (state: ExtractionReducer) => state.extraction;

const jobs = (state: ExtractionReducer) => selectAll(state).jobs;
const newJob = (state: ExtractionReducer) => selectAll(state).newJob;
const completedJob = (state: ExtractionReducer) =>
  selectAll(state).completedJob;
const selectJobById = (state: ExtractionReducer, id: string) =>
  state.extraction.jobs.find((job) => job.id === id) as ExtractionJob;

const selectJobsByType = (
  state: ExtractionReducer,
  type: string
): ExtractionJob[] | undefined => {
  // Access the jobs array from the state
  const {jobs} = state.extraction;

  // Filter the jobs by the provided type
  const filteredJobs = jobs.filter((job) => job.type === type);

  // Return the filtered jobs array
  return filteredJobs.length ? filteredJobs : [];
};

export const selectActiveJobs = createSelector(
  (state: ExtractionReducer) => state.extraction.jobs,
  (jobs: ExtractionJob[]) =>
    jobs.filter((job) => isActiveStatus(job.status?.status))
);

export const extractionSelectors = {
  selectAll,
  jobs,
  newJob,
  completedJob,
  selectJobById,
  selectJobsByType,
  selectActiveJobs
};
