import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Input,
  Button,
  Flex,
  Badge,
  Heading,
  Card,
  Spacer,
  CardFooter
} from "@chakra-ui/react";
import { clickedStyles } from "../../../../../styles/common-styles";
import styles from "../../../../../styles/chatbot.module.css";

export const Chatbot = () => {
  const navigate = useNavigate();
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [displayedMessages, setDisplayedMessages] = useState<Message[]>([]);
  const suggestedAnswers = [
    "Yes",
    "No",
    "Skip",
    "Not applicable",
    "Clarify question"
  ];
  const maxMessages = 6; // Adjust this value as needed

  type Message = {
    text: string;
    sender: string;
  };

  const sendMessage = (message: string) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: message, sender: "user" }
    ]);
    setInputValue("");
  };

  const handleSuggestedAnswerClick = (answer: string) => {
    sendMessage(answer);
  };

  useEffect(() => {
    const newDisplayedMessages = messages.slice(-maxMessages);
    setDisplayedMessages(newDisplayedMessages);
  }, [messages]);

  return (
    <Flex flexDirection="column" w="100%" alignItems="center">
      <Card mb="30px" w="100%">
        <Flex flexDirection="column" height="70vh" rowGap="20px">
          <Box py="15px" sx={clickedStyles} w="100%" textAlign="center">
            <Heading size="md">Immigration form chatbot</Heading>
          </Box>
          <Flex
            direction="column"
            gap="10px"
            overflowY="auto"
            px={4}
            py={2}
            height="40%"
          >
            {displayedMessages.map((message, index) => (
              <Badge
                key={index}
                colorScheme={message.sender === "user" ? "blue" : "green"}
                p={2}
                borderRadius="lg"
                className={
                  message.sender === "user"
                    ? styles.user_bubble
                    : styles.bot_bubble
                }
              >
                {message.text}
              </Badge>
            ))}
          </Flex>
          <Spacer />
          <Flex
            alignSelf="center"
            flexDirection="column"
            alignItems="center"
            rowGap="6px"
          >
            <Flex px={4}>
              {suggestedAnswers.slice(0, 3).map((answer, index) => (
                <Button
                  key={index}
                  onClick={() => handleSuggestedAnswerClick(answer)}
                  ml={index > 0 ? 2 : 0}
                >
                  {answer}
                </Button>
              ))}
            </Flex>
            <Flex width="100%" px={4}>
              {suggestedAnswers
                .slice(3, suggestedAnswers.length)
                .map((answer, index) => (
                  <Button
                    key={index}
                    onClick={() => handleSuggestedAnswerClick(answer)}
                    ml={index > 0 ? 2 : 0}
                  >
                    {answer}
                  </Button>
                ))}
            </Flex>
          </Flex>
          <Flex p={4} boxShadow="md" borderRadius="md" width="100%">
            <Input
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Respond or ask any follow up question..."
              flexGrow={1}
            />
            <Button
              bg="bgColor.300"
              color="text.100"
              ml={2}
              onClick={() => sendMessage(inputValue)}
            >
              Send
            </Button>
          </Flex>
        </Flex>
      </Card>
      <Flex>
        <Button
          bg="bgColor.300"
          color="text.100"
          type="button"
          onClick={() => navigate(-1)}
          mx="15px"
        >
          Cancel
        </Button>
        {/* {briefBlurbsArgs && !extractedData?.prongArguments && ( */}

        {/* <TextFromApiBlock text={briefBlurbsArgs} title="Arguments" /> */}
        <Button
          bg="bgColor.300"
          color="text.100"
          mx="15px"
          type="button"
          onClick={() => navigate(-1)}
          // isLoading={isLoadingSaveProngArguments}
        >
          Save
        </Button>
      </Flex>
    </Flex>
  );
};
