import { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { db } from "../api/firebaseApi";
import { DATABASE } from "../types/tables-data";
import {
  ExpertLetter,
  FirstForm,
  SecondForm
} from "../redux/clients/clientsSlice";

import { individualSelectors } from "../redux/individual/selectors";

export const useUpdateFormsData = () => {
  const [isLoadingUpdateFormsData, setIsLoadingUpdateFormsData] =
    useState(false);
  const [errorUpdateFormsData, setErrorUpdateFormsData] = useState();
  const email = useSelector(individualSelectors.selectEmail);
  const params = useParams();

  const onSubmitUpdateFormsData = async (
    values: Record<string, ExpertLetter | FirstForm | SecondForm | null>
  ) => {
    setIsLoadingUpdateFormsData(true);
    try {
      const ref = doc(db, DATABASE.FORMS, `${params.caseID}`);
      const updatedData = {
        ...values,
        last_updated_by: email
      };

      await updateDoc(ref, updatedData);
    } catch (e: any) {
      const errorCode = e.code;
      console.log(e);
      setErrorUpdateFormsData(errorCode);
    } finally {
      setIsLoadingUpdateFormsData(false);
    }
  };

  return {
    isLoadingUpdateFormsData,
    onSubmitUpdateFormsData,
    errorUpdateFormsData
  };
};
