import { Box, Button, Flex, Skeleton, Text, useToast } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useExpertLetterDrafts } from "../../../hooks/useExpertLetterDrafts";
import { useGetIndivFormsData } from "../../../hooks/useGetIndivFormsData";
import { DocumentReducer } from "../../../redux/documents/documentSlice";
import { documentSelectors } from "../../../redux/documents/selectors";
import {
  EXTRACTIONSTATES,
  GenerationStatesDescription
} from "../../../redux/extraction-jobs/extractionJobsSlice";
import { extractionSelectors } from "../../../redux/extraction-jobs/selectors";
import { setActiveExpertLetter } from "../../../redux/individual/individualSlice";
import { individualSelectors } from "../../../redux/individual/selectors";
import { lawyerSelectors } from "../../../redux/lawyer/selectors";
import { ExpertLetterDraft } from "../../../types/expert-letter-draft";
import { DataDocs } from "../../../types/tables-data";
import { CustomBox } from "../../common/CustomBox";
import { CustomButton } from "../../common/CustomButton";
import { ExtractionCard } from "../../common/ExtractionCard";
import { DeleteExperLetterDialog } from "../individualQuestionnaires/expertLetter/DeleteExpertLetterDialog";
import { SpecialHeading } from "../PanelLayout";
import useDeleteExpertLetter from "../../../hooks/useDeleteIndivExpertLetter";
import { ExpertLetter } from "../../../redux/clients/clientsSlice";
import { useUserRoleAndSubscription } from "../../../hooks/useUserRoleAndSubscription";

export const ExpertLetterDraftsSection = () => {
  const loginType = localStorage.getItem("loginType");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, visaType } = useParams();
  const { isIndividual } = useUserRoleAndSubscription();
  const toast = useToast();
  const expertLetters = useSelector(individualSelectors.selectExpertLetters);
  const expertLettersDrafts = useSelector(
    individualSelectors.selectExpertsDrafts
  );

  const activeJobs = useSelector(extractionSelectors.selectActiveJobs);
  const reducedActiveJobs = activeJobs.map((job) => job.id);
  const [loading, setLoading] = useState<boolean>(true);
  const [showDeletionDialog, setShowDeletionDialog] = useState<boolean>(false);
  const [showGenerateOptions, setShowGenerateOptions] =
    useState<boolean>(false);
  const [documentToDelete, setDocumentToDelete] = useState<ExpertLetter | null>(
    null
  );
  useGetIndivFormsData(id);

  const candidateResume = useSelector((state: DocumentReducer) =>
    documentSelectors.selectDocumentByType(state, "resume" ?? "")
  );

  const { subscription } = useSelector(lawyerSelectors.selectAll);
  const isFreemium = subscription?.status === "freemium";

  const { deleteDraft } = useExpertLetterDrafts(id ?? "");
  const { onSubmitDeleteExpertLetter } = useDeleteExpertLetter(id!);

  // Input requirement
  const [missingRequirement, setMissingRequirement] = useState<
    string | undefined
  >(undefined);
  const [requirementMsg, setRequirementMsg] = useState<string | undefined>(
    undefined
  );

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const deleteDocumentConfirm = async () => {
    setShowDeletionDialog(false);
    if (documentToDelete?.uid) {
      await deleteDraft(documentToDelete.uid);
      await onSubmitDeleteExpertLetter(documentToDelete.uid);
      toast({
        title: "Success",
        description: "Expert letter deleted successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right"
      });
      scrollToTop();
    }
  };

  const onEdit = (expertId: string) => {
    const letter = expertLetters?.find((letter) => letter.uid === expertId);
    if (letter) {
      dispatch(setActiveExpertLetter(letter));
      navigate(
        `/individual/${id}/${visaType}/drafts/expert-letters/expert-letter-inputs/${expertId}`
      );
    }
  };

  const onView = (expertId: string) => {
    if (expertId) {
      navigate(
        `/individual/${id}/${visaType}/drafts/expert-letters/${expertId}/edit`
      );
    }
  };

  const onDelete = (expertId: string) => {
    const expertLetter = expertLetters?.find(
      (e: ExpertLetter) => e.uid === expertId
    );
    if (expertLetter) {
      setDocumentToDelete(expertLetter);
    }
    setShowDeletionDialog(true);
  };

  const resumeFieldsPresentAndNonEmpty = () => {
    return (
      candidateResume !== null &&
      (candidateResume as any)?.autoOCRText !== undefined &&
      (candidateResume as any)?.autoOCRText !== ""
    );
  };

  let content;

  try {
    if (expertLetters && expertLetters.length > 0) {
      // Combine expertLetters and expertLettersDrafts into a single array for sorting
      const combinedLetters = expertLetters.map((expertLetter) => {
        const draft =
          expertLettersDrafts instanceof Map
            ? expertLettersDrafts.get(expertLetter.uid!)
            : null;

        // Extract the expert name from both expertLetter and draft
        const expertName =
          expertLetter?.expertName ||
          draft?.extracted_argument?.letter_author ||
          "";

        return {
          expertLetter,
          draft,
          expertName
        };
      });

      // Sort the combined array based on expertName
      combinedLetters.sort((a, b) => a.expertName.localeCompare(b.expertName));

      // Render the sorted expert letters and drafts
      content = combinedLetters.map(({ expertLetter, draft }) => {
        const isLoading =
          !draft &&
          expertLetter?.uid &&
          reducedActiveJobs.includes(expertLetter.uid);

        if (isLoading) {
          return (
            <Box key={expertLetter.uid} w="100%" my={2}>
              <Skeleton height="72px" width="960px" borderRadius={4} />
            </Box>
          );
        }

        const statusMsg = draft?.status
          ? GenerationStatesDescription[draft.status.status]
          : GenerationStatesDescription.NotStarted;

        return (
          <Box key={expertLetter.uid} w="100%" my={2}>
            <ExtractionCard
              disableIcons={
                [
                  EXTRACTIONSTATES.Pending,
                  EXTRACTIONSTATES.Processing
                ].includes(draft?.status?.status as EXTRACTIONSTATES) || false
              }
              showIcons
              expertLetterId={expertLetter.uid}
              type="drafts/expert-letter"
              id={id}
              statusMsg={statusMsg}
              extractedData={draft || expertLetter}
              onExtractionDelete={() => onDelete(expertLetter.uid!)}
              onEdit={() => onEdit(expertLetter.uid!)}
              onView={() => onView(expertLetter.uid!)}
            />
          </Box>
        );
      });
    }
  } catch (error) {
    console.error("Error processing expert letters:", error);
    // Handle the error (e.g., show a notification or fallback content)
    content = (
      <Box>
        <Text color="red.500">
          An error occurred while processing expert letters.
        </Text>
      </Box>
    );
  }

  const onGenerate = () => {
    navigate(
      `/individual/${id}/${visaType}/drafts/expert-letters/expert-letter-inputs/`
    );
  };

  const isGenerateDisabled = useMemo(() => {
    const checkIfRequirementsExist = (
      foundDocument: DataDocs | undefined,
      requirement: string
    ) => {
      // Inexisting dependency
      if (!foundDocument) {
        setMissingRequirement(requirement);
        setRequirementMsg(
          "To generate expert letters, please upload the necessary document:"
        );
        return true;
      }

      // Processing dependency
      if (foundDocument?.status?.status !== EXTRACTIONSTATES.Completed) {
        setMissingRequirement(requirement);
        setRequirementMsg(
          "Dependency document is processing. Please wait until it's complete:"
        );
        return true;
      }
      return false;
    };

    return checkIfRequirementsExist(candidateResume, "Candidate resume");
  }, [candidateResume]);

  return (
    <Flex flexDirection="column">
      <SpecialHeading title="Expert Letters" />
      {/* TODO: refactor link depending on missing requirement type */}
      {isGenerateDisabled && missingRequirement && (
        <CustomBox type="info">
          <Flex flexDirection="column">
            <Text>{requirementMsg}</Text>
            <Flex p={4} columnGap={2}>
              <Text as="span" fontWeight="bold">
                {missingRequirement}
              </Text>
              {missingRequirement.includes("resume") && !candidateResume && (
                <Text
                  as="a"
                  href="documents/standard-documents"
                  textDecoration="underline"
                  color="blue.500"
                >
                  Click here to upload
                </Text>
              )}
            </Flex>
          </Flex>
        </CustomBox>
      )}
      {/* Conditional Skeleton Loader Row */}
      {/* <Box w="100%" my={4} height="44px" borderRadius={2}>
        {newDocLoading && <Skeleton />}
      </Box> */}
      {content}
      <Flex mt={4} justifyContent="center">
        <CustomButton
          type="ai"
          title="Add Expert"
          isPremium
          isDisabled={!candidateResume}
          onClick={onGenerate}
        />
        {isIndividual && (
          <Button
            className="btn primary-btn"
            isDisabled={!candidateResume}
            onClick={onGenerate}
          >
            Add Expert
          </Button>
        )}
      </Flex>

      {showDeletionDialog && documentToDelete?.uid && (
        <DeleteExperLetterDialog
          isLoading={false}
          setShowDeleteDialog={setShowDeletionDialog}
          onConfirm={() => deleteDocumentConfirm()}
          expertName={documentToDelete.expertName || ""}
        />
      )}
    </Flex>
  );
};
