import {
  Flex,
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Button
} from "@chakra-ui/react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { ArrowBackIcon, Search2Icon } from "@chakra-ui/icons";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { Header } from "../components/Header";
import { Sidebar } from "../components/Sidebar/Sidebar";
import { DataTable } from "../components/DataTable";
import { ClientData, DataDocs } from "../types/tables-data";
import { CaseIdOverview } from "../components/CaseIdOverview";
import { CaseIdNotes } from "../components/CaseIdNotes";
import { CaseIdDocuments } from "../components/CaseIdDocuments";
import { CaseIdPacket } from "../components/CaseIdPacket";
import { clientsSelectors } from "../redux/clients/selectors";
import { clickedStyles, selectedTab } from "../styles/common-styles";
import { useGetClientDocuments } from "../hooks/useGetClientDocuments";
import { eb2Docs } from "../testTableData";
import { useAddExtractInfo } from "../hooks/useAddExtractInfo";
import { CaseIdQuestionnaires } from "../components/CaseIdQuestionnaires";
import { ClientState } from "../redux/clients/clientsSlice";

export const CurrentCaseId = () => {
  const [searchData, setSearchData] = useState("");
  const [showSidebar, setShowSidebar] = useState(false);
  const { onSubmitGetClientDocs, dbDocuments } = useGetClientDocuments();
  const { onSubmitAddEmptyData, isExtractadDataNotFound } = useAddExtractInfo();

  useEffect(() => {
    if (isExtractadDataNotFound) {
      onSubmitAddEmptyData();
    }
  }, [isExtractadDataNotFound]);

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const activeClients = useSelector(clientsSelectors.selectAll);
  const currentCaseData = activeClients.filter(
    (client) => `${client.id}` === params.caseID
  );
  const currentCaseIndex = activeClients.findIndex(
    (client) => `${client.id}` === params.caseID
  );

  const columnHelper = createColumnHelper<ClientState>();
  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: "Name"
    }),
    columnHelper.accessor("birthDay", {
      cell: (info) => dayjs(info.getValue()).format("MM/DD/YYYY"),
      header: "DoB",
      meta: {
        isNumeric: true
      }
    }),
    columnHelper.accessor("visa", {
      cell: (info) => info.getValue(),
      header: "Visa"
    }),
    columnHelper.accessor("citizenship", {
      cell: (info) => info.getValue() || "-",
      header: "Citizenship"
    }),
    columnHelper.accessor("createdDate", {
      cell: (info) => dayjs(info.getValue()).format("MM/DD/YYYY"),
      header: "Creation Date",
      meta: {
        isNumeric: true
      }
    }),
    columnHelper.accessor("status", {
      cell: (info) => `Step ${info.getValue()}`,
      header: "Status"
    }),
    columnHelper.accessor("caseId", {
      cell: (info) => info.getValue(),
      header: "Case ID",
      meta: {
        isNumeric: true
      }
    }),
    columnHelper.accessor("priority", {
      cell: (info) => info.getValue(),
      header: "Priority"
    }),
    columnHelper.accessor("actionNeeded", {
      cell: (info) => (info.getValue() ? "Yes" : "No"),
      header: "Action needed"
    })
  ];

  const getDefaultTabIndex = (pathname: string) => {
    let index = 0;

    const tabIndexesMap = {
      "/overview": 0,
      "/documents": 1,
      "/questionnaires": 2,
      "/notes": 3,
      "/packet": 4
    };

    Object.entries(tabIndexesMap).forEach(([path, value]) => {
      if (pathname.includes(path)) {
        index = value;
      }
    });

    return index;
  };
  const [docsTableData, setDocsTableData] = useState<DataDocs[]>([]);

  const getClientDocuments = async () => {
    if (params.caseID) {
      await onSubmitGetClientDocs();
    }
  };

  useEffect(() => {
    getClientDocuments();
  }, []);

  const updateDocsTableData = () => {
    const docs: DataDocs[] = [];
    const titles: string[] = [];
    dbDocuments?.forEach((data) => {
      docs.push(data.documents);
      titles.push(data.documents.documentTitle!);
    });
    const docsToUpload = eb2Docs.filter(
      (doc) => !titles.includes(doc?.documentTitle ?? "")
    );
    docs.push(...docsToUpload);
    setDocsTableData(docs);
  };

  useEffect(() => {
    updateDocsTableData();
  }, [dbDocuments]);

  return (
    <Flex alignItems="center" flexDirection="column" flex="1" mx="15px">
      <Box py="15px" width="100%" sx={clickedStyles} position="relative">
        <Box m="15px">
          <Box position="absolute" top="5px" left="-10px">
            <Button
              type="button"
              w="40px"
              h="40px"
              borderRadius="30%"
              bgColor="transparent"
              border="1px solid #000"
              _hover={{
                bgColor: "blue.500",
                border: "2px solid transparent",
                color: "#fff"
              }}
              onClick={() => navigate("/cases")}
              mx="15px"
            >
              <ArrowBackIcon w={6} h={6} />
            </Button>
          </Box>
          <DataTable columns={columns} data={currentCaseData} />
        </Box>
        <InputGroup my="15px">
          <InputLeftElement pointerEvents="none" marginLeft="15px">
            <Search2Icon color="gray.400" />
          </InputLeftElement>
          <Input
            type="search"
            size="md"
            placeholder="Search information within client records"
            value={searchData}
            onChange={(e) => setSearchData(e.target.value)}
            backgroundColor="#fff"
            mx="15px"
          />
        </InputGroup>
      </Box>
      <Tabs
        isFitted
        variant="enclosed"
        m="15px"
        width="100%"
        defaultIndex={getDefaultTabIndex(location.pathname)}
      >
        <TabList mb="1em">
          <Tab
            _selected={selectedTab}
            onClick={() => navigate(`/cases/${params.caseID}/overview`)}
          >
            Overview
          </Tab>
          <Tab
            _selected={selectedTab}
            onClick={() => navigate(`/cases/${params.caseID}/documents`)}
          >
            Documents
          </Tab>
          <Tab
            _selected={selectedTab}
            onClick={() => navigate(`/cases/${params.caseID}/questionnaires`)}
          >
            Questionnaires
          </Tab>
          <Tab
            _selected={selectedTab}
            onClick={() => navigate(`/cases/${params.caseID}/notes`)}
          >
            Notes
          </Tab>
          <Tab
            _selected={selectedTab}
            onClick={() => navigate(`/cases/${params.caseID}/packet`)}
          >
            Final packet
          </Tab>
        </TabList>
        {currentCaseData.length && (
          <TabPanels>
            <TabPanel>
              {currentCaseData[0].overview ? (
                <CaseIdOverview
                  data={currentCaseData[0].overview}
                  clientId={`${params.caseID}`}
                  clientIndex={currentCaseIndex}
                />
              ) : null}
            </TabPanel>

            <TabPanel>
              <CaseIdDocuments
                visaType={currentCaseData[0].visa || ""}
                data={docsTableData || []}
                clientId={params.caseID || ""}
                onSubmitGetClientDocs={onSubmitGetClientDocs}
                index={currentCaseIndex}
              />
            </TabPanel>
            <TabPanel>
              <CaseIdQuestionnaires
                index={currentCaseIndex}
                visaType={currentCaseData[0].visa || ""}
              />
            </TabPanel>
            <TabPanel>
              <CaseIdNotes
                data={currentCaseData[0].notes}
                index={currentCaseIndex}
              />
            </TabPanel>
            <TabPanel>
              <CaseIdPacket
                index={currentCaseIndex}
                data={docsTableData || []}
              />
            </TabPanel>
          </TabPanels>
        )}
      </Tabs>
    </Flex>
  );
};
