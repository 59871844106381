import { EditIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Flex,
  IconButton,
  Link,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RichTextEditor } from "../components/common/RichTextEditor";
import EditMainDocModal from "../components/individualTabs/individualDocuments/EditMainDocModal";
import { getFileName } from "../helpers/file_helpers";
import { useAddExtractInfo } from "../hooks/useAddExtractInfo";
import { updateDocument } from "../redux/documents/documentSlice";
import { documentSelectors } from "../redux/documents/selectors";
import { VisaDocumentType } from "../redux/documents/types";
import { DataDocs } from "../types/tables-data";

type ExtractedEvidenceItemProps = {
  evidenceId: string;
  exhibit: string;
  text: { current: string; original: string };
};

const ExtractedEvidenceItem = ({
  evidenceId,
  exhibit,
  text
}: ExtractedEvidenceItemProps) => {
  const navigate = useNavigate();
  const { id, visaType } = useParams<{ id: string; visaType: string }>();
  const evidenceDocs = useSelector(documentSelectors.evidenceDocs);
  const evidence = evidenceDocs.find((e) => e.id === evidenceId);
  const [summary, setSummary] = useState(text || { original: "", current: "" });
  const [isSaving, setIsSaving] = useState(false);
  const { onSaveEvidenceArguments } = useAddExtractInfo(id);
  const dispatch = useDispatch();

  const [documentToEdit, setDocumentToEdit] = useState<DataDocs | undefined>();

  const handleEditClick = (document: DataDocs) => {
    setDocumentToEdit({ ...document });
  };

  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose
  } = useDisclosure();

  useEffect(() => {
    if (documentToEdit) {
      onEditOpen();
    }
  }, [documentToEdit, onEditOpen]);

  return (
    <Box className="h-full">
      {evidence ? (
        <Box p={6} key={evidence.uid} className="border rounded-md my-2 h-full">
          <Flex className="flex items-center h-full">
            <Flex flexDirection="column" mb="4" className="h-full">
              <Text fontWeight="400" textAlign="left">
                Exhibit: {exhibit}
              </Text>
              <Text as="h2" fontWeight="700" textAlign="left">
                Title:{" "}
                <Text as="span" fontWeight="600">
                  {evidence.autoTitle}
                </Text>
                <br />
                Document Type:{" "}
                <Badge>
                  <Text as="span" fontWeight="600">
                    {" "}
                    {evidence.type || "N/A"}
                  </Text>
                </Badge>
              </Text>
              <Flex alignItems="center">
                <Text fontWeight="700" mr="2" className="max-w-[120ch]">
                  Link:{" "}
                  <Badge>
                    <Link
                      color="primary.darkBlue"
                      href={evidence.docUrl}
                      isExternal
                    >
                      {getFileName(evidence.filePath)}{" "}
                      <ExternalLinkIcon ml="2px" />
                    </Link>
                  </Badge>
                </Text>
              </Flex>
            </Flex>
            <IconButton
              bg="primary.darkBlue"
              icon={<EditIcon color="white" />}
              onClick={() => handleEditClick(evidence)}
              aria-label="Edit Evidence"
              className="ml-auto"
            />
          </Flex>
          <Box className="h-full">
            <RichTextEditor
              customHeight="auto"
              saveText={async (html, text) => {
                setIsSaving(true);
                await onSaveEvidenceArguments(evidence, text, html);
                dispatch(
                  updateDocument({
                    data: {
                      ...evidence,
                      extracted_argument: {
                        ...evidence.extracted_argument,
                        argument_text: text,
                        argument_html: html
                      }
                    },
                    type: VisaDocumentType.Evidence
                  })
                );
                setIsSaving(false);
              }}
              text={summary.current}
              updateText={(html, text) => {
                setSummary((prev) => ({ ...prev, current: text ?? "" }));
              }}
            />
          </Box>

          {documentToEdit !== undefined && (
            <EditMainDocModal
              isDeleting={false}
              document={documentToEdit}
              headerText="Edit Main Document"
              isOpen={isEditOpen}
              onClose={onEditClose}
              visaDocumentType={VisaDocumentType.Evidence}
            />
          )}
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};

export default ExtractedEvidenceItem;
