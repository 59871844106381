import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type Subscription = {
  status: "freemium" | "paid";
  stripeCustomerId?: string;
  planId?: string;
  remainingCases?: number;
  request?: boolean;
};

export interface LawyerState {
  firstName: string;
  middleName: string;
  lastName: string;
  pronouns: string;
  title: string;
  email: string;
  address: string;
  state: string;
  city: string;
  zipcode: string;
  phone: string;
  practiceName: string;
  lawFirm: string;
  lawFirmLogo?: string | null;
  loadingLogo?: boolean;
  website: string;
  avatarSrc: string;
  avatarName: string;
  uid: string;
  role: string;
  subscription?: Subscription;
  created_at?: string;
  hasAcceptedTerms: boolean;
}

export interface UpdateLawyer {
  firstName: string;
  middleName: string;
  lastName: string;
  title: string;
  lawFirm: string;
  address: string;
  phone: string;
  state: string;
  city: string;
  zipcode: string;
}

const initialState: LawyerState = {
  firstName: "",
  middleName: "",
  lastName: "",
  pronouns: "",
  title: "",
  email: "",
  address: "",
  state: "",
  city: "",
  zipcode: "",
  phone: "",
  practiceName: "",
  lawFirm: "",
  lawFirmLogo: null,
  loadingLogo: true,
  website: "",
  avatarSrc: "",
  avatarName: "",
  uid: "",
  role: "",
  hasAcceptedTerms: false
};
export interface LawyerReducer {
  lawyer: LawyerState;
}

export interface UpdateAvatar {
  avatarSrc: string;
  avatarName: string;
}

export const lawyerSlice = createSlice({
  name: "lawyer",
  initialState,
  reducers: {
    setLoadingLogo: (state, action: PayloadAction<boolean>) => {
      state.loadingLogo = action.payload;
    },
    setLogo: (state, action: PayloadAction<null | string>) => {
      state.lawFirmLogo = action.payload;
    },
    setLawyerData: (state, action: PayloadAction<LawyerState>) => {
      const { lawFirmLogo, ...rest } = action.payload;
      Object.assign(state, rest);
    },
    resetLawyerData: (state) => {
      const { lawFirmLogo, loadingLogo, ...rest } = initialState;
      Object.assign(state, rest);
    },
    setLawyerUID: (state, action: PayloadAction<string>) => {
      state.uid = action.payload;
    },
    updateLawyerEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    updateLawyerAvatar: (state, action: PayloadAction<UpdateAvatar>) => {
      state.avatarSrc = action.payload.avatarSrc;
      state.avatarName = action.payload.avatarName;
    },
    updateLawyerData: (state, { payload }: PayloadAction<UpdateLawyer>) => {
      return {
        ...state,
        ...payload
      };
    }
  }
});

export const {
  setLawyerData,
  setLawyerUID,
  resetLawyerData,
  updateLawyerData,
  updateLawyerEmail,
  updateLawyerAvatar,
  setLogo,
  setLoadingLogo
} = lawyerSlice.actions;

export default lawyerSlice.reducer;
