import React, { useState } from "react";
import { Box, Collapse, IconButton, VStack } from "@chakra-ui/react";
import { MinusIcon, AddIcon } from "@chakra-ui/icons";

type Props = {
  title: JSX.Element;
  content: JSX.Element;
};

const FaqDropdown = ({ title, content }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleIconClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    handleToggle();
  };

  return (
    <Box borderWidth="1px" borderRadius="md" my={2} p={4} onClick={handleToggle}>
      <VStack align="start">
        <Box display="flex" alignItems="center" width="100%">
          <Box
            flex="1"
            textAlign="left"
            fontSize="md"
            fontWeight="bold"
            color="primary.darkBlue"
          >
            {title}
          </Box>
          <Box
            transform={isOpen ? "rotate(180deg)" : "rotate(0deg)"}
            transition="transform 0.2s ease-in-out"
          >
            <IconButton
              color="primary.darkBlue"
              aria-label="Expand or collapse content"
              icon={isOpen ? <MinusIcon /> : <AddIcon />}
              size="sm"
              onClick={handleIconClick}
              transition="transform 0.2s"
            />
          </Box>
        </Box>
        <Collapse in={isOpen} animateOpacity>
          <Box
            fontSize="md"
            color="primary.darkBlue"
            fontWeight="lighter"
            pt={2}
          >
            {content}
          </Box>
        </Collapse>
      </VStack>
    </Box>
  );
};

export default FaqDropdown;
