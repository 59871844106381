export const clickedStyles = {
  backgroundColor: "rgba(127, 168, 248, 0.15)",
  border: "1px",
  borderColor: "rgba(0, 0, 0, 0)"
};
export const activeLinkStyles = {
  boxShadow: "lg",
  border: "1px",
  borderColor: "#fff",
  fontWeight: "700"
};

export const selectedTab = {
  color: "white",
  bg: "blue.500",
  borderRadius: "6px"
};
