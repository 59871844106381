import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DocumentData, DocumentReference } from "firebase/firestore";

// Define the research status enum
export enum RESEARCHSTATES {
  Pending = "Pending",
  InProgress = "In Progress",
  Completed = "Completed",
  Archived = "Archived"
}

export type ResearchStatus = {
  status: RESEARCHSTATES;
  errorMsg?: string | null;
};

// Define the state structure for the case research slice
export interface CaseResearchState {
  topics: CaseResearchTopic[];
  extractedData?: ExtractedResearchData | null;
}

// Define the initial state
const initialState: CaseResearchState = {
  topics: [],
  extractedData: null
};

// Define the interface for the reducer
export interface CaseResearchReducer {
  caseResearch: CaseResearchState;
}

interface TopicReference {
  type: string;
  uid: string;
}

interface TopicSource {
  title: string;
  url: string;
}

export interface ExtractedResearchData {
  summary: string;
  summaryHtml: string;
}

// Define the type for each research topic
export interface CaseResearchTopic {
  id: string;
  topicTitle: string;
  sources: string[];
  reference: TopicReference;
  created_by: string;
  created_at: string;
  status: ResearchStatus;
  summary: string;
  summaryHtml: string;
}

// Create the case research slice
export const caseResearchSlice = createSlice({
  name: "caseResearch",
  initialState,
  reducers: {
    addResearchTopic: (state, action: PayloadAction<CaseResearchTopic>) => {
      state.topics.push(action.payload);
    },
    updateResearchTopic: (state, action: PayloadAction<CaseResearchTopic>) => {
      const { id } = action.payload;
      const index = state.topics.findIndex((topic) => topic.id === id);
      if (index !== -1) {
        state.topics[index] = { ...state.topics[index], ...action.payload };
      }
    },
    removeResearchTopic: (state, action: PayloadAction<string>) => {
      state.topics = state.topics.filter(
        (topic) => topic.id !== action.payload
      );
    },
    setResearchTopics: (state, action: PayloadAction<CaseResearchTopic[]>) => {
      state.topics = action.payload;
    },
    setExtractedData: (state, action: PayloadAction<ExtractedResearchData | null>) => {
      state.extractedData = action.payload;
    },
    removeAllGeneratedResearch: (state) => {
      state.topics.forEach((topic) => {
        topic.summary = "";
      });
    },
  }
});

// Export the actions
export const {
  addResearchTopic,
  updateResearchTopic,
  removeResearchTopic,
  setResearchTopics,
  setExtractedData,
  removeAllGeneratedResearch
} = caseResearchSlice.actions;

// Export the reducer
export default caseResearchSlice.reducer;
