import { Box, Heading } from "@chakra-ui/react";
import { clickedStyles } from "../../styles/common-styles";

export const ClientPacket = () => {
  return (
    <Box ml="0">
      <Box py="15px" sx={clickedStyles} width="100%">
        <Heading size="md" textAlign="center">
          Final packet
        </Heading>
      </Box>

      <Box py="15px" width="100%" my="60px">
        <Heading
          mb="15px"
          textTransform="uppercase"
          fontWeight="700"
          lineHeight="normal"
          fontStyle="normal"
          fontSize="62px"
          textAlign="center"
        >
          Coming soon
        </Heading>
        <Box
          fontWeight="400"
          lineHeight="normal"
          fontStyle="normal"
          fontSize="28px"
          textAlign="center"
        >
          Final packet coming soon
        </Box>
      </Box>
    </Box>
  );
};
