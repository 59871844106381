import { shutdown } from "@intercom/messenger-js-sdk";
import { signOut } from "firebase/auth";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { auth } from "../api/firebaseApi";
import { resetActiveClientData } from "../redux/client-case/activeClientSlice";
import { resetClientsData } from "../redux/clients/clientsSlice";
import { clearAllDocuments } from "../redux/documents/documentSlice";
import { clearAllExtractions } from "../redux/extraction-jobs/extractionJobsSlice";
import {
  clearExpertLetters,
  clearExpertLettersDrafts,
  resetIndividualData
} from "../redux/individual/individualSlice";
import { resetLawyerData } from "../redux/lawyer/lawyerSlice";
import { clearLegalBrief } from "../redux/drafts/draftsSlice";

export const clearStoredToken = () => {
  localStorage.removeItem("token");
};

export const useLogOut = () => {
  const [isLoadingLogout, setIsLoadingLogout] = useState(false);
  const [errorLogin, setErrorLogin] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const resetIndivReduxStore = () => {
    dispatch(resetActiveClientData());
    dispatch(resetIndividualData());
    dispatch(clearAllDocuments());
    dispatch(clearAllExtractions());
    dispatch(clearExpertLetters());
    dispatch(clearExpertLettersDrafts());
    dispatch(clearLegalBrief());
  };

  const resetReduxStore = () => {
    dispatch(resetLawyerData());
    dispatch(resetClientsData());
    resetIndivReduxStore();
  };

  const onSubmitLogOut = async () => {
    setIsLoadingLogout(true);
    try {
      signOut(auth).then(() => {
        resetReduxStore();
        shutdown();
        navigate("/login");
        clearStoredToken();
      });
    } catch (e: any) {
      const errorCode = e.code;
      setErrorLogin(errorCode);
    } finally {
      setIsLoadingLogout(false);
    }
  };

  return {
    resetIndivReduxStore,
    resetReduxStore,
    isLoadingLogout,
    onSubmitLogOut,
    errorLogin
  };
};
