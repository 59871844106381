import {
  Button,
  Card,
  CardHeader,
  Flex,
  Heading,
  Text
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Header } from "../components/Header";
import { Sidebar } from "../components/Sidebar/Sidebar";
import { clickedStyles } from "../styles/common-styles";

const questionnaires = ["Expert letter"];

export const ClientForms = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [questionnaireSelected, setQuestionnaire] = useState("");
  const navigate = useNavigate();
  const params = useParams();

  return (
    <>
      <Header />
      <Flex justifyContent="space-between" flexDirection="row" mt="30px">
        <Sidebar
          closeSidebar={() => setShowSidebar(false)}
          showSidebar={showSidebar}
        />

        <Flex alignItems="center" flexDirection="column" flex="1">
          <Card align="center" width="90%">
            <CardHeader sx={clickedStyles} width="100%">
              <Heading size="md">Select Questionnaire</Heading>
            </CardHeader>

            <Flex p="15px" gap="50px">
              {questionnaires.map((form, i) => (
                <Card
                  key={i}
                  p="15px"
                  border="1px solid #E2E2E2"
                  color={questionnaireSelected === form ? "#fff" : "#000"}
                  bgColor={
                    questionnaireSelected === form ? "blue.500" : "#FFFCFC"
                  }
                  cursor="pointer"
                  onClick={() => setQuestionnaire(form)}
                  flex="1"
                >
                  <Text w="100px">{`${form} questionnaire`}</Text>
                </Card>
              ))}
            </Flex>
            <Flex my="20px">
              <Button
                type="button"
                onClick={() => navigate(-1)}
                mx="15px"
                flex="1"
              >
                Cancel
              </Button>
              <Button
                bg="bgColor.300"
                color="text.100"
                mx="15px"
                type="button"
                isDisabled={!questionnaireSelected}
                flex="1"
                onClick={() => {
                  if (questionnaireSelected === "Forms") {
                    navigate(`/questionnaire/${params.caseID}/forms-step-1`);
                  } else {
                    navigate(`/questionnaire/${params.caseID}/expert-letter`);
                  }
                }}
              >
                Next
              </Button>
            </Flex>
          </Card>
        </Flex>
      </Flex>
    </>
  );
};
