import { DeleteIcon, EditIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Flex,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure
} from "@chakra-ui/react";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { db } from "../../../api/firebaseApi";
import { formatDate } from "../../../helpers/date_helpers";
import {
  getDocumentExhibitById,
  getStoragePathByVisaType,
  getSubExhibitNumberArray,
  openFilePreview
} from "../../../helpers/helpers";
import { getShortString } from "../../../helpers/string_helpers";
import useFileActions from "../../../hooks/useFileActions";
import { documentSelectors } from "../../../redux/documents/selectors";
import { VisaDocumentType } from "../../../redux/documents/types";
import { DataDocs } from "../../../types/tables-data";
import { FileExtensionThumbnail } from "../../common/Filethumbnail";
import { TargetBox } from "../../inputDnD/TargetBox";
import { ModalDeleteFile } from "../../ModalDeleteFile";
import { UploadFilesPlaceholder } from "../../UploadFilesPlaceholder";
import { getFileName } from "../../../helpers/file_helpers";
import { deleteSubDocument } from "../../../redux/documents/documentSlice";
import { EditSubdocumentModal } from "./EditSubdocumentModal";

type Props = {
  document: DataDocs;
  visaType: VisaDocumentType;
};
export const SubdocumentsTable = ({ document, visaType }: Props) => {
  const dispatch = useDispatch();
  const [subDocument, setSubDocument] = useState<DataDocs | null>(null);

  const evidenceExhibitMap = useSelector(documentSelectors.evidenceExhibitMap);
  const standardExhibitMap = useSelector(documentSelectors.standardExhibitMap);
  const updatedDocument = useSelector((state: any) => {
    return documentSelectors.selectDocumentById(state, document.id ?? "");
  });

  const subDocuments = updatedDocument?.sub_documents;
  const {
    onOpen: deleteOnOpen,
    onClose: deleteOnClose,
    isOpen: deleteIsOpen
  } = useDisclosure();
  const {
    onOpen: editOnOpen,
    onClose: editOnClose,
    isOpen: editIsOpen
  } = useDisclosure();
  const mainDocumentExhibit = getDocumentExhibitById(
    visaType === VisaDocumentType.Evidence
      ? evidenceExhibitMap
      : standardExhibitMap,
    document?.id ?? ""
  );

  const subDocumentsExhibitArray = getSubExhibitNumberArray(
    mainDocumentExhibit,
    subDocuments?.length ?? 0
  );

  const { handleFileActions } = useFileActions();
  const { id: uid } = useParams();
  const filePath = getStoragePathByVisaType(visaType, uid!);

  const handleDrop = (item: DataTransfer, documentId?: string) => {
    const { files } = item;
    const filesAsArray = Array.from(files!);

    handleFileActions({
      files: filesAsArray,
      filePath,
      uid: uid!,
      mainDocumentId: documentId ?? "",
      type: visaType!
    });
  };

  return (
    <DndProvider backend={HTML5Backend} context={window} key={2}>
      <TargetBox onDrop={(item) => handleDrop(item, document?.id)}>
        <TableContainer whiteSpace="pre-wrap">
          <Table>
            <Thead>
              <Tr>
                <Th>Exhibit</Th>
                <Th>Document</Th>
                <Th>Type</Th>
                <Th>Date</Th>
                <Th>Actions</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {updatedDocument &&
                updatedDocument.sub_documents
                  ?.filter((subdoc) => subdoc.deleted === false)
                  .map((doc, indx) => {
                    return (
                      <Tr key={doc.id}>
                        <Td>{subDocumentsExhibitArray[indx]}</Td>
                        <Tooltip label={doc?.docNames}>
                          <Td className="">
                            <div className="inline-flex items-start gap-2">
                              <FileExtensionThumbnail doc={doc} />
                              <span>{getShortString(doc?.docNames)}</span>
                            </div>
                            <ExternalLinkIcon
                              className="cursor-pointer"
                              onClick={() => openFilePreview(doc?.docUrl)}
                              ml={2}
                            />
                          </Td>
                        </Tooltip>

                        <Td>{doc.type}</Td>
                        <Td>{formatDate(doc.uploadDate)}</Td>
                        <Td>
                          <Flex flexGrow="0" gap="15px">
                            <IconButton
                              icon={<DeleteIcon />}
                              onClick={() => {
                                setSubDocument(doc);
                                deleteOnOpen();
                              }}
                              aria-label=""
                            />
                            <IconButton
                              icon={<EditIcon />}
                              onClick={() => {
                                setSubDocument(doc);
                                editOnOpen();
                              }}
                              aria-label=""
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    );
                  })}

              <Tr>
                <Td colSpan={6}>
                  <UploadFilesPlaceholder
                    title="Drag and drop additional files to bundle with this document here"
                    subTitle="Click Here to Upload"
                    onUpload={(files) => {
                      const filesAsArray = Array.from(files!);
                      handleFileActions({
                        files: filesAsArray,
                        filePath,
                        uid: uid!,
                        mainDocumentId: document.id ?? "",
                        type: visaType!
                      });
                    }}
                  />
                </Td>
              </Tr>
            </Tbody>
          </Table>
          <ModalDeleteFile
            docTitle={getFileName(subDocument?.filePath) ?? ""}
            isOpen={deleteIsOpen}
            onClose={deleteOnClose}
            onDelete={async () => {
              const docRef = doc(db, subDocument?.docRef ?? "");
              await updateDoc(docRef, { deleted: true });
              dispatch(
                deleteSubDocument({
                  data: { document: subDocument, id: document.id ?? "" },
                  type: visaType
                } as any)
              );
            }}
          />
          <EditSubdocumentModal
            isOpen={editIsOpen}
            onClose={editOnClose}
            onOpen={editOnOpen}
            subDocument={subDocument}
            documentType={visaType}
            mainDocument={document}
          />
        </TableContainer>
      </TargetBox>
    </DndProvider>
  );
};
