import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import images from "../assets/image-export-assets";
import { FORMDATA } from "../types/tables-data";
import {
  ExpertLetter,
  updateQuestionnaireLetter
} from "../redux/clients/clientsSlice";
import { useGetFormsData } from "../hooks/useGetFormsData";
import { clientsSelectors } from "../redux/clients/selectors";
import { clickedStyles } from "../styles/common-styles";
import { useAddEmptyForms } from "../hooks/useAddEmptyForms";
import { useUpdateFormsData } from "../hooks/useUpdateFormsData";

interface QuestionnairesProps {
  index: number;
  visaType: string;
}
export const CaseIdQuestionnaires = ({
  index,
  visaType
}: QuestionnairesProps) => {
  const [questionnaireLetter, setQuestionnaireLetter] =
    useState<ExpertLetter | null>(null);

  const { onSubmitAddEmptyForms } = useAddEmptyForms();
  const { onSubmitUpdateFormsData } = useUpdateFormsData();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { formsData, isFormsNotFound } =
    useGetFormsData();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const createFormsData = async () => {
    if (params.caseID) {
      await onSubmitAddEmptyForms();
      // await onSubmitGetFormsData();
    }
  };
  const [formsToDelete, setFormsToDelete] = useState("");

  const handleDeleteConfirm = async () => {
    if (formsToDelete === FORMDATA.EXPERT) {
      dispatch(
        updateQuestionnaireLetter({
          index,
          questionnaireLetter: null
        })
      );

      await onSubmitUpdateFormsData({
        expertLetter: null
      });
      // await onSubmitGetFormsData();
    }
    setFormsToDelete("");
    onClose();
  };

  useEffect(() => {
    if (isFormsNotFound && !formsData) {
      createFormsData();
    }
  }, [isFormsNotFound]);

  const mail = useSelector(clientsSelectors.selectAll)[index].email;

  useEffect(() => {
    if (formsData) {
      setQuestionnaireLetter(formsData.expertLetter);
    }

    if (formsData && formsData.expertLetter) {
      setQuestionnaireLetter(formsData.expertLetter);
      dispatch(
        updateQuestionnaireLetter({
          index,
          questionnaireLetter: formsData.expertLetter
        })
      );
    }
  }, [formsData]);

  const questionnairesData = useMemo(() => {
    return [
      {
        title: FORMDATA.EXPERT,
        date: questionnaireLetter?.uploadDate,
        name: questionnaireLetter?.uploadedByName,
        expertName: questionnaireLetter?.expertName
      }
    ];
  }, [questionnaireLetter]);
  return (
    <>
      <div>
        <Card mb="15px">
          <Box py="15px" sx={clickedStyles}>
            <Heading size="md">{`${visaType} visa intake questionnaires`}</Heading>
          </Box>

          <Card p="15px" m="15px">
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th />
                    <Th>Document</Th>
                    <Th>Date</Th>
                    <Th>Uploaded by</Th>
                    <Th />
                  </Tr>
                </Thead>
                <Tbody>
                  {questionnairesData.map((doc, i) => (
                    <Tr key={i}>
                      <Td>
                        <Checkbox isChecked={!!doc.date} />
                      </Td>
                      <Td>{`${doc.title} questionnaire ${
                        doc.expertName || ""
                      }`}</Td>
                      <Td>
                        {doc.date ? dayjs(doc.date).format("MM/DD/YYYY") : ""}
                      </Td>
                      <Td color="#000">{doc.name ? `${doc.name}` : ""}</Td>
                      <Td>
                        <Flex flexGrow="0" gap="15px">
                          <EditIcon
                            cursor="pointer"
                            onClick={() =>
                              navigate(
                                `/cases/${params.caseID}/questionnaire/expert-letter`
                              )
                            }
                          />
                          <DeleteIcon
                            cursor="pointer"
                            onClick={() => {
                              if (doc.date) {
                                setFormsToDelete(doc.title);
                                onOpen();
                              }
                            }}
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Card>
          <Flex gap="25px" justifyContent="center" mt="40px" mb="20px">
            <Flex
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              gap="5px"
            >
              <Image
                boxSize="30px"
                objectFit="contain"
                src={images.emailClient}
                alt="Email client"
              />
              <Button>
                <a href={`mailto:${mail}`}>
                  Remind client to complete a questionnaire
                </a>
              </Button>
            </Flex>
            <Flex
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              gap="5px"
            >
              <Image
                boxSize="30px"
                objectFit="cover"
                src={images.uploadDocument}
                alt="Complete a questionnaire"
              />
              <Button
                onClick={() =>
                  navigate(
                    `/cases/${params.caseID}/questionnaire/expert-letter`
                  )
                }
              >
                Complete a questionnaire
              </Button>
            </Flex>
          </Flex>
        </Card>
      </div>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalCloseButton
            onClick={() => {
              onClose();
              setFormsToDelete("");
            }}
          />
          <ModalBody pb={6}>
            Would you like to delete this questionnaire:{" "}
            <strong>{formsToDelete}</strong>?
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={() => {
                onClose();
                setFormsToDelete("");
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={() => {
                handleDeleteConfirm();
              }}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
