import {
  Box,
  Button,
  Flex,
  IconButton,
  Textarea,
  Divider,
  Spinner,
  Card,
  Text
} from "@chakra-ui/react";
import { AddIcon, CheckCircleIcon, MinusIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { DATABASE, DataOverview } from "../types/tables-data";
import "../App.css";
import { db } from "../api/firebaseApi";
import {
  UpdateOverviewStep,
  addOverviewStepsToClient,
  updateOverviewStep
} from "../redux/clients/clientsSlice";
import { clientsSelectors } from "../redux/clients/selectors";
import { ArchiveDeleteCase } from "./ArchiveDeleteCase";

type CaseIdOverviewProps = {
  data: DataOverview;
  clientId: string;
  clientIndex: number;
};

export const CaseIdOverview = ({
  data,
  clientId,
  clientIndex
}: CaseIdOverviewProps) => {
  const dispatch = useDispatch();
  const clientsAll = useSelector(clientsSelectors.selectAll);

  const [isNewStepAdded, setIsNewStepAdded] = useState(false);
  const [newStepValue, setNewStepValue] = useState("");
  const [stepValue, setStepValue] = useState<UpdateOverviewStep | null>(null);

  const handleUpdateOverviewData = async () => {
    try {
      const clientRef = doc(db, DATABASE.CLIENTS, `${clientId}`);

      await updateDoc(clientRef, {
        "overview.steps": clientsAll[clientIndex].overview.steps
      });
    } catch (e) {
      console.error("Error is:", e);
    }
  };

  useEffect(() => {
    if (stepValue) {
      dispatch(updateOverviewStep(stepValue));
      setStepValue(null);
    }
  }, [stepValue]);

  useEffect(() => {
    handleUpdateOverviewData();
  }, [clientsAll]);

  const handleAddStep = () => {
    dispatch(
      addOverviewStepsToClient({
        steps: [
          ...clientsAll[clientIndex].overview.steps,
          {
            title: `Step ${clientsAll[clientIndex].overview.steps.length}`,
            description: newStepValue,
            isComplete: false
          }
        ],
        index: clientIndex
      })
    );
    setNewStepValue("");
    setIsNewStepAdded(false);
  };

  const indexActiveStep = data.steps.findIndex((step) => !step.isComplete);

  return (
    <div>
      <Flex flexWrap="wrap" gap="10px">
        {data.steps.map((step, i) => (
          <Card key={i} p="15px" alignItems="center" w="172px" gap="10px">
            {step.isComplete ? (
              <CheckCircleIcon
                color="blue.500"
                w="40px"
                h="40px"
                cursor="pointer"
                onClick={() => {
                  setStepValue({
                    index: clientIndex,
                    stepIndex: i,
                    value: !step.isComplete
                  });
                }}
              />
            ) : (
              <Flex
                w="40px"
                h="40px"
                borderRadius="50%"
                bgColor="#fff"
                border="2px solid"
                borderColor="gray.200"
                justifyContent="center"
                alignItems="center"
                fontSize="20px"
                cursor="pointer"
                onClick={() => {
                  setStepValue({
                    index: clientIndex,
                    stepIndex: i,
                    value: !step.isComplete
                  });
                }}
              >
                {indexActiveStep === i ? (
                  <Spinner
                    thickness="3px"
                    speed="2s"
                    emptyColor="gray.200"
                    color="blue.500"
                  />
                ) : (
                  i + 1
                )}
              </Flex>
            )}
            <Text>Step {i + 1}</Text>
            <Text>{step.description}</Text>
          </Card>
        ))}
        <Card
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap="5px"
          p="15px"
          w="172px"
        >
          <IconButton
            variant="outline"
            colorScheme="blue"
            aria-label="New step"
            fontSize="20px"
            icon={!isNewStepAdded ? <AddIcon /> : <MinusIcon />}
            onClick={() => setIsNewStepAdded(!isNewStepAdded)}
          />
          {!isNewStepAdded && <Box>Add new step</Box>}
        </Card>
      </Flex>

      {isNewStepAdded && (
        <Box mt="20px">
          <Textarea
            placeholder="Extra steps to do..."
            size="sm"
            resize="none"
            value={newStepValue}
            onChange={(e) => setNewStepValue(e.target.value)}
          />
          <Button
            bg="bgColor.300"
            color="text.100"
            type="button"
            m="15px"
            onClick={handleAddStep}
          >
            Save
          </Button>
        </Box>
      )}

      <ArchiveDeleteCase index={clientIndex} />

      <Divider mt="15px" />
    </div>
  );
};
