import { useState, memo, useEffect } from "react";
import { Box, Icon } from "@chakra-ui/react";
import { MdInsertDriveFile } from "react-icons/md";
import { ref } from "firebase/storage";
import { DocUrl } from "../../../types/tables-data";
import { generatePdfThumbnail, openInNewTab } from "../../../helpers/helpers";
import { downloadFileAsBlob, getFileName } from "../../../helpers/file_helpers";
import { storage } from "../../../api/firebaseApi";

interface Props {
  filePath: string;
  disableThumbnailClick?: boolean;
}

const Thumbnail: React.FC<Props> = ({
  filePath,
  disableThumbnailClick
}: Props) => {
  const [loading, setLoading] = useState(true);
  const [fileUrl, setFileUrl] = useState("");
  const [originalFileUrl, setOriginalFileUrl] = useState("");

  const handleClick = () => {
    if (originalFileUrl && !disableThumbnailClick) {
      openInNewTab(originalFileUrl, getFileName(originalFileUrl));
    }
  };
  useEffect(() => {
    const fileRef = ref(storage, filePath ?? "");

    if (filePath && filePath?.endsWith(".pdf")) {
      // Check if the file is a PDF or an image
      downloadFileAsBlob(fileRef).then((url) => {
        setOriginalFileUrl(url);
        generatePdfThumbnail(url).then((pdfThumbnailUrl) => {
          setFileUrl(pdfThumbnailUrl);
        });
      });
    } else {
      downloadFileAsBlob(fileRef).then((fileurl) => {
        setFileUrl(fileurl);
      });
    }
  }, [filePath]);
  return (
    <Box
      border="1px solid"
      borderColor="gray.200"
      p={2}
      borderRadius="md"
      textAlign="center"
      h="auto"
      title="Document preview"
    >
      {fileUrl ? (
        <img
          src={fileUrl}
          alt={`Thumbnail for ${getFileName(filePath)}`}
          onLoad={() => setLoading(false)}
          className={`thumbnail-image ${loading ? "hidden" : "block"}`}
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        />
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          w="full"
          h="full"
        >
          <Icon
            as={MdInsertDriveFile}
            w={8}
            h={8}
            color="primary.darkBlue"
            className={loading ? "block" : "hidden"}
          />
        </Box>
      )}
    </Box>
  );
};

export default Thumbnail;
