import {
  VStack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Heading,
  Checkbox
} from "@chakra-ui/react";
import { useFormikContext, Field } from "formik";
import { useParams } from "react-router-dom";
import { ClientState } from "../../redux/clients/clientsSlice";
import { InputField } from "../InputField";
import { AddressFields } from "../common/AddressFields";

export const ClientContactForm = () => {
  const { values, errors, touched } = useFormikContext<ClientState>();
  const params = useParams();
  const newCase = !params.caseID;

  return (
    <VStack spacing={4} align="stretch">
      <InputField label="Email Address" name="email" isRequired />

      {!newCase && (
        <>
          <Field name="phone">
            {({ field, form }: any) => (
              <FormControl isInvalid={form.errors.phone && form.touched.phone}>
                <FormLabel htmlFor="phone">Phone Number</FormLabel>
                <Input {...field} id="phone" placeholder="Phone Number" />
                <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Heading
            size="16"
            textAlign="left"
            fontWeight="700"
            color="primary.darkBlue"
            textDecoration="underline"
          >
            Physical Address
          </Heading>
          <AddressFields
            name="physicalAddress"
            errors={errors}
            touched={touched}
          />

          <Heading
            size="16"
            textAlign="left"
            fontWeight="700"
            color="primary.darkBlue"
            textDecoration="underline"
          >
            Mailing Address
          </Heading>
          <Field name="similarAddress">
            {({ field }: any) => (
              <Checkbox isChecked={field.value} {...field}>
                Same as Physical Address
              </Checkbox>
            )}
          </Field>
          {!values?.similarAddress && (
            <AddressFields
              name="mailingAddress"
              errors={errors}
              touched={touched}
            />
          )}
        </>
      )}
    </VStack>
  );
};
