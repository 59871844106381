import { useSelector } from "react-redux";
import { WarningTwoIcon } from "@chakra-ui/icons";
import { Alert, AlertTitle, Box, Divider, Flex } from "@chakra-ui/react";
import { FaFolderOpen } from "react-icons/fa6";
import { supportedExtensionsString, supportedExtensionsString2 } from "../../constans";

const SupportedExtensionsMsg = ({
  isStandardDocs = false,
  dragAndDrop = false
}) => {
  return (
    <Alert
      status="warning"
      variant="left-accent"
      bg="secondary.offWhite"
      borderColor="gray.300"
      rounded="md"
      p={4}
    >
      <div className="flex flex-col justify-start">
        <Flex alignItems="flex-start" gap={4}>
          <WarningTwoIcon boxSize="22px" color="yellow.500" />
          <Box flex="1">
            <AlertTitle fontSize="sm" mb={1} fontWeight="bold" color="black">
              Currently supported formats: {supportedExtensionsString2}
              <Divider my={2} />
            </AlertTitle>
          </Box>
        </Flex>
        {isStandardDocs && (
          <Flex alignItems="flex-start" gap={4}>
            <WarningTwoIcon boxSize="22px" color="yellow.500" />
            <Box flex="1">
              <AlertTitle fontSize="sm" mb={1} fontWeight="bold" color="black">
                Applicant resume is required.
                <Divider my={2} />
              </AlertTitle>
            </Box>
          </Flex>
        )}
        {/* { dragAndDrop && <Flex alignItems="flex-start" gap={4}>
                  <FaFolderOpen color="yellow.500" className="h-6 w-6" />
                  <Box>
                    <AlertTitle
                      fontSize="sm"
                      mb={1}
                      fontWeight="bold"
                      color="primary.darkGray"
                    >
                      <strong>Tip:</strong> Drag and drop your files directly into the
                      table for easy upload.
                    </AlertTitle>
                  </Box>
                </Flex> } */}
      </div>
    </Alert>
  );
};

export default SupportedExtensionsMsg;
