import { CircularProgress } from "@chakra-ui/react";
import { getDownloadURL, ref } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { storage } from "./api/firebaseApi";
import App from "./App";
import { firmConfiguration } from "./constans";
import { getSubdomain } from "./helpers/helpers";
import { setLoadingLogo, setLogo } from "./redux/lawyer/lawyerSlice";

const AppInitializer: React.FC = () => {
  const dispatch = useDispatch();
  const [isReady, setIsReady] = useState(false);
  const subdomain = getSubdomain();

  const updateFirmLogo = async () => {
    const practiceName = firmConfiguration[subdomain]?.practiceName;
    dispatch(setLoadingLogo(true));
    if (practiceName !== undefined) {
      const fileRef = ref(storage, `firms/avatars/${practiceName}/logo`);
      const url = await getDownloadURL(fileRef);
      dispatch(setLogo(url));
    }
    dispatch(setLoadingLogo(false));

    setIsReady(true);
  };

  useEffect(() => {
    updateFirmLogo();
  }, []);

  if (!isReady) {
    return (
      <div className="flex justify-center items-center h-[100vh] w-full">
        <CircularProgress isIndeterminate className="place-content-center" />
      </div>
    ); // You can customize this loading UI
  }

  return <App />;
};

export default AppInitializer;
