import { doc, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { db } from "../api/firebaseApi";
import { setExtractedDataD } from "../redux/documents/documentSlice";
import { individualSelectors } from "../redux/individual/selectors";
import { DATABASE, DataDocs } from "../types/tables-data";

interface PassportExtractUpdate {
  firstName: string;
  lastName: string;
  passport: Record<string, string | number>;
}
interface VisaExtractUpdate {
  firstName: string;
  lastName: string;
  visa: Record<string, string>;
}
interface ResumeExtractUpdate {
  firstName: string;
  lastName: string;
  resume: Record<string, string>;
}
interface I94ExtractUpdate {
  firstName: string;
  lastName: string;
  i94: Record<string, string>;
}

export interface ExtractedData {
  uploadedBy: string;
  uploadedDate: number;
  data: {
    passport: null | Record<string, string | number>;
    visa: null | Record<string, string>;
    resume: null | Record<string, string>;
    i94: null | Record<string, string>;
  };
  expertLetter?: string;
  summaryPoints?: string;
  summaryPointsHtml?: string;
  prongArguments?: string;
}
interface SaveExpertLetter {
  firstName: string;
  lastName: string;
  expertLetter: string;
}
interface SaveSummaryPoints {
  firstName: string;
  lastName: string;
  summaryPoints: string;
  summaryPointsHtml: string;
}
interface SaveProngArguments {
  firstName: string;
  lastName: string;
  prongArguments: string;
}

interface SaveEvidenceArgProps {
  firstName: string;
  lastName: string;
  evidenceArguments: Record<string, string>[];
}

export const useAddExtractInfo = (id?: string) => {
  const dispatch = useDispatch();
  const params = useParams();
  const docRef = doc(db, DATABASE.EXTRACTED, `${id ?? params.caseID}`);
  const email = useSelector(individualSelectors.selectEmail);
  const [isLoadingAddEmptyData, setIsLoadingAddEmptyData] = useState(false);
  const [isExtractadDataNotFound, setIsExtractadDataNotFound] = useState(false);
  const [isLoadingGetExtractedData, setIsLoadingGetExtractedData] =
    useState(false);
  const [isLoadingAddPassExtractInfo, setIsLoadingAddPassExtractInfo] =
    useState(false);
  const [isLoadingAddVisaExtractInfo, setIsLoadingAddVisaExtractInfo] =
    useState(false);
  const [isLoadingAddResumeExtractInfo, setIsLoadingAddResumeExtractInfo] =
    useState(false);
  const [isLoadingAddI94ExtractInfo, setIsLoadingAddI94ExtractInfo] =
    useState(false);
  const [isLoadingSaveExpertLetter, setIsLoadingSaveExpertLetter] =
    useState(false);
  const [isLoadingSaveSummaryPoints, setIsLoadingSaveSummaryPoints] =
    useState(false);
  const [isLoadingSaveProngArguments, setIsLoadingSaveProngArguments] =
    useState(false);

  const [isLoadingSaveExpLetter, setIsLoadingSaveExpLetter] = useState(false);
  const [isLoadingSummaryPoints, setIsLoadingSummaryPoints] = useState(false);

  const [extractedData, setExtractedData] = useState<ExtractedData | null>(
    null
  );
  const [isLoadingSaveArgs, setIsLoadingSaveArgs] = useState(false);

  const onSubmitAddEmptyData = async () => {
    setIsLoadingAddEmptyData(true);
    try {
      await setDoc(doc(db, DATABASE.EXTRACTED, `${id ?? params.caseID}`), {
        uploadedBy: "",
        uploadedDate: 0,
        data: {
          passport: null,
          visa: null,
          resume: null,
          i94: null
        }
      });
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingAddEmptyData(false);
    }
  };

  useEffect(() => {
    if (!id) return;

    const unsubscribe = onSnapshot(
      docRef,
      (docSnap) => {
        if (docSnap.exists()) {
          const data = {
            uploadedBy: docSnap.data().uploadedBy || "",
            uploadedDate: docSnap.data().uploadedDate || 0,
            data: docSnap.data().data || null,
            expertLetter: docSnap.data().expertLetter || "",
            summaryPoints: docSnap.data().summaryPoints || "",
            summaryPointsHtml: docSnap.data().summaryPointsHtml || "",
            prongArguments: docSnap.data().prongArguments || "",
            status: docSnap.data().status
          };
          setExtractedData(data);
          dispatch(setExtractedDataD(data));
        } else {
          setIsExtractadDataNotFound(true);
        }
      },
      (error) => {
        console.error("Error fetching document snapshot:", error);
      }
    );
  }, [id]);

  const onSubmitAddPassExtractInfo = async ({
    firstName,
    lastName,
    passport
  }: PassportExtractUpdate) => {
    setIsLoadingAddPassExtractInfo(true);
    const updaterRef = doc(db, DATABASE.EXTRACTED, `${id ?? params.caseID}`);
    try {
      await updateDoc(updaterRef, {
        uploadedBy: `${firstName} ${lastName}`,
        uploadedDate: Date.now(),
        "data.passport": passport,
        last_updated_by: email
      });
    } catch (e) {
      console.error("Error add doc: ", e);
    } finally {
      setIsLoadingAddPassExtractInfo(false);
    }
  };

  const onSubmitAddVisaExtractInfo = async ({
    firstName,
    lastName,
    visa
  }: VisaExtractUpdate) => {
    setIsLoadingAddVisaExtractInfo(true);
    const updaterRef = doc(db, DATABASE.EXTRACTED, `${id ?? params.caseID}`);
    try {
      await updateDoc(updaterRef, {
        uploadedBy: `${firstName} ${lastName}`,
        uploadedDate: Date.now(),
        "data.visa": visa,
        last_updated_by: email
      });
    } catch (e) {
      console.error("Error add doc: ", e);
    } finally {
      setIsLoadingAddVisaExtractInfo(false);
    }
  };

  const onSubmitAddResumeExtractInfo = async ({
    firstName,
    lastName,
    resume
  }: ResumeExtractUpdate) => {
    setIsLoadingAddResumeExtractInfo(true);
    const updaterRef = doc(db, DATABASE.EXTRACTED, `${id ?? params.caseID}`);
    try {
      await updateDoc(updaterRef, {
        uploadedBy: `${firstName} ${lastName}`,
        uploadedDate: Date.now(),
        "data.resume": resume,
        last_updated_by: email
      });
    } catch (e) {
      console.error("Error add doc: ", e);
    } finally {
      setIsLoadingAddResumeExtractInfo(false);
    }
  };
  const onSubmitAddI94ExtractInfo = async ({
    firstName,
    lastName,
    i94
  }: I94ExtractUpdate) => {
    setIsLoadingAddI94ExtractInfo(true);
    const updaterRef = doc(db, DATABASE.EXTRACTED, `${id ?? params.caseID}`);
    try {
      await updateDoc(updaterRef, {
        uploadedBy: `${firstName} ${lastName}`,
        uploadedDate: Date.now(),
        "data.i94": i94,
        last_updated_by: email
      });
    } catch (e) {
      console.error("Error add doc: ", e);
    } finally {
      setIsLoadingAddI94ExtractInfo(false);
    }
  };

  const onSubmitSaveExpertLetter = async ({
    firstName,
    lastName,
    expertLetter
  }: SaveExpertLetter) => {
    setIsLoadingSaveExpertLetter(true);
    const updaterRef = doc(db, DATABASE.EXTRACTED, `${id ?? params.caseID}`);
    try {
      await updateDoc(updaterRef, {
        uploadedBy: `${firstName} ${lastName}`,
        uploadedDate: Date.now(),
        expertLetter,
        last_updated_by: email
      });
    } catch (e) {
      console.error("Error add doc: ", e);
    } finally {
      setIsLoadingSaveExpertLetter(false);
    }
  };
  const onSaveSummaryPoints = async ({
    firstName,
    lastName,
    summaryPoints,
    summaryPointsHtml
  }: SaveSummaryPoints) => {
    setIsLoadingSaveSummaryPoints(true);
    const updaterRef = doc(db, DATABASE.EXTRACTED, `${id ?? params.caseID}`);
    try {
      await updateDoc(updaterRef, {
        uploadedBy: `${firstName} ${lastName}`,
        uploadedDate: Date.now(),
        summaryPoints,
        summaryPointsHtml,
        last_updated_by: email
      });
    } catch (e) {
      console.error("Error add doc: ", e);
    } finally {
      setIsLoadingSaveSummaryPoints(false);
    }
  };
  const onSaveProngArguments = async ({
    firstName,
    lastName,
    prongArguments
  }: SaveProngArguments) => {
    setIsLoadingSaveProngArguments(true);
    const updaterRef = doc(db, DATABASE.EXTRACTED, `${id ?? params.caseID}`);
    try {
      await updateDoc(updaterRef, {
        uploadedBy: `${firstName} ${lastName}`,
        uploadedDate: Date.now(),
        prongArguments,
        last_updated_by: email
      });
    } catch (e) {
      console.error("Error add doc: ", e);
    } finally {
      setIsLoadingSaveProngArguments(false);
    }
  };

  const onSaveExpertLetterBlurb = async (
    expertLetterId: string,
    summary: string,
    summaryHtml: string
  ) => {
    setIsLoadingSaveExpLetter(true); // Assume this is defined in your component
    if (!id) return;
    const docRef = doc(
      db,
      DATABASE.FORMS,
      `${id}`,
      "expert_letters",
      `${expertLetterId}`
    );

    try {
      await updateDoc(docRef, {
        summary,
        summaryHtml,
        updatedDate: Date.now(),
        last_updated_by: email
      });
    } catch (e) {
      console.error("Error updating doc: ", e);
    } finally {
      setIsLoadingSaveExpLetter(false);
    }
  };

  const onSaveEvidenceArguments = async (
    evidence: DataDocs,
    argumentText: string,
    argumentHtml: string
  ) => {
    try {
      if (!id) return;
      setIsLoadingSaveArgs(true);

      const docRef = doc(
        db,
        DATABASE.DOCUMENTS,
        `${id}`,
        "evidence_docs",
        `${evidence.id}`
      );

      const updatedExtractedArgument = {
        ...evidence.extracted_argument,
        argument_text: argumentText || null,
        argument_html: argumentHtml || null
      };

      const dataToUpdate = {
        extracted_argument: updatedExtractedArgument,
        last_updated_by: email,
        updatedDate: Date.now()
      };

      await updateDoc(docRef, dataToUpdate);

      setIsLoadingSaveArgs(false);
    } catch (e) {
      console.error("Error updating doc: ", e);
      setIsLoadingSaveArgs(false);
    }
  };

  const resetSummaryPoints = async (id: string) => {
    console.log(`resetSummaryPoints called with id: ${id}`);
    const docRef = doc(db, DATABASE.EXTRACTED, id);
    console.log(`Document reference created: ${docRef.path}`);

    try {
      console.log(`Attempting to update document with id: ${id}`);
      await updateDoc(docRef, {
        summaryPoints: null,
        summaryPointsHtml: null,
        last_updated_by: email,
        updatedDate: Date.now()
      });
      console.log(`Document with id: ${id} successfully updated`);
    } catch (e) {
      console.error(`Error updating document with id: ${id}`, e);
    }
  };

  return {
    resetSummaryPoints,
    onSaveExpertLetterBlurb,
    isLoadingSaveExpLetter,
    isLoadingSaveArgs,
    onSaveEvidenceArguments,
    isLoadingAddPassExtractInfo,
    isLoadingAddVisaExtractInfo,
    isLoadingAddResumeExtractInfo,
    isLoadingAddI94ExtractInfo,
    isLoadingAddEmptyData,
    isLoadingGetExtractedData,
    extractedData,
    isExtractadDataNotFound,
    isLoadingSaveExpertLetter,
    isLoadingSaveSummaryPoints,
    isLoadingSaveProngArguments,
    onSubmitAddPassExtractInfo,
    onSubmitAddVisaExtractInfo,
    onSubmitAddResumeExtractInfo,
    onSubmitAddI94ExtractInfo,
    onSubmitAddEmptyData,
    onSubmitSaveExpertLetter,
    onSaveSummaryPoints,
    onSaveProngArguments,
    isLoadingSummaryPoints,
    setIsLoadingSummaryPoints,
    setExtractedData
  };
};
