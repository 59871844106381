import { GiPassport } from "react-icons/gi";
import { IoDocumentText } from "react-icons/io5";
import { Box, Image } from "@chakra-ui/react";
import { ImProfile } from "react-icons/im";

import { DocumentTypeTitle } from "../types/tables-data";
import images from "../assets/image-export-assets";

export const CustomDocumentIcon = ({
  documentTitle
}: {
  documentTitle: DocumentTypeTitle;
}) => {
  const renderIcon = () => {
    switch (documentTitle) {
      case "I-94":
        // Return icon for I-94 document
        return <IoDocumentText />;
      case "Passport":
        // Return icon for Passport document
        return <GiPassport />;
      case "Visa":
        // Return icon for Visa document
        return <IoDocumentText />;
      case "Resume | CV":
        // Return icon for Candidate resume document
        return <ImProfile />;
      default:
        // Handle other document titles or the generic string case
        return <IoDocumentText />;
    }
  };

  return <Box className="self-start">{renderIcon()}</Box>;
};
