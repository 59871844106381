import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  collection,
  deleteField,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc
} from "firebase/firestore";
import { useParams } from "react-router-dom";
import { db } from "../api/firebaseApi";
import { DATABASE } from "../types/tables-data";
import {
  ExpertLetter,
  FirstForm,
  SecondForm
} from "../redux/clients/clientsSlice";
import { setExpertLetters } from "../redux/individual/individualSlice";
import { getFileName } from "../helpers/file_helpers";

export interface FormsData {
  expertLetter: ExpertLetter[] | null;
  questionnaires: {
    firstStep?: FirstForm;
    secondStep?: SecondForm;
  } | null;
}

// TODO : remove migration code
export const useGetIndivFormsData = (indivId?: string) => {
  const [isLoadingGetFormsData, setIsLoadingGetFormsData] = useState(false);
  const [formsData, setFormsData] = useState<FormsData | null>(null);
  const [isFormsNotFound, setIsFormsNotFound] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!indivId) {
      return () => {}; // No-op cleanup function when indivId is not defined
    }
    setIsLoadingGetFormsData(true);

    const ref = collection(db, DATABASE.FORMS, `${indivId}`, "expert_letters");
    const q = query(ref, orderBy("expertName"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const expertLetters: ExpertLetter[] = [];
      const updates: any[] = [];
      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        if (docData.resumeUrl) {
          const fileName = getFileName(docData.resumeUrl);
          const filePath = `individuals/documents/${indivId}/Experts/${doc.id}/${fileName}`;
          updates.push(
            updateDoc(doc.ref, { filePath, resumeUrl: deleteField() })
          );
        }

        const expertLetterWithId = { ...docData, uid: doc.id };
        expertLetters.push(expertLetterWithId as ExpertLetter);
      });

      Promise.all(updates)
        .then(() => {
          setFormsData({
            expertLetter: expertLetters,
            questionnaires: null
          });
          console.log("Expert letters from get formdata : ", expertLetters);
          dispatch(setExpertLetters(expertLetters));
        })
        .catch((error) => {
          console.error(
            "Testing new expert flow: Error updating documents:",
            error
          );
        })
        .finally(() => {
          setIsLoadingGetFormsData(false);
        });
    });

    // Cleanup subscription on unmount or when indivId changes
    return () => {
      unsubscribe();
    };
  }, [indivId, dispatch]);

  return {
    isLoadingGetFormsData,
    formsData,
    isFormsNotFound
  };
};
