import { CircularProgress } from "@chakra-ui/react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { individualSelectors } from "../redux/individual/selectors";
import { VISAVALUE, visaTypeToPath } from "../types/tables-data";

export const RedirectHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const visaType = useSelector(individualSelectors.selectVisaType);

  useEffect(() => {
    const loginType = localStorage.getItem("loginType");
    const userId = localStorage.getItem("userId");

    // If the user is not logged in
    if (!loginType) {
      if (location.pathname !== "/login") {
        // Redirect to the login page
        navigate("/login", { replace: true });
      }
      return;
    }

    // Handle logged-in users based on login type and visaType
    switch (loginType) {
      case "lawyer":
        if (location.pathname !== "/cases") {
          navigate("/cases", { replace: true });
        }
        break;
      case "client":
        if (location.pathname !== "/client") {
          navigate("/client", { replace: true });
        }
        break;
      case "individual":
        if (visaType in visaTypeToPath && visaType !== VISAVALUE.EMPTY) {
          const individualPath = `/individual/${userId}/${visaTypeToPath[visaType]}/`;
          if (location.pathname !== individualPath) {
            navigate(individualPath, { replace: true });
          }
        } else {
          console.error("Invalid visa type");
          navigate("/login", { replace: true });
        }
        break;
      default:
        navigate("/login", { replace: true });
        break;
    }
  }, [navigate, location.pathname, visaType]);

  return <CircularProgress isIndeterminate color="blue.300" />;
};
