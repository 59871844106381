import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from "@chakra-ui/react";

type Props = {
  expertName?: string;
  onConfirm: () => void;
  setShowConfirmationDialog: (value: boolean) => void;
  isLoading: boolean;
};

export const ReplaceExpertLetterDraft = ({
  expertName,
  onConfirm,
  setShowConfirmationDialog,
  isLoading
}: Props) => {
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen
      onClose={() => setShowConfirmationDialog(false)}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Warning !</ModalHeader>
        <ModalCloseButton
          onClick={() => {
            setShowConfirmationDialog(false);
          }}
        />
        <ModalBody pb={6}>
          <Text>
            You already generated a letter for expert <b>{expertName}</b>. Would
            you like to replace it ?
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={() => {
              setShowConfirmationDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button
            colorScheme="red"
            ml={3}
            onClick={onConfirm}
            isLoading={isLoading}
          >
            Replace
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
