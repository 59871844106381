import {
  Box,
  Card,
  CardHeader,
  Container,
  Flex,
  Heading,
  Spinner
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { ProfileSidebar } from "../components/ProfileSidebar";
import { individualSelectors } from "../redux/individual/selectors";
import styles from "../styles/account.module.css";

export const Account = () => {
  const location = useLocation();
  const email = useSelector(individualSelectors.selectEmail);

  let headerTitle;
  if (!email) {
    headerTitle = <Spinner size="md" />;
  } else {
    headerTitle = location.pathname.includes("account/profile")
      ? "Profile"
      : "Payment";
  }

  return (
    <Flex className="w-full h-full">
      <Flex justifyContent="space-between" flexDirection="row">
        <Flex alignItems="center" flexDirection="column" flex="1">
          <Card className="w-full bg-white">
            <CardHeader>
              <Heading size="lg" textAlign="center">
                {headerTitle}
              </Heading>
            </CardHeader>
            <Flex className={styles.content}>
              <ProfileSidebar />
              <Box className="!w-full">
                <Container>
                  <Outlet />
                </Container>
              </Box>
            </Flex>
          </Card>
        </Flex>
      </Flex>
    </Flex>
  );
};
