import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  updateDoc,
  where
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../api/firebaseApi";
import { AdminUpdate } from "../../pages/Admin/Updates/AdminUpdate";
import { DATABASE } from "../../types/tables-data";

const useAdminUpdates = () => {
  const [adminUpdates, setAdminUpdates] = useState<AdminUpdate[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  interface FetchAdminUpdatesParams {
    isAdmin?: boolean;
  }

  const fetchAdminUpdates = async ({
    isAdmin = false
  }: FetchAdminUpdatesParams = {}) => {
    setIsLoading(true);
    setError(null);
    try {
      let q;

      if (isAdmin) {
        q = collection(db, DATABASE.ADMIN_UPDATES);
      } else {
        q = query(
          collection(db, DATABASE.ADMIN_UPDATES),
          where("status", "!=", "resolved")
        );
      }

      const querySnapshot = await getDocs(q);
      const updates = querySnapshot.docs.map(
        (doc) =>
          ({
            id: doc.id,
            ...doc.data()
          } as AdminUpdate)
      );

      setAdminUpdates(updates);
    } catch (err: any) {
      console.error(err);
      setError("Failed to fetch admin updates.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleFirestoreOperation = async (operation: () => Promise<any>) => {
    setIsLoading(true);
    setError(null);
    try {
      await operation();
      await fetchAdminUpdates({ isAdmin: true });
    } catch (err: any) {
      console.error(err);
      setError("Operation failed.");
    } finally {
      setIsLoading(false);
    }
  };

  const createAdminUpdate = (adminUpdate: AdminUpdate) => {
    const { id, ...rest } = adminUpdate;
    const newAdminUpdate = {
      ...rest,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now()
    };

    console.log("new update: ", newAdminUpdate);

    handleFirestoreOperation(() =>
      addDoc(collection(db, DATABASE.ADMIN_UPDATES), newAdminUpdate)
    );
  };

  const updateAdminUpdate = (
    id: string,
    updatedFields: Partial<AdminUpdate>
  ) => {
    const updateRef = doc(db, DATABASE.ADMIN_UPDATES, id);

    console.log(
      "Updating admin update with ID: ",
      id,
      "Fields: ",
      updatedFields
    );

    handleFirestoreOperation(() =>
      updateDoc(updateRef, {
        ...updatedFields,
        updatedAt: Timestamp.now()
      })
    );
  };

  const deleteAdminUpdate = (id: string) => {
    const deleteRef = doc(db, DATABASE.ADMIN_UPDATES, id);
    handleFirestoreOperation(() => deleteDoc(deleteRef));
  };

  return {
    adminUpdates,
    createAdminUpdate,
    updateAdminUpdate,
    deleteAdminUpdate,
    fetchAdminUpdates,
    isLoading,
    error
  };
};

export default useAdminUpdates;
