import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input
} from "@chakra-ui/react";
import { FieldAttributes, FieldConfig, useField } from "formik";

interface Props extends FieldConfig, FieldAttributes<any> {
  label: string;
  isRequired?: boolean;
}

export const InputField = ({ label, isRequired = false, ...props }: Props) => {
  const [field, meta] = useField(props.name);

  return (
    <FormControl
      isRequired={isRequired}
      isInvalid={meta.touched && !!meta.error}
      className="whitespace-nowrap"
    >
      <FormLabel>{label}</FormLabel>
      <Input {...field} type={props?.type} />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
