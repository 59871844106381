import { RefObject } from "react";

export const scrollToElementByRef = (
  refOrElement: RefObject<HTMLElement> | HTMLElement
) => {
  if ("current" in refOrElement && refOrElement.current) {
    refOrElement.current.scrollIntoView({
      behavior: "smooth",
      block: "center"
    });
    refOrElement.current.focus();
  } else if (refOrElement instanceof HTMLElement) {
    refOrElement.scrollIntoView({ behavior: "smooth", block: "center" });
    refOrElement.focus();
  }
};

export const setFavicon = (faviconUrl: string) => {
  const link =
    (document.querySelector("link[rel*='icon']") as HTMLLinkElement) ||
    document.createElement("link");
  link.type = "image/x-icon";
  link.rel = "shortcut icon";
  link.href = faviconUrl;
  document.getElementsByTagName("head")[0].appendChild(link);
};
