import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { getAuth, onIdTokenChanged } from "firebase/auth";

// Create an instance of Firebase Auth
const auth = getAuth();

const baseURL = process.env.REACT_APP_API_BASE_URL;

const axiosConfig: AxiosRequestConfig = {
  baseURL,
  headers: {
    "Content-Type": "application/json"
  }
};

const axiosInstance: AxiosInstance = axios.create(axiosConfig);

// Function to get the latest auth token
async function getAuthToken() {
  return new Promise((resolve, reject) => {
    onIdTokenChanged(auth, (user) => {
      if (user) {
        user.getIdToken().then(resolve).catch(reject);
      } else {
        resolve(null);
      }
    });
  });
}

// Set up interceptor to inject the token
axiosInstance.interceptors.request.use(
  async (config) => {
    const token = await getAuthToken();

    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
