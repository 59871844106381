// this code is following chakra styling conventions
// make sure to read the documents to understand
// the anatomy approach
// https://chakra-ui.com/docs/styled-system/component-style

import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const primary = defineStyle({
  bg: "primary.red",
  lightingColor: "green"
});
const secondary = defineStyle({
  borderRadius: "25px",
  textColor: "#00008B",
  outlineColor: "#00008B"
});

export const buttonTheme = defineStyleConfig({
  baseStyle: {
    rounded: "full",
    fontWeight: "500",
    outlineOffset: "0"
  },
  variants: { primary, secondary }
});
