import { collection, getDocs } from "firebase/firestore";
import { useEffect } from "react";
import { db } from "../api/firebaseApi";
import { setSubDocuments } from "../redux/documents/documentSlice";
import { generateDocumentUrls } from "../redux/documents/documentsThunk";
import { VisaDocumentType } from "../redux/documents/types";
import { useTypedDispatch } from "../redux/store";
import { DataDocs } from "../types/tables-data";

export const useGetSubDocuments = (
  document: DataDocs,
  visaType: VisaDocumentType
) => {
  const dispatch = useTypedDispatch();

  useEffect(() => {
    if (document) {
      const fetchSubDocuments = async () => {
        // ask John if we can run a migration on documents to use
        // sub_docs or docs to avoid such conditions
        const collectionRef = collection(
          db,
          visaType === VisaDocumentType.Evidence
            ? `${document.docRef}/docs`
            : `${document.docRef}/sub_documents`
        );

        const subDocuments: DataDocs[] = [];
        const subDocumentsSnapshot = await getDocs(collectionRef);

        subDocumentsSnapshot.forEach((doc) => {
          const subDocument = doc.data() as DataDocs;
          subDocuments.push({
            id: doc.id,
            ...subDocument,
            docRef: doc.ref.path,
            deleted: subDocument.deleted ?? false
          });
        });
        dispatch(
          setSubDocuments({
            data: { documents: subDocuments, id: document.id ?? "" },
            type: visaType
          })
        );

        dispatch(
          generateDocumentUrls({
            documents: subDocuments?.map((doc) => ({
              id: doc.id as string,
              filePath: doc.filePath as string
            })),
            type: visaType,
            mainDocId: document.id
          })
        );
      };

      fetchSubDocuments();
    }
  }, []);
};
