import { Box, Card, Divider, Flex, Heading, VStack } from "@chakra-ui/react";
import { ExpertLetterDraftsSection } from "./individualDrafts/ExpertLettersDraftSection";
import { CoverLetterDraftsSection } from "./individualDrafts/CoverLetterSection";
import { SopDraftSection } from "./individualDrafts/SopDraftSection";
import { ExhibitListSection } from "./individualDrafts/ExhibitListSection";

interface Props {
  mainTabIndex?: number | null;
  subTabIndex?: number | null;
}

export const IndividualDrafts = ({ mainTabIndex, subTabIndex }: Props) => {
  return (
    <div className="w-fulll h-full">
      <VStack
        alignItems="stretch"
        gap={8}
        divider={<Divider my={8} />}
        w="100%"
      >
        <ExpertLetterDraftsSection />
        {/* TODO : uncomment after fixing no key ‘summaryPoints’ */}
        {/* <CoverLetterDraftsSection /> */}
        <ExhibitListSection />
        <SopDraftSection />
      </VStack>
    </div>
  );
};
