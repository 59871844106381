import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  FormControl,
  Heading,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

type DropdownWithFilterProps = {
  options: string[];
  setSelectedGreet: (value: string) => void;
};

export const DropdownWithFilter = ({
  options,
  setSelectedGreet
}: DropdownWithFilterProps) => {
  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);

  const filterOptions = (value: string) => {
    setInputValue(value);
    setSelectedGreet(value);

    // if filter needed uncomment the code below

    // const filtered = options.filter((option) =>
    //   option.toLowerCase().includes(value.toLowerCase())
    // );
    // setFilteredOptions(filtered);
  };
  useEffect(() => {
    setSelectedGreet(inputValue);
  }, [inputValue]);

  return (
    <FormControl variant="fixed" className="w-18">
      <Heading fontSize="18" color="primary.darkBlue" textAlign="left" py={4}>
        Greeting
      </Heading>
      <Menu>
        <div className="flex w-18">
          <Input
            value={inputValue}
            onChange={(e) => filterOptions(e.target.value)}
            placeholder="Type or select a greeting"
            roundedRight={0}
            focusBorderColor="primary.red"
          />
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            bg="primary.red"
            color="white"
            roundedLeft={0}
            iconSpacing={0}
          />
        </div>
        <MenuList>
          {filteredOptions.map((option, index) => (
            <MenuItem key={index} onClick={() => setInputValue(option)}>
              {option}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </FormControl>
  );
};

export default DropdownWithFilter;
