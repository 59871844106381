import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure
} from "@chakra-ui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setError,
  setExpanded,
  setVisibilty
} from "../../redux/file-upload/fileUploadSlice";
import { uploadFileSelectors } from "../../redux/file-upload/selectors";
import { uploadFiles } from "../../redux/file-upload/fileUploadThunk";
import {
  supportedExtensionsString,
  supportedExtensionsString2
} from "../../constans";

export const FileExtensionDialog = () => {
  const { error } = useSelector(uploadFileSelectors.selectAll);
  const { onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<any>();
  const isOpen = error !== null;
  const dispatch = useDispatch();

  return error ? (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Unsupported File Types Detected
          </AlertDialogHeader>

          <AlertDialogBody>
            Some of the files selected have unsupported extensions and will not
            be uploaded. You may proceed to upload files in this supported
            format: {supportedExtensionsString2}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                dispatch(setError(null));
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => {
                dispatch(setVisibilty(true));
                dispatch(setExpanded(true));
                dispatch(uploadFiles() as any);
                dispatch(setError(null));
              }}
              ml={3}
            >
              Proceed
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  ) : null;
};
