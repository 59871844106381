import { List, ListIcon, ListItem, Text } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { useMemo } from "react";

type FilesListProps = {
  files: FileList | null;
};

function list(files: FileList) {
  const label = (file: File) => `${file.name}`;

  return Array.from(files).map((file) => (
    <ListItem key={file.name}>
      <ListIcon as={CheckCircleIcon} color="green.500" />
      {label(file)}
    </ListItem>
  ));
}
export const FileList = ({ files }: FilesListProps) => {
  if (!files) {
    return null;
  }
  const fileList = useMemo(() => list(files), [files]);
  return (
    <List spacing={3} mt="10px">
      {fileList}
    </List>
  );
};
