import {
  ArrowDownIcon,
  ArrowForwardIcon,
  DeleteIcon,
  EditIcon,
  ExternalLinkIcon
} from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Container,
  Divider,
  Flex,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure
} from "@chakra-ui/react";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { deleteObject, ref } from "firebase/storage";
import { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { db, storage } from "../../../api/firebaseApi";

import { formatDate } from "../../../helpers/date_helpers";
import {
  copyDocumentToNewCollection,
  getDocumentExhibitById,
  getDocumentTypesByTypeVisaCategory,
  getSubExhibitNumberArray,
  mapVisaTypeToPath,
  openFilePreview
} from "../../../helpers/helpers";
import { getShortString } from "../../../helpers/string_helpers";
import useFileActions from "../../../hooks/useFileActions";
import {
  DocumentReducer,
  deleteSubDocument,
  setSubDocuments
} from "../../../redux/documents/documentSlice";
import { generateDocumentUrls } from "../../../redux/documents/documentsThunk";
import { documentSelectors } from "../../../redux/documents/selectors";
import { VisaDocumentType } from "../../../redux/documents/types";
import { useTypedDispatch } from "../../../redux/store";
import { AddEvidence, DATABASE, DataDocs } from "../../../types/tables-data";
import { UploadFilesPlaceholder } from "../../UploadFilesPlaceholder";
import Dropdown from "../../common/Dropdown";
import { FileExtensionThumbnail } from "../../common/Filethumbnail";
import { TargetBox } from "../../inputDnD/TargetBox";
import { SpecialHeading } from "../PanelLayout";
import EditMainDocModal from "./EditMainDocModal";

export const EditEvidenceDocument = () => {
  const { docId, id: uid, visaType } = useParams();
  const location = useLocation();
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment !== "");
  const currentTab = pathSegments[4] || undefined;

  const foundDocument = useSelector((state: DocumentReducer) =>
    documentSelectors.selectBundledDocById(state, docId!, currentTab!)
  );
  const [document, setDocument] = useState(foundDocument);
  const documentTypes = useSelector(documentSelectors.documentTypes);
  const [formState, setFormState] = useState<AddEvidence>({
    type: document?.type,
    criterion: document?.criterion ?? "",
    documentTitle: "",
    description: document?.description ?? "",
    autoTitle: document?.autoTitle ?? ""
  });
  const { handleFileActions } = useFileActions();
  const [subDocument, setSubDocument] = useState<DataDocs | null>(null);
  const [expanded, setExpanded] = useState(true);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onClose: onEditClose,
    onOpen: onEditOpen
  } = useDisclosure();
  const {
    isOpen: isSubEditOpen,
    onClose: onSubEditClose,
    onOpen: onSubEditOpen
  } = useDisclosure();
  const {
    isOpen: isSubOpen,
    onClose: onSubClose,
    onOpen: onSubOpen
  } = useDisclosure();
  const [isDeleting, setIsDeleting] = useState(false);

  const mainDocRef = document?.docRef ? doc(db, document.docRef) : null;

  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  const getDocTypeAndPaths = (currentTab: string) => {
    let docType;
    let subPath;
    let pathSegment;
    let returnLink;

    switch (currentTab) {
      case "evidence-documents":
        docType = VisaDocumentType.Evidence;
        subPath = "/sub_documents/";
        pathSegment = "Evidences";
        returnLink = `/individual/${uid}/${visaType}/documents/evidence-documents`;
        break;
      case "signed-expert-letters":
        docType = VisaDocumentType.SignedExpertLetters;
        subPath = "/docs/";
        pathSegment = "signed_expert_letters";
        returnLink = `/individual/${uid}/${visaType}/packet`;
        break;
      default:
        docType = null;
        subPath = "";
        pathSegment = "default_path";
    }
    return { docType, subPath, pathSegment, returnLink };
  };

  const { docType, subPath, pathSegment, returnLink } = getDocTypeAndPaths(
    currentTab!
  );

  const evidenceExhibitMap = useSelector(documentSelectors.evidenceExhibitMap);

  const mainDocumentExhibit = getDocumentExhibitById(
    evidenceExhibitMap!,
    document?.id ?? ""
  );
  const subDocumentsExhibitArray = getSubExhibitNumberArray(
    mainDocumentExhibit,
    document?.sub_documents?.length ?? 0
  );
  useEffect(() => {
    if (document) {
      const fetchSubDocuments = async () => {
        const collectionRef = collection(db, `${document.docRef}/docs`);
        const subDocuments: DataDocs[] = [];
        const subDocumentsSnapshot = await getDocs(collectionRef);

        subDocumentsSnapshot.forEach((doc) => {
          const subDocument = doc.data() as DataDocs;
          subDocuments.push({ id: doc.id, ...subDocument });
        });

        dispatch(
          setSubDocuments({
            data: { documents: subDocuments, id: docId ?? "" },
            type: docType!
          })
        );

        // alert(JSON.stringify(subDocuments));
        dispatch(
          generateDocumentUrls({
            documents: subDocuments?.map((doc) => ({
              id: doc.id as string,
              filePath: doc.filePath as string
            })),
            type: VisaDocumentType.Evidence,
            mainDocId: docId
          })
        );
      };

      fetchSubDocuments();
    }
  }, []);
  interface FormErrors {
    type?: string;
    criterion?: string;
    documentTitle?: string;
    description?: string;
    docs?: string;
  }
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const handleInputChange = (key: keyof DataDocs, value: any) => {
    setDocument((prevState: DataDocs | undefined) => {
      if (!prevState) {
        // If prevState is undefined, return a new object with the provided key-value pair
        return { [key]: value } as DataDocs;
      }

      // Otherwise, spread the previous state and update the specific key-value pair
      return {
        ...prevState,
        [key]: value,
        // resetting type dropdown
        ...(key === "criterion" && {
          type: undefined
        })
      };
    });

    setSubDocument((prevState: DataDocs | null) => {
      if (!prevState) {
        // If prevState is undefined, return a new object with the provided key-value pair
        return { [key]: value } as DataDocs;
      }

      // Otherwise, spread the previous state and update the specific key-value pair
      return {
        ...prevState,
        [key]: value
      };
    });
  };

  useEffect(() => {
    if (foundDocument) setDocument(foundDocument);
  }, [foundDocument?.sub_documents]);

  const handleDeleteClick = async () => {
    if (!subPath || !mainDocRef) return;

    const subDocPath = `${mainDocRef.path.concat(
      subPath,
      subDocument?.id ?? ""
    )}`;

    const { originalDocRef } = await copyDocumentToNewCollection(
      subDocPath,
      mainDocRef.parent.path
    );

    if (originalDocRef !== null && subDocument) {
      await deleteDoc(originalDocRef);
      dispatch(
        deleteSubDocument({
          data: {
            document: subDocument,
            id: document?.id ?? ""
          },
          type: docType!
        })
      );
      setIsDeleting(false);
      onSubClose();
    } else {
      onSubClose();
    }
  };

  const handleDrop = (item: DataTransfer, documentId?: string) => {
    if (!pathSegment) return;

    const filePath = `${DATABASE.INDIVIDUALS}/documents/${uid!}/${pathSegment}`;

    const { files } = item;
    const filesAsArray = Array.from(files!);
    handleFileActions({
      files: filesAsArray,
      filePath,
      uid: uid!,
      mainDocumentId: documentId ?? "",
      type: docType!
    });
  };

  const typeSection = () => {
    const mappedVisaType = mapVisaTypeToPath(visaType!);

    return (
      <Flex flexDirection="column" rowGap={1}>
        <>
          <Text my={2} fontWeight={500} fontSize={16}>
            Category
          </Text>
          <Dropdown
            selectedValue={document?.criterion}
            title="Category"
            onItemSelect={(value) => handleInputChange("criterion", value)}
            list={Object.keys(
              getDocumentTypesByTypeVisaCategory(
                documentTypes,
                VisaDocumentType.Evidence,
                mappedVisaType
              )
            )}
          />
          {formErrors.type && <Text color="red">{formErrors.type}</Text>}
        </>
        <Text my={2} fontWeight={500} fontSize={16}>
          Document Type
        </Text>
        <Dropdown
          selectedValue={document?.type}
          title="Type"
          onItemSelect={(value) => handleInputChange("type", value)}
          list={getDocumentTypesByTypeVisaCategory(
            documentTypes,
            VisaDocumentType.Evidence,
            mappedVisaType,
            document?.criterion
          )}
        />
        {document?.type === "Other" && (
          <>
            <Input
              placeholder="Document title"
              value={document?.documentTitle ?? ""}
              onChange={(e) =>
                handleInputChange("documentTitle", e.target.value)
              }
            />
            {formErrors.documentTitle && (
              <Text color="red">{formErrors.documentTitle}</Text>
            )}
          </>
        )}
      </Flex>
    );
  };

  return (
    <Container py={2}>
      <SpecialHeading
        title={`Editing document: ${document?.documentTitle}`}
        backNav
      />
      <div className="flex flex-col gap-4">
        <DndProvider backend={HTML5Backend} context={window} key={2}>
          <TargetBox onDrop={(item) => handleDrop(item, document?.id)}>
            <TableContainer whiteSpace="pre-wrap">
              <Table>
                <Thead>
                  <Tr>
                    <Th>Exhibit</Th>
                    <Th>Document</Th>
                    <Th>Type</Th>
                    <Th>Date</Th>
                    <Th>Actions</Th>
                    <Th />
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr className="shadow-sm">
                    <Td>{mainDocumentExhibit}</Td>
                    <Td className="">
                      <div className="inline-flex items-center gap-2">
                        <IconButton
                          size="sm"
                          aria-label=""
                          icon={
                            expanded ? <ArrowDownIcon /> : <ArrowForwardIcon />
                          }
                          onClick={() => {
                            setExpanded(!expanded);
                          }}
                        />{" "}
                        <div className="inline-flex gap-2">
                          <FileExtensionThumbnail doc={document!} />
                          <Tooltip label={document?.docNames}>
                            <span> {getShortString(document?.docNames)}</span>
                          </Tooltip>

                          <Badge
                            bg="primary.darkBlue"
                            textColor="white"
                            h="min-content"
                          >
                            main doc
                          </Badge>
                          <ExternalLinkIcon
                            className="cursor-pointer"
                            onClick={() => openFilePreview(document?.docUrl)}
                            ml={2}
                          />
                        </div>
                      </div>
                    </Td>
                    <Td>
                      {getShortString(
                        typeof document?.type === "object"
                          ? (document.type as any).title
                          : document?.type
                      )}
                    </Td>
                    <Td>{formatDate(document?.uploadDate)}</Td>
                    <Td className="flex gap-2">
                      <IconButton
                        icon={<DeleteIcon />}
                        onClick={() => {
                          onOpen();
                        }}
                        aria-label=""
                      />
                      <IconButton
                        icon={<EditIcon />}
                        onClick={() => {
                          onEditOpen();
                        }}
                        aria-label=""
                      />
                    </Td>
                  </Tr>
                  {expanded &&
                    document?.sub_documents?.map((doc, indx) => {
                      return (
                        <Tr key={doc.id}>
                          <Td>{subDocumentsExhibitArray[indx]}</Td>
                          <Tooltip label={doc?.docNames}>
                            <Td className="">
                              <div className="inline-flex items-start gap-2">
                                <FileExtensionThumbnail doc={doc} />
                                <span>{getShortString(doc?.docNames)}</span>
                              </div>
                              <ExternalLinkIcon
                                className="cursor-pointer"
                                onClick={() => openFilePreview(doc?.docUrl)}
                                ml={2}
                              />
                            </Td>
                          </Tooltip>

                          <Td>-</Td>
                          <Td>{formatDate(doc.uploadDate)}</Td>
                          <Td>
                            <Flex flexGrow="0" gap="15px">
                              <IconButton
                                icon={<DeleteIcon />}
                                onClick={() => {
                                  setSubDocument(doc);
                                  onSubOpen();
                                }}
                                aria-label=""
                              />
                              <IconButton
                                icon={<EditIcon />}
                                onClick={() => {
                                  setSubDocument(doc);
                                  onSubEditOpen();
                                }}
                                aria-label=""
                              />
                            </Flex>
                          </Td>
                        </Tr>
                      );
                    })}
                  {expanded &&
                    document &&
                    document.sub_documents?.length === 0 && (
                      <Tr>
                        <Td colSpan={5}>
                          <UploadFilesPlaceholder
                            title="Drag and drop additional files to bundle with this document here"
                            subTitle="Click Here to Upload"
                            onUpload={(files) => {
                              const filesAsArray = Array.from(files!);
                              const filePath = `${
                                DATABASE.INDIVIDUALS
                              }/documents/${uid!}/Evidences`;

                              handleFileActions({
                                files: filesAsArray,
                                filePath,
                                uid: uid!,
                                mainDocumentId: document.id ?? "",
                                type: docType!
                              });
                            }}
                          />
                        </Td>
                      </Tr>
                    )}
                </Tbody>
              </Table>
            </TableContainer>
          </TargetBox>
          <Flex flexDirection="row" columnGap={4} justifyContent="center">
            <Button
              my={4}
              className="btn tertiary-btn"
              onClick={() => navigate(returnLink!)}
              width="fit-content"
            >
              Back
            </Button>
          </Flex>
        </DndProvider>
        {/* mainDoc delete modal */}
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirmation</ModalHeader>
            <ModalCloseButton
              onClick={() => {
                onClose();
              }}
            />
            <ModalBody pb={6}>
              Are you sure you want to delete the document? <br />
              <small>
                This action will permanently delete all associated
                sub-documents.
              </small>
            </ModalBody>

            <ModalFooter>
              <Button
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                ml={3}
                // isLoading={isDeleting}
                onClick={async () => {
                  // console.log(document?.sub_documents);

                  const deleteFilePromises = document?.sub_documents?.map(
                    (subDoc) => {
                      const fileRef = ref(storage, subDoc.filePath);
                      console.log(fileRef);
                      // console.log()

                      return deleteObject(fileRef);
                    }
                  );
                  // console.log(deleteFilePromises);

                  setIsDeleting(true);
                  // console.log(deleteFilePromises);

                  if (deleteFilePromises && isDeleting) {
                    await Promise.all(deleteFilePromises);
                  }
                  await deleteDoc(mainDocRef!);

                  setIsDeleting(false);
                  onClose();
                  navigate(returnLink!);
                }}
              >
                Delete
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* SubDocument delete modal */}
        <Modal
          closeOnOverlayClick={false}
          isOpen={isSubOpen}
          onClose={onSubClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirmation</ModalHeader>
            <ModalCloseButton
              onClick={() => {
                onClose();
              }}
            />
            <ModalBody pb={6}>
              Are you sure you want to delete this document?
            </ModalBody>

            <ModalFooter>
              <Button
                onClick={() => {
                  onSubClose();
                }}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                ml={3}
                isLoading={isDeleting}
                onClick={async () => {
                  const subDocFileRef = ref(storage, subDocument?.filePath);
                  if (!mainDocRef) return;
                  const subDocRef = subDocument
                    ? doc(
                        db,
                        `${mainDocRef?.path.concat(subPath, subDocument.id!)}`
                      )
                    : null;
                  if (subDocRef && subDocument) {
                    await deleteObject(subDocFileRef);

                    setIsDeleting(true);
                    await deleteDoc(subDocRef);
                    dispatch(
                      deleteSubDocument({
                        data: {
                          document: subDocument,
                          id: document?.id ?? ""
                        },
                        type: docType!
                      })
                    );
                    setIsDeleting(false);
                    onSubClose();
                  } else {
                    onSubClose();
                  }
                }}
              >
                Delete
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* Edit Main document */}
        <EditMainDocModal
          headerText="Editing Main Document"
          onClose={onEditClose}
          isOpen={isEditOpen}
          document={document}
          isDeleting={isDeleting}
          visaDocumentType={VisaDocumentType.Evidence}
        />
        {/* Sub-Document modify Modal */}
        <Modal
          closeOnOverlayClick={false}
          isOpen={isSubEditOpen}
          onClose={onSubEditClose}
          size="l"
        >
          <ModalOverlay />
          <ModalContent w="40%">
            <ModalHeader>Editing sub-document</ModalHeader>
            <ModalCloseButton
              onClick={() => {
                onSubEditClose();
              }}
            />
            <ModalBody pb={6}>
              {/* {typeSection()} */}
              Change Later
              <Divider my={5} />
            </ModalBody>

            <ModalFooter justifyContent="space-between">
              <Box>
                <div className="inline-flex gap-2">
                  <div className="w-4 h-4 ">
                    <FileExtensionThumbnail doc={subDocument!} />
                  </div>
                  <span>{subDocument?.documentTitle}</span>
                </div>
                <br />
                <small>you can change the category of the document</small>
              </Box>
              <Box>
                {" "}
                <Button
                  onClick={() => {
                    onSubEditClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  ml={3}
                  isLoading={isDeleting}
                  onClick={handleDeleteClick}
                >
                  Save
                </Button>
              </Box>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    </Container>
  );
};
