import {
  Avatar,
  Box,
  Button,
  Card,
  CardFooter,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  UnorderedList,
  useColorModeValue,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { getDownloadURL } from "@firebase/storage";
import { isEqual } from "lodash";
import React, { FocusEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import { useAddFileToDB } from "../hooks/useAddFileToDB";
import { useDeleteFileFromDB } from "../hooks/useDeleteFileFromDB";
import { useGetFilesFromDB } from "../hooks/useGetFilesFromDB";
import { useGetIndividualData } from "../hooks/useGetIndividualData";
import { useUpdateUser } from "../hooks/useUpdateUser";
import {
  IndividualState,
  updateIndividualAvatar,
  updateIndividualData
} from "../redux/individual/individualSlice";
import { individualSelectors } from "../redux/individual/selectors";
import { DATABASE } from "../types/tables-data";
import AllCountriesSelect from "../components/common/AllCountriesSelect";
import AllStatesSelect from "../components/common/AllStatesSelect";
import { supportedExtensionsString } from "../constans";

const steps = [
  { label: "Personal Information" },
  { label: "Contact Information" },
  { label: "Employer Information" }
];

interface TouchedState {
  [key: string]: boolean;
}

const adrTypes = ["Apartment", "Suite", "Floor"];

export const IndividualProfile = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, visaType } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onSubmitGetIndividualData, isLoadingGetIndividualData } =
    useGetIndividualData();
  const { onSubmitUpdateUser, isLoadingUpdateUser, onUpdateUserAvatar } =
    useUpdateUser();
  const uid = id ?? useSelector(individualSelectors.selectUid);
  const individualData = useSelector(individualSelectors.selectAll);
  const [formData, setFormData] = useState<IndividualState | null>(
    individualData
  );

  // Form
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [changedFields, setChangedFields] = useState<string[]>([]);
  const [touched, setTouched] = useState<TouchedState>({});
  const [isChanged, setIsChanged] = useState(false);

  // Avatar
  const avatarName = useSelector(individualSelectors.selectAvatarName);
  const avatarSrc = useSelector(individualSelectors.selectAvatarSrc);
  const { onSubmitGetFiles, storageFiles } = useGetFilesFromDB();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { onSubmitAddFile, fileRef } = useAddFileToDB();
  const { onSubmitDeleteFile } = useDeleteFileFromDB();

  // Steps
  const { nextStep, prevStep, reset, activeStep } = useSteps({
    initialStep: 0
  });
  const isLastStep = activeStep === steps.length - 1;
  // const hasCompletedAllSteps = activeStep === steps.length;

  const getImagesFromBD = async () => {
    try {
      await onSubmitGetFiles(`${DATABASE.INDIVIDUALS}/avatars/${uid}/`);
    } catch (e) {
      console.log(e);
      dispatch(updateIndividualAvatar({ avatarSrc: "/", avatarName: "" }));
    }
  };

  const uploadImage = async () => {
    if (selectedFile) {
      try {
        await onSubmitAddFile(
          selectedFile,
          `${DATABASE.INDIVIDUALS}/avatars/${uid}/${selectedFile.name}`
        );
        setSelectedFile(null);
        getImagesFromBD();
      } catch (e) {
        console.log(e);
      }
    }
  };

  const deleteImage = async () => {
    if (avatarName) {
      try {
        await onSubmitDeleteFile(
          `${DATABASE.INDIVIDUALS}/avatars/${uid}/${avatarName}`
        );
        await onUpdateUserAvatar(uid, {
          avatarSrc: "",
          avatarName: ""
        });
        dispatch(updateIndividualAvatar({ avatarSrc: "/", avatarName: "" }));
      } catch (e) {
        console.log(e);
      } finally {
        uploadImage();
      }
    }
  };

  useEffect(() => {
    getImagesFromBD();
  }, []);

  useEffect(() => {
    if (storageFiles.length) {
      storageFiles.forEach((item) => {
        getDownloadURL(item).then((url) => {
          dispatch(
            updateIndividualAvatar({ avatarSrc: url, avatarName: item.name })
          );
          onUpdateUserAvatar(uid, {
            avatarSrc: url,
            avatarName: item.name
          });
        });
      });
    }
    if (storageFiles.length === 0) {
      dispatch(updateIndividualAvatar({ avatarSrc: "/", avatarName: "" }));
    }
  }, [storageFiles]);

  useEffect(() => {
    if (selectedFile && avatarName) {
      deleteImage();
    } else if (selectedFile) {
      uploadImage();
    }
  }, [selectedFile]);

  const handleValidation = () => {
    if (!formData) return false;
    const newErrors: { [key: string]: string } = {};
    if (!formData.firstName.trim()) {
      newErrors.firstName = "First Name is required";
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name } = e.target;

    // Mark the field as touched when it loses focus
    setTouched({
      ...touched,
      [name]: true
    });
  };

  const handleUpdateData = async () => {
    console.log("handleUpdateData triggered ");
    if (!uid || !formData) return;

    try {
      const updatedValues = {
        // personal info
        firstName: formData.firstName,
        middleName: formData.middleName,
        lastName: formData.lastName,
        dob: formData.dob,
        countryob: formData.countryob,
        citizenship: formData.citizenship,
        title: formData.title,
        pronouns: formData.pronouns,
        otherPronouns: formData.otherPronouns,
        // contact
        email: formData.email,
        phone: formData.phone,
        // address
        addressLine1: formData.addressLine1,
        state: formData.state,
        city: formData.city,
        zipcode: formData.zipcode,

        // Employer info
        employer_name: formData.employer_name,
        employer_contact_name: formData.employer_contact_name,
        employer_contact_email: formData.employer_contact_email,
        employer_contact_phone: formData.employer_contact_phone,

        employer_addressLine1: formData.employer_addressLine1,
        employer_state: formData.employer_state,
        employer_city: formData.employer_city,
        employer_zipcode: formData.employer_zipcode,

        // usAddress
        usAddressInCareOf: formData.usAddressInCareOf,
        usAddressStreetNumber: formData.usAddressStreetNumber,
        usAddressStreetName: formData.usAddressStreetName,
        usAddressType: formData.usAddressType,
        usAddressAptSteFloor: formData.usAddressAptSteFloor,
        usAddressCityOrTown: formData.usAddressCityOrTown,
        usAddressState: formData.usAddressState,
        usAddressProvince: formData.usAddressProvince,
        usAddressZipCode: formData.usAddressZipCode,
        // foreignAddress
        foreignAddressInCareOf: formData.foreignAddressInCareOf,
        foreignAddressStreetNumber: formData.foreignAddressStreetNumber,
        foreignAddressStreetName: formData.foreignAddressStreetName,
        foreignAddressType: formData.foreignAddressType,
        foreignAddressAptSteFloor: formData.foreignAddressAptSteFloor,
        foreignAddressCityOrTown: formData.foreignAddressCityOrTown,
        foreignAddressState: formData.foreignAddressState,
        foreignAddressProvince: formData.foreignAddressProvince,
        foreignAddressPostalCode: formData.foreignAddressPostalCode,
        visaType: formData.visaType,
        company: formData.company
      };

      await onSubmitUpdateUser(uid, updatedValues);
      dispatch(updateIndividualData(updatedValues));
      await onSubmitGetIndividualData(uid);
      toast({
        title: "Information updated",
        description: "Information updated.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right"
      });
      navigate(`/individual/${id}/${visaType}`);
    } catch (e) {
      console.error("Error is:", e);
      toast({
        title: "Error",
        description: "Error updating information.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right"
      });
    } finally {
      onClose();
    }
  };

  const handleConfirmSubmit = async () => {
    setIsDialogOpen(false);
    setIsSubmitting(true);
    await handleUpdateData();
    setIsSubmitting(false);
    setChangedFields([]);
  };

  useEffect(() => {
    setIsChanged(!isEqual(formData, individualData));
  }, [formData, individualData]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, id } = e.target;
    setFormData((prevFormData) => {
      if (!prevFormData) return null;
      if (!changedFields.includes(name)) {
        setChangedFields([...changedFields, name]);
      }
      return {
        ...prevFormData,
        [id]: value
      };
    });
  };

  return formData ? (
    <Card width="100%" p={2}>
      <form className="w-full ">
        <Flex flexDirection="row" mt="30px" width="100%">
          <Flex ml="10px" width="100%">
            <Flex
              justifyContent="space-between"
              flexDirection="column"
              width="100%"
              align="center"
            >
              <Avatar
                name={
                  formData
                    ? `${formData.firstName} ${formData.middleName} ${formData.lastName}`
                    : undefined
                }
                src={avatarSrc}
                marginBottom="11px"
                width="6rem"
                height="6rem"
                cursor="pointer"
                onClick={() => {
                  fileRef.current?.click();
                }}
              />
              <Input
                type="file"
                ref={fileRef}
                onChange={(e) => {
                  if (e.target.files) {
                    setSelectedFile(e.target.files[0]);
                  }
                }}
                accept={supportedExtensionsString}
                opacity="0"
                h="0"
                w="0"
                lineHeight="0"
                overflow="hidden"
                p="0"
                m="0"
              />
              <Box width="100%" my={6}>
                <Flex flexDir="column" width="100%" my={6}>
                  <Steps
                    variant="simple"
                    colorScheme="red"
                    activeStep={activeStep}
                  >
                    {steps.map(({ label }, index) => (
                      <Step label={label} key={label}>
                        <Box mt={4}>
                          {index === 0 && (
                            <>
                              {/* Personal Information */}
                              <Box>
                                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                  {/* First Name */}
                                  <GridItem colSpan={1}>
                                    <FormControl flex="1" mr={4}>
                                      <FormLabel>First Name</FormLabel>
                                      <Input
                                        type="text"
                                        id="firstName"
                                        name="First Name"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        isInvalid={!!errors.firstName}
                                        required
                                      />
                                      <Text color="red.500">
                                        {errors.firstName}
                                      </Text>
                                    </FormControl>
                                  </GridItem>

                                  <GridItem colSpan={1}>
                                    <FormControl flex="1">
                                      <FormLabel>Middle Name</FormLabel>
                                      <Input
                                        type="text"
                                        id="middleName"
                                        name="Middle Name"
                                        value={formData.middleName}
                                        onChange={handleChange}
                                      />
                                    </FormControl>
                                  </GridItem>

                                  <GridItem colSpan={1}>
                                    <FormControl flex="1" mr={4}>
                                      <FormLabel>Last Name</FormLabel>
                                      <Input
                                        type="text"
                                        id="lastName"
                                        name="Last Name"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        required
                                      />
                                    </FormControl>
                                  </GridItem>

                                  <GridItem colSpan={1}>
                                    <FormControl flex="1">
                                      <FormLabel>Date of Birth</FormLabel>
                                      <Input
                                        type="date"
                                        id="dob"
                                        name="Date of Birth"
                                        value={formData.dob}
                                        onChange={handleChange}
                                        isInvalid={!!errors.dob}
                                        required
                                      />
                                      <Text color="red.500">{errors.dob}</Text>
                                    </FormControl>
                                  </GridItem>
                                  <GridItem colSpan={1}>
                                    <FormControl flex="1">
                                      <FormLabel>Country of Birth</FormLabel>

                                      <AllCountriesSelect
                                        value={formData.countryob}
                                        handleChange={handleChange}
                                        name="countryob"
                                      />
                                      <Text color="red.500">{errors.dob}</Text>
                                    </FormControl>
                                  </GridItem>
                                  <GridItem colSpan={1}>
                                    <FormControl flex="1">
                                      <FormLabel>
                                        Country of Citizenship
                                      </FormLabel>

                                      <AllCountriesSelect
                                        value={formData.citizenship}
                                        handleChange={handleChange}
                                        name="citizenship"
                                      />
                                      <Text color="red.500">{errors.dob}</Text>
                                    </FormControl>
                                  </GridItem>

                                  <GridItem colSpan={1}>
                                    <FormControl flex="1" mr={4}>
                                      <FormLabel>Pronouns</FormLabel>
                                      <Select
                                        placeholder="Select Pronouns"
                                        onChange={handleChange}
                                        name="pronouns"
                                        id="pronouns"
                                        value={formData.pronouns ?? undefined}
                                        required
                                      >
                                        <option value="He/him">He/him</option>
                                        <option value="She/Her">She/Her</option>
                                        <option value="They/Them">
                                          They/Them
                                        </option>
                                        <option value="Other">Other</option>
                                      </Select>
                                    </FormControl>
                                  </GridItem>
                                  <GridItem colSpan={1}>
                                    <FormControl flex="1" mr={4}>
                                      <FormLabel>Title</FormLabel>
                                      <Input
                                        type="text"
                                        id="title"
                                        name="Title"
                                        value={formData.title || undefined}
                                        onChange={handleChange}
                                      />
                                    </FormControl>
                                  </GridItem>

                                  {formData.pronouns === "Other" && (
                                    <GridItem colSpan={1}>
                                      <FormControl flex="1">
                                        <FormLabel>Other Pronouns</FormLabel>
                                        <Input
                                          placeholder=" "
                                          name="otherPronouns"
                                          id="otherPronouns"
                                          value={
                                            formData.otherPronouns || undefined
                                          }
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          required
                                        />
                                        <FormErrorMessage>
                                          Other Pronouns are required when
                                          Pronouns is Other
                                        </FormErrorMessage>
                                      </FormControl>
                                    </GridItem>
                                  )}
                                </Grid>
                              </Box>
                            </>
                          )}
                          {index === 1 && (
                            <>
                              {/* Contact Information */}
                              <Box mt={6}>
                                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                  <GridItem colSpan={1}>
                                    <FormControl flex="1" mr={4}>
                                      <FormLabel>Email</FormLabel>
                                      <Input
                                        type="email"
                                        id="email"
                                        name="Email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        isInvalid={!!errors.email}
                                        isDisabled
                                      />
                                      <Text color="red.500">
                                        {errors.email}
                                      </Text>
                                    </FormControl>
                                  </GridItem>
                                  <GridItem colSpan={1}>
                                    <FormControl flex="1" mr={4}>
                                      <FormLabel>Phone</FormLabel>
                                      <Input
                                        type="text"
                                        id="phone"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        isInvalid={!!errors.phone}
                                      />
                                      <Text color="red.500">
                                        {errors.phone}
                                      </Text>
                                    </FormControl>
                                  </GridItem>
                                  <GridItem colSpan={1}>
                                    <FormControl flex="1" mr={4}>
                                      <FormLabel>Address Line</FormLabel>
                                      <Input
                                        type="text"
                                        id="addressLine1"
                                        name="addressLine1"
                                        value={formData.addressLine1 ?? ""}
                                        onChange={handleChange}
                                        isInvalid={!!errors.addressLine1}
                                      />
                                      <Text color="red.500">
                                        {errors.addressLine1}
                                      </Text>
                                    </FormControl>
                                  </GridItem>
                                  <GridItem colSpan={1}>
                                    <FormControl flex="1" mr={4}>
                                      <FormLabel>City</FormLabel>
                                      <Input
                                        type="text"
                                        id="city"
                                        name="city"
                                        value={formData.city ?? ""}
                                        onChange={handleChange}
                                        isInvalid={!!errors.city}
                                      />
                                      <Text color="red.500">{errors.city}</Text>
                                    </FormControl>
                                  </GridItem>
                                  <GridItem colSpan={1}>
                                    <FormControl flex="1" mr={4}>
                                      <FormLabel>State</FormLabel>
                                      <AllStatesSelect
                                        handleChange={handleChange}
                                        value={formData.state ?? ""}
                                      />
                                      <Text color="red.500">
                                        {errors.state}
                                      </Text>
                                    </FormControl>
                                  </GridItem>
                                  <GridItem colSpan={1}>
                                    <FormControl flex="1" mr={4}>
                                      <FormLabel>Zip code</FormLabel>
                                      <Input
                                        type="text"
                                        id="zipcode"
                                        name="zipcode"
                                        value={formData.zipcode ?? ""}
                                        onChange={handleChange}
                                        isInvalid={!!errors.zipcode}
                                      />
                                      <Text color="red.500">
                                        {errors.zipcode}
                                      </Text>
                                    </FormControl>
                                  </GridItem>
                                  {/* <GridItem colSpan={1}>
                                    <FormControl flex="1" mr={4}>
                                      <FormLabel>Phone</FormLabel>
                                      <Input
                                        type="text"
                                        id="phone"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        isInvalid={!!errors.phone}
                                      />
                                      <Text color="red.500">
                                        {errors.phone}
                                      </Text>
                                    </FormControl>
                                  </GridItem> */}

                                  {/* <GridItem colSpan={1}>
                                    <FormControl flex="1" mr={4}>
                                      <FormLabel>Company</FormLabel>
                                      <Input
                                        type="text"
                                        id="company"
                                        name="Company"
                                        value={formData.company || undefined}
                                        onChange={handleChange}
                                        isInvalid={!!errors.company}
                                      />
                                      <Text color="red.500">
                                        {errors.company}
                                      </Text>
                                    </FormControl>
                                  </GridItem> */}

                                  {/* <GridItem colSpan={1}>
                                    <FormControl flex="1">
                                      <FormLabel>Visa Type</FormLabel>
                                      <Select
                                        name="visaType"
                                        id="visaType"
                                        value={formData.visaType || undefined}
                                        onChange={handleChange}
                                        placeholder="Select visa type"
                                        isInvalid={!!errors.visaType}
                                        isDisabled
                                      >
                                        <option>{formData.visaType}</option>
                                      </Select>
                                      <Text color="red.500">
                                        {errors.visaType}
                                      </Text>
                                    </FormControl>
                                  </GridItem> */}
                                </Grid>
                              </Box>
                            </>
                          )}
                          {index === 2 && (
                            <>
                              {/* U.S. Address */}
                              <Box mt={6}>
                                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                  <GridItem colSpan={1}>
                                    <FormControl flex="1" mr={4}>
                                      <FormLabel>Employer Name</FormLabel>
                                      <Input
                                        name="employer_name"
                                        id="employer_name"
                                        value={formData.employer_name ?? ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </FormControl>
                                  </GridItem>
                                  <GridItem colSpan={1}>
                                    <FormControl flex="1" mr={4}>
                                      <FormLabel>Address Line</FormLabel>
                                      <Input
                                        type="text"
                                        id="employer_addressLine1"
                                        name="employer_addressLine1"
                                        value={
                                          formData.employer_addressLine1 ?? ""
                                        }
                                        onChange={handleChange}
                                        isInvalid={
                                          !!errors.employer_addressLine1
                                        }
                                      />
                                      <Text color="red.500">
                                        {errors.employer_addressLine1}
                                      </Text>
                                    </FormControl>
                                  </GridItem>
                                  <GridItem colSpan={1}>
                                    <FormControl flex="1" mr={4}>
                                      <FormLabel>City</FormLabel>
                                      <Input
                                        type="text"
                                        id="employer_city"
                                        name="employer_city"
                                        value={formData.employer_city ?? ""}
                                        onChange={handleChange}
                                        isInvalid={!!errors.employer_city}
                                      />
                                      <Text color="red.500">
                                        {errors.employer_city}
                                      </Text>
                                    </FormControl>
                                  </GridItem>
                                  <GridItem colSpan={1}>
                                    <FormControl flex="1" mr={4}>
                                      <FormLabel>State</FormLabel>
                                      <AllStatesSelect
                                        name="employer_state"
                                        handleChange={handleChange}
                                        value={formData.employer_state ?? ""}
                                      />
                                      <Text color="red.500">
                                        {errors.employer_state}
                                      </Text>
                                    </FormControl>
                                  </GridItem>
                                  <GridItem colSpan={1}>
                                    <FormControl flex="1" mr={4}>
                                      <FormLabel>Zip code</FormLabel>
                                      <Input
                                        type="text"
                                        id="zipcode"
                                        name="zipcode"
                                        value={formData.zipcode ?? ""}
                                        onChange={handleChange}
                                        isInvalid={!!errors.zipcode}
                                      />
                                      <Text color="red.500">
                                        {errors.zipcode}
                                      </Text>
                                    </FormControl>
                                  </GridItem>
                                </Grid>
                              </Box>
                            </>
                          )}
                        </Box>
                      </Step>
                    ))}
                  </Steps>
                </Flex>
              </Box>
            </Flex>
          </Flex>
        </Flex>

        <CardFooter display="flex" flexDirection="column" alignItems="center">
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={2}
          >
            <Flex justifyContent="center" gap={4} m={6}>
              <Button
                className="btn tertiary-btn"
                isDisabled={activeStep === 0}
                onClick={prevStep}
              >
                Prev
              </Button>

              <Button
                onClick={isLastStep ? onOpen : nextStep}
                className="btn secondary-btn"
                width="fit-content"
                isLoading={isSubmitting}
              >
                {isLastStep ? "Save" : "Next"}
              </Button>
            </Flex>
            <Button
              className="btn tertiary-btn"
              onClick={reset}
              width="fit-content"
            >
              Reset
            </Button>
          </Flex>
        </CardFooter>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirm Changes</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {changedFields.length > 0 && (
                <div>
                  <p>The following information has been changed:</p>
                  <UnorderedList>
                    {changedFields.map((item, index) => (
                      <ListItem key={index}>{item}</ListItem>
                    ))}
                  </UnorderedList>
                </div>
              )}
              <p>Do you want to save these changes?</p>
            </ModalBody>
            <ModalFooter>
              <Button
                className="btn tertiary-btn"
                variant="outline"
                mr={3}
                onClick={onClose}
                isDisabled={isLoadingUpdateUser}
              >
                Close
              </Button>
              <Button
                type="submit"
                className="btn secondary-btn"
                onClick={() => {
                  handleConfirmSubmit();
                  onClose();
                }}
                isLoading={isLoadingUpdateUser}
              >
                Save Changes
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </form>
    </Card>
  ) : null;
};
