import { useState } from "react";
import { doc, setDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { db } from "../api/firebaseApi";
import { DATABASE } from "../types/tables-data";

export const useAddEmptyForms = (indivId?: string) => {
  const [isLoadingAddEmptyForms, setIsLoadingAddEmptyForms] = useState(false);
  const params = useParams();

  const onSubmitAddEmptyForms = async () => {
    setIsLoadingAddEmptyForms(true);
    try {
      await setDoc(
        doc(db, DATABASE.FORMS, indivId ? `${indivId}` : `${params.caseID}`),
        {
          expertLetter: null,
          questionnaires: null
        }
      );
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingAddEmptyForms(false);
    }
  };

  return {
    isLoadingAddEmptyForms,
    onSubmitAddEmptyForms
  };
};
