import { useState } from "react";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { db } from "../api/firebaseApi";
import { DATABASE } from "../types/tables-data";

export interface RemoveDocProps {
  firstName: string;
  lastName: string;
  uid: string;
  docTitle: string;
  docId?: string;
}

export const useDeleteClientDocument = () => {
  const [isLoadingDeleteClientDocs, setIsLoadingDeleteClientDocs] =
    useState(false);

  const params = useParams();

  const onSubmitDeleteUserDocuments = async ({
    firstName,
    lastName,
    uid,
    docTitle
  }: RemoveDocProps) => {
    const updaterRef = doc(db, DATABASE.DOCUMENTS, `${params.caseID}`);

    setIsLoadingDeleteClientDocs(true);
    try {
      await updateDoc(updaterRef, {
        lastUploadBy: `${firstName} ${lastName}`,
        lastuploadByUid: uid
      });
      await deleteDoc(
        doc(db, DATABASE.DOCUMENTS, `${params.caseID}`, "docs", docTitle)
      );
    } catch (e) {
      console.error("Error add doc: ", e);
    } finally {
      setIsLoadingDeleteClientDocs(false);
    }
  };

  return {
    isLoadingDeleteClientDocs,
    onSubmitDeleteUserDocuments
  };
};
