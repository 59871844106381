import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Skeleton,
  Text
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { doc, onSnapshot } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { db } from "../api/firebaseApi";
import { RichTextEditor } from "../components/common/RichTextEditor";
import Thumbnail from "../components/individualTabs/individualDocuments/Thumbnail";
import { DocumentReducer } from "../redux/documents/documentSlice";
import { documentSelectors } from "../redux/documents/selectors";
import {
  EXTRACTIONSTATES,
  JobType
} from "../redux/extraction-jobs/extractionJobsSlice";
import styles from "../styles/extracted-info.module.css";
import { DATABASE, DocUrl } from "../types/tables-data";
import { useAddExtractInfo } from "../hooks/useAddExtractInfo";

export type ResumeFormProps = {
  resumeUrl: DocUrl | null;
  formik: any;
  values: {
    resumeFirstName: string;
    resumeMiddleName: string;
    resumeLastName: string;
    resumeEmailAddress: string;
    resumeLastRole: string;
    resumeLastEmployer: string;
    resumeLastRoleStartDate: string;
    resumeLastRoleEndDate: string;
    resumeFirstRoleBeforeLast: string;
    resumeFirstEmployerBeforeLast: string;
    resumeFirstBeforeLastRoleStartDate: string;
    resumeFirstBeforeLastRoleEndDate: string;
    resumeSecondRoleBeforeLast: string;
    resumeSecondEmployerBeforeLast: string;
    resumeSecondBeforeLastRoleStartDate: string;
    resumeSecondBeforeLastRoleEndDate: string;
    resumeLastEducationInstitution: string;
    resumeLastEducationDegree: string;
    resumeLastEducationDateObtained: string;
    resumeFirstBeforeLastEducationInstitution: string;
    resumeFirstBeforeLastEducationDegree: string;
    resumeFirstBeforeLastEducationDateObtained: string;
    resumeSecondBeforeLastEducationInstitution: string;
    resumeSecondBeforeLastEducationDegree: string;
    summaryPoints: string;
    summaryPointsHtml: string;
  };
  handleChange: React.ChangeEventHandler<any>;
};

export const ResumeForm = ({
  handleChange,
  values,
  resumeUrl,
  formik
}: ResumeFormProps) => {
  const { id, caseId } = useParams();

  const resumeDocument = useSelector((state: DocumentReducer) =>
    documentSelectors.selectDocumentByType(state, "resume")
  );

  const extractedData = useSelector(documentSelectors.extractedData);

  const [isLoadingSummaryPoints, setIsLoadingSummaryPoints] = useState(false);

  useAddExtractInfo(id);

  useEffect(() => {
    const docRef = doc(db, DATABASE.EXTRACTED, `${id ?? caseId}`);
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const status = docSnap.get("status");
        if (
          status?.taskType === JobType.SummarizingResume &&
          (status?.status === EXTRACTIONSTATES.Processing ||
            status?.status === EXTRACTIONSTATES.Pending)
        ) {
          setIsLoadingSummaryPoints(true);
        } else {
          setIsLoadingSummaryPoints(false);
        }
      }
    });

    return () => unsubscribe();
  }, [id, caseId]);

  // Sync extractedData with formik values
  useEffect(() => {
    if (extractedData?.summaryPointsHtml) {
      formik.setFieldValue(
        "summaryPoints",
        extractedData.summaryPoints || "",
        false
      );
      formik.setFieldValue(
        "summaryPointsHtml",
        extractedData.summaryPointsHtml || "",
        false
      );
    }
  }, [extractedData?.summaryPointsHtml]);

  return (
    <Box>
      <Flex flexDirection="column" mt={2}>
        <Flex flexDirection="column">
          <Flex flexDirection="column">
            <FormControl mt={8} variant="fixed" id="summaryPointsHtml" my={4}>
              <Flex flexDirection="column">
                <Text fontWeight={700} fontSize={20} color="primary.darkBlue">
                  Resume summary
                </Text>
                {isLoadingSummaryPoints ? (
                  <Skeleton height="150px" />
                ) : (
                  (values.summaryPointsHtml !== "" ||
                    values.summaryPoints !== "") && (
                    <RichTextEditor
                      customHeight="auto"
                      shouldShowSaveBtn={false}
                      updateText={(html, text) => {
                        formik.setFieldValue(
                          "summaryPointsHtml",
                          html || "",
                          false
                        );
                        formik.setFieldValue(
                          "summaryPoints",
                          text || "",
                          false
                        );
                      }}
                      text={values.summaryPointsHtml || values.summaryPoints}
                      saveText={null}
                    />
                  )
                )}
                <Divider />
              </Flex>
            </FormControl>
          </Flex>

          <Flex className={styles.document_section}>
            <Flex flexDirection="column" gap={4}>
              <Text fontWeight={700} fontSize={20} color="primary.darkBlue">
                Extracted Data
              </Text>
              <Text fontWeight={550} fontSize={20} color="primary.darkBlue">
                Resume
              </Text>
              <Box className="flex gap-4">
                <FormControl variant="floating" id="resumeEmailAddress" my={4}>
                  <Input
                    placeholder=" "
                    name="resumeEmailAddress"
                    value={values.resumeEmailAddress}
                    onChange={handleChange}
                  />
                  <FormLabel>Email Address</FormLabel>
                </FormControl>
                <FormControl variant="floating" id="resumeLastEmployer" my={4}>
                  <Input
                    placeholder=" "
                    name="resumeLastEmployer"
                    value={values.resumeLastEmployer}
                    onChange={handleChange}
                  />
                  <FormLabel>Last Employer</FormLabel>
                </FormControl>
              </Box>
              <Box className="flex gap-4">
                <FormControl
                  variant="floating"
                  id="resumeLastRoleStartDate"
                  my={4}
                >
                  <Input
                    placeholder=" "
                    name="resumeLastRoleStartDate"
                    value={values.resumeLastRoleStartDate}
                    onChange={handleChange}
                  />
                  <FormLabel>Last Role Start Date</FormLabel>
                </FormControl>
                <FormControl
                  variant="floating"
                  id="resumeLastEducationInstitution"
                  my={4}
                >
                  <Input
                    placeholder=" "
                    name="resumeLastEducationInstitution"
                    value={values.resumeLastEducationInstitution}
                    onChange={handleChange}
                  />
                  <FormLabel>Last Education Institution</FormLabel>
                </FormControl>
              </Box>
              <Box className="flex gap-4">
                <FormControl
                  variant="floating"
                  id="resumeLastEducationDegree"
                  my={4}
                >
                  <Input
                    placeholder=" "
                    name="resumeLastEducationDegree"
                    value={values.resumeLastEducationDegree}
                    onChange={handleChange}
                  />
                  <FormLabel>Last Education Degree</FormLabel>
                </FormControl>
                <FormControl
                  variant="floating"
                  id="resumeLastEducationDateObtained"
                  my={4}
                >
                  <Input
                    placeholder=" "
                    name="resumeLastEducationDateObtained"
                    value={values.resumeLastEducationDateObtained}
                    onChange={handleChange}
                  />
                  <FormLabel>Last Education Date Obtained</FormLabel>
                </FormControl>
              </Box>
            </Flex>

            <Flex>
              {resumeDocument?.filePath && (
                <Thumbnail filePath={resumeDocument?.filePath} />
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
