import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../api/firebaseApi";
import { individualSelectors } from "../redux/individual/selectors";

const useDeleteExpertLetter = (individualId : string) => {
  const email = useSelector(individualSelectors.selectEmail);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [resumeDeletionLoading, setResumeDeletionLoading] = useState(false);

  const onSubmitDeleteResume = async (
    experLetterId: string
  ) => {
    setResumeDeletionLoading(true);
    setError(null);

    try {
      const docRef = doc(
        db,
        "forms",
        `${individualId}`,
        "expert_letters",
        `${experLetterId}`
      );
      await updateDoc(docRef, {
        resumeUrl: null,
        last_updated_by: email
      });
    } catch (e) {
      console.log(e);
    } finally {
      setResumeDeletionLoading(false);
    }
  };

  const onSubmitDeleteExpertLetter = async (
    experLetterId: string
  ) => {
    setIsLoading(true);
    setError(null);

    try {
      const docRef = doc(
        db,
        "forms",
        `${individualId}`,
        "expert_letters",
        `${experLetterId}`
      );
      await deleteDoc(docRef);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    onSubmitDeleteExpertLetter,
    onSubmitDeleteResume,
    isLoading,
    resumeDeletionLoading,
    error
  };
};

export default useDeleteExpertLetter;
