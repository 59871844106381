import { useState } from "react";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  serverTimestamp,
  Timestamp,
  updateDoc
} from "firebase/firestore";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { db } from "../api/firebaseApi";
import { DATABASE } from "../types/tables-data";

import { individualSelectors } from "../redux/individual/selectors";

interface DeleteExtractedInfo {
  firstName: string;
  lastName: string;
}

interface DeleteEvidenceInfo {
  firstName: string;
  lastName: string;
  evidenceId: string;
}

export const useDeleteExtractedInfo = (indivId?: string) => {
  const email = useSelector(individualSelectors.selectEmail);
  const [isLoadingDeleteExtractedInfo, setIsLoadingDeleteExtractedInfo] =
    useState(false);
  const [isLoadingDeleteEvidence, setIsLoadingDeleteEvidence] = useState(false);
  const [isLoadingDeleteAllExtractedInfo, setIsLoadingDeleteAllExtractedInfo] =
    useState(false);

  const params = useParams();

  const onDeleteExtractedInfo = async ({
    firstName,
    lastName
  }: DeleteExtractedInfo) => {
    setIsLoadingDeleteExtractedInfo(true);
    const extractedDataRef = doc(
      db,
      DATABASE.EXTRACTED,
      `${params.id ?? params.caseID}`
    );
    // const resumeRef = doc(
    //   db,
    //   DATABASE.DOCUMENTS,
    //   `${params.id ?? params.caseID}`,
    //   "docs",
    //   "Resume | CV"
    // );
    try {
      await updateDoc(extractedDataRef, {
        data: {
          passport: null,
          visa: null,
          resume: null,
          i94: null
        },
        summaryPoints: null,
        summaryPointsHtml: null,
        last_updated_by: email,
        last_updated_at: serverTimestamp()
      });
      // await updateDoc(resumeRef, {
      //   uploadedDate: Date.now(),
      //   summary: null,
      //   summaryHtml: null,
      //   last_updated_by: email,
      //   last_updated_at: serverTimestamp()
      // });
    } catch (e) {
      console.error("Error add doc: ", e);
    } finally {
      setIsLoadingDeleteExtractedInfo(false);
    }
  };

  const onDeleteAllExtractedInfo = async () => {
    setIsLoadingDeleteAllExtractedInfo(true);
    try {
      await deleteDoc(
        doc(db, DATABASE.EXTRACTED, `${params.id ?? params.caseID}`)
      );
    } catch (e) {
      console.error("Error add doc: ", e);
    } finally {
      setIsLoadingDeleteAllExtractedInfo(false);
    }
  };

  const onDeleteExtractedEvidence = async ({
    firstName,
    lastName
  }: DeleteEvidenceInfo) => {
    setIsLoadingDeleteEvidence(true);
    const evidenceDocsRef = collection(
      db,
      DATABASE.DOCUMENTS,
      `${indivId ?? params.caseID}`,
      "evidence_docs"
    );

    try {
      // Get all evidence documents
      const querySnapshot = await getDocs(evidenceDocsRef);
      querySnapshot.forEach(async (documentSnapshot) => {
        // For each document, update the extracted_argument field to null
        const docRef = doc(
          db,
          DATABASE.DOCUMENTS,
          `${indivId ?? params.caseID}`,
          "evidence_docs",
          documentSnapshot.id
        );
        await updateDoc(docRef, {
          extracted_argument: null,
          uploadedBy: `${firstName} ${lastName}`,
          uploadedDate: Date.now(),
          last_updated_by: email
        });
      });
    } catch (e) {
      console.error("Error updating documents: ", e);
    } finally {
      setIsLoadingDeleteEvidence(false);
    }
  };

  const fieldMapping: { [key: string]: string } = {
    passport: "data.passport",
    resume: "data.resume",
    visa: "data.visa",
    "i-94": "data.i94"
  };

  const deleteExtractionByDocType = async (docType: string) => {
    const docRef = doc(db, DATABASE.EXTRACTED, params.id ?? params.caseID!);

    const fieldToDelete = Object.keys(fieldMapping).find((key) =>
      docType.toLowerCase().includes(key)
    );

    if (!fieldToDelete) {
      console.error("Invalid document type", {
        keyword: "deleteExtractionByDocType",
        value: { docType, fieldMapping }
      });
      return;
    }

    console.log("Deleting document field", {
      keyword: "deleteExtractionByDocType",
      value: { docType, fieldToDelete }
    });

    try {
      await updateDoc(docRef, {
        [`data.${fieldToDelete}`]: null,
        last_updated_by: email,
        last_updated_at: serverTimestamp()
      });
      console.log("Document field deleted successfully", {
        keyword: "deleteExtractionByDocType",
        value: { docType, fieldToDelete, updated_by: email }
      });
    } catch (e) {
      console.error("Error deleting doc field", {
        keyword: "deleteExtractionByDocType",
        value: { error: e, docType, fieldToDelete }
      });
    }
  };

  return {
    deleteExtractionByDocType,
    onDeleteAllExtractedInfo,
    onDeleteExtractedEvidence,
    isLoadingDeleteExtractedInfo,
    isLoadingDeleteEvidence,
    isLoadingDeleteAllExtractedInfo,
    onDeleteExtractedInfo
  };
};
