import type { ChangeEventHandler } from "react";
import { useSelector } from "react-redux";
import { FormControl, FormLabel, Input, Box, Flex, Text } from "./_index";
import Thumbnail from "../components/individualTabs/individualDocuments/Thumbnail";

import styles from "../styles/extracted-info.module.css";
import { DocUrl } from "../types/tables-data";
import { DocumentReducer } from "../redux/documents/documentSlice";
import { documentSelectors } from "../redux/documents/selectors";

export type PasseportFormProps = {
  passportUrl: DocUrl | null;
  values: {
    passportFirstName: string;
    passportMiddleName: string;
    passportLastName: string;
    passportDoB: string;
    passportNumber: string;
    passportDateOfIssue: string;
    passportExpirationDate: string;
    passportCountry: string;
  };
  handleChange: ChangeEventHandler<any>;
};

export const PasseportForm = ({
  handleChange,
  values,
  passportUrl
}: PasseportFormProps) => {
  const passeportDocument = useSelector((state: DocumentReducer) =>
    documentSelectors.selectDocumentByType(state, "passport")
  );
  return (
    <Box>
              <Text fontWeight={550} fontSize={20} color="primary.darkBlue">
                Passport
              </Text>
      <Flex flexDirection="column" mt={8}>
        <Flex flexDirection="column">
          <Flex className={styles.document_section}>
            <Flex flexDirection="column">
              <Box className="flex gap-4">
                <FormControl
                  className=""
                  variant="floating"
                  id="passportFirstName"
                  my={4}
                >
                  <Input
                    placeholder=" "
                    name="passportFirstName"
                    type="text"
                    value={values.passportFirstName}
                    onChange={handleChange}
                  />
                  <FormLabel>First Name</FormLabel>
                </FormControl>

                <FormControl
                  className="basis-1/2"
                  variant="floating"
                  id="passportMiddleName"
                  my={4}
                >
                  <Input
                    placeholder=" "
                    name="passportMiddleName"
                    type="text"
                    value={values.passportMiddleName}
                    onChange={handleChange}
                  />
                  <FormLabel>Middle Name</FormLabel>
                </FormControl>
                <FormControl
                  className=" "
                  variant="floating"
                  id="passportLastName"
                  my={4}
                >
                  <Input
                    placeholder=" "
                    name="passportLastName"
                    type="text"
                    value={values.passportLastName}
                    onChange={handleChange}
                  />
                  <FormLabel>Last Name</FormLabel>
                </FormControl>
              </Box>
              <FormControl variant="floating" id="passportDoB" my={4}>
                <Input
                  placeholder=" "
                  name="passportDoB"
                  type="date"
                  min="1900-01-01"
                  max="2100-01-01"
                  value={values.passportDoB}
                  onChange={handleChange}
                />
                <FormLabel>Date of Birth</FormLabel>
              </FormControl>
              <Box className="flex gap-4">
                <FormControl variant="floating" id="passportNumber" my={4}>
                  <Input
                    placeholder=" "
                    name="passportNumber"
                    type="text"
                    value={values.passportNumber}
                    onChange={handleChange}
                  />
                  <FormLabel>Passport Number</FormLabel>
                </FormControl>
                <FormControl variant="floating" id="passportCountry" my={4}>
                  <Input
                    placeholder=" "
                    name="passportCountry"
                    type="text"
                    value={values.passportCountry}
                    onChange={handleChange}
                  />
                  <FormLabel>Country</FormLabel>
                </FormControl>
              </Box>

              <Box className="flex gap-4">
                <FormControl variant="floating" id="passportDateOfIssue" my={4}>
                  <Input
                    placeholder=" "
                    name="passportDateOfIssue"
                    type="date"
                    min="1900-01-01"
                    max="2100-01-01"
                    value={values.passportDateOfIssue}
                    onChange={handleChange}
                  />
                  <FormLabel>Passport Issuing Date</FormLabel>
                </FormControl>

                <FormControl
                  variant="floating"
                  id="passportExpirationDate"
                  my={4}
                >
                  <Input
                    placeholder=" "
                    name="passportExpirationDate"
                    type="date"
                    min="1900-01-01"
                    max="2100-01-01"
                    value={values.passportExpirationDate}
                    onChange={handleChange}
                  />
                  <FormLabel>Passport Expiration Date</FormLabel>
                </FormControl>
              </Box>
            </Flex>
            <Flex>
              {passeportDocument?.filePath && (
                <Thumbnail filePath={passeportDocument?.filePath} />
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
