import { Image, Link } from "@chakra-ui/react";
import { useRef } from "react";
import images from "../assets/image-export-assets";

interface FileUploaderProps {
  title?: string;
  onUpload: (files: FileList | null) => void;
}

export const UploadFilesFooterLink = ({
  title = "Drag & drop above or click here to upload",
  onUpload,
}: FileUploaderProps) => {
  const linkFileInputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <input
        onChange={(e) => onUpload(e.target.files)}
        className="hidden"
        multiple
        type="file"
        ref={linkFileInputRef}
      />
      <Link
          onClick={(e) => {
            linkFileInputRef?.current?.click();
          }}
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center", // Optional, if you also want to center vertically
            width: "100%",
            height: "100%", // Optional, adjust as needed
            color: "darkblue",
            cursor: "pointer",
            textDecoration: "none", // Removes the default underline of the link
            fontWeight: "normal",
          }}
        >
          { title }
        </Link>   
    </>
  );
};
