import { Box, Button, Icon, Text } from "@chakra-ui/react";
import { FaExclamationTriangle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { auth } from "../api/firebaseApi";

const NotFound = () => {
  const navigate = useNavigate();

  const onClick = () => {
    if (!auth.currentUser) {
      navigate("/login", { replace: true });
      return;
    }
    navigate(-1); // Go back in history if the user is authenticated
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="90vh"
      bg="primary.background"
      color="primary.darkGray"
      textAlign="center"
      px={4}
    >
      <Icon
        as={FaExclamationTriangle}
        boxSize={16}
        color="primary.red"
        mb={4}
      />
      <Text fontSize="4xl" fontWeight="bold" mb={2}>
        404
      </Text>
      <Text fontSize="2xl" mb={6}>
        Page Not Found
      </Text>
      <Text fontSize="lg" mb={6}>
        Sorry, the page you are looking for does not exist.
      </Text>
      <Button onClick={onClick} className="btn primary-btn">
        Back
      </Button>
    </Box>
  );
};

export default NotFound;
