import { createAsyncThunk } from "@reduxjs/toolkit";
import { getBlob, ref } from "firebase/storage";
import { db, storage } from "../../api/firebaseApi";
import { GenerateDocumentsProps } from "../../helpers/helpers";

export const generateDocumentUrls = createAsyncThunk(
  "document/generateDocumentUrls",
  async (
    { documents, type, mainDocId }: GenerateDocumentsProps,
    { rejectWithValue }
  ) => {
    try {
      const result: {
        id: string;
        fileUrl: string;
        type: string;
        mainDocId: string | undefined;
      }[] = [];
      const promises = documents.map(async (document) => {
        const fileRef = ref(storage, document.filePath);
        const fileBlob = await getBlob(fileRef);
        const fileUrl = URL.createObjectURL(fileBlob);
        result.push({ id: document.id, fileUrl, type, mainDocId });
      });
      await Promise.allSettled(promises);
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const generateSingleDocumentUrl = createAsyncThunk(
  "document/generateSingleDocumentUrl",
  async (
    document: { id: string; filePath: string; type: string },
    { rejectWithValue }
  ) => {
    try {
      const fileRef = ref(storage, document.filePath);
      const fileBlob = await getBlob(fileRef);
      const fileUrl = URL.createObjectURL(fileBlob);
      return { id: document.id, fileUrl, type: document.type };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
