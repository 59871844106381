import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Input,
  Button,
  Flex,
  Badge,
  Heading,
  Card,
  Spacer,
  Text
} from "@chakra-ui/react";
import { clickedStyles } from "../../../../../styles/common-styles";

export const I140Form = () => {
  const navigate = useNavigate();
  return (
    <Flex flexDirection="column" rowGap={4} w="100%" alignItems="center">
      <Card mb="30px" w="100%">
        <Flex flexDirection="column" rowGap="20px">
          <Box py="15px" sx={clickedStyles} w="100%" textAlign="center">
            <Heading size="md">Pre-filled form</Heading>
          </Box>
        </Flex>
        <Card>
          <Flex flexDirection="column" rowGap={4} w="100%" alignItems="center">
            <Box
              m={6}
              p="15px"
              width="94%"
              fontWeight="400"
              textAlign="left"
              bgColor="rgba(255, 251, 229, 0.67)"
              mb="10px"
              borderRadius="5px"
              border="1px solid #ECE4E4"
            >
              <Text fontSize="18px" fontStyle="italic">
                It is your responsibility is to{" "}
                <b>double check the pre-filled data</b>. Please edit or amend
                any fields that were not correctly pre-filled.
              </Text>
            </Box>
            <Flex m="10px">
              <Button
                bg="bgColor.300"
                color="text.100"
                type="button"
                onClick={() => navigate(-1)}
                mx="15px"
              >
                Cancel
              </Button>
              {/* {briefBlurbsArgs && !extractedData?.prongArguments && ( */}

              {/* <TextFromApiBlock text={briefBlurbsArgs} title="Arguments" /> */}
              <Button
                bg="bgColor.300"
                color="text.100"
                mx="15px"
                type="button"
                // onClick={handleSaveProngArguments}
                // isLoading={isLoadingSaveProngArguments}
              >
                Save
              </Button>
            </Flex>
          </Flex>
        </Card>
      </Card>
    </Flex>
  );
};
