import { doc, setDoc } from "firebase/firestore";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../api/firebaseApi";
import { ExpertLetter } from "../redux/clients/clientsSlice";
import { DATABASE } from "../types/tables-data";

export const useUpdateIndivFormsData = (indivId: string) => {
  const [isLoadingUpdateFormsData, setIsLoadingUpdateFormsData] =
    useState(false);
  const [errorUpdateFormsData, setErrorUpdateFormsData] = useState();

  const params = useParams();

  const onSubmitAddUpdateLetter = async (
    values: Partial<ExpertLetter>,
    expertLetterId: string,
    _useLoadingFlag = true
  ) => {
    if (_useLoadingFlag) setIsLoadingUpdateFormsData(true);
    console.log("experttesting: Starting onSubmitAddUpdateLetter");
    console.log("experttesting: Values received:", values);
    console.log("experttesting: Expert Letter ID:", expertLetterId);

    try {
      let ref;
      if (indivId && expertLetterId) {
        ref = doc(
          db,
          DATABASE.FORMS,
          `${indivId}`,
          "expert_letters",
          `${expertLetterId}`
        );
        console.log(
          "experttesting: Firestore document reference created:",
          ref
        );
      }

      if (ref) {
        const filteredValues = Object.fromEntries(
          Object.entries(values).filter(([_, v]) => v != null)
        );
        console.log(
          "experttesting: Preparing to set document in Firestore with values:",
          filteredValues
        );

        await setDoc(
          ref,
          { ...filteredValues, uid: expertLetterId },
          { merge: true }
        );

        console.log("experttesting: Document set successfully in Firestore");
      }
    } catch (e: any) {
      const errorCode = e.code;
      console.log("experttesting: Error occurred:", e);
      setErrorUpdateFormsData(errorCode);
    } finally {
      if (_useLoadingFlag) setIsLoadingUpdateFormsData(false);
      console.log("experttesting: Finished onSubmitAddUpdateLetter");
    }
  };

  return {
    isLoadingUpdateFormsData,
    onSubmitAddUpdateLetter,
    errorUpdateFormsData
  };
};
