import { useState } from "react";
import { User, signInWithPopup } from "firebase/auth";
import { auth, provider } from "../api/firebaseApi";

export const useGoogleSignIn = () => {
  const [isLoadingGoogleSignIn, setIsLoadingGoogleSignIn] = useState(false);
  const [userGoogle, setUserGoogle] = useState<User | null>(null);
  const [errorGoogleSignIn, setErrorGoogleSignIn] = useState();

  const onSubmitGoogleSignIn = async () => {
    setIsLoadingGoogleSignIn(true);
    try {
      const userAnswer = await signInWithPopup(auth, provider);
      setUserGoogle(userAnswer.user);
    } catch (e: any) {
      const errorCode = e.code;
      setErrorGoogleSignIn(errorCode);
    } finally {
      setIsLoadingGoogleSignIn(false);
    }
  };

  return {
    isLoadingGoogleSignIn,
    onSubmitGoogleSignIn,
    userGoogle,
    errorGoogleSignIn
  };
};
