import { doc, getDoc, setDoc } from "firebase/firestore";
import { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { db } from "../api/firebaseApi";
import { groupDocumentsWithSubrows, GroupedDocument } from "../helpers/helpers";
import { setEvidenceExhibitMap } from "../redux/documents/documentSlice";
import { documentSelectors } from "../redux/documents/selectors";

const useGroupedEvidenceDocuments = () => {
  const { id, visaType } = useParams();
  const dispatch = useDispatch();
  const evidenceDocuments = useSelector(documentSelectors.evidenceDocs);
  const isLoadingGetEvidence = useSelector(
    documentSelectors.isLoadingGetEvidence
  );

  const [groupedDocuments, setGroupedDocuments] = useState<
    GroupedDocument[] | null
  >(null);

  const fetchOrderFromFirestore = useCallback(async () => {
    if (!id) return [];
    const documentPath = `/documents/${id}/`;
    const orderDocumentReference = doc(db, documentPath);
    const document = await getDoc(orderDocumentReference);
    return document.exists()
      ? (document.data() as any)?.evidenceTableOrderMap
      : [];
  }, [id]);

  const sortGroupedDocuments = useCallback(
    (groupedDocuments: GroupedDocument[], order: any) => {
      const orderMap: {
        [key: string]: {
          order: number;
          subrows: { [key: string]: number };
        };
      } = {};
      if (order) {
        order.forEach((document: any, index: any) => {
          orderMap[document.category] = { order: index, subrows: {} };
          document.evidenceIds.forEach((element: any, ind: any) => {
            orderMap[document.category].subrows[element] = ind;
          });
        });
      }
      const groupedDocumentsCopy = groupedDocuments.sort((a, b) => {
        return (
          (orderMap[a.type]?.order ?? Infinity) -
          (orderMap[b.type]?.order ?? Infinity)
        );
      });

      groupedDocumentsCopy.forEach((group) => {
        group?.subrows.sort((a, b) => {
          const orderA = orderMap[group.type]?.subrows[a.id!];
          const orderB = orderMap[group.type]?.subrows[b.id!];

          return orderA - orderB;
        });
      });

      return groupedDocumentsCopy;
    },
    []
  );

  const updateCurrentOrder = useCallback(
    (_groupedDocuments: GroupedDocument[]) => {
      if (!id) return;

      const docRef = doc(db, `/documents/${id}`);
      const todosOrderMap = _groupedDocuments.map((evidence) => ({
        category: evidence.type,
        evidenceIds: evidence.subrows.map((subrow) => subrow.id)
      }));

      setDoc(docRef, {
        evidenceTableOrderMap: todosOrderMap
      });
    },
    [id]
  );

  const updateDocuments = useCallback(async () => {
    const grouped = groupDocumentsWithSubrows(evidenceDocuments);
    const fetchedOrder = await fetchOrderFromFirestore();
    const sortedGrouped = sortGroupedDocuments(grouped, fetchedOrder);
    setGroupedDocuments(sortedGrouped);
  }, [evidenceDocuments, fetchOrderFromFirestore, sortGroupedDocuments]);

  const exhibitMap = useMemo(() => {
    if (groupedDocuments === null) return {};

    return groupedDocuments.reduce((acc, row, rowIndex) => {
      row.subrows.forEach((subRow, subRowIndex) => {
        const key = `${rowIndex + 1}.${subRowIndex + 1}`;
        acc[key] = subRow.id ?? "";
      });
      return acc;
    }, {} as Record<string, string>);
  }, [groupedDocuments]);

  useEffect(() => {
    dispatch(setEvidenceExhibitMap(exhibitMap));
  }, [exhibitMap, dispatch]);

  useEffect(() => {
    updateDocuments();
  }, [updateDocuments]);

  return {
    groupedDocuments,
    setGroupedDocuments,
    updateCurrentOrder,
    isLoadingGetEvidence,
    uid: id,
    visaType
  };
};

export default useGroupedEvidenceDocuments;
