import { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Flex,
  Heading,
  Text
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { Header } from "../components/Header";
import { Sidebar } from "../components/Sidebar/Sidebar";
import { clickedStyles } from "../styles/common-styles";

export const ClientFormStepThree = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <Header />
      <Flex justifyContent="space-between" flexDirection="row" mt="30px">
        <Sidebar
          closeSidebar={() => setShowSidebar(false)}
          showSidebar={showSidebar}
        />

        <Flex alignItems="center" flexDirection="column" flex="1">
          <Card align="center" width="90%">
            <CardHeader sx={clickedStyles} width="100%">
              <Flex alignItems="center">
                <Button
                  type="button"
                  w="40px"
                  h="40px"
                  borderRadius="50%"
                  bgColor="transparent"
                  border="2px solid #000"
                  _hover={{
                    bgColor: "blue.500",
                    border: "2px solid transparent",
                    color: "#fff"
                  }}
                  onClick={() => navigate(-1)}
                  mx="15px"
                >
                  <ArrowBackIcon w={6} h={6} />
                </Button>
                <Heading size="md" flex="1">
                  Forms questionnaire - Step 3
                </Heading>
              </Flex>
            </CardHeader>

            <Box p="15px" gap="50px" w="60%">
              <Text fontSize="22px" fontWeight="700">
                Processing information
              </Text>
            </Box>

            <Text>
              Current country of residence or, if now in the United States, last
              country of permanent residence abroad.
            </Text>
          </Card>
        </Flex>
      </Flex>
    </>
  );
};
