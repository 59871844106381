import { useState } from "react";

export const useDocumentAI = () => {
  const [isLoadingGetTextData, setIsLoadingGetTextData] = useState(false);
  const [textFromDocs, setTextFromDocs] = useState<string | null>(null);

  const ocrGetPDFData = async (content: string, mimeType: string) => {
    const payload = {
      requests: [
        {
          inputConfig: {
            content,
            mimeType
          },
          features: [
            {
              type: "DOCUMENT_TEXT_DETECTION",
              model: "builtin/stable"
            }
          ]
        }
      ]
    };
    setIsLoadingGetTextData(true);
    try {
      const resp = await fetch(
        `${process.env.REACT_APP_GOOGLE_VISION_API_PATH_FILE}?key=${process.env.REACT_APP_GOOGLE_CLOUD_VISION_API_KEY}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(payload)
        }
      );
      const { responses } = await resp.json();
      const pdfText: string[] = responses[0].responses.map(
        (resp: any) => resp.fullTextAnnotation.text
      );
      const joinedText = pdfText.join("\n");
      setTextFromDocs(joinedText);
    } catch (e) {
      console.log("error ===", e);
    } finally {
      setIsLoadingGetTextData(false);
    }
  };
  const ocrGetImageData = async (content: string) => {
    const payload = {
      requests: [
        {
          image: {
            content
          },
          features: [
            {
              type: "DOCUMENT_TEXT_DETECTION",
              model: "builtin/stable"
            }
          ]
        }
      ]
    };

    setIsLoadingGetTextData(true);

    try {
      const resp = await fetch(
        `${process.env.REACT_APP_GOOGLE_VISION_API_PATH_IMG}?key=${process.env.REACT_APP_GOOGLE_CLOUD_VISION_API_KEY}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(payload)
        }
      );
      const bodyRes = await resp.json();
      const { text } = bodyRes.responses[0].fullTextAnnotation;
      setTextFromDocs(text);
    } catch (e) {
      console.log("error ===", e);
    } finally {
      setIsLoadingGetTextData(false);
    }
  };

  return {
    isLoadingGetTextData,
    textFromDocs,
    ocrGetImageData,
    ocrGetPDFData
  };
};
