import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react';
import React from 'react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const DeleteEvidenceExtractionDialog: React.FC<Props> = ({ isOpen, onClose, onConfirm }) => (
  <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Confirmation</ModalHeader>
      <ModalCloseButton onClick={onClose} />
      <ModalBody pb={6}>
        Are you sure you want to delete the existing generated data?
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Cancel</Button>
        <Button colorScheme="red" ml={3} onClick={onConfirm}>
          Delete
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default DeleteEvidenceExtractionDialog;
