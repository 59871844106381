import {
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  Firestore,
  WithFieldValue,
  DocumentData,
  deleteField,
  FieldValue
} from "firebase/firestore";

export class FirestoreWrapper<T> {
  private collectionRef;

  constructor(private db: Firestore, private path: string) {
    this.collectionRef = collection(db, path);
  }

  async create(
    id: string,
    data: Partial<WithFieldValue<DocumentData>>
  ): Promise<void> {
    const docRef = doc(this.collectionRef, id);

    return await setDoc(docRef, data);
  }

  async read(id: string): Promise<T | null> {
    const docRef = doc(this.collectionRef, id);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? (docSnap.data() as T) : null;
  }

  async update(
    id: string,
    data: Partial<WithFieldValue<DocumentData>>
  ): Promise<void> {
    const docRef = doc(this.collectionRef, id);

    const result = await setDoc(docRef, data, { merge: true });

    return result;
  }

  async deleteFields(id: string, fields: string[]) {
    const docRef = doc(this.collectionRef, id);
    const query: Record<string, FieldValue> = {};
    fields.forEach((field) => {
      query[field] = deleteField();
    });
    return await updateDoc(docRef, query);
  }

  async delete(id: string): Promise<void> {
    const docRef = doc(this.collectionRef, id);

    return await deleteDoc(docRef);
  }
}
