import { useState } from "react";
import dayjs from "dayjs";
import DP from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdCalendarToday } from "react-icons/md";
import { toDate } from "../../helpers/date_helpers";

type DatePickerProps = {
  date?: Date | string | number | null;
  handleChange?: (date: number) => void | null;
  isRequired?: boolean;
};

export const DatePicker = ({
  date: _date,
  handleChange,
  isRequired = false
}: DatePickerProps) => {
  const convertedDate = toDate(_date);
  const [date, setDate] = useState<Date | null | undefined>(convertedDate);

  return (
    <DP
      name="relationshipStartDate"
      className="border rounded-full"
      required={isRequired}
      placeholderText="mm/yyyy"
      selected={date}
      maxDate={new Date()}
      onChange={(date, e) => {
        setDate(date);
        if (handleChange) {
          const newDate = dayjs(date).utc().unix();
          handleChange(newDate);
        }
      }}
      dateFormat="MMMM yyyy"
      showMonthYearPicker
      icon={<MdCalendarToday className="top-[2px] " />}
      showIcon
      calendarIconClassname="!grid !items-center"
      popperClassName="!z-50 "
    />
  );
};
