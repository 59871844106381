import {
  deleteField,
  doc,
  getDoc,
  updateDoc
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { db } from "../api/firebaseApi";
import { DATABASE } from "../types/tables-data";

import { DraftType } from "../redux/drafts/draftsSlice";
import { individualSelectors } from "../redux/individual/selectors";

export const useIndivCoverLetter = (indivId?: string) => {
  const email = useSelector(individualSelectors.selectEmail);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingCoverLetter, setIsLoadingCoverLetter] = useState(false);
  const [coverLetter, setCoverLetter] = useState<DraftType | null>(null);
  const [deleteError, setDeleteError] = useState<any>(null);
  const [getError, setGetError] = useState<any>(null);
  const [updateError, setUpdateError] = useState<any>(null);

  const getCoverLetter = async () => {
    if (!indivId) return;

    const documentReference = doc(db, `${DATABASE.DRAFTS}/${indivId}`);
    setIsLoadingCoverLetter(true);

    try {
      const documentSnapshot = await getDoc(documentReference);

      if (!documentSnapshot.exists()) {
        setGetError(new Error("No such document"));
        setCoverLetter(null);
        return;
      }

      const coverLetterData = documentSnapshot.data().coverLetter;
      if (coverLetterData && "text" in coverLetterData) {
        const coverLetterDraft = {
          value: {
            text: coverLetterData.text,
            html: coverLetterData?.html ?? ""
          }
        };
        setCoverLetter(coverLetterDraft);
      } else {
        setCoverLetter(null);
      }
    } catch (error) {
      console.error("Error fetching cover letter: ", error);
      setGetError(error);
    } finally {
      setIsLoadingCoverLetter(false);
    }
  };

  const deleteCoverLetter = async () => {
    if (!indivId) return; // Ensure the ID is present

    const docRef = doc(db, `${DATABASE.DRAFTS}/${indivId}`);

    setIsLoadingDelete(true); // If you have a loading state for deletion

    try {
      await updateDoc(docRef, {
        coverLetter: deleteField(),
        last_updated_by: email
      });
    } catch (e) {
      setDeleteError(e); // Use an appropriate error handling state
    } finally {
      setIsLoadingDelete(false); // Reset the loading state
    }
  };

  const updateCoverLetter = async (newData: any) => {
    if (!indivId) return; // Ensure both IDs are present

    const docRef = doc(db, `${DATABASE.DRAFTS}/${indivId}`);

    setIsLoadingUpdate(true); // If you have a loading state for updating

    try {
      await updateDoc(docRef, {
        coverLetter: newData,
        last_updated_by: email
      });
    } catch (e) {
      setUpdateError(e); // Use an appropriate error handling state
    } finally {
      setIsLoadingUpdate(false); // Reset the loading state
    }
  };

  useEffect(() => {
    getCoverLetter();
  }, []);

  return {
    coverLetter,
    getCoverLetter,
    getError,
    isLoadingCoverLetter,
    updateError,
    isLoadingUpdate,
    updateCoverLetter,
    deleteCoverLetter,
    isLoadingDelete,
    deleteError
  };
};
