import { Formik } from "formik";

import { Box, Button } from "@chakra-ui/react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Form, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { clientsSelectors } from "../../redux/clients/selectors";
import { clientPersonalSchema } from "./Client.schema";
import { ClientState, ClientsReducer } from "../../redux/clients/clientsSlice";
import { DATABASE } from "../../types/tables-data";
import useFirestoreDocument from "../../hooks/useFirestoreDocument";

import { ClientPersonalForm } from "./ClientPersonalFields";

dayjs.extend(utc);

export const ClientPersonalInformation = () => {
  const params = useParams();
  const client = useSelector((state: ClientsReducer) =>
    clientsSelectors.selectClientById(state, params.caseID!)
  );

  const { updateDocument, loading } = useFirestoreDocument<ClientState>(
    DATABASE.INDIVIDUALS,
    params.caseID!
  );
  const navigate = useNavigate();

  return (
    <Box p={5} className="max-w-3xl">
      <Formik
        key="cpi"
        initialValues={{
          ...client,
          birthDay: dayjs(client?.birthDay).format("YYYY-MM-DD")
        }}
        enableReinitialize
        validationSchema={clientPersonalSchema}
        onSubmit={async (values) => {
          // reconvert string to epoch/unix
          // utc time
          const convertedBirthDay = new Date(
            `${values.birthDay}T00:00:00`
          ).getTime();

          const updatedClient = {
            ...values,
            birthDay: convertedBirthDay,
            caseId: -1
          };

          await updateDocument(updatedClient);
        }}
      >
        {({ handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <ClientPersonalForm />
              <div className="flex gap-4 my-8 justify-end">
                <Button
                  variant="outline"
                  color="primary.darkBlue"
                  onClick={() => navigate(-1)}
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  bg="primary.darkBlue"
                  color="white"
                  isLoading={loading}
                  type="submit"
                >
                  Update Personal Information
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};
