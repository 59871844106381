import { Box, Link } from "@chakra-ui/react";
import { Link as LinkDom, useLocation, useParams } from "react-router-dom";
import { activeLinkStyles } from "../styles/common-styles";
import styles from "../styles/profile-sidebar.module.css";
import { getUserType } from "../helpers/helpers";

type ProfileSidebarProps = {
  className?: string | undefined;
};

export const ProfileSidebar = ({ className }: ProfileSidebarProps) => {
  const location = useLocation();
  const { id } = useParams();
  const loginType = getUserType();

  return (
    <Box
      className={className}
      width="140px"
      border="0px"
      borderColor="rgba(0, 0, 0, 0)"
      borderRadius="10px"
      paddingBottom="0px"
      flexShrink="0"
      bgColor="#fff"
    >
      <div className={styles.tabs_container}>
        <Box
          width="100%"
          textAlign="left"
          sx={
            location.pathname.includes(`/individual/${id}/account/profile`)
              ? activeLinkStyles
              : {}
          }
        >
          <Link
            as={LinkDom}
            to={`/individual/${id}/account/profile`}
            p="16px"
            display="block"
          >
            Profile
          </Link>
        </Box>
        { loginType === "lawyer" && <Box
                  textAlign="left"
                  sx={
                    location.pathname.includes(`/individual/${id}/account/payment`)
                      ? activeLinkStyles
                      : {}
                  }
                >
                  <Link
                    as={LinkDom}
                    to={`/individual/${id}/account/payment`}
                    p="16px"
                    display="block"
                  >
                    Payment
                  </Link>
                </Box>}
      </div>
    </Box>
  );
};
