// NavbarContext.js
import React, { createContext, useContext, useState } from "react";

const NavbarContext = createContext({
  position: "sticky",
  setPos: (position: string) => {}
});

export const NavbarProvider = ({ children }: any) => {
  const [position, setPosition] = useState("relative");

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const setPos = (position: string) => {
    setPosition(position);
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <NavbarContext.Provider value={{ position, setPos }}>
      {children}
    </NavbarContext.Provider>
  );
};

export const useNavbar = () => useContext(NavbarContext);
