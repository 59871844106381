import {
  Box,
  Button,
  Card,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useMemo, useState } from "react";
import { Header } from "../components/Header";
import { Sidebar } from "../components/Sidebar/Sidebar";
import { clickedStyles } from "../styles/common-styles";
import { addQuestionnaireLetter } from "../redux/client-case/activeClientSlice";
import { activeClientSelectors } from "../redux/client-case/selectors";
import { ExplanationFormBlock } from "../components/ExplanationFormBlock";
import { useUpdateFormsData } from "../hooks/useUpdateFormsData";
import { lawyerSelectors } from "../redux/lawyer/selectors";
import { clientsSelectors } from "../redux/clients/selectors";
import { updateQuestionnaireLetter } from "../redux/clients/clientsSlice";
import { maxInputDateValue } from "../helpers/date_helpers";

export const ClientFormExpertLetter = () => {
  const [showSidebar, setShowSidebar] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const clients = useSelector(clientsSelectors.selectAll);
  const indexInClients = clients.findIndex(
    (client) => client.id === params.caseID
  );

  const clientId = useSelector(activeClientSelectors.selectId);
  const clientFirstName = useSelector(activeClientSelectors.selectFirstName);
  const clientLastName = useSelector(activeClientSelectors.selectLastName);

  const lawyerID = useSelector(lawyerSelectors.selectUid);
  const lawyerFirstName = useSelector(lawyerSelectors.selectFirstName);
  const lawyerLastName = useSelector(lawyerSelectors.selectLastName);

  const questionnaireExpertLetter =
    useSelector(activeClientSelectors.selectQuestionnaireExpertLetter) ||
    useSelector(clientsSelectors.selectAll)[indexInClients].questionnaireLetter;

  const { onSubmitUpdateFormsData } = useUpdateFormsData();

  const initListSpecific = questionnaireExpertLetter?.specificRoleList || [];
  const initListAbility = questionnaireExpertLetter?.abilitiesList || [];
  const initListImportance =
    questionnaireExpertLetter?.roleImportanceList || [];
  const [specificRoleList, setSpecificRoleList] =
    useState<string[]>(initListSpecific);
  const [abilitiesList, setAbilitiesList] = useState<string[]>(initListAbility);
  const [roleImportanceList, setRoleImportanceList] =
    useState<string[]>(initListImportance);

  const formik = useFormik({
    initialValues: {
      expertName: questionnaireExpertLetter?.expertName || "",
      institution: questionnaireExpertLetter?.institution || "",
      relationship: questionnaireExpertLetter?.relationship || "",
      expertRole: questionnaireExpertLetter?.expertRole || "",
      expertCurrent: questionnaireExpertLetter?.expertCurrent || "",
      relationshipStartDate:
        questionnaireExpertLetter?.relationshipStartDate || "",
      relationshipEndDate: questionnaireExpertLetter?.relationshipEndDate || ""
    },

    onSubmit: (values) => {
      const letterData = {
        expertName: values.expertName,
        institution: values.institution,
        relationship: values.relationship,
        expertRole: values.expertRole,
        expertCurrent: values.expertCurrent,
        relationshipStartDate: values.relationshipStartDate,
        relationshipEndDate: values.relationshipEndDate,
        uploadDate: Date.now(),
        uploadedByUid: lawyerID || clientId,
        uploadedByName: lawyerID
          ? `${lawyerFirstName} ${lawyerLastName}`
          : `${clientFirstName} ${clientLastName}`,
        specificRoleList,
        abilitiesList,
        roleImportanceList
      };
      if (clientId) {
        dispatch(addQuestionnaireLetter(letterData));
      }
      if (lawyerID) {
        dispatch(
          updateQuestionnaireLetter({
            index: indexInClients,
            questionnaireLetter: letterData
          })
        );
      }
      onSubmitUpdateFormsData({
        expertLetter: letterData
      });
      if (lawyerID) {
        navigate(`/cases/${params.caseID}/questionnaires`);
      } else {
        navigate(`/client-case/${clientId}/questionnaires`);
      }
    }
  });

  const [activeBlockIndex, setActiveBlockIndex] = useState(-1);
  const changeActiveBlock = (i: number) => setActiveBlockIndex(i);

  const setNewSpecificRoleList = (v: string[]) => setSpecificRoleList(v);
  const setNewAbilitiesList = (v: string[]) => setAbilitiesList(v);
  const setNewRoleImportanceList = (v: string[]) => setRoleImportanceList(v);

  const explainBlocks = useMemo(() => {
    return [
      {
        blockTitle: "Explain specific role contributions to emphasize",
        list: specificRoleList,
        setList: setNewSpecificRoleList
      },
      {
        blockTitle:
          "Explain the ways in which you demonstrated extraordinary abilities in your work",
        list: abilitiesList,
        setList: setNewAbilitiesList
      },
      {
        blockTitle:
          "Explain the importance of your role in the context of national importance",
        list: roleImportanceList,
        setList: setNewRoleImportanceList
      }
    ];
  }, [specificRoleList, abilitiesList, roleImportanceList]);

  return (
    <>
      <Header />
      <Flex justifyContent="space-between" flexDirection="row" mt="30px">
        <Sidebar
          closeSidebar={() => setShowSidebar(false)}
          showSidebar={showSidebar}
        />

        <Flex alignItems="center" flexDirection="column" flex="1">
          <Card align="center" width="90%">
            <CardHeader sx={clickedStyles} width="100%">
              <Heading size="md" flex="1">
                Expert letter questionnaire
              </Heading>
            </CardHeader>

            <Box p="15px" gap="50px" w="60%">
              <form onSubmit={formik.handleSubmit}>
                <FormControl my="30px" variant="floating" id="expertName">
                  <Input
                    placeholder=" "
                    name="expertName"
                    type="text"
                    value={formik.values.expertName}
                    onChange={formik.handleChange}
                  />
                  <FormLabel>Expert&rsquo;s full name</FormLabel>
                </FormControl>
                <FormControl my="30px" variant="floating" id="institution">
                  <Input
                    placeholder=" "
                    name="institution"
                    type="text"
                    value={formik.values.institution}
                    onChange={formik.handleChange}
                  />
                  <FormLabel>Institution</FormLabel>
                </FormControl>

                <FormControl my="30px" variant="floating" id="relationship">
                  <Input
                    placeholder=" "
                    name="relationship"
                    type="text"
                    value={formik.values.relationship}
                    onChange={formik.handleChange}
                  />
                  <FormLabel>Relationship</FormLabel>
                </FormControl>
                <FormControl my="30px" variant="floating" id="expertRole">
                  <Input
                    placeholder=" "
                    name="expertRole"
                    type="text"
                    value={formik.values.expertRole}
                    onChange={formik.handleChange}
                  />
                  <FormLabel>Expert role when working together</FormLabel>
                </FormControl>

                <FormControl my="30px" variant="floating" id="expertCurrent">
                  <Input
                    placeholder=" "
                    name="expertCurrent"
                    type="text"
                    value={formik.values.expertCurrent}
                    onChange={formik.handleChange}
                  />
                  <FormLabel>Expert current</FormLabel>
                </FormControl>

                <FormControl
                  my="20px"
                  variant="floating"
                  id="relationshipStartDate"
                >
                  <Input
                    placeholder=" "
                    type="date"
                    min="1900-01-01"
                    max={maxInputDateValue}
                    name="relationshipStartDate"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.relationshipStartDate}
                  />
                  <FormLabel>Relationship start date</FormLabel>
                </FormControl>

                <FormControl
                  my="20px"
                  variant="floating"
                  id="relationshipEndDate"
                >
                  <Input
                    placeholder=" "
                    type="date"
                    min="1900-01-01"
                    max={maxInputDateValue}
                    name="relationshipEndDate"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.relationshipEndDate}
                  />
                  <FormLabel>Relationship end date</FormLabel>
                </FormControl>

                {explainBlocks.map((block, i) => (
                  <ExplanationFormBlock
                    key={i}
                    blockTitle={block.blockTitle}
                    list={block.list}
                    setList={block.setList}
                    isActive={activeBlockIndex === i}
                    index={i}
                    changeActiveBlock={changeActiveBlock}
                  />
                ))}

                {/* will add functionality to this button */}
                {/* <Flex
                  justifyContent="center"
                  flexDirection="column"
                  alignItems="center"
                  gap="5px"
                >
                  <Image
                    boxSize="30px"
                    objectFit="cover"
                    src={images.uploadDocument}
                    alt="upload"
                  />
                  <Button>Upload expert resume</Button>
                </Flex> */}

                <Flex m="30px">
                  <Button
                    type="button"
                    onClick={() => {
                      if (lawyerID) {
                        navigate(`/cases/${params.caseID}/questionnaires`);
                      } else {
                        navigate(`/client-case/${clientId}/questionnaires`);
                      }
                    }}
                    mx="15px"
                    flex="1"
                  >
                    Cancel
                  </Button>
                  <Button
                    bg="bgColor.300"
                    color="text.100"
                    mx="15px"
                    type="submit"
                    flex="1"
                  >
                    Save
                  </Button>
                </Flex>
              </form>
            </Box>
          </Card>
        </Flex>
      </Flex>
    </>
  );
};
