import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Button,
  Flex,
  SimpleGrid
} from "@chakra-ui/react";

export const Payment = () => {
  // Mock payment options data
  const paymentOptions = [
    {
      id: 1,
      name: "Stripe",
      description: "Pay with your credit card using Stripe"
    },
    { id: 2, name: "PayPal", description: "Pay securely with PayPal" }
    // Add more payment options here
  ];

  const handlePayment = (paymentOptionId) => {
    // Implement the payment processing logic here
    // For example, you can redirect the user to the selected payment gateway or trigger a modal, depending on your chosen payment service.
    // In a real application, you would integrate with the payment service API.
    switch (paymentOptionId) {
      case 1:
        // Handle Stripe payment
        break;
      case 2:
        // Handle PayPal payment
        break;
      // Add more cases for other payment options
      default:
        break;
    }
  };

  return (
    <Container maxW="container.sm">
      <Text fontSize="lg" p={6} mb={4} mt={10}>
        Select a payment option:
      </Text>

      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
        {paymentOptions.map((option) => (
          <Box
            key={option.id}
            borderWidth="1px"
            borderRadius="lg"
            p={6}
            boxShadow="md"
          >
            <Heading as="h2" size="lg">
              {option.name}
            </Heading>
            <Text fontSize="md" my={4}>
              {option.description}
            </Text>
            <Flex justify="flex-end">
              <Button
                colorScheme="blue"
                onClick={() => handlePayment(option.id)}
              >
                Pay with {option.name}
              </Button>
            </Flex>
          </Box>
        ))}
      </SimpleGrid>
    </Container>
  );
};
