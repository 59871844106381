import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Heading
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Header } from "../components/Header";
import { Sidebar } from "../components/Sidebar/Sidebar";
import { clickedStyles } from "../styles/common-styles";
import { useAddExtractInfo } from "../hooks/useAddExtractInfo";

export const ClientExpertLetter = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  const { extractedData } = useAddExtractInfo();

  return (
    <>
      <Header />
      <Flex justifyContent="space-between" flexDirection="row" mt="30px">
        <Sidebar
          closeSidebar={() => setShowSidebar(false)}
          showSidebar={showSidebar}
        />

        <Flex alignItems="center" flexDirection="column" flex="1">
          <Card align="center" width="90%">
            <CardHeader sx={clickedStyles} width="100%">
              <Heading size="md">Expert letter</Heading>
            </CardHeader>
            <CardBody>
              <Card m="15px" p="15px">
                <Box p="15px" textAlign="left">
                  {extractedData?.expertLetter &&
                    extractedData.expertLetter
                      .split("\n")
                      .map((p: string, i) => (
                        <Box py="5px" key={i}>
                          {p}
                        </Box>
                      ))}
                </Box>
              </Card>
            </CardBody>
            <CardFooter my="25px" w="100%" justifyContent="flex-end">
              <Button
                bg="bgColor.300"
                color="text.100"
                type="button"
                onClick={() => navigate(`/cases/${params.caseID}/packet`)}
                mx="15px"
              >
                Back
              </Button>
            </CardFooter>
          </Card>
        </Flex>
      </Flex>
    </>
  );
};
