import { useEffect, useState } from "react";

import { Box, Tooltip } from "@chakra-ui/react";
import Slider, { Settings } from "react-slick";
import Thumbnail from "../components/individualTabs/individualDocuments/Thumbnail";
import { getShortString } from "../helpers/string_helpers";
import { DataDocs } from "../types/tables-data";
import ExtractedEvidenceItem from "./ExtractedEvidenceItem";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./document_slider.css";

type DocumentSlideComponentProps = {
  indx: string;
  document: DataDocs;
  visible: boolean;
  onClick: () => void;
};

const DocumentSlideComponent = ({
  indx,
  document,
  visible,
  onClick
}: DocumentSlideComponentProps) => {
  return (
    <Box
      border={`3px solid ${visible ? "blue" : "#dadde0"}`}
      className="w-48 h-64 flex flex-col rounded-lg mx-2 "
      onClick={onClick}
    >
      <div className="max-h-48 w-48 overflow-hidden ">
        <Thumbnail filePath={document.filePath ?? ""} disableThumbnailClick />
      </div>
      <Box
        className="relative flex flex-col border py-1"
        bg={`${visible ? "primary.darkBlue" : "gray"} `}
        color="white"
        p={1}
      >
        <Tooltip label={document.autoTitle}>
          {getShortString(document.autoTitle)}
        </Tooltip>
        <small className="flex justify-center font-bold">Exhibit: {indx}</small>
      </Box>
    </Box>
  );
};

type DocumentsSliderProps = {
  categoryIndex: number;
  documents: DataDocs[];
};

export const DocumentsSlider = ({
  categoryIndex,
  documents
}: DocumentsSliderProps) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const selectedDocument = documents[activeSlide];

  const settings: Settings = {
    speed: 250,
    arrows: true,
    slidesToScroll: 3,
    adaptiveHeight: true,
    infinite: false,
    variableWidth: true,
    vertical: false,
    centerMode: false,
    rows: 1
  };

  useEffect(() => {
    setActiveSlide(0);
  }, [categoryIndex]);

  return (
    <div className="overflow-hidden">
      <Slider {...settings}>
        {documents.map((document: DataDocs, indx: number) => (
          <DocumentSlideComponent
            onClick={() => {
              setActiveSlide(indx);
            }}
            key={document.id}
            indx={`${categoryIndex + 1}.${indx + 1}`}
            document={document}
            visible={activeSlide === indx}
          />
        ))}
      </Slider>

      {/* EvidenceItem Preview */}
      {selectedDocument !== undefined && (
        <ExtractedEvidenceItem
          key={selectedDocument.id}
          evidenceId={selectedDocument.id ?? ""}
          exhibit={`${categoryIndex + 1}.${activeSlide + 1}`}
          text={{
            current:
              selectedDocument?.extracted_argument?.argument_html ||
              selectedDocument?.extracted_argument?.argument_text ||
              "",
            original:
              selectedDocument?.extracted_argument?.argument_html ||
              selectedDocument?.extracted_argument?.argument_text ||
              ""
          }}
        />
      )}
    </div>
  );
};
