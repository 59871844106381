import {
  Box,
  Button,
  CircularProgress,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { doc } from "firebase/firestore";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAddExtractInfo } from "../../hooks/useAddExtractInfo";
import { useDeleteFileFromDB } from "../../hooks/useDeleteFileFromDB";
import useDeleteIndivDocs from "../../hooks/useDeleteIndivDocs";
import { useExtractNIWEvidenceData } from "../../hooks/useExtractNIWEvidenceInfo";
import { removeAllEvidenceExtractions } from "../../redux/documents/documentSlice";
import { documentSelectors } from "../../redux/documents/selectors";
import { individualSelectors } from "../../redux/individual/selectors";
import { DATABASE, DataDocs } from "../../types/tables-data";
import { AlreadyExtractedDialog } from "./individualDocuments/AlreadyExtractedDialog";
import { SelectExhibitDialog } from "./individualDocuments/SelectExhibitDialog";

import { db } from "../../api/firebaseApi";
import { deleteFirestoreDoc } from "../../helpers/helpers";
import { useDeleteExtractedInfo } from "../../hooks/useDeleteExtractedInfo";
import { useSubscribeToJob } from "../../hooks/useSubscribeToJob";
import {
  EXTRACTIONSTATES,
  ExtractionJob,
  JobType
} from "../../redux/extraction-jobs/extractionJobsSlice";
import { lawyerSelectors } from "../../redux/lawyer/selectors";
import { EvidenceDocumentsTable } from "../EvidenceDocumentsTable";
import { CustomButton } from "../common/CustomButton";
import { ExtractionCard } from "../common/ExtractionCard";
import FaqDropdown from "../common/FaqDropdown";
import SupportedExtensionsMsg from "../common/SupportedExtensionsMsg";
import { SpecialHeading } from "./PanelLayout";

export const EvidenceDocs = () => {
  const { id, visaType } = useParams();
  const toast = useToast();
  const dispatch = useDispatch();
  // const { onSubmitGetEvidenceDocs: onSubmitGetDocs } = useGetIndividualDocs();
  const { initiateJob } = useSubscribeToJob();
  const docs = useSelector(documentSelectors.evidenceDocs).filter(
    (doc) => doc.deleted === false
  );
  const uid = id ?? useSelector(individualSelectors.selectUid);
  const firstName = useSelector(individualSelectors.selectFirstName);
  const lastName = useSelector(individualSelectors.selectLastName);
  const isLoadingGetEvidence = useSelector(
    documentSelectors.isLoadingGetEvidence
  );
  const [isLoadingDoc, setIsLoadingDoc] = useState(false);
  const [evidenceToDelete, setEvidenceToDelete] = useState<DataDocs | null>(
    null
  );
  const [isExtractionDialogOpen, setIsExtractionDialogOpen] =
    useState<boolean>(false);
  const [isValidatingOCR, setIsValidatingOCR] = useState(false);
  const { extractedData } = useAddExtractInfo();
  const { onDeleteExtractedEvidence } = useDeleteExtractedInfo(uid);

  const { subscription } = useSelector(lawyerSelectors.selectAll);

  const isFreemium = subscription?.status === "freemium";

  // Extract
  const { onSubmitExtractData, isLoadingExtractData } =
    useExtractNIWEvidenceData();

  const navigate = useNavigate();
  const [selectedExhibits, setSelectedExhibits] = useState<number[]>([]);
  const [showSelectExhibit, setShowSelectExhibit] = useState<boolean>(false);

  const statusMsg = useMemo(() => {
    let allCompleted = false;
    if (docs)
      allCompleted = docs.every(
        (doc: DataDocs) =>
          doc?.status?.status &&
          doc?.status?.status === EXTRACTIONSTATES.Completed &&
          doc?.status?.taskType === JobType.Extracting
      );
    return allCompleted ? "Finalized" : "In progress";
  }, [docs]);

  // DIALOGS
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isExtractDeleteOpen,
    onOpen: onExtractDeleteOpen,
    onClose: onExtractDeleteClose
  } = useDisclosure();

  const { deleteFolderContents, onSubmitDeleteFile } = useDeleteFileFromDB();
  const { onSubmitDeleteEvidenceDoc } = useDeleteIndivDocs();

  const handleDeleteConfirm = async () => {
    if (evidenceToDelete) {
      if (evidenceToDelete.sub_documents)
        try {
          await deleteFolderContents(
            `${DATABASE.INDIVIDUALS}/documents/${uid}/Evidences/${evidenceToDelete.uid}`
          );
          await onSubmitDeleteEvidenceDoc({
            firstName,
            lastName,
            uid,
            docId: evidenceToDelete.uid
          });
          // Delete evidence extraction job if exists
          const jobPath = doc(
            db,
            `${DATABASE.ACTIVE_JOBS}/${uid}/jobs/${evidenceToDelete.uid}`
          );
          const jobDeleted = await deleteFirestoreDoc(jobPath);
          if (jobDeleted) {
            // await onSubmitGetDocs(uid);
          }
        } catch (e) {
          console.log(e);
        } finally {
          onClose();
          setEvidenceToDelete(null);
        }
    }
  };

  // Listener
  const onExtractConfirm = async (ids: string[], exhibits: number[]) => {
    setIsExtractionDialogOpen(false);
    setShowSelectExhibit(false);

    // const newExhibitNumbers = exhibits.map((exhibit) => {
    //   if(evidenceExhibitMap !== null) {
    //     return evidenceExhibitMap[exhibit];
    //   }
    //   return []
    // });
    // alert(newExhibitNumbers);
    if (uid) await onSubmitExtractData(uid, exhibits, ids);
  };

  const exhibitEvidenceMap =
    useSelector(documentSelectors.evidenceExhibitMap) ?? null;

  const logWithKeyword = (message: string) => {
    console.log(`OcrValidation : ${message}`);
  };

  const handleExtract = async (exhibits: number[]) => {
    console.log("Exhibits to validate:", exhibits);
    console.log("current evidence docs:", docs);
    setShowSelectExhibit(false);

    if (uid && exhibits.length > 0) {
      console.log("UID:", uid);
      console.log("Exhibits length:", exhibits.length);

      const matchingDocs: DataDocs[] = [];

      const documentsIds = exhibits.map((exhibit) => {
        const evidenceId = exhibitEvidenceMap
          ? exhibitEvidenceMap[exhibit]
          : [];
        if (evidenceId) {
          const matchingDoc = docs.find((doc) => doc.id === evidenceId);
          if (matchingDoc) {
            matchingDocs.push(matchingDoc);
          }
        }
        console.log("Mapping exhibit:", exhibit, "to evidence:", evidenceId);
        return evidenceId;
      }) as string[];

      const parsedExhibits = exhibits.map(Number);
      console.log("Parsed exhibits:", parsedExhibits);

      // Initiating db real-time tracking

      console.log("Matching documents:", matchingDocs);

      const initiateJobs = matchingDocs.map(async (matchingDoc: DataDocs) => {
        if (matchingDoc.id) {
          const job: ExtractionJob = {
            id: matchingDoc.id,
            type: "Evidence extraction",
            docRef: doc(
              db,
              DATABASE.DOCUMENTS,
              `${uid}`,
              "evidence_docs",
              `${matchingDoc.id}`
            ),
            jobRef: doc(
              db,
              DATABASE.ACTIVE_JOBS,
              `${uid}`,
              "jobs",
              `${matchingDoc.id}`
            ),
            status: { status: EXTRACTIONSTATES.Pending },
            toastTitle: "Extraction status",
            toastDesc: `The extraction process is completed for document: ${matchingDoc.mainDoc?.documentTitle}`,
            docName: matchingDoc.mainDoc?.documentTitle ?? ""
          };
          console.log("Initiating job with:", job);
          await initiateJob(job);
          console.log(
            "Job initiated for document:",
            matchingDoc.mainDoc?.documentTitle
          );
        }
      });

      await Promise.all(initiateJobs);
      console.log(
        "notifications: Extraction process completed for exhibits:",
        exhibits
      );

      // Initiating extraction
      onExtractConfirm(documentsIds, exhibits);
      console.log("Called onExtractConfirm with:", {
        documentsIds,
        exhibits
      });
    }
  };

  const handleDeleteExtracted = async () => {
    try {
      await onDeleteExtractedEvidence({ evidenceId: "", firstName, lastName });
      dispatch(removeAllEvidenceExtractions());
      toast({
        title: "Evidence extraction deleted",
        description: "Information deleted.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right"
      });
      onExtractDeleteClose();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {
        <>
          <FaqDropdown
            title={<Text>What are Evidence Documents?</Text>}
            content={
              <Text textAlign="left">
                <li>Publications</li>
                <li>Awards or prizes</li>
                <li>Articles</li>
                <li>Art exhibits</li>
                <li>Proof of high salary</li>
                <li>Evidence of leading role</li>
                <li>Citation records</li>
                <li>Entrepreneur documents</li>
                <li>Patents, trademarks, certificates</li>
                <li>Information about the area of expertise</li>
              </Text>
            }
          />
          <Box my={4}>
            <SupportedExtensionsMsg dragAndDrop />
          </Box>

          {/* Evidence Documents Table with Drag&Drop */}
          <EvidenceDocumentsTable />

          {isLoadingDoc && (
            <div className="flex w-full justify-center items-center py-2">
              <CircularProgress size="26" isIndeterminate />
            </div>
          )}
        </>
      }

      {showSelectExhibit && (
        <SelectExhibitDialog
          selectedExhibits={selectedExhibits}
          setExhibits={setSelectedExhibits}
          onConfirm={() => handleExtract(selectedExhibits)}
          evidenceDocs={docs}
          closeDialog={() => {
            setShowSelectExhibit(false);
            setSelectedExhibits([]);
          }}
        />
      )}

      {/* <Flex justifyContent="center" p="20px" m="20px">
        <Flex
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          gap="5px"
        >
          {isFreemium && (
            <CustomButton
              type="ai"
              title="New evidence"
              isPremium
              onClick={() => {
                setPremiumModalOpen(true);
              }}
            />
          )}
        </Flex>
      </Flex> */}

      <SpecialHeading title="Extractions from evidence documents" />

      {/* <CustomBox type="info">
        <Text fontSize="18px" fontStyle="italic">
          Now, we will automatically extract information from the uploaded
          documents. It is your responsibility to{" "}
          <b>double check the extracted data</b>.
        </Text>
      </CustomBox> */}

      <ExtractionCard
        type="documents/evidence"
        extractedData={extractedData}
        id={id}
        onExtractionDelete={onExtractDeleteOpen}
        statusMsg={statusMsg}
      />

      <CustomButton
        isDisabled={isLoadingGetEvidence}
        type="ai"
        title="Extract"
        isLoading={isLoadingExtractData || isValidatingOCR}
        onClick={() => {
          setShowSelectExhibit(true);
        }}
      />

      <Modal
        closeOnOverlayClick={false}
        isOpen={isExtractDeleteOpen}
        onClose={onExtractDeleteClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalCloseButton
            onClick={() => {
              onExtractDeleteClose();
              //     setDocumentToDelete(null);
            }}
          />
          <ModalBody pb={6}>
            Are you sure you want to delete the existing extracted data?
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={() => {
                onExtractDeleteClose();
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={async () => {
                await handleDeleteExtracted();
                onExtractDeleteClose();
              }}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalCloseButton
            onClick={() => {
              onClose();
              //     setDocumentToDelete(null);
            }}
          />
          <ModalBody pb={6}>Are you sure you want to delete evidence</ModalBody>

          <ModalFooter>
            <Button
              onClick={() => {
                onClose();
                setEvidenceToDelete(null);
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={() => {
                handleDeleteConfirm();
              }}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlreadyExtractedDialog
        exhibits={selectedExhibits}
        type="evidence"
        isOpen={isExtractionDialogOpen}
        onClose={() => setIsExtractionDialogOpen(false)}
        onConfirm={() => handleExtract(selectedExhibits)}
      />
    </>
  );
};
