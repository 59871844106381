import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Textarea,
  Th,
  Thead,
  Tr,
  useDisclosure
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon, EditIcon, MinusIcon } from "@chakra-ui/icons";
import { useState } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { DATABASE, DataNotes } from "../types/tables-data";
import { addNewNote, setNewNotes } from "../redux/clients/clientsSlice";
import { lawyerSelectors } from "../redux/lawyer/selectors";
import { db } from "../api/firebaseApi";
import { clientsSelectors } from "../redux/clients/selectors";
import { clickedStyles } from "../styles/common-styles";

type CaseIdNotesProps = {
  data: DataNotes[];
  index: number;
};

export const CaseIdNotes = ({ data, index }: CaseIdNotesProps) => {
  const [isNewNoteAdded, setIsNewNoteAdded] = useState(false);
  const [isNoteEdited, setIsNoteEdited] = useState(false);
  const [editedNoteIndex, setEditedNoteIndex] = useState<null | number>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [newNoteValue, setNewNoteValue] = useState("");
  const [editedNoteValue, setEditedNoteValue] = useState("");
  const dispatch = useDispatch();
  const params = useParams();

  const firstName = useSelector(lawyerSelectors.selectFirstName);
  const lastName = useSelector(lawyerSelectors.selectLastName);
  const allNotes = useSelector(clientsSelectors.selectAll)[index].notes;

  const saveNewNote = async () => {
    const noteToAdd = {
      date: Date.now(),
      author: `${firstName} ${lastName}`,
      notes: newNoteValue
    };
    setIsLoading(true);
    try {
      const clientRef = doc(db, DATABASE.CLIENTS, `${params.caseID}`);
      await updateDoc(clientRef, {
        notes: arrayUnion(noteToAdd)
      });
      dispatch(
        addNewNote({
          index,
          note: noteToAdd
        })
      );
    } catch (e) {
      console.error(e);
    } finally {
      setIsNewNoteAdded(false);
      setIsLoading(false);
      setNewNoteValue("");
    }
  };
  const saveEditNote = async () => {
    const notes = data.filter((_, i) => i !== editedNoteIndex);
    const noteToEdit = {
      date: Date.now(),
      author: `${firstName} ${lastName}`,
      notes: editedNoteValue
    };
    notes.push(noteToEdit);
    setIsLoading(true);
    try {
      const clientRef = doc(db, DATABASE.CLIENTS, `${params.caseID}`);
      await updateDoc(clientRef, {
        notes
      });
      dispatch(
        setNewNotes({
          index,
          notes
        })
      );
    } catch (e) {
      console.error(e);
    } finally {
      setIsNoteEdited(false);
      setIsLoading(false);
      setEditedNoteValue("");
      setEditedNoteIndex(null);
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [noteToDelete, setNoteToDelete] = useState(0);

  const handleDeleteNote = (date: number) => {
    setNoteToDelete(date);
    onOpen();
  };

  const handleDeleteConfirm = async () => {
    const updatedNotes = allNotes.filter((note) => note.date !== noteToDelete);
    setIsLoading(true);
    try {
      const clientRef = doc(db, DATABASE.CLIENTS, `${params.caseID}`);
      await updateDoc(clientRef, {
        notes: updatedNotes
      });
      dispatch(
        setNewNotes({
          index,
          notes: updatedNotes
        })
      );
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
    setNoteToDelete(0);
    onClose();
  };

  return (
    <>
      <Card mb="15px">
        <Box py="15px" sx={clickedStyles}>
          <Heading size="md">Notes</Heading>
        </Box>
        <Box>
          {data[0] && (
            <Card m="10px" p="15px">
              <TableContainer>
                <Table
                  variant="simple"
                  p="15px"
                  __css={{
                    tableLayout: "fixed",
                    width: "100%",
                    wordBreak: "break-all",
                    wordWrap: "break-word",
                    whiteSpace: "normal"
                  }}
                >
                  <Thead>
                    <Tr>
                      <Th>Date</Th>
                      <Th>Author</Th>
                      <Th>Notes</Th>
                      <Th />
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data.map((note, i) => (
                      <Tr key={i}>
                        <Td>{dayjs(note.date).format("MM/DD/YYYY")}</Td>
                        <Td>{note.author}</Td>
                        <Td>{note.notes}</Td>
                        <Td>
                          <Flex
                            flexGrow="0"
                            gap="15px"
                            justifyContent="flex-end"
                          >
                            <EditIcon
                              onClick={() => {
                                setEditedNoteIndex(i);
                                setIsNoteEdited(true);
                                setEditedNoteValue(note.notes);
                              }}
                              cursor="pointer"
                            />
                            <DeleteIcon
                              cursor="pointer"
                              onClick={() => handleDeleteNote(note.date)}
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Card>
          )}

          {/* {data[0] &&
            [...data]
              .sort((noteA, noteB) => noteB.date - noteA.date)
              .map((note, i) => (
                <Card key={i} flexDirection="row" m="20px 50px">
                  <CardFooter flexBasis="10%" flexGrow="0" flexShrink="1">
                    {dayjs(note.date).format("MM/DD/YYYY")}
                  </CardFooter>
                  <CardHeader
                    flexBasis="20%"
                    flexGrow="0"
                    flexShrink="1"
                    textAlign="left"
                  >
                    {note.author}
                  </CardHeader>
                  <CardBody textAlign="left">
                    <Flex
                      justifyContent="space-between"
                      gap="10px"
                      alignItems="center"
                    >
                      <Box flexGrow="1">{note.notes}</Box>
                      <Flex flexGrow="0" gap="15px">
                        <EditIcon cursor="pointer" />
                        <DeleteIcon
                          cursor="pointer"
                          onClick={() => handleDeleteNote(note.date)}
                        />
                      </Flex>
                    </Flex>
                  </CardBody>
                </Card>
              ))} */}
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            m="15px"
            gap="5px"
          >
            <IconButton
              variant="outline"
              colorScheme="blue"
              aria-label="New step"
              fontSize="20px"
              icon={!isNewNoteAdded ? <AddIcon /> : <MinusIcon />}
              onClick={() => setIsNewNoteAdded(!isNewNoteAdded)}
            />
            {!isNewNoteAdded && !isNoteEdited && <Box>Add new note</Box>}
          </Flex>
          {isNewNoteAdded && (
            <Box m="20px">
              <Textarea
                placeholder="New note about the case..."
                size="sm"
                resize="none"
                value={newNoteValue}
                onChange={(e) => setNewNoteValue(e.target.value)}
              />
              <Button
                bg="bgColor.300"
                color="text.100"
                type="button"
                m="15px"
                onClick={saveNewNote}
                isLoading={isLoading}
                isDisabled={!newNoteValue}
              >
                Save
              </Button>
            </Box>
          )}
          {isNoteEdited && (
            <Box m="20px">
              <Textarea
                size="sm"
                resize="none"
                value={editedNoteValue}
                onChange={(e) => setEditedNoteValue(e.target.value)}
              />
              <Button
                bg="bgColor.300"
                color="text.100"
                type="button"
                m="15px"
                onClick={() => {
                  setIsNoteEdited(false);
                  setEditedNoteValue("");
                  setEditedNoteIndex(null);
                }}
                isDisabled={!editedNoteValue}
              >
                Cancel
              </Button>
              <Button
                bg="bgColor.300"
                color="text.100"
                type="button"
                m="15px"
                onClick={() => saveEditNote()}
                isLoading={isLoading}
              >
                Save
              </Button>
            </Box>
          )}
        </Box>
      </Card>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <strong>Would you like to delete this note?</strong>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose}>Cancel</Button>
            <Button colorScheme="red" ml={3} onClick={handleDeleteConfirm}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
