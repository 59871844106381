import {
  Divider,
  Heading,
  VStack
} from "@chakra-ui/react";
import { useFormikContext } from "formik";
import {
  ClientEmployerInfo
} from "../../redux/clients/clientsSlice";
import { InputField } from "../InputField";
import { AddressFields } from "../common/AddressFields";

export const ClientEmployerForm = () => {
  const { errors, touched } = useFormikContext<ClientEmployerInfo>();
  return (
    <VStack spacing={4} align="stretch">
      {/* Legal Business Name */}

      <InputField
        label="Legal Business Name"
        name="employerInfo.legalBusinessName"
      />

      {/* DBA */}

      <InputField label="DBA (if any)" name="employerInfo.dba" />

      <Heading
        size="16"
        textAlign="left"
        fontWeight="700"
        color="primary.darkBlue"
        textDecoration="underline"
      >
        Business Address
      </Heading>

      {/* Business Address */}
      <AddressFields
        name="employerInfo.businessAddress"
        errors={errors}
        touched={touched}
        usBased
      />

      {/* Contact Name */}

      <InputField label="Contact Name" name="employerInfo.contactName" />

      {/* Contact Title */}

      <InputField label="Contact Title" name="employerInfo.contactTitle" />

      <Divider />
    </VStack>
  );
};
