import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useSelector } from "react-redux";

import { deleteDoc, doc, doc as gdoc } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { TargetBox } from "./inputDnD/TargetBox";

import { db } from "../api/firebaseApi";
import useFileActions from "../hooks/useFileActions";
import { useGetNewEvidenceDocs } from "../hooks/useGetNewEvidenceDocs";
import { documentSelectors } from "../redux/documents/selectors";
import { DATABASE } from "../types/tables-data";
import { generatedEvidenceColumnsWithActions } from "./EvidenceColumns";
import { UploadFilesPlaceholder } from "./UploadFilesPlaceholder";
import { TableGroupedData } from "./common/TableGroupedData";
import { VisaDocumentType } from "../redux/documents/types";
import { UploadFilesFooterLink } from "./UploadFilesFooterLink";
import { EvidenceTable } from "./common/SimpleTable";

export const EvidenceDocumentsTable = () => {
  const { id: uid, visaType } = useParams();
  const evidenceDocsCollectionPath = `documents/${uid}/evidence_docs`;
  const documentsPath = `documents/${uid}`;

  useGetNewEvidenceDocs(
    evidenceDocsCollectionPath,
    VisaDocumentType.Evidence,
    documentsPath
  );

  const docs = useSelector(documentSelectors.evidenceDocs);

  const evidenceDocumentsStoragePath = `${DATABASE.INDIVIDUALS}/documents/${uid}/Evidences`;

  const { handleFileActions } = useFileActions();

  return (
    <div>
      <DndProvider backend={HTML5Backend} key={6} context={window}>
        <TargetBox
          onDrop={(item) => {
            const { files } = item;
            handleFileActions({
              files: Array.from(files),
              filePath: evidenceDocumentsStoragePath,
              uid: uid!,
              type: VisaDocumentType.Evidence
            });
          }}
        >
          {docs.length === 0 && (
            <UploadFilesPlaceholder
              title="Drag and drop your files here"
              subTitle="Click Here to Upload"
              withImg
              onUpload={(files) => {
                if (files)
                  handleFileActions({
                    files: Array.from(files),
                    filePath: evidenceDocumentsStoragePath,
                    uid: uid!,
                    type: VisaDocumentType.Evidence
                  });
              }}
            />
          )}

          {/* Evidence Documents Table */}
          {docs.length > 0 && <EvidenceTable />}
        </TargetBox>
      </DndProvider>

      {docs.length > 0 && (
        <UploadFilesFooterLink
          onUpload={(files) => {
            if (files)
              handleFileActions({
                files: Array.from(files),
                filePath: evidenceDocumentsStoragePath,
                uid: uid!,
                type: VisaDocumentType.Evidence
              });
          }}
        />
      )}
    </div>
  );
};
