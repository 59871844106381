import { useState } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where
} from "firebase/firestore";
import { useSelector } from "react-redux";
import { db } from "../api/firebaseApi";
import { DATABASE } from "../types/tables-data";
import { ClientState } from "../redux/clients/clientsSlice";
import { getStatusValue } from "../helpers/helpers";
import { lawyerSelectors } from "../redux/lawyer/selectors";

export const useGetClientsData = () => {
  let q = null;
  const [isLoadingGetClientsData, setIsLoadingGetClientsData] = useState(false);
  const [clientsData, setClientsData] = useState<ClientState[]>([]);
  const role = useSelector(lawyerSelectors.selectRole);
  const practiceName = useSelector(lawyerSelectors.selectPracticeName);

  // Single client
  const [isLoadingGetClientById, setIsLoadingGetClientById] = useState(false);
  const [clientData, setClientData] = useState<ClientState | null>(null);

  const mapDocToClientState = (doc: any): ClientState => ({
    id: doc.id,
    firstName: doc.data().firstName,
    middleName: doc.data().middleName || "",
    lastName: doc.data().lastName,
    name: `${doc.data().firstName} ${doc.data().lastName}`,
    birthDay: doc.data().birthDay,
    createdDate: doc.data().createdDate,
    visa: doc.data().visa,
    email: doc.data().email,
    isActive: doc.data().isActive,
    address: doc.data().address,
    state: doc.data().state || "",
    city: doc.data().city || "",
    zipcode: doc.data().zipcode || "",
    practiceName: doc.data().practiceName,
    actionNeeded: doc.data().actionNeeded,
    caseId: doc.data().caseId || 0,
    citizenship: doc.data().citizenship,
    employer: doc.data().employer,
    priority: doc.data().priority,
    status: doc.data().overview && doc.data().overview.steps
      ? getStatusValue(doc.data().overview.steps)
      : 1,
    notes: doc.data().notes ? doc.data().notes : [],
    documents: [],
    overview: {
      steps: doc.data().overview && doc.data().overview.steps
        ? doc.data().overview.steps
        : []
    },
    lawyerId: doc.data().lawyerId || "",
    pronouns: doc.data().pronouns || "",
    otherPronouns: doc.data().otherPronouns || "",
    title: doc.data().title || "",
    countryob: doc.data().countryob || "",
    citizenships: doc.data().citizenships || [],
    phone: doc.data().phone || "",
    physicalAddress: doc.data().physicalAddress || {
      city: "",
      country: "",
      state: "",
      street: "",
      zipCode: ""
    },
    mailingAddress: doc.data().mailingAddress || {
      city: "",
      country: "",
      state: "",
      street: "",
      zipCode: ""
    },
    similarAddress: doc.data().similarAddress || false,
    employerInfo: doc.data().employerInfo || {
      businessAddress: { city: "", state: "", street: "", zipCode: "" },
      contactName: "",
      contactTitle: "",
      dba: "",
      legalBusinessName: ""
    }
  });

  const onSubmitGetClientsData = async (isActive: boolean) => {
    setIsLoadingGetClientsData(true);
    try {
      const clientsRef = collection(db, DATABASE.INDIVIDUALS);

      q = query(
        clientsRef,
        where("isActive", "==", isActive),
        where("practiceName", "==", `${practiceName}`)
      );
      if (role === "superadmin") {
        console.log("superadmin");
        q = query(clientsRef, where("isActive", "==", isActive));
      }
      const querySnapshot = await getDocs(q);

      const clientsData: ClientState[] = [];

      querySnapshot.forEach((doc) => {
        clientsData.push(mapDocToClientState(doc));
      });
      setClientsData(clientsData);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingGetClientsData(false);
    }
  };

  const onSubmitGetClientById = async (clientId: string) => {
    setIsLoadingGetClientById(true);
    try {
      const clientDocRef = doc(db, DATABASE.INDIVIDUALS, clientId);
      const docSnapshot = await getDoc(clientDocRef);

      if (docSnapshot.exists()) {
        setClientData(mapDocToClientState(docSnapshot));
      } else {
        console.error("Client not found");
        setClientData(null);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingGetClientById(false);
    }
  };

  return {
    clientsData,
    isLoadingGetClientsData,
    onSubmitGetClientsData,
    clientData,
    isLoadingGetClientById,
    onSubmitGetClientById
  };
};
