import { useState } from "react";
import { openai } from "../api/OpenAIApi";

export const useProngArguments = () => {
  const [isLoadingProngArguments, setIsLoadingProngArguments] = useState(false);

  const [apiResponseProngArguments, setApiResponseProngArguments] = useState<
    string | null
  >(null);

  const onSubmitProngArguments = async (
    visaType: string,
    resumeInfo: string
  ) => {
    const resumeInstruction = `Your are a lawyer writing a portion of the legal brief for a ${visaType}.\
    Before writing the blurb, you use the below resume, extract arguments for the ${visaType}.\
    Do not just list facts, make sure you explain why this person meet the criteria for this green card visa.\
    List in bullet points, finish your sentences.\
    Person resume: ${resumeInfo}`;

    setIsLoadingProngArguments(true);
    try {
      const result = await openai.createChatCompletion({
        model: "gpt-4",
        messages: [{ role: "user", content: resumeInstruction }],
        temperature: 0
      });
      setApiResponseProngArguments(
        result.data.choices[0].message?.content || ""
      );
    } catch (e) {
      setApiResponseProngArguments("Something went wrong, Please try again.");
    } finally {
      setIsLoadingProngArguments(false);
    }
  };

  return {
    isLoadingProngArguments,
    onSubmitProngArguments,
    apiResponseProngArguments
  };
};
