import {
  createMultiStyleConfigHelpers,
  defineStyleConfig,
  extendTheme
} from "@chakra-ui/react";
import { tabsAnatomy } from "@chakra-ui/anatomy";
import { StepsTheme as Steps } from "chakra-ui-steps";
import { tabsTheme } from "./styles/chakra/tabs.style";
import { tablesTheme } from "./styles/chakra/table.style";
import { textAreaTheme } from "./styles/chakra/textarea.style";
import { buttonTheme } from "./styles/chakra/button.style";
import { inputTheme } from "./styles/chakra/input.style";
import { selectTheme } from "./styles/chakra/select.style";
import { formTheme } from "./styles/chakra/form.style";
import { containerTheme } from "./styles/chakra/container.style";

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-24px)",
  borderColor: "primary.darkBlue"
};

const fadeAnimation = {
  "@keyframes fadeInUp": {
    from: {
      opacity: 0,
      transform: "translateY(20px)"
    },
    to: {
      opacity: 1,
      transform: "translateY(0)"
    }
  },
  animation: "fadeInUp 0.5s ease-in-out"
};

const theme = extendTheme({
  styles: {
    global: {
      // "*": {
      //   ...fadeAnimation
      // },
      "html, body": {
        overflow:"hidden",
        bg: "primary.background",
        fontFamily: "Inter"
      }
    }
  },
  colors: {
    primary: {
      darkBlue: "#00004d",
      red: "#ed2024",
      lightRed: "#e21a1d",
      background: "#EEF2F6",
      darkGray: "#55636e",
    },
    secondary: {
      darkGray: "#55636e",
      softGray: "#4D596293",
      lightGray: "#d1dbe5",
      offWhite: "#eef2f6",
      white: "#ffffff"
    },
    tertiary: {
      blue: "#0c5bbf",
      red: "#E21A1D"
    },
    bgColor: {
      100: "#ECE4E4",
      200: "#7FA8F8",
      300: "#260DBC",
      400: "#B6ECC2",
      500: "#D9D9D9",
      600: "#FFFCFC"
    },
    text: {
      100: "#FFFFFF",
      900: "#000000"
    }
  },
  components: {
    Container: containerTheme,
    Textarea: textAreaTheme,
    Tabs: tabsTheme,
    Table: tablesTheme,
    Button: buttonTheme,
    Steps,
    Input: inputTheme,
    Select: selectTheme,
    Form: formTheme
  }
});

export default theme;
