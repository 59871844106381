import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../api/firebaseApi";
import { setExpertLettersDrafts as setExpertsLettersD } from "../redux/individual/individualSlice";
import { individualSelectors } from "../redux/individual/selectors";
import { ExpertLetterDraft } from "../types/expert-letter-draft";
import { DATABASE } from "../types/tables-data";

interface DraftType {
  id: string;
  value: any;
  date?: any;
}

export const useExpertLetterDrafts = (indivId: string) => {
  const dispatch = useDispatch();
  const email = useSelector(individualSelectors.selectEmail);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingExpertLetters, setIsLoadingExpertLetters] = useState(false);
  const [isLoadingSingleDraft, setIsLoadingSingleDraft] = useState(false);
  const [expertLettersDrafts, setExpertLettersDrafts] = useState(new Map());
  const [singleDraft, setSingleDraft] = useState<DraftType | null>(null);
  const [deleteError, setDeleteError] = useState<any>(null);
  const [expertLettersError, setExpertLettersError] = useState<any>(null);
  const [singleDraftError, setSingleDraftError] = useState<any>(null);
  const [updateError, setUpdateError] = useState<any>(null);

  const fetchExpertLettersDrafts = () => {
    if (!indivId) {
      return; // Abort fetch if no individual ID is provided
    }

    const colRef = collection(
      db,
      `${DATABASE.DRAFTS}/${indivId}/expert_letters`
    );
    const q = query(colRef, orderBy("extracted_argument.letter_author"));

    setIsLoadingExpertLetters(true); // Set loading state to true

    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        // Create a new Map to store expert letter drafts
        const draftsMap = new Map<string, ExpertLetterDraft>();

        // Process each document in the snapshot
        querySnapshot.forEach((doc) => {
          const data = doc.data();

          const draft: ExpertLetterDraft = {
            uid: doc.id || null,
            expert_name: data.expert_name || null,
            recommended_person: data.recommended_person || null,
            visa_type: data.visa_type || null,
            letter_date: data.letter_date || null,
            greeting_style: data.greeting_style || null,
            creativity: data.creativity || null,
            tone: data.tone || null,
            length: data.length || null,
            institution: data.institution || null,
            expert_relationship_role: data.expert_relationship_role || null,
            relationship: data.relationship || null,
            start_date: data.start_date || null,
            end_date: data.end_date || null,
            expert_current_role: data.expert_current_role || null,
            expert_current_institution: data.expert_current_institution || null,
            contributions_to_emphasize: data.contributions_to_emphasize || null,
            extra_contributions: data.extra_contributions || null,
            national_importance: data.national_importance || null,
            expert_resume_summary: data.expert_resume_summary || null,
            candidate_resume_summary: data.candidate_resume_summary || null,
            template: data.template || null,
            example_letter: data.example_letter || null,
            extracted_argument: data.extracted_argument
              ? {
                  employmentInCriticalCapacity:
                    data.extracted_argument[
                      "Employment in a critical capacity"
                    ] || "",
                  letter_author: data.extracted_argument.letter_author || ""
                }
              : null,
            created_at: data.created_at,
            last_updated_at: data.last_updated_at,
            last_updated_by: data.last_updated_by,
            status: data.status
          };

          draftsMap.set(doc.id, draft); // Add each draft to the draftsMap
        });

        // Update the redux store with the new drafts data
        dispatch(setExpertsLettersD(new Map(draftsMap)));

        setIsLoadingExpertLetters(false); // Set loading state to false after successful fetch
      },
      (error) => {
        console.error("Error fetching expert letters drafts:", error); // Log error
        setExpertLettersError(error);
        setIsLoadingExpertLetters(false); // Set loading state to false after error
      }
    );
    // eslint-disable-next-line consistent-return
    return unsubscribe; // Return the unsubscribe function for cleanup
  };

  const getSingleDraft = async (experLetterId: string) => {
    if (!indivId || !experLetterId) return;

    const docRef = doc(
      db,
      `${DATABASE.DRAFTS}/${indivId}/expert_letters/${experLetterId}`
    );

    setIsLoadingSingleDraft(true);

    try {
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // Ensure that you're setting a new object rather than mutating an existing one
        setSingleDraft({ id: docSnap.id, value: { ...docSnap.data() } });
      } else {
        console.log("No such document!");
        setSingleDraftError(new Error("No such document"));
      }
    } catch (e) {
      setSingleDraftError(e);
    } finally {
      setIsLoadingSingleDraft(false);
    }
  };

  const deleteDraft = async (expertLetterId: string) => {
    if (!indivId || !expertLetterId) return; // Ensure both IDs are present

    const docRef = doc(
      db,
      `${DATABASE.DRAFTS}/${indivId}/expert_letters`,
      expertLetterId
    );

    setIsLoadingDelete(true); // If you have a loading state for deletion

    try {
      await deleteDoc(docRef);
      // Handle any post-deletion logic here, e.g., updating the UI
    } catch (e) {
      setDeleteError(e); // Use an appropriate error handling state
    } finally {
      setIsLoadingDelete(false); // Reset the loading state
    }
  };

  const updateDraft = async (expertLetterId: string, newData: any) => {
    if (!indivId || !expertLetterId) return; // Ensure both IDs are present
    console.log("Processing document");

    const docRef = doc(
      db,
      `${DATABASE.DRAFTS}/${indivId}/expert_letters/${expertLetterId}`
    );

    setIsLoadingUpdate(true); // If you have a loading state for updating

    try {
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        console.log("Document does not exist, creating new document");
        await setDoc(docRef, newData); // Create the document with newData
      } else {
        console.log("Updating specific field in the existing document");
        newData.last_updated_by = email;
        await updateDoc(docRef, { ...newData }); // Dynamically update only the field(s) in newData
      }

      // Update the state or UI to reflect the change
    } catch (e) {
      console.log("Error: ", e);
      setUpdateError(e); // Use an appropriate error handling state
    } finally {
      setIsLoadingUpdate(false); // Reset the loading state
    }
  };

  const updateNestedField = async (
    expertLetterId: string,
    fieldPath: string,
    fieldValue: string
  ) => {
    if (!indivId || !expertLetterId) return; // Ensure both IDs are present

    const docRef = doc(
      db,
      `${DATABASE.DRAFTS}/${indivId}/expert_letters/${expertLetterId}`
    );

    setIsLoadingUpdate(true); // If you have a loading state for updating

    try {
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        return;
      }
      console.log("Updating specific field in the existing document");
      await updateDoc(docRef, {
        [fieldPath]: fieldValue // Dynamically update only the nested field
      });

      // Update the state or UI to reflect the change
    } catch (e) {
      console.log("Error: ", e);
      setUpdateError(e); // Use an appropriate error handling state
    } finally {
      setIsLoadingUpdate(false); // Reset the loading state
    }
  };

  useEffect(() => {
    const unsubscribe = fetchExpertLettersDrafts();

    // Clean up the Firestore onSnapshot listener when the component unmounts or `indivId` changes
    return () => {
      // eslint-disable-next-line consistent-return
      if (unsubscribe) {
        unsubscribe();
        console.log(
          "[exp drafts fetching] Unsubscribed from Firestore listener."
        );
      }
    };
  }, [indivId]);

  return {
    updateNestedField,
    fetchExpertLettersDrafts,
    updateError,
    isLoadingUpdate,
    updateDraft,
    getSingleDraft,
    deleteDraft,
    isLoadingDelete,
    deleteError,
    isLoadingSingleDraft,
    singleDraft,
    singleDraftError,
    expertLettersError,
    isLoadingExpertLetters,
    expertLettersDrafts
  };
};
