import {
  Badge,
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { deleteDoc, doc, setDoc } from "firebase/firestore";
import { FormikConsumer } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { createNewFirebaseUser } from "../api/OpenAIApi";
import { db } from "../api/firebaseApi";
import { SpecialHeading } from "../components/individualTabs/PanelLayout";
import { InputField } from "../forms/InputField";
import { SelectField } from "../forms/SelectField";
import { addClientPersonalSchema } from "../forms/client/Client.schema";
import { ContactInfo } from "../forms/client/ClientContactInformation";
import { ClientEmployerInformation } from "../forms/client/ClientEmployerInformation";
import { ClientPersonalForm } from "../forms/client/ClientPersonalFields";
import { ClientPersonalInformation } from "../forms/client/ClientPersonalInformation";
import { MultiStepForm, StepForm } from "../forms/multipStepForm";
import { isValidVisaKey } from "../helpers/string_helpers";
import { useDeleteFileFromDB } from "../hooks/useDeleteFileFromDB";
import useFirestoreDocument from "../hooks/useFirestoreDocument";
import { useGetClientsData } from "../hooks/useGetClientsData";
import {
  ClientState,
  ClientStateWithNoQuestionnaire,
  deleteCase,
  initialClientState,
  setActiveClientsData
} from "../redux/clients/clientsSlice";
import { clientsSelectors } from "../redux/clients/selectors";
import { UpdateIndividual } from "../redux/individual/individualSlice";
import { lawyerSelectors } from "../redux/lawyer/selectors";
import { DATABASE, visaTypeToPath, VISAVALUE } from "../types/tables-data";

export const NewCase = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAddClientLoading, setIsAddClientLoading] = useState(false);
  const [existClient, setExistClient] = useState<ClientState | null>(null);
  const [existClientIndex, setExistClientIndex] = useState(-1);
  const [showSidebar, setShowSidebar] = useState(false);
  const dispatch = useDispatch();
  const uid = useSelector(lawyerSelectors.selectUid);
  const allClients = useSelector(clientsSelectors.selectAll);
  const practiceName = useSelector(lawyerSelectors.selectPracticeName);
  const { clientData, isLoadingGetClientById, onSubmitGetClientById } =
    useGetClientsData();
  const [newClient, setNewClient] = useState<ClientState | null>(null);
  const [userRef, setUserRef] = useState<any>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const navigate = useNavigate();
  const params = useParams();
  const { updateDocument, loading } = useFirestoreDocument<UpdateIndividual>(
    DATABASE.INDIVIDUALS,
    params.caseID!
  );

  useEffect(() => {
    if (clientData) {
      dispatch(setActiveClientsData([clientData]));
      const visaPath =
        newClient?.visa && isValidVisaKey(newClient.visa)
          ? visaTypeToPath[newClient.visa]
          : newClient?.visa || "";
      const path = visaPath
        ? `/individual/${userRef.uid}/${visaPath}`
        : `/individual/${userRef.uid}`;
      navigate(path);
    }
  }, [clientData]);

  const addNewClientData = async (values: ClientStateWithNoQuestionnaire) => {
    const newClient = { ...values };

    try {
      setIsAddClientLoading(true);
      const userRef = await createNewFirebaseUser(
        values.email,
        "default",
        true
      );
      // todo: ask khalil about sending new client email automatically
      // const auth = getAuth();
      // await sendPasswordResetEmail(auth, values.email);

      const docRef = doc(db, DATABASE.INDIVIDUALS, userRef.uid);
      const questionnairesDocRef = doc(
        db,
        DATABASE.QUESTIONNAIRES,
        userRef.uid
      );
      setUserRef(userRef);
      setNewClient(newClient);
      await setDoc(docRef, newClient);
      if (values.employerInfo?.legalBusinessName) {
        if (values.visa === VISAVALUE.O1A)
          await setDoc(questionnairesDocRef, {
            petitionerName: values.employerInfo?.legalBusinessName,
            petitionerType: "Employer"
          });
        if (values.visa === VISAVALUE.EB1A)
          await setDoc(questionnairesDocRef, {
            employerName: values.employerInfo?.legalBusinessName,
            petitionerType: "Employer"
          });
        if (values.visa === VISAVALUE.EB1B)
          await setDoc(questionnairesDocRef, {
            employerName: values.employerInfo?.legalBusinessName
          });
      }
      onSubmitGetClientById(userRef.uid);
    } catch (e) {
      console.log(e);

      toast({
        description: "Individual with the same email already exists!",
        colorScheme: "red",
        duration: 3000,
        isClosable: true
      });
    } finally {
      setIsAddClientLoading(false);
      setIsSubmitting(false);
    }
  };

  const { onDeleteAllClientFiles, onDeleteClientAvatar } =
    useDeleteFileFromDB();

  const handleDeleteCase = async () => {
    setIsSubmitting(true);
    if (params.caseID) {
      await onDeleteAllClientFiles(`${params.caseID}`, DATABASE.INDIVIDUALS);
      await onDeleteClientAvatar(`${params.caseID}`, DATABASE.INDIVIDUALS);
      await deleteDoc(doc(db, DATABASE.DOCUMENTS, `${params.caseID}`));
      await deleteDoc(doc(db, DATABASE.EXTRACTED, `${params.caseID}`));
      await deleteDoc(doc(db, DATABASE.FORMS, `${params.caseID}`));
      await deleteDoc(doc(db, DATABASE.INDIVIDUALS, `${params.caseID}`));

      dispatch(deleteCase(existClientIndex));
      onClose();
      setIsSubmitting(false);
      navigate("/cases");
    }
  };
  const user = allClients.find((client) => client.id === params?.caseID);
  const newCase = !params.caseID;

  return (
    <div style={{ cursor: isSubmitting ? "progress" : "default" }}>
      <Box>
        {newCase ? (
          <div className="max-w-xl mx-auto">
            <MultiStepForm
              loading={isAddClientLoading}
              initialValues={initialClientState}
              onSubmit={(values) => {
                const convertedBirthDay = new Date(
                  `${values.birthDay}T00:00:00`
                ).getTime();
                const createdDate = new Date().getTime();

                const clientData = {
                  ...values,
                  birthDay: convertedBirthDay,
                  lawyerId: uid,
                  isActive: true,
                  practiceName,
                  createdDate
                } as ClientStateWithNoQuestionnaire;
                addNewClientData(clientData);
              }}
            >
              <StepForm
                validationSchema={addClientPersonalSchema}
                stepName="Client Personal Information"
              >
                <Box w="50%">
                  <SelectField
                    placeHolder="Select Type"
                    label="Visa Type"
                    name="visa"
                    options={Object.values(VISAVALUE).filter(
                      (value) => value !== ""
                    )}
                    isRequired
                  />
                </Box>
                <ClientPersonalForm />
                <Box w="50%">
                  {/* <InputField label="Employer name" name="employer" /> */}
                  <InputField
                    label="Name of Employer Petitioner (if any)"
                    name="employerInfo.legalBusinessName"
                  />
                </Box>
                <Box w="50%">
                  <InputField label="Email Address" name="email" isRequired />
                </Box>
              </StepForm>
            </MultiStepForm>
          </div>
        ) : (
          <Flex alignItems="start" flexDirection="column" flex="1">
            <Tabs isLazy variant="sub">
              <TabList className="border-b" gap={4}>
                <SpecialHeading
                  title="Edit case"
                  className="!place-self-end !p-0 !w-fit mr-8"
                />
                <div className="flex gap-4 relative">
                  <Tab>Personal Information</Tab>
                  <Tab>Contact Information</Tab>
                  <Tab>Employer Information</Tab>
                </div>
              </TabList>
              <Badge className=" right-0 left-0 bottom-0 mx-auto  !bg-transparent  my-4  font-bold  !rounded-none">
                <Text className="text-2xl underline" color="primary.darkBlue">
                  {`${user?.firstName} ${user?.lastName} / ${user?.visa}`}
                </Text>
              </Badge>
              <TabPanels>
                <TabPanel>
                  <ClientPersonalInformation />
                </TabPanel>
                <TabPanel>
                  <ContactInfo />
                </TabPanel>
                <TabPanel>
                  <ClientEmployerInformation />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        )}
      </Box>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <strong>Deletion</strong> will remove the case permanently.
            <br />
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} mr={3}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              mr={3}
              onClick={handleDeleteCase}
              isDisabled={isSubmitting}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};
