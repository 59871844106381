import { Box } from "@chakra-ui/react";

interface TextBlock {
  title: string;
  text: string;
}

export const TextFromApiBlock = ({ title, text }: TextBlock) => {
  return (
    <Box p="10px">
      <Box fontWeight="700" mb="30px" textAlign="left">
        {title}
      </Box>
      <Box>
        {text.split("\n").map((p, i) => (
          <Box key={i} textAlign="left" py="10px">
            {p}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
