import { useState } from "react";
import { User, UserCredential, signInWithCustomToken, signInWithEmailAndPassword } from "firebase/auth";
import { getAnalytics, setUserId } from "firebase/analytics";
import { auth } from "../api/firebaseApi";

export const useLogin = () => {
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [loginAs, setLoginAs] = useState<boolean>(false);
  const [userRole, setUserRole] = useState<string | null>(null);
  const [errorLogin, setErrorLogin] = useState<string | null>(null);

  const onSubmitLogin = async (email: string, password: string, customToken=null, userRole=null) => {
    setIsLoadingLogin(true);
    try {
      if (customToken){
        console.log("logging in with custom token")
        setLoginAs(true);
        const {user} = await signInWithCustomToken(auth, customToken);
        setUserRole(userRole)
        setUser(user);
      }
      else {
        console.log("logging in with username and password");
        const {user} = await signInWithEmailAndPassword(auth, email, password);
        setUser(user);
        const analytics = getAnalytics();
        setUserId(analytics, user.uid);
      }
    } catch (e: any) {
      const errorCode = e.code;
      setErrorLogin(errorCode);
    } finally {
      setIsLoadingLogin(false);
    }
  };

  return {
    isLoadingLogin,
    onSubmitLogin,
    user,
    errorLogin,
    loginAs,
    userRole
  };
};
