import { useState } from "react";
import { extractInfoFromNIWEvidence } from "../api/OpenAIApi";
import { ExtractedPassport } from "../types/extracted-passport";

export const useExtractNIWEvidenceData = () => {
  const [isLoadingExtractData, setIsLoadingExtractData] = useState(false);
  const [apiResponseExtractData, setApiResponseExtractData] = useState<
    any | null | ExtractedPassport
  >(null);

  const onSubmitExtractData = async (individualId: string, exhibits: number[], ids: string[]) => {
    setIsLoadingExtractData(true);

    try {
      const extractedInfo = await extractInfoFromNIWEvidence(
        individualId,
        exhibits,
        ids,
      );
      if (extractedInfo) {
        setApiResponseExtractData(extractedInfo);
      }
    } catch (error) {
      console.error("Something went wrong:", error);
    } finally {
      setIsLoadingExtractData(false);
    }
  };

  return {
    isLoadingExtractData,
    onSubmitExtractData,
    apiResponseExtractData
  };
};
