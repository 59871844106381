import { List, ListItem } from "@chakra-ui/react";
import { NavigationLink } from "./NavigationLink";
import { SidebarLink } from "./types";

type Props = {
  links: SidebarLink[];
  onClick?: () => void;
};
const SidebarLinks = ({ links, onClick }: Props) => {
  return (
    <List className="flex flex-col gap-4 text-left p-4">
      {links.map((link, index) => (
        <ListItem key={index}>
          <NavigationLink key={index} link={link} onClick={onClick} />
        </ListItem>
      ))}
    </List>
  );
};

export default SidebarLinks;
