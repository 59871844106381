import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea
} from "@chakra-ui/react";
import { doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { db } from "../../../api/firebaseApi";
import {
  getDocumentCategories,
  getDocumentCategoryTypes,
  getDocumentExhibitById,
  getSubExhibitNumberArray,
  mapVisaTypeToPath
} from "../../../helpers/helpers";
import { useGetSubDocuments } from "../../../hooks/useGetSubDocuments";
import { updateDocument } from "../../../redux/documents/documentSlice";
import { documentSelectors } from "../../../redux/documents/selectors";
import { VisaDocumentType } from "../../../redux/documents/types";
import { DataDocs } from "../../../types/tables-data";
import Dropdown from "../../common/Dropdown";
import { FileExtensionThumbnail } from "../../common/Filethumbnail";
import { SubdocumentsTable } from "./SubdocumentsTable";

interface Props {
  document: DataDocs | DataDocs | undefined;
  isDeleting: boolean;
  isOpen: boolean;
  onClose: () => void;
  visaDocumentType: VisaDocumentType;
  headerText: string | undefined;
}

const EditMainDocModal: React.FC<Props> = ({
  document,
  isDeleting,
  isOpen,
  onClose,
  headerText,
  visaDocumentType
}) => {
  if (document === undefined) return null;
  const { visaType } = useParams();
  const documentTypes = useSelector(documentSelectors.documentTypes);
  const mappedVisaType = mapVisaTypeToPath(visaType!);
  const [currentDocument, setCurrentDocument] = useState<DataDocs | undefined>(
    document
  );
  const dispatch = useDispatch();
  const [error, setError] = useState<string | undefined>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (document && isOpen) {
      setCurrentDocument({ ...document });
      setError(undefined);
    }
  }, [document, isOpen]);

  const validate = (): boolean => {
    if (currentDocument?.type === undefined) {
      setError("Document type is required");
      return false;
    }
    setError(undefined);
    return true;
  };
  useEffect(() => {
    validate();
  }, [currentDocument]);

  const handleInputChange = (key: any, value: any) => {
    setCurrentDocument((prevState: any) => {
      // Otherwise, spread the previous state and update the specific key-value pair
      return {
        ...prevState,
        [key]: value,
        // resetting type dropdown
        ...(key === "criterion" && {
          type: undefined
        })
      };
    });
  };

  const handleSubmit = async () => {
    if (!currentDocument?.docRef || !currentDocument || validate() === false) {
      return;
    }

    setIsSubmitting(true);

    const dataPayload: DataDocs = {
      ...currentDocument,
      uploadBy: "",
      uploadDate: Date.now(),
      documentTitle: currentDocument?.documentTitle ?? ""
    };

    const docRef = doc(db, currentDocument.docRef);
    // await setDoc(docRef, dataPayload);
    await updateDoc(docRef, dataPayload);
    dispatch(updateDocument({ type: visaDocumentType, data: dataPayload }));
    setIsSubmitting(false);
    onClose();
  };
  const evidenceExhibitMap = useSelector(documentSelectors.evidenceExhibitMap);

  const mainDocumentExhibit = getDocumentExhibitById(
    evidenceExhibitMap,
    currentDocument?.id ?? ""
  );

  useGetSubDocuments(currentDocument!, visaDocumentType);

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="l"
    >
      <ModalOverlay />
      <ModalContent width="70%">
        <ModalHeader>{headerText}</ModalHeader>
        <ModalCloseButton onClick={onClose} />
        <ModalBody pb={6}>
          <Flex flexDirection="column" rowGap={1}>
            {visaDocumentType !== VisaDocumentType.SignedExpertLetters && (
              <>
                <Text my={2} fontWeight={500} fontSize={16}>
                  Category
                </Text>
                <Dropdown
                  selectedValue={currentDocument?.criterion}
                  title="Category"
                  onItemSelect={(value) =>
                    handleInputChange("criterion", value)
                  }
                  list={getDocumentCategories(
                    documentTypes,
                    visaDocumentType,
                    mappedVisaType
                  )}
                />
              </>
            )}

            <Text my={2} fontWeight={500} fontSize={16}>
              Document Type
            </Text>
            <Dropdown
              selectedValue={currentDocument?.type}
              title="Type"
              onItemSelect={(value) => {
                handleInputChange("type", value);
              }}
              list={getDocumentCategoryTypes(
                documentTypes,
                visaDocumentType,
                mappedVisaType,
                currentDocument?.criterion ?? ""
              )}
            />
            {currentDocument?.type === "Other" && (
              <>
                <Input
                  placeholder="Document title"
                  value={currentDocument?.documentTitle ?? ""}
                  onChange={(e) => {
                    handleInputChange("documentTitle", e.target.value);
                  }}
                />
                {/* {formErrors.documentTitle && (
                  <Text color="red">{formErrors.documentTitle}</Text>
                )} */}
              </>
            )}
            {error && <Text color="red">{error}</Text>}
          </Flex>

          {currentDocument?.autoTitle !== undefined && (
            <Flex flexDirection="column" py={2} gap={2}>
              <Text my={2} fontWeight={500}>
                AI-generated title
              </Text>
              <Textarea
                size="lg"
                resize="vertical"
                value={currentDocument?.autoTitle ?? ""}
                onChange={(e) => handleInputChange("autoTitle", e.target.value)}
                name="ai-title"
                id="ai-title"
              />
            </Flex>
          )}

          <Flex flexDirection="column" py={2} fontSize={16}>
            <Text my={2} fontWeight={500}>
              Description
            </Text>
            <Textarea
              size="lg"
              resize="vertical"
              placeholder="Please explain why this document is relevant and how it showcases the applicant's accomplishments."
              value={currentDocument?.description ?? ""}
              onChange={(e) => handleInputChange("description", e.target.value)}
            />
          </Flex>
          <Divider my={5} />

          {/* add sub_documents table  */}
          {visaDocumentType !== VisaDocumentType.SignedExpertLetters &&
            currentDocument && (
              <div className="">
                <Heading size="md" my={4}>
                  Supporting documents
                </Heading>
                <SubdocumentsTable
                  document={currentDocument!}
                  visaType={visaDocumentType}
                />
              </div>
            )}
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Box>
            <div className="inline-flex gap-2">
              <div className="w-4 h-4 ">
                <FileExtensionThumbnail doc={document!} />
              </div>
              <span className="">{currentDocument?.documentTitle}</span>
            </div>
            <br />
            <span className="text-xs">document id: {currentDocument?.id}</span>
            <br />
            <small>you can change the category of the document</small>
          </Box>

          <Box>
            <Button
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              isLoading={isSubmitting}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditMainDocModal;
