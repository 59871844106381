import {
  Box,
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { doc } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { db } from "../../api/firebaseApi";
import useManageCaseResearch from "../../hooks/useManageCaseResearch";
import { useSubscribeToJob } from "../../hooks/useSubscribeToJob";
import {
  removeAllGeneratedResearch,
  removeResearchTopic
} from "../../redux/case-research/caseResearchSlice";
import { caseResearchSelectors } from "../../redux/case-research/selectors";
import {
  ExtractionJob,
  ExtractionReducer,
  EXTRACTIONSTATES,
  GenerationStatesDescription
} from "../../redux/extraction-jobs/extractionJobsSlice";
import { extractionSelectors } from "../../redux/extraction-jobs/selectors";
import { DATABASE, visaFromPath, VISAVALUE } from "../../types/tables-data";
import { CustomBox } from "../common/CustomBox";
import { CustomButton } from "../common/CustomButton";
import { ExtractionCard } from "../common/ExtractionCard";
import { SpecialHeading } from "./PanelLayout";
import CaseResearchDatatable from "./caseResearch/Datatable";
import { ReplaceDialog } from "./individualDrafts/sopDrafts/ReplaceDialog";
import useFirestoreDocument from "../../hooks/useFirestoreDocument";

export const CaseResearch = () => {
  const { id, visaType } = useParams();
  const dispatch = useDispatch();
  const toast = useToast();
  const { topics, extractedData } = useSelector(
    caseResearchSelectors.selectAll
  );

  const { initiateJob } = useSubscribeToJob();

  const {
    generateTopics,
    generateSummary,
    deleteResearchTopic,
    onSaveCaseResearchTopic
  } = useManageCaseResearch(id);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedTopicTitle, setSelectedTopicTitle] = useState<string>();
  const [selectedTopicId, setSelectedTopicId] = useState<string>();
  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState<boolean>(false);
  const [missingRequirement, setMissingRequirement] = useState<
    string | undefined
  >(undefined);

  const caseResearchJob = useSelector((state: ExtractionReducer) =>
    extractionSelectors.selectJobById(state, "Case Research" ?? "")
  );

  const statusMsg = useMemo(() => {
    const state = caseResearchJob?.status?.status as EXTRACTIONSTATES;
    return (
      GenerationStatesDescription[state] ??
      GenerationStatesDescription.NotStarted
    );
  }, [caseResearchJob]);

  const status = caseResearchJob?.status?.status;

  const isGenerated = useMemo(
    () => status === EXTRACTIONSTATES.Completed,
    [status]
  );

  const isGenerating = useMemo(
    () => status === EXTRACTIONSTATES.Processing,
    [status]
  );

  const handleGenerateSummary = async () => {
    await generateSummary();
  };

  const handleGenerateTopics = async () => {
    // Initiating db real-time tracking
    const job: ExtractionJob = {
      id: "Case Research",
      type: "Case research generation",
      docRef: doc(db, DATABASE.CASE_RESEARCH, `${id}`),
      jobRef: doc(db, DATABASE.ACTIVE_JOBS, `${id}`, `jobs`, `Case Research`),
      status: { status: EXTRACTIONSTATES.Pending },
      toastTitle: "Generation status",
      toastDesc: `Case research generation is completed`
    };
    await initiateJob(job);
    await generateTopics();
  };

  const openDialog = () => {
    if (isGenerated) {
      setShowConfirmationDialog(true);
      return;
    }
    handleGenerateTopics();
    setShowConfirmationDialog(false);
  };

  const handleDeleteTopic = async (topicId: string) => {
    setConfirmModalOpen(false);
    removeResearchTopic(topicId);
    deleteResearchTopic(topicId);
  };

  const openDeleteConfirm = (topicTitle: string, topicId: string) => {
    setSelectedTopicTitle(topicTitle);
    setSelectedTopicId(topicId);
    setConfirmModalOpen(true);
  };

  const {
    isOpen: isExtractDeleteOpen,
    onOpen: onExtractDeleteOpen,
    onClose: onExtractDeleteClose
  } = useDisclosure();

  const handleDeleteExtracted = async () => {
    try {
      topics.forEach((topic) => {
        onSaveCaseResearchTopic(topic.id, "", "");
      });
      dispatch(removeAllGeneratedResearch());
      toast({
        title: "Generated case research deleted",
        description: "Information deleted.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right"
      });
      onExtractDeleteClose();
    } catch (e) {
      console.log(e);
    }
  };

  // TODO : generate formdata interfaces for all visa questions in db
  interface Prong1Data {
    areaOfExpertise?: string;
  }
  interface FormData {
    generalField?: string;
    applicantField?: string;
    prong1Data?: Prong1Data;
    academicField?: string;
  }

  const { document: questionnaireData, listenAndFetch } =
    useFirestoreDocument<FormData>(DATABASE.QUESTIONNAIRES, id!);

  useEffect(() => {
    listenAndFetch();
  }, []);

  const isGenerateDisabled = useMemo(() => {
    if (!visaType) return true;

    const checkIfRequirementsExist = (
      condition: string | undefined,
      requirement: string
    ) => {
      if (!condition || condition.trim() === "") {
        setMissingRequirement(requirement);
        return true;
      }
      return false;
    };

    switch (visaFromPath[visaType]) {
      case VISAVALUE.O1A:
        return checkIfRequirementsExist(
          questionnaireData?.applicantField,
          "General field of work"
        );
      case VISAVALUE.O1B:
        return checkIfRequirementsExist(
          questionnaireData?.applicantField,
          "Field of work"
        );
      case VISAVALUE.EB1A:
        return checkIfRequirementsExist(
          questionnaireData?.generalField,
          "General field of extraordinary ability"
        );
      case VISAVALUE.EB1B:
        return checkIfRequirementsExist(
          questionnaireData?.academicField,
          "Academic Field"
        );
      case VISAVALUE.EB2NIW:
        return checkIfRequirementsExist(
          questionnaireData?.prong1Data?.areaOfExpertise,
          "Area of expertise"
        );
      default:
        return true;
    }
  }, [questionnaireData, visaType]);

  return (
    <Flex flexDirection="column" rowGap={10}>
      <Box>
        <CustomBox type="warning">
          <Text fontSize="18px">
            This feature in Beta Testing: Please report any odd behaviors and/or
            share any feedback.
          </Text>
        </CustomBox>
        <CaseResearchDatatable topics={topics} onDelete={openDeleteConfirm} />
        {isGenerateDisabled && missingRequirement && (
          <CustomBox type="info" mt={10}>
            <Text>
              The{" "}
              <Text as="span" fontWeight="bold">
                {missingRequirement}
              </Text>{" "}
              field in the questionnaires is required to generate case research.
              Please provide the necessary information.
            </Text>
          </CustomBox>
        )}
        <Flex className="flex justify-center mt-4" columnGap={4}>
          {/* <Button my={4} bg="primary.darkBlue" color="white" leftIcon={<AddIcon h={4} />}>
            Add manually
          </Button> */}

          <CustomButton
            isDisabled={isGenerateDisabled}
            isLoading={isGenerating}
            type="ai"
            title="Auto Generate Research"
            onClick={openDialog}
          />
        </Flex>
      </Box>
      <Divider />

      <Box>
        <SpecialHeading title="Extractions from case research" px={0} />

        <ExtractionCard
          type="case-research"
          id={id}
          extractedData={extractedData!}
          onExtractionDelete={onExtractDeleteOpen}
          statusMsg={statusMsg}
        />
        {/* <Flex justifyContent="center">
          <CustomButton
            type="ai"
            title="Generate Research Summary"
            onClick={handleGenerateSummary}
          />
        </Flex> */}
      </Box>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isExtractDeleteOpen}
        onClose={onExtractDeleteClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            Are you sure you want to delete the existing generated data?
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={() => {
                onExtractDeleteClose();
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={async () => {
                await handleDeleteExtracted();
                onExtractDeleteClose();
              }}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        closeOnOverlayClick={false}
        isOpen={confirmModalOpen}
        onClose={() => {
          setConfirmModalOpen(false);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            Are you sure you want to delete "{selectedTopicTitle}"?
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={() => {
                setConfirmModalOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={() => handleDeleteTopic(selectedTopicId!)}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ReplaceDialog
        isOpen={showConfirmationDialog}
        onConfirm={() => {
          setShowConfirmationDialog(false);
          handleGenerateTopics();
        }}
        setShowConfirmationDialog={setShowConfirmationDialog}
        isLoading={false}
        jobType="generated"
        jobName="Case Research"
      />
    </Flex>
  );
};
