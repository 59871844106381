import { useState } from "react";
import { generateDraft } from "../api/OpenAIApi";
import { GenerateDraftParams } from "../types/drafts.type";

export const useGenerateDrafts = (indivId?: string) => {
  const [isLoadingDraftExpertLetter, setIsLoadingDraftExpertLetter] =
    useState(false);
  const [expertLetterDraft, setExpertLetterDraft] = useState<string | null>(
    null
  );

  const onSubmitGenerateDraft = async ({
    draftType,
    expertId = null,
    tone = null,
    length = null,
    creativity = null,
    greeting = null,
    useAsync = true,
    referenceResume = false,
    isSupportLetter = false
  }: GenerateDraftParams) => {
    if (!indivId) return;
    setIsLoadingDraftExpertLetter(true);
    try {
      await generateDraft({
        individualId: indivId,
        draftType,
        useAsync: useAsync ?? false,
        expertId: expertId ?? null,
        tone: tone ?? null,
        length: length ?? null,
        creativity: creativity ?? null,
        greeting: greeting ?? null,
        referenceResume: referenceResume ?? false,
        isSupportLetter: isSupportLetter ?? false
      });
    } catch (e) {
      console.log("Something went wrong, Please try again.", e);
    } finally {
      setIsLoadingDraftExpertLetter(false);
    }
  };

  return {
    isLoadingDraftExpertLetter,
    onSubmitGenerateDraft,
    expertLetterDraft
  };
};
