import { DefaultExtensionType, FileIcon, defaultStyles } from "react-file-icon";
import { DataDocs } from "../../types/tables-data";

export const FileExtensionThumbnail = ({ doc }: { doc: DataDocs }) => {
  const fileExtension = doc?.filePath?.split(".").pop() as
    | DefaultExtensionType
    | undefined;

  const iconProps =
    fileExtension && defaultStyles[fileExtension]
      ? defaultStyles[fileExtension]
      : defaultStyles.docx;

  return (
    <div className="h-4 w-4">
      <FileIcon extension={fileExtension} {...iconProps} />
    </div>
  );
};
