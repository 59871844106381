import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  runTransaction,
  updateDoc,
  writeBatch
} from "firebase/firestore";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../api/firebaseApi";
import { individualSelectors } from "../redux/individual/selectors";
import { DATABASE } from "../types/tables-data";
import { RemoveDocProps } from "./useDeleteClientDocument";

export interface RemoveEvidenceDocProps {
  firstName: string;
  lastName: string;
  uid: string;
  docId?: string;
}

const useDeleteIndivDocs = () => {
  const email = useSelector(individualSelectors.selectEmail);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const logWithKeyword = (keyword: string, message: string) => {
    console.log(`${keyword}: ${message}`);
  };

  const KEYWORD = "EvidenceDeletion";

  const deleteDocumentFromEvidence = async (
    indivId: string,
    evidenceDocId: string,
    docId: string
  ) => {
    logWithKeyword(
      KEYWORD,
      `Starting to delete document with id ${docId} from evidenceDocId ${evidenceDocId} for indivId ${indivId}`
    );
    setIsLoading(true);
    setError(null);

    try {
      // Start a transaction to ensure atomic updates
      await runTransaction(db, async (transaction) => {
        logWithKeyword(KEYWORD, "Transaction started");

        // Reference to the document in sub-collection 'docs' within 'evidence_docs' of the individual
        const docRef = doc(
          db,
          "documents",
          indivId,
          "evidence_docs",
          evidenceDocId,
          "docs",
          docId
        );
        logWithKeyword(
          KEYWORD,
          `Document reference created for deletion: ${docRef.path}`
        );

        // Delete the document
        transaction.delete(docRef);
        logWithKeyword(
          KEYWORD,
          `Document with id ${docId} marked for deletion`
        );

        // Now fetch the remaining documents to reorder their exhibits
        const docsRef = collection(
          db,
          "documents",
          indivId,
          "evidence_docs",
          evidenceDocId,
          "docs"
        );
        const orderedDocsQuery = query(docsRef, orderBy("exhibit", "asc"));
        const snapshot = await getDocs(orderedDocsQuery);
        logWithKeyword(
          KEYWORD,
          `Fetched documents for reordering, count: ${snapshot.size}`
        );

        let exhibitChar = "A";
        snapshot.forEach((docSnapshot) => {
          if (docSnapshot.id !== docId) {
            const docToUpdateRef = docSnapshot.ref;
            transaction.update(docToUpdateRef, { exhibit: exhibitChar });
            logWithKeyword(
              KEYWORD,
              `Updated exhibit for document ${docSnapshot.id} to ${exhibitChar}`
            );
            exhibitChar = String.fromCharCode(exhibitChar.charCodeAt(0) + 1);
          }
        });
      });
      logWithKeyword(KEYWORD, "Transaction successfully committed");
    } catch (e) {
      logWithKeyword(
        KEYWORD,
        `Error during the deleteDocumentFromEvidence operation: ${e}`
      );
    } finally {
      setIsLoading(false);
      logWithKeyword(KEYWORD, "deleteDocumentFromEvidence operation completed");
    }
  };

  const onSubmitDeleteStandardDoc = async ({
    firstName,
    lastName,
    uid,
    docTitle
  }: RemoveDocProps) => {
    setIsLoading(true);

    try {
      // Delete the specified document first
      const docToDeleteRef = doc(
        db,
        DATABASE.DOCUMENTS,
        `${uid}`,
        "docs",
        docTitle
      );
      await deleteDoc(docToDeleteRef);

      // Start a batched write to update the remaining documents
      const batch = writeBatch(db);

      // Get all remaining documents after deletion
      const documentsRef = collection(db, DATABASE.DOCUMENTS, `${uid}`, "docs");
      const queryRef = query(documentsRef, orderBy("exhibit", "asc"));
      const querySnapshot = await getDocs(queryRef);

      // Adjust exhibits and prepare updates
      let exhibitCounter = 1; // Start exhibits at 1
      querySnapshot.forEach((docSnapshot) => {
        if (docSnapshot.exists()) {
          const docRef = doc(
            db,
            DATABASE.DOCUMENTS,
            `${uid}`,
            "docs",
            docSnapshot.id
          );
          batch.update(docRef, { exhibit: exhibitCounter });
          exhibitCounter += 1;
        }
      });

      // Commit the batched writes to update the documents
      await batch.commit();

      // Update the user's last upload info
      const updaterRef = doc(db, DATABASE.DOCUMENTS, `${uid}`);
      await updateDoc(updaterRef, {
        lastUploadBy: `${firstName} ${lastName}`,
        lastUploadByUid: uid,
        last_updated_by: email
      });
    } catch (e) {
      console.error("Error deleting document and updating exhibits: ", e);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteSubcollection = async (parentDocRef: any) => {
    const subCollectionRef = collection(parentDocRef, "docs");
    const snapshot = await getDocs(subCollectionRef);

    // Use map to create an array of delete promises and then use Promise.all to execute them concurrently
    const deletePromises = snapshot.docs.map((doc) => deleteDoc(doc.ref));
    await Promise.all(deletePromises);
  };

  const onSubmitDeleteEvidenceDoc = async ({
    firstName,
    lastName,
    uid,
    docId
  }: RemoveEvidenceDocProps) => {
    setIsLoading(true);

    try {
      // Step 1: Delete the specified evidence document
      const docToDeleteRef = doc(
        db,
        DATABASE.DOCUMENTS,
        `${uid}`,
        "evidence_docs",
        `${docId}`
      );

      await deleteSubcollection(docToDeleteRef);
      await deleteDoc(docToDeleteRef);

      // Step 2: Start a batched write to update the remaining evidence documents
      const batch = writeBatch(db);

      // Get all remaining evidence documents after deletion, ordered numerically by 'exhibit'
      const documentsRef = collection(
        db,
        DATABASE.DOCUMENTS,
        `${uid}`,
        "evidence_docs"
      );
      const queryRef = query(documentsRef, orderBy("exhibit", "asc"));
      const querySnapshot = await getDocs(queryRef);

      // Adjust exhibits and prepare updates
      let exhibitCounter = 1; // Start exhibits at 1
      querySnapshot.forEach((docSnapshot) => {
        if (docSnapshot.exists()) {
          const docRef = doc(
            db,
            DATABASE.DOCUMENTS,
            `${uid}`,
            "evidence_docs",
            docSnapshot.id
          );
          batch.update(docRef, { exhibit: exhibitCounter });
          exhibitCounter += 1;
        }
      });

      // Commit the batched writes to update the documents
      await batch.commit();

      // Step 3: Update the user's last upload info
      const updaterRef = doc(db, DATABASE.DOCUMENTS, `${uid}`);
      await updateDoc(updaterRef, {
        lastUploadBy: `${firstName} ${lastName}`,
        lastUploadByUid: uid,
        last_updated_by: email
      });
    } catch (e) {
      console.error(
        "Error deleting evidence document and updating exhibits: ",
        e
      );
    } finally {
      setIsLoading(false);
    }
  };

  return {
    deleteDocumentFromEvidence,
    onSubmitDeleteStandardDoc,
    onSubmitDeleteEvidenceDoc,
    isLoading,
    error
  };
};

export default useDeleteIndivDocs;
