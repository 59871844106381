import {
  Box,
  Button,
  CircularProgress,
  Container,
  Flex,
  Switch,
  useToast
} from "@chakra-ui/react";
import { doc, updateDoc } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../../../api/firebaseApi";
import { isSuperAdmin } from "../../../../helpers/helpers";
import { useIndivSop } from "../../../../hooks/useSopDrafts";
import { draftsSelectors } from "../../../../redux/drafts/selectors";
import { individualSelectors } from "../../../../redux/individual/selectors";
import { DATABASE } from "../../../../types/tables-data";
import { RichTextEditor } from "../../../common/RichTextEditor";
import { SpecialHeading } from "../../PanelLayout";

export const EditSopDraft = () => {
  const { id, visaType } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const uid = id ?? useSelector(individualSelectors.selectUid);
  const sop = useSelector(draftsSelectors.selectLegalBrief);
  const [userAccessEnabled, setUserAccessEnabled] = useState<boolean>(
    sop?.value.accessEnabled ?? false
  );
  const { updateSop, isLoadingSop } = useIndivSop(uid);

  const handleSwitchChange = async (checked: boolean) => {
    setUserAccessEnabled(checked);
    try {
      const docRef = doc(db, `${DATABASE.DRAFTS}/${id}`);
      await updateDoc(docRef, {
        "sop.accessEnabled": checked
      });

      toast({
        title: "Success",
        description: `User access has been ${
          checked ? "enabled" : "disabled"
        }.`,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right"
      });
    } catch (error) {
      console.error("Failed to update user access:", error);
      toast({
        title: "Error",
        description: "Failed to update user access.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom-right"
      });
    }
  };

  const handleSubmit = async (html: string, plainText: string) => {
    const payload = { text: plainText, html, accessEnabled: userAccessEnabled };
    if (uid) await updateSop(payload);

    toast({
      title: "Success",
      description: "Draft updated successfully",
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "bottom-right"
    });
    navigate(`/individual/${id}/${visaType}/drafts`);
  };

  return (
    <Container>
      <Flex flexDirection="column" rowGap="20px" alignItems="center">
        <SpecialHeading title="Draft legal brief" backNav />
        {isLoadingSop ? (
          <CircularProgress isIndeterminate />
        ) : (
          <Box className="w-full">
            {sop?.value ? (
              <RichTextEditor
                customHeight="auto"
                text={sop.value.html || sop.value.text}
                saveText={(html, plainText) => handleSubmit(html, plainText)}
              />
            ) : (
              <Box fontSize="18px">
                Click Generate in the previous page for a first draft
              </Box>
            )}
          </Box>
        )}
        <Flex flexDirection="row" columnGap={4}>
          <Button
            my={4}
            className="btn tertiary-btn"
            colorScheme="blue"
            onClick={() => navigate(-1)}
            width="fit-content"
          >
            Back
          </Button>
          {isSuperAdmin() && (
            <Flex alignItems="center" gap={2}>
              <Switch
                isChecked={userAccessEnabled}
                onChange={(e) => handleSwitchChange(e.target.checked)}
                colorScheme="teal"
              />
              <Box>Enable user access</Box>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Container>
  );
};
