import {
  Box,
  BoxProps,
  Divider,
  Flex,
  Heading,
  IconButton,
  Indicator,
  TabIndicator,
  Text
} from "@chakra-ui/react";
import { IoArrowBackSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import CustomBackButton from "../common/CustomBackButton";

interface Props extends BoxProps {
  title: string;
  backNav?: boolean;
  asTab?: boolean;
}

export const SpecialHeading = ({ title, backNav = false, ...rest }: Props) => {
  return (
    <Box py="15px" width="100%" {...rest} textAlign="left">
      {backNav && <CustomBackButton ariaLabel="Back to Dashboard" />}
      <Heading
        fontFamily="Inter"
        color="primary.darkBlue"
        size="md"
        textAlign="start"
        {...rest}
      >
        <Text className="relative">{title}</Text>
        <Box mt="8px" height="4px" width="56px" bg="primary.red" />
        <Divider color="primary.red" />
      </Heading>
    </Box>
  );
};

/** a wrapper for more consistent panel layouts
 *  @param {string} title : heading title
 */

export const PanelLayout = ({ children, title, asTab, ...rest }: Props) => {
  const excludedTitles = ["Expert letter questionnaires", "Drafts"];
  return (
    <div className="!w-[100%] bg-white p-4">
      {!excludedTitles.includes(title) && (
        <SpecialHeading title={title} {...rest} />
      )}
      <Box className="flex flex-col">{children}</Box>
    </div>
  );
};
