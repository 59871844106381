import { useState } from "react";
import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { db } from "../api/firebaseApi";
import { DATABASE } from "../types/tables-data";

type AddDocProps = {
  docNames: string;
  docUrls: string;
  firstName: string;
  lastName: string;
  uid: string;
  docTitle: string;
  text: string;
};
type RemoveDocProps = {
  firstName: string;
  lastName: string;
  uid: string;
  docTitle: string;
};

export const useAddNewClientDocument = () => {
  const [isLoadingAddClientDocs, setIsLoadingAddClientDocs] = useState(false);

  const params = useParams();

  const onSubmitAddUserDocuments = async ({
    docNames,
    docUrls,
    firstName,
    lastName,
    uid,
    docTitle,
    text
  }: AddDocProps) => {
    const docsRef = collection(
      db,
      DATABASE.DOCUMENTS,
      `${params.caseID}`,
      "docs"
    );
    setIsLoadingAddClientDocs(true);
    try {
      await setDoc(doc(db, DATABASE.DOCUMENTS, `${params.caseID}`), {
        lastUploadBy: `${firstName} ${lastName}`,
        lastuploadByUid: uid
      });
      await setDoc(doc(docsRef, docTitle), {
        documentTitle: docTitle,
        uploadDate: Date.now(),
        uploadBy: `${firstName} ${lastName}`,
        uploadByUid: uid,
        isRequireForForms: true,
        isRequireForDraft: true,
        docNames,
        docUrls,
        text
      });
    } catch (e) {
      console.error("Error add doc: ", e);
    } finally {
      setIsLoadingAddClientDocs(false);
    }
  };
  const onSubmitDeleteUserDocuments = async ({
    firstName,
    lastName,
    uid,
    docTitle
  }: RemoveDocProps) => {
    const docsRef = doc(
      db,
      DATABASE.DOCUMENTS,
      `${params.caseID}`,
      "docs",
      docTitle
    );

    const updaterRef = doc(db, DATABASE.DOCUMENTS, `${params.caseID}`);

    setIsLoadingAddClientDocs(true);
    try {
      await updateDoc(updaterRef, {
        lastUploadBy: `${firstName} ${lastName}`,
        lastuploadByUid: uid
      });
      await updateDoc(docsRef, {
        documentTitle: docTitle,
        uploadDate: 0,
        uploadBy: "",
        uploadByUid: "",
        docNames: "",
        docUrls: ""
      });
    } catch (e) {
      console.error("Error add doc: ", e);
    } finally {
      setIsLoadingAddClientDocs(false);
    }
  };

  return {
    isLoadingAddClientDocs,
    onSubmitAddUserDocuments
  };
};
