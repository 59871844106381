import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Row, flexRender } from "@tanstack/react-table";
import { Td, Tr } from "@chakra-ui/react";
import { MdDragIndicator } from "react-icons/md";
import { CSSProperties, useEffect, useRef } from "react";
import { ClientData, DataDocs } from "../../types/tables-data";

interface DraggableRowProps {
  row: Row<any>;
  onClick?: () => void;
  children: any;
}
export const RowDragHandleCell = ({ rowId }: { rowId: string }) => {
  const { attributes, listeners } = useSortable({
    id: rowId
  });
  return (
    <button type="button" {...listeners} {...attributes} className="">
      <MdDragIndicator />
    </button>
  );
};
export const DraggableRow = ({ row, children }: DraggableRowProps) => {
  const {
    transform,
    transition,
    setNodeRef,
    isDragging,
    listeners,
    attributes
  } = useSortable({
    id: `${row.original.id}`
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition ?? "",
    opacity: isDragging ? 0.9 : 1,
    zIndex: isDragging ? 1 : 0,
    position: "relative",
    boxShadow: isDragging ? "0 8px 16px rgba(0, 0, 0, 0.3)" : ""
  };

  return (
    <Tr style={style} ref={setNodeRef} {...attributes}>
      {children}
    </Tr>
  );
};

export const DraggableDiv = ({ row, onClick, children }: DraggableRowProps) => {
  const {
    transform,
    transition,
    setNodeRef,
    isDragging,
    listeners,
    attributes
  } = useSortable({
    id: `${row.id}`
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition ?? "",
    opacity: isDragging ? 0.9 : 1,
    zIndex: isDragging ? 1 : 0,
    position: "relative",
    boxShadow: isDragging ? "0 8px 16px rgba(0, 0, 0, 0.3)" : ""
  };

  return (
    <Tr style={style} ref={setNodeRef}>
      {children}
    </Tr>
  );
};
