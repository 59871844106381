import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { DataNotes, OverviewStep, VISAVALUE } from "../../types/tables-data";
import {
  ClientState,
  ExpertLetter,
  FirstForm,
  SecondForm
} from "../clients/clientsSlice";

const initialState: ClientState = {
  id: "",
  firstName: "",
  lastName: "",
  birthDay: 0,
  createdDate: 0,
  visa: VISAVALUE.EMPTY,
  email: "",
  isActive: true,
  status: 1,
  overview: {
    steps: []
  },
  notes: [],
  documents: [],
  packet: [],
  lawyerId: "",
  lawyerEmail: "",
  lawyerFirstName: ""
};
export interface ActiveClientReducer {
  activeClient: ClientState;
}
export interface UpdateActiveClient {
  firstName: string;
  lastName: string;
  birthDay: number;
  citizenship: string;
  employer: string;
  address: string;
  visa: VISAVALUE;
  email: string;
}
export interface AddClientOverviewStepsData {
  steps: OverviewStep[];
}
export interface UpdateClientOverviewStep {
  stepIndex: number;
  value: boolean;
}
export interface AddClientNotesData {
  note: DataNotes;
}
export interface ClientLawyerData {
  lawyerEmail: string;
  lawyerFirstName: string;
  lawyerLastName: string;
}

export const activeClientSlice = createSlice({
  name: "activeClient",
  initialState,
  reducers: {
    resetActiveClientData: () => initialState,
    updateActiveClientEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setActiveClientData: (state, action: PayloadAction<ClientState>) =>
      action.payload,
    updateActiveClientData: (
      state,
      { payload }: PayloadAction<UpdateActiveClient>
    ) => {
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.birthDay = payload.birthDay;
      state.citizenship = payload.citizenship;
      state.employer = payload.employer;
      state.address = payload.address;
      state.visa = payload.visa;
    },
    addActiveClientOverview: (
      state,
      { payload }: PayloadAction<AddClientOverviewStepsData>
    ) => {
      state.overview.steps = payload.steps;
    },
    updateActiveClientOverviewStep: (
      state,
      { payload }: PayloadAction<UpdateClientOverviewStep>
    ) => {
      state.overview.steps[payload.stepIndex].isComplete = payload.value;
      state.status = state.overview.steps.reduce((acc, step) => {
        if (step.isComplete) {
          acc += 1;
        }
        return acc;
      }, 1);
    },
    addActiveClientNote: (
      state,
      { payload }: PayloadAction<AddClientNotesData>
    ) => {
      state.notes.push(payload.note);
    },
    addClientLawyerData: (
      state,
      { payload }: PayloadAction<ClientLawyerData>
    ) => {
      state.lawyerEmail = payload.lawyerEmail;
      state.lawyerFirstName = payload.lawyerFirstName;
      state.lawyerLastName = payload.lawyerLastName;
    },
    addQuestionnaireFirst: (state, { payload }: PayloadAction<FirstForm>) => {
      state.questionnaireFirst = payload;
    },
    addQuestionnaireSecond: (state, { payload }: PayloadAction<SecondForm>) => {
      state.questionnaireSecond = payload;
    },
    addQuestionnaireLetter: (
      state,
      { payload }: PayloadAction<ExpertLetter>
    ) => {
      state.questionnaireLetter = payload;
    },
    deleteQuestionnairesForms: (state) => {
      delete state.questionnaireFirst;
      delete state.questionnaireSecond;
    },
    deleteExpertLetter: (state) => {
      delete state.questionnaireLetter;
    },
    updateActiveClientAvatar: (state, { payload }) => {
      state.avatarName = payload.avatarName;
      state.avatarSrc = payload.avatarSrc;
    }
  }
});

export const {
  resetActiveClientData,
  updateActiveClientEmail,
  setActiveClientData,
  updateActiveClientData,
  addActiveClientOverview,
  updateActiveClientOverviewStep,
  addActiveClientNote,
  addClientLawyerData,
  addQuestionnaireFirst,
  addQuestionnaireSecond,
  addQuestionnaireLetter,
  deleteQuestionnairesForms,
  deleteExpertLetter,
  updateActiveClientAvatar
} = activeClientSlice.actions;

export default activeClientSlice.reducer;
