import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListIcon,
  Button,
  Text,
  Link
} from "@chakra-ui/react";
import { URLS } from "../../constans";

export const EnterpriseCard = () => {
  return (
    <Box
      background="primary.darkBlue"
      color="white"
      boxShadow="md"
      borderRadius="md"
      padding={8}
      width="full"
      maxWidth="md"
      fontWeight={500}
    >
      <Text
        color="primary.red"
        fontWeight="bold"
        textTransform="uppercase"
        className="text-sm opacity-0"
      >
        Current Plan
      </Text>
      <Text fontSize="2xl" fontWeight="bold" marginBottom={4}>
        Enterprise
      </Text>
      <Divider my={2} />
      <div className="flex flex-col justify-start items-start">
        <Text marginBottom={4} align="start">
          Contact us for custom solutions and additional features.
        </Text>
        <List
          className="gap-1 flex flex-col justify-start items-start"
          whiteSpace="nowrap"
        >
          <ListItem>
            <ListIcon as={CheckCircleIcon} color="primary.red" />
            Tailored solutions
          </ListItem>
          <ListItem>
            <ListIcon as={CheckCircleIcon} color="primary.red" />
            Customized writing style
          </ListItem>
          <ListItem>
            <ListIcon as={CheckCircleIcon} color="primary.red" />
            Early access to new features
          </ListItem>
        </List>
      </div>
      <Link href={URLS.contactForm} isExternal>
        <Button variant="primary" marginTop={8}>
          Contact us
        </Button>
      </Link>
    </Box>
  );
};
