import {
  Text,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from "@chakra-ui/react";
import { Source } from "../../../../hooks/useEvidenceProngs";

type Props = {
  source: Source;
  onConfirm: () => void;
  setShowSourceDeletion: (value: boolean) => void;
  isLoading: boolean;
};

export const DeleteSourceDialog = ({
  source,
  onConfirm,
  setShowSourceDeletion,
  isLoading
}: Props) => {
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen
      onClose={() => setShowSourceDeletion(false)}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Warning !</ModalHeader>
        <ModalCloseButton
          onClick={() => {
            setShowSourceDeletion(false);
          }}
        />
        <ModalBody pb={6}>
          <Text>
            Are you sure you want to delete source : {source.description} ?
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={() => {
              setShowSourceDeletion(false);
            }}
          >
            Cancel
          </Button>
          <Button
            colorScheme="red"
            ml={3}
            onClick={() => onConfirm()}
            isLoading={isLoading}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
