import { ExpertLetter } from "../clients/clientsSlice";
import { IndividualReducer } from "./individualSlice";

const selectAll = (state: IndividualReducer) => state.individual;
const selectFirstName = (state: IndividualReducer) =>
  selectAll(state).firstName;
const selectLastName = (state: IndividualReducer) => selectAll(state).lastName;
const selectMiddleName = (state: IndividualReducer) =>
  selectAll(state).middleName;
const selectDob = (state: IndividualReducer) => selectAll(state).dob;
const selectEmail = (state: IndividualReducer) => selectAll(state).email;
const selectCompany = (state: IndividualReducer) => selectAll(state).company;
const selectVisaType = (state: IndividualReducer) => selectAll(state).visaType;
const selectAvatarSrc = (state: IndividualReducer) =>
  selectAll(state).avatarSrc;
const selectAvatarName = (state: IndividualReducer) =>
  selectAll(state).avatarName;
const selectUid = (state: IndividualReducer) => selectAll(state).uid;
const selectOverview = (state: IndividualReducer) => selectAll(state).overview;
const selectActiveExpertLetter = (state: IndividualReducer) =>
  selectAll(state).activeExpertLetter;
const selectExpertLetters = (state: IndividualReducer) =>
  selectAll(state).questionnaireLetter;
const selectExpertsDrafts = (state: IndividualReducer) =>
  selectAll(state).expertsDrafts;
const selectExpertById = (state: IndividualReducer, id: string) =>
  selectAll(state).questionnaireLetter?.find((el) => el.uid === id) as ExpertLetter;

// New fields added
const selectPronouns = (state: IndividualReducer) => selectAll(state).pronouns;
const selectOtherPronouns = (state: IndividualReducer) =>
  selectAll(state).otherPronouns;
const selectUsAddressInCareOf = (state: IndividualReducer) =>
  selectAll(state).usAddressInCareOf;
const selectUsAddressStreetNumber = (state: IndividualReducer) =>
  selectAll(state).usAddressStreetNumber;
const selectUsAddressStreetName = (state: IndividualReducer) =>
  selectAll(state).usAddressStreetName;
const selectUsAddressType = (state: IndividualReducer) =>
  selectAll(state).usAddressType;
const selectUsAddressAptSteFloor = (state: IndividualReducer) =>
  selectAll(state).usAddressAptSteFloor;
const selectUsAddressCityOrTown = (state: IndividualReducer) =>
  selectAll(state).usAddressCityOrTown;
const selectUsAddressState = (state: IndividualReducer) =>
  selectAll(state).usAddressState;
const selectUsAddressProvince = (state: IndividualReducer) =>
  selectAll(state).usAddressProvince;
const selectUsAddressZipCode = (state: IndividualReducer) =>
  selectAll(state).usAddressZipCode;
const selectForeignAddressInCareOf = (state: IndividualReducer) =>
  selectAll(state).foreignAddressInCareOf;
const selectForeignAddressStreetNumber = (state: IndividualReducer) =>
  selectAll(state).foreignAddressStreetNumber;
const selectForeignAddressStreetName = (state: IndividualReducer) =>
  selectAll(state).foreignAddressStreetName;
const selectForeignAddressType = (state: IndividualReducer) =>
  selectAll(state).foreignAddressType;
const selectForeignAddressAptSteFloor = (state: IndividualReducer) =>
  selectAll(state).foreignAddressAptSteFloor;
const selectForeignAddressCityOrTown = (state: IndividualReducer) =>
  selectAll(state).foreignAddressCityOrTown;
const selectForeignAddressState = (state: IndividualReducer) =>
  selectAll(state).foreignAddressState;
const selectForeignAddressProvince = (state: IndividualReducer) =>
  selectAll(state).foreignAddressProvince;
const selectForeignAddressPostalCode = (state: IndividualReducer) =>
  selectAll(state).foreignAddressPostalCode;
const selectHasAcceptedTerms = (state: IndividualReducer) =>
  selectAll(state).hasAcceptedTerms;

export const individualSelectors = {
  selectAll,
  selectFirstName,
  selectMiddleName,
  selectLastName,
  selectDob,
  selectEmail,
  selectCompany,
  selectVisaType,
  selectAvatarSrc,
  selectAvatarName,
  selectUid,
  selectOverview,
  selectActiveExpertLetter,
  selectExpertLetters,
  selectExpertById,
  selectExpertsDrafts,
  selectPronouns,
  selectOtherPronouns,
  selectUsAddressInCareOf,
  selectUsAddressStreetNumber,
  selectUsAddressStreetName,
  selectUsAddressType,
  selectUsAddressAptSteFloor,
  selectUsAddressCityOrTown,
  selectUsAddressState,
  selectUsAddressProvince,
  selectUsAddressZipCode,
  selectForeignAddressInCareOf,
  selectForeignAddressStreetNumber,
  selectForeignAddressStreetName,
  selectForeignAddressType,
  selectForeignAddressAptSteFloor,
  selectForeignAddressCityOrTown,
  selectForeignAddressState,
  selectForeignAddressProvince,
  selectForeignAddressPostalCode,
  selectHasAcceptedTerms,
};
