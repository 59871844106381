import { PropsWithChildren, useCallback } from "react";
import { TargetBox } from "./TargetBox";
import { FileList } from "./FileList";

type InputDnDProps = {
  files: FileList | null;
  loadFiles: (files: FileList) => void;
} & PropsWithChildren;

export const InputDND = ({ files, loadFiles }: InputDnDProps) => {
  const handleFileDrop = useCallback(
    (item: DataTransfer) => {
      if (item) {
        const { files } = item;
        loadFiles(files);
      }
    },
    [loadFiles]
  );
  return (
    <>
      <TargetBox onDrop={handleFileDrop} />
      <FileList files={files} />
    </>
  );
};
