import { useState } from "react";
import { openai } from "../api/OpenAIApi";

export const useBriefBlurbs = () => {
  const [isLoadingBriefBlurbs, setIsLoadingBriefBlurbs] = useState(false);

  const [apiResponseBriefBlurbs, setApiResponseBriefBlurbs] = useState<
    string | null
  >(null);

  const onSubmitBriefBlurbs = async (
    visaType: string,
    argumentsInfo: string
  ) => {
    const briefInstruction = `Your are an immigration lawyer writing a portion of the legal brief for a ${visaType}.\
    Using the below arguments, write a draft making a compelling argumentation as to why this person meets the eligibility criteria.\
    Do not just list facts, make sure you explain the reason why this person qualifies for the ${visaType}.\
    Break down your reponse by criteria. For example, the three prong, if this is for the NIW.\
    Finish your sentences.\
    Arguments: ${argumentsInfo}`;

    setIsLoadingBriefBlurbs(true);
    try {
      const result = await openai.createChatCompletion({
        model: "gpt-4",
        messages: [{ role: "user", content: briefInstruction }],
        temperature: 0
      });
      setApiResponseBriefBlurbs(result.data.choices[0].message?.content || "");
    } catch (e) {
      setApiResponseBriefBlurbs("Something went wrong, Please try again.");
    } finally {
      setIsLoadingBriefBlurbs(false);
    }
  };

  return {
    isLoadingBriefBlurbs,
    onSubmitBriefBlurbs,
    apiResponseBriefBlurbs
  };
};
