import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Card,
  Flex,
  FormLabel,
  Text,
  Textarea,
  useToast
} from "@chakra-ui/react";
import { serverTimestamp } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useEvidenceProngs, { Source } from "../../../hooks/useEvidenceProngs";
import { individualSelectors } from "../../../redux/individual/selectors";
import { DATABASE, VISAVALUE, visaFromPath } from "../../../types/tables-data";
import { CustomBox } from "../../common/CustomBox";
import { AddSourceDialog } from "./evidenceQuestions/AddSourceDialog";
import { DeleteSourceDialog } from "./evidenceQuestions/DeleteSourceDialog";
import { VisaQuestionsForm } from "./VisaQuestionsForm";

export const EvidenceQuestions = () => {
  const { id, visaType } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const [defaultIndex, setDefaultIndex] = useState<
    number | undefined | number[]
  >(0);
  const [index, setIndex] = useState<number[]>([0]);
  const [showSourceDialog, setShowSourceDialog] = useState<boolean>(false);
  const [showSourceDeletion, setShowSourceDeletion] = useState<boolean>(false);
  const [sourceToDelete, setSourceToDelete] = useState<Source | null>(null);
  const uid = id ?? useSelector(individualSelectors.selectUid);
  const {
    deleteSource,
    deleteLoading,
    deleteError,
    getLargestIndex,
    fetchData,
    addSourcesToProng1,
    addSourcesLoading,
    prongs,
    saveData,
    updateField,
    loading,
    error
  } = useEvidenceProngs(uid);

  const [prong1Data, setProng1Data] = useState({
    areaOfExpertise: "",
    specialization: "",
    summary: "",
    description: "",
    sources: []
  });

  const [prong2Data, setProng2Data] = useState({
    qualifications: "",
    pastAchievements: "",
    impactOnField: "",
    workUtilization: "",
    futurePlans: ""
  });

  const [prong3Data, setProng3Data] = useState({
    laborWaiverExplanation: ""
  });

  useEffect(() => {
    if (prongs !== null) {
      setProng1Data({
        areaOfExpertise: prongs.prong1Data?.areaOfExpertise || "",
        specialization: prongs.prong1Data?.specialization || "",
        summary: prongs.prong1Data?.summary || "",
        description: prongs.prong1Data?.description || "",
        sources: prongs.prong1Data?.sources || []
      });

      setProng2Data({
        qualifications: prongs.prong2Data?.qualifications || "",
        pastAchievements: prongs.prong2Data?.pastAchievements || "",
        impactOnField: prongs.prong2Data?.impactOnField || "",
        workUtilization: prongs.prong2Data?.workUtilization || "",
        futurePlans: prongs.prong2Data?.futurePlans || ""
      });

      setProng3Data({
        laborWaiverExplanation: prongs.prong3Data?.laborWaiverExplanation || ""
      });
    }
  }, [prongs]);

  const handleSubmit = async () => {
    const dataToSave = {
      prong1Data,
      prong2Data,
      prong3Data
    };

    try {
      await saveData(`${DATABASE.QUESTIONNAIRES}/${uid}/`, dataToSave);

      toast({
        title: "Success",
        description: " Information saved successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right"
      });
      setIndex([]);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleAccordionChange = (expandedIndex: number[]) => {
    setIndex(expandedIndex);
  };
  const handleAddSource = async (data: any) => {
    try {
      const largestIndex = await getLargestIndex();
      data.exhibit = largestIndex + 1;
      data.createdAt = serverTimestamp();
      await addSourcesToProng1([data]);
      await fetchData();

      toast({
        title: "Success",
        description: "Source added successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right"
      });
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleDeleteSource = async (sourceId: string) => {
    try {
      await deleteSource(sourceId);
      await fetchData();

      // If no error occurred, display a success toast
      toast({
        title: "Success",
        description: "Source added successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right"
      });
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const openDeleteConfirmation = (source: Source) => {
    setSourceToDelete(source);
    setShowSourceDeletion(true);
  };

  const isEB2 = visaType ? visaFromPath[visaType] === VISAVALUE.EB2NIW : false;

  const eb2 = () => {
    return (
      <Card ml="0">
        <Accordion allowMultiple index={index} onChange={handleAccordionChange}>
          {/* Prong 1 */}
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Text> Prong 1 questions</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel p={10}>
              {/* Prong 1 questions */}

              <FormLabel mb={2} fontWeight={700}>
                What is the applicant's area of expertise?
              </FormLabel>
              <Text fontSize="sm" color="gray.500" mb={2} as="i">
                For example: Data Science, Machine Learning Engineering,
                Biomedical Engineering, Biological Sciences
              </Text>
              <Textarea
                placeholder=""
                value={prong1Data.areaOfExpertise}
                onChange={(e) =>
                  setProng1Data({
                    ...prong1Data,
                    areaOfExpertise: e.target.value
                  })
                }
                mb={10}
              />

              <FormLabel mb={2} fontWeight={700}>
                Does the applicant have a specialization area within their area
                of expertise?
              </FormLabel>
              <Text fontSize="sm" color="gray.500" mb={2} as="i">
                For example: Healthcare Data Science, Natural Language
                Processing, Genetics
              </Text>
              <Textarea
                placeholder=""
                value={prong1Data.specialization}
                onChange={(e) =>
                  setProng1Data({
                    ...prong1Data,
                    specialization: e.target.value
                  })
                }
                mb={10}
              />

              <FormLabel mb={2} fontWeight={700}>
                Summarize the applicant's proposed endeavor in 1-2 sentences
              </FormLabel>
              <Text fontSize="sm" color="gray.500" mb={2} as="i">
                For example: The applicant's proposed endeavor is to advance the
                field of soil science and its role in maximizing crop
                production, food and water security, and greenhouse gas
                emissions.
              </Text>
              <Textarea
                placeholder=""
                value={prong1Data.summary}
                onChange={(e) =>
                  setProng1Data({
                    ...prong1Data,
                    summary: e.target.value
                  })
                }
                mb={10}
              />

              <FormLabel mb={2} fontWeight={700}>
                Describe the substantial merit and national importance of the
                proposed endeavor.
              </FormLabel>
              <Text fontSize="sm" color="gray.500" mb={2} as="i">
                Provide a detailed description of the applicant's work and its
                national importance. Describe the applicant's specific projects
                and goals rather than just listing the general job duties of the
                applicant's position. Use lay terms and describe any complicated
                concepts.
              </Text>
              <Textarea
                placeholder=""
                value={prong1Data.description}
                onChange={(e) =>
                  setProng1Data({
                    ...prong1Data,
                    description: e.target.value
                  })
                }
                mb={10}
              />
              {/* 
              <FormLabel mb={2} fontWeight={700}>
                Our AI tool will search the web for additional context about the
                applicant's area of expertise. You have the option to add
                sources that you think demonstrate the importance of the
                applicant's field. Please cite external sources, share links, or
                provide economic impact statistics below.
              </FormLabel>
              <TableContainer w="90%">
                <Table
                  variant="simple"
                  p="15px"
                  __css={{
                    width: "100%",
                    wordWrap: "break-word",
                    whiteSpace: "normal"
                  }}
                >
                  <Thead>
                    <Tr>
                      <Th>Document Type</Th>
                      <Th>Description</Th>
                      <Th>Date</Th>
                      <Th>Exhibit</Th>
                    </Tr>
                  </Thead>
                  <tbody>
                    {prong1Data.sources.map((source: Source, index: number) => (
                      <Tr
                        cursor="pointer"
                        key={index}
                        onClick={() => {
                          // Open the link in a new window/tab
                          if (source.link) {
                            window.open(source.link, "_blank");
                          }
                        }}
                      >
                        <Td>{source.type}</Td>
                        <Td>{source.description}</Td>
                        <Td>{dayjs(source.date).format("YYYY-MM-DD")}</Td>
                        <Td>{source.exhibit}</Td>
                        <Td>
                          <Flex flexGrow="0" gap="15px">
                            <DeleteIcon
                              cursor="pointer"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent the click event on the delete icon from propagating to the row
                                if (source && source.id) {
                                  openDeleteConfirmation(source);
                                }
                              }}
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </tbody>
                </Table>
              </TableContainer>

              <Flex w="100%" justifyContent="center" my={6}>
                <Button
                  my={4}
                  className="btn primary-btn"
                  mx="15px"
                  type="button"
                  onClick={() => setShowSourceDialog(true)}
                  isLoading={addSourcesLoading}
                >
                  Add source
                </Button>
              </Flex> */}
            </AccordionPanel>
          </AccordionItem>

          {/* Prong 2 */}
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Prong 2 questions
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel p={10}>
              {/* Prong 2 questions */}
              <FormLabel htmlFor="qualifications" fontWeight={700}>
                Describe the applicant's qualifications
              </FormLabel>

              <Textarea
                id="qualifications"
                placeholder=""
                value={prong2Data.qualifications}
                onChange={(e) =>
                  setProng2Data({
                    ...prong2Data,
                    qualifications: e.target.value
                  })
                }
                mb={10}
              />

              <FormLabel htmlFor="pastAchievements" fontWeight={700}>
                How do the applicant's past achievements make them qualified to
                improve on the applicant's proposed work?
              </FormLabel>
              <Textarea
                id="pastAchievements"
                placeholder=""
                value={prong2Data.pastAchievements}
                onChange={(e) =>
                  setProng2Data({
                    ...prong2Data,
                    pastAchievements: e.target.value
                  })
                }
                mb={10}
              />

              <FormLabel htmlFor="impactOnField" fontWeight={700}>
                What impact has the applicant's past work already had in the
                field?
              </FormLabel>
              <Textarea
                id="impactOnField"
                placeholder=""
                value={prong2Data.impactOnField}
                onChange={(e) =>
                  setProng2Data({
                    ...prong2Data,
                    impactOnField: e.target.value
                  })
                }
                mb={10}
              />

              <FormLabel htmlFor="workUtilization" fontWeight={700}>
                How are others using the applicant's work?
              </FormLabel>
              <Textarea
                id="workUtilization"
                placeholder=""
                value={prong2Data.workUtilization}
                onChange={(e) =>
                  setProng2Data({
                    ...prong2Data,
                    workUtilization: e.target.value
                  })
                }
                mb={10}
              />

              <FormLabel htmlFor="futurePlans" fontWeight={700}>
                What are the applicant's future plans to advance their field of
                work?
              </FormLabel>
              <Textarea
                id="futurePlans"
                placeholder=""
                value={prong2Data.futurePlans}
                onChange={(e) =>
                  setProng2Data({
                    ...prong2Data,
                    futurePlans: e.target.value
                  })
                }
                mb={10}
              />
            </AccordionPanel>
          </AccordionItem>

          {/* Prong 3 */}
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Prong 3 questions
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel p={10}>
              {/* Prong 3 questions */}
              <FormLabel htmlFor="laborWaiverExplanation" fontWeight={700}>
                Waiver of labor certification
              </FormLabel>
              <Text fontSize="sm" color="gray.500" mb={2} as="i">
                Write a paragraph explaining why it would be beneficial to the
                U.S. to waive the requirements of a job offer and thus of a
                labor certification. Note if there are any urgent
                considerations, if there is a potential for job creation, or if
                the applicant's role is especially unique and a labor
                certification is impractical.
              </Text>
              <Textarea
                id="laborWaiverExplanation"
                placeholder=""
                value={prong3Data.laborWaiverExplanation}
                onChange={(e) =>
                  setProng3Data({
                    ...prong3Data,
                    laborWaiverExplanation: e.target.value
                  })
                }
                mb={10}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Flex w="100%" justifyContent="center" my={6}>
          <Button
            my={4}
            className="btn primary-btn"
            mx="15px"
            type="button"
            onClick={handleSubmit}
            isLoading={loading}
          >
            Save
          </Button>
        </Flex>
        {showSourceDeletion && sourceToDelete && (
          <DeleteSourceDialog
            source={sourceToDelete}
            onConfirm={() => {
              if (sourceToDelete.id) {
                handleDeleteSource(sourceToDelete.id);
                setShowSourceDeletion(false);
              }
            }}
            setShowSourceDeletion={setShowSourceDeletion}
            isLoading={deleteLoading}
          />
        )}
        {showSourceDialog && (
          <AddSourceDialog
            onConfirm={(data) => {
              handleAddSource(data);
              setShowSourceDialog(false);
            }}
            setShowSourceDialog={setShowSourceDialog}
            isLoading={addSourcesLoading}
          />
        )}
      </Card>
    );
  };

  const questionsSection = () => {
    const renderCustomBox = (
      <CustomBox type="warning">
        <Text fontSize="18px">
          <b>Update</b>: Expert intake was moved to Drafts. Navigate to the
          Drafts tab, and click <b>Generate</b> to start an expert letter.
        </Text>
      </CustomBox>
    );

    // Conditional rendering logic
    let content = null;

    if (visaType == null) {
      content = null; // Render nothing if visaType is null
    } else if (isEB2) {
      content = eb2(); // Render the EB2 component or function if isEB2 is true
    } else {
      content = <VisaQuestionsForm visaType={visaFromPath[visaType]} />; // Render VisaQuestionsForm for other visa types
    }

    return (
      <>
        {renderCustomBox}
        {content}
      </>
    );
  };

  return questionsSection();
};
