import { Auth } from "firebase/auth";

class SessionService {
  private auth: Auth;

  private onSubmitLogOut: () => void;

  private inactivityTime: number;

  private activityHandler: () => void;

  private logoutTimer: NodeJS.Timeout | null;
  
  private unsubscribe: (() => void) | null;

  constructor(auth: Auth, onSubmitLogOut: () => void, inactivityTime = 1200000) {
    this.auth = auth;
    this.onSubmitLogOut = onSubmitLogOut;
    this.inactivityTime = inactivityTime;
    this.activityHandler = this.resetLogoutTimer.bind(this);
    this.logoutTimer = null;
    this.unsubscribe = null;
  }

  private resetLogoutTimer() {
    if (this.logoutTimer) {
      clearTimeout(this.logoutTimer);
    }
    this.logoutTimer = setTimeout(() => {
      this.onSubmitLogOut();
    }, this.inactivityTime);
  }

  public startSessionCheck() {
    this.unsubscribe = this.auth.onAuthStateChanged((user) => {
      if (user) {
        this.resetLogoutTimer();
        document.addEventListener("mousemove", this.activityHandler);
        document.addEventListener("keypress", this.activityHandler);
      } else {
        this.stopSessionCheck(); // Stop session check if user logs out
      }
    });
  }

  public stopSessionCheck() {
    if (this.unsubscribe) {
      this.unsubscribe();
      this.unsubscribe = null; // Clear unsubscribe after calling it
    }
    if (this.logoutTimer) {
      clearTimeout(this.logoutTimer);
      this.logoutTimer = null; // Clear the timer reference after stopping it
    }
    document.removeEventListener("mousemove", this.activityHandler);
    document.removeEventListener("keypress", this.activityHandler);
  }
}

export default SessionService;
