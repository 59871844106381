import React, { useState, useEffect } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Input,
  Checkbox
} from "@chakra-ui/react";
import { MdAddCircle } from "react-icons/md";

interface Props {
  title: string;
  onItemSelect: (values: any[]) => void;
  list: any[];
  selectedValues: any[];
  isDisabled?: boolean;
}

const DropdownMulti = ({
  title,
  onItemSelect,
  list,
  selectedValues,
  isDisabled
}: Props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  const filteredList = list.filter((item) => {
    if (typeof item === "object" && item.title) {
      return item.title.toLowerCase().includes(searchTerm.toLowerCase());
    }
    if (typeof item === "string") {
      return item.toLowerCase().includes(searchTerm.toLowerCase());
    }
    if (typeof item === "number") {
      return item.toString().includes(searchTerm);
    }
    return false;
  });

  useEffect(() => {
    if (initialLoad && list.length > 0) {
      onItemSelect(list);
      setInitialLoad(false);
    }
  }, [initialLoad, list, onItemSelect]);

  const handleItemClick = (item: any, event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const selectedIndex = selectedValues.indexOf(item);
    let newSelected = selectedValues.filter(
      (val, index) => index !== selectedIndex
    );

    if (selectedIndex === -1) {
      newSelected = [...selectedValues, item];
    }

    onItemSelect(newSelected);
  };

  const handleSelectAll = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (selectedValues.length === filteredList.length) {
      onItemSelect([]);
    } else {
      onItemSelect([...filteredList]);
    }
  };

  const handleMenuToggle = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  const handleMenuClose = () => {
    setMenuIsOpen(false);
  };

  const renderButtonLabel = () => {
    if (selectedValues.length === filteredList.length) {
      return "Select All";
    }
    if (selectedValues.length > 0) {
      return selectedValues.map((item, index) => (
        <span key={index}>
          {typeof item === "object" ? item.title : item}
          {index < selectedValues.length - 1 ? ", " : ""}
        </span>
      ));
    }
    return title;
  };

  return (
    <Menu isOpen={menuIsOpen} onClose={handleMenuClose}>
      <MenuButton
        as={Button}
        bg="primary.darkBlue"
        color="white"
        isDisabled={isDisabled}
        leftIcon={<MdAddCircle />}
        variant="outline"
        className="w-fit overflow-auto"
        onClick={handleMenuToggle}
      >
        {renderButtonLabel()}
      </MenuButton>
      <MenuList
        px={2}
        maxHeight={300}
        overflowY="auto"
        overflowX="scroll"
        sx={{
          "&::-webkit-scrollbar": {
            width: "10px"
          },
          "&::-webkit-scrollbar-track": {
            background: "white"
          },
          "&::-webkit-scrollbar-thumb": {
            background: "gray",
            borderRadius: "8px"
          }
        }}
      >
        <Input
          autoFocus
          placeholder="Search..."
          variant="filled"
          onChange={(e) => setSearchTerm(e.target.value)}
          my={2}
        />
        <MenuItem onClick={(e) => handleSelectAll(e)} closeOnSelect={false}>
          <Checkbox
            isChecked={
              selectedValues.length === filteredList.length &&
              selectedValues.length > 0
            }
          >
            Select All
          </Checkbox>
        </MenuItem>
        {filteredList.map((item, index) => (
          <MenuItem
            key={index}
            onClick={(e) => handleItemClick(item, e)}
            closeOnSelect={false}
          >
            <Checkbox isChecked={selectedValues.indexOf(item) !== -1}>
              {typeof item === "object" ? item.title : item}
            </Checkbox>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default DropdownMulti;
