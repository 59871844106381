// /src/services/toast/toastManager.ts

import { createStandaloneToast } from "@chakra-ui/react";
import { DATABASE } from "../../types/tables-data";
import {
  ActionType,
  getDatabaseEntityActionMessage
} from "./toastConfiguration";

type ToastType = "success" | "info" | "warning";

const toastConfigs: Record<
  ToastType,
  { status: "success" | "info" | "warning"; colorScheme?: string }
> = {
  success: { status: "success", colorScheme: "green" },
  info: { status: "info", colorScheme: "blue" },
  warning: { status: "warning", colorScheme: "red" }
};

class ToastManager {
  private static instance: ToastManager;

  private toast = createStandaloneToast();

  static getInstance(): ToastManager {
    if (!ToastManager.instance) {
      ToastManager.instance = new ToastManager();
    }
    return ToastManager.instance;
  }

  showToast(documentName: DATABASE, type: ToastType, action: ActionType): void {
    const message =
      type === "warning"
        ? "Something went wrong"
        : getDatabaseEntityActionMessage(documentName, action);
    const config = toastConfigs[type];

    this.toast.toast({
      title: message,
      status: config.status,
      colorScheme: config.colorScheme,
      duration: 2000,
      isClosable: true
    });
  }
}

export { ToastManager, type ToastType };
