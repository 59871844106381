import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  updateDoc
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { db } from "../api/firebaseApi";
import {
  CaseResearchTopic,
  ExtractedResearchData,
  removeResearchTopic,
  setExtractedData,
  setResearchTopics
} from "../redux/case-research/caseResearchSlice";
import { DATABASE } from "../types/tables-data";
import {
  autoGenerateTopics,
  generateCaseResearchSummary
} from "../api/OpenAIApi";

const useManageCaseResearch = (userId?: string | null) => {
  const dispatch = useDispatch();
  // const [researchTopics, setResearchTopics] = useState<CaseResearchTopic[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!userId) {
      setLoading(false);
      return () => {}; // Return an empty cleanup function if no userId
    }

    const unsubscribeTopics = onSnapshot(
      collection(db, DATABASE.CASE_RESEARCH, userId, "research_topics"),
      (snapshot) => {
        const topics: CaseResearchTopic[] = snapshot.docs.map((doc) => ({
          ...(doc.data() as CaseResearchTopic),
          id: doc.id
        }));
        dispatch(setResearchTopics(topics));
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching case research topics:", error);
        setLoading(false);
      }
    );

    const fetchExtractedData = async () => {
      const extractedDocRef = doc(db, DATABASE.CASE_RESEARCH, userId);
      const extractedDoc = await getDoc(extractedDocRef);

      if (extractedDoc.exists()) {
        const data = extractedDoc.data() as ExtractedResearchData;
        setExtractedData(data);
      } else {
        setExtractedData(null);
      }
    };

    fetchExtractedData();

    return () => unsubscribeTopics();
  }, [userId]);

  // const addResearchTopic = async (newTopic: CaseResearchTopic) => {
  //     if (!userId) return;
  //     try {
  //         await addDoc(collection(db, `${DATABASE.CASE_RESEARCH}/${userId}/research_topics`), newTopic);
  //         setResearchTopics(prev => [...prev, newTopic]);
  //     } catch (e) {
  //         setError(e as Error);
  //     }
  // };

  // const updateResearchTopic = async (topicId: string, updatedTopic: Partial<CaseResearchTopic>) => {
  //     if (!userId) return;
  //     try {
  //         const topicRef = doc(db, `${DATABASE.CASE_RESEARCH}/${userId}/research_topics`, topicId);
  //         await updateDoc(topicRef, updatedTopic);
  //         setResearchTopics(prev => prev.map(topic => topic.id === topicId ? { ...topic, ...updatedTopic } : topic));
  //     } catch (e) {
  //         setError(e as Error);
  //     }
  // };

  const onSaveCaseResearchTopic = async (
    topicId: string,
    updatedSummary: string,
    updatedSummaryHtml: string
  ) => {
    if (!userId) return;
    try {
      const updateToMake = {
        summary: updatedSummary || null,
        summaryHtml: updatedSummaryHtml || null
      };

      const topicRef = doc(
        db,
        `${DATABASE.CASE_RESEARCH}/${userId}/research_topics`,
        topicId
      );

      await updateDoc(topicRef, updateToMake);
    } catch (e) {
      setError(e as Error);
    }
  };

  const deleteResearchTopic = async (topicId: string) => {
    if (!userId) return;
    try {
      const topicRef = doc(
        db,
        `${DATABASE.CASE_RESEARCH}/${userId}/research_topics`,
        topicId
      );
      await deleteDoc(topicRef);
      removeResearchTopic(topicId);
    } catch (e) {
      setError(e as Error);
    }
  };

  const generateSummary = async () => {
    generateCaseResearchSummary(userId!);
  };

  const generateTopics = async () => {
    await autoGenerateTopics(userId!);
  };

  return {
    loading,
    error,
    generateSummary,
    generateTopics,
    deleteResearchTopic,
    onSaveCaseResearchTopic
  };
};

export default useManageCaseResearch;
