import {
  Box,
  Card,
  Flex,
  Heading,
  List,
  ListItem,
  Text
} from "@chakra-ui/react";
import { getDownloadURL, getStorage, listAll, ref } from "firebase/storage";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { visaFromPath } from "../../types/tables-data";

export const IndividualTutorials = () => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [videos, setVideos] = useState<string[]>([]);
  const {visaType} = useParams();

  useEffect(() => {
    const fetchVideoUrls = async () => {
      if (!visaType) return;

      const visa = visaFromPath[visaType];

      const storage = getStorage();
      const videosRef = ref(storage, `tutorials/${visa}`);
      console.log("ref" ,videosRef);

      try {
        const result = await listAll(videosRef);
        const urlPromises = result.items.map((itemRef) =>
          getDownloadURL(itemRef)
        );
        const urls = await Promise.all(urlPromises);
        setVideos(urls);
      } catch (error) {
        console.error("Error fetching video URLs", error);
      }
    };

    fetchVideoUrls();
  }, [visaType]);

  const handleListItemClick = (index: number) => {
    setCurrentVideoIndex(index);
  };

  const isCurrentVideo = (index: number) => currentVideoIndex === index;

  return (
    <Flex flexDirection="column" rowGap={6}>
      <Card ml="0">
        <Box py="15px" width="100%">
          <Heading size="md" textAlign="center">
            Section {currentVideoIndex + 1}
          </Heading>
        </Box>

        {/* Video Player */}
        {videos.length > 0 && (
          <video key={videos[currentVideoIndex]} width="100%" controls>
            <source src={videos[currentVideoIndex]} type="video/mp4" />
            <track
              src="path_to_caption_file.vtt" // Update with your caption file path
              kind="captions"
              srcLang="en"
              label="English"
              default
            />
            Your browser does not support the video tag.
          </video>
        )}

        <List className="flex gap-2 justify-center p-4">
          {videos.map((video, index) => (
            <ListItem
              className="p-4 rounded-md cursor-pointer"
              key={index}
              bg={isCurrentVideo(index) ? "primary.red" : "secondary.lightGray"}
              color="white"
              onClick={() => handleListItemClick(index)}
            >
              <Text>Section {index + 1}</Text>
            </ListItem>
          ))}
        </List>
      </Card>
    </Flex>
  );
};
