import { Image } from "@chakra-ui/react";
import { useRef } from "react";
import images from "../assets/image-export-assets";

interface FileUploaderProps {
  title: string;
  subTitle: string;
  onUpload: (files: FileList | null) => void;
  withImg?: boolean;
}

export const UploadFilesPlaceholder = ({
  title,
  subTitle,
  onUpload,
  withImg = false
}: FileUploaderProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  return (
    <div className="border-2 border-dashed p-4 flex flex-col gap-2 justify-center items-center">
      {withImg && <Image src={images.fileupload} height={150} />}
      <p>{title ?? ""}</p>
      <p
        onClick={() => {
          fileInputRef?.current?.click();
        }}
        className="font-normal cursor-pointer"
        style={{ color: "darkblue" }}
      >
        {subTitle ?? ""}
      </p>
      <input
        onChange={(e) => onUpload(e.target.files)}
        className="hidden"
        multiple
        type="file"
        ref={fileInputRef}
      />
    </div>
  );
};
