import {
  Box,
  Button,
  Card,
  Flex,
  Heading,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { clickedStyles } from "../styles/common-styles";
import images from "../assets/image-export-assets";
import { db } from "../api/firebaseApi";
import { DATABASE } from "../types/tables-data";
import { archiveClientCase, deleteCase } from "../redux/clients/clientsSlice";

interface ArchiveDeleteData {
  index: number;
}

export const ArchiveDeleteCase = ({ index }: ArchiveDeleteData) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [caseAction, setCaseAction] = useState("");
  const params = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleArchiveCase = async () => {
    if (params.caseID) {
      try {
        const clientRef = doc(db, DATABASE.CLIENTS, `${params.caseID}`);

        await updateDoc(clientRef, {
          isActive: false
        });

        dispatch(archiveClientCase({ index, isActive: false }));
      } catch (e) {
        console.error("Error is:", e);
      } finally {
        onClose();
        navigate("/cases");
      }
    }
  };

  const handleDeleteCase = async () => {
    if (params.caseID) {
      try {
        await deleteDoc(doc(db, DATABASE.CLIENTS, `${params.caseID}`));
        dispatch(deleteCase(index));
        onClose();
        navigate("/cases");
      } catch (e) {
        console.error("Error is:", e);
      }
    }
  };
  return (
    <>
      <Card my="15px">
        <Box py="15px" sx={clickedStyles}>
          <Heading size="md">Archive or delete</Heading>
        </Box>
        <Flex justifyContent="center" gap="25px" pb="15px">
          <Flex
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            gap="5px"
            mt="15px"
          >
            <Image
              boxSize="30px"
              objectFit="contain"
              src={images.archive}
              alt="Archive"
            />
            <Button
              onClick={() => {
                setCaseAction("archive");
                onOpen();
              }}
            >
              Archive case
            </Button>
          </Flex>
          <Flex
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            gap="5px"
            mt="15px"
          >
            <Image
              boxSize="30px"
              objectFit="contain"
              src={images.deleteCase}
              alt="Delete"
            />
            <Button
              onClick={() => {
                setCaseAction("delete");
                onOpen();
              }}
            >
              Delete case
            </Button>
          </Flex>
        </Flex>
      </Card>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {`Would you like to ${caseAction} this case?`}
            <br />
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} mr={3}>
              Cancel
            </Button>
            {caseAction === "archive" ? (
              <Button colorScheme="orange" mr={3} onClick={handleArchiveCase}>
                Archive
              </Button>
            ) : (
              <Button colorScheme="red" mr={3} onClick={handleDeleteCase}>
                Delete
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
