import { countryListAllIsoData } from "../../data/countries";

export const AllCountriesOptions = () => {
  return (
    <>
      <option value="">Select country</option>
      {countryListAllIsoData.map((country) => (
        <option key={country.code} value={country.name}>
          {country.name}
        </option>
      ))}
    </>
  );
};
