import {
  Box,
  Button,
  Card,
  Checkbox,
  Flex,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  List,
  ListIcon,
  ListItem,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  UnorderedList,
  Text
} from "@chakra-ui/react";
import { CheckCircleIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import dayjs from "dayjs";
import { useState, useRef, useEffect, useMemo } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch, useSelector } from "react-redux";
import { getDownloadURL } from "firebase/storage";
import { DATABASE, DataDocs, FORMDATA } from "../types/tables-data";
import images from "../assets/image-export-assets";
import { InputDND } from "./inputDnD/InputDND";
import { useAddFileToDB } from "../hooks/useAddFileToDB";
import { useGetFilesFromDB } from "../hooks/useGetFilesFromDB";
import { lawyerSelectors } from "../redux/lawyer/selectors";
import { clickedStyles } from "../styles/common-styles";
import { useAddNewClientDocument } from "../hooks/useAddNewClientDocument";
import { clientsSelectors } from "../redux/clients/selectors";
import { useDeleteFileFromDB } from "../hooks/useDeleteFileFromDB";
import { useDeleteClientDocument } from "../hooks/useDeleteClientDocument";
import { useGetFormsData } from "../hooks/useGetFormsData";
import {
  ExpertLetter,
  SecondForm,
  updateQuestionnaireLetter
} from "../redux/clients/clientsSlice";
import { useDocumentAI } from "../hooks/useDocumentAI";
import { useConvertFileTo64String } from "../hooks/useConvertFileTo64String";
import { getMimeType } from "../helpers/file_helpers";
import { supportedExtensionsString } from "../constans";

type CaseIdDocsProps = {
  visaType: string;
  data: DataDocs[];
  clientId: string;
  onSubmitGetClientDocs: () => Promise<void>;
  index: number;
};

export const CaseIdDocuments = ({
  visaType,
  data,
  clientId,
  onSubmitGetClientDocs,
  index
}: CaseIdDocsProps) => {
  const [droppedFiles, setDroppedFiles] = useState<FileList | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const [isLoadingDoc, setIsLoadingDoc] = useState(false);
  const [loadingDocTitle, setLoadingDocTitle] = useState("");
  const [loadingDocTitleInput, setLoadingDocTitleInput] = useState("");
  const openLoadDocHandler = () => {
    setIsLoadingDoc(!isLoadingDoc);
    setDroppedFiles(null);
    setSelectedFile(null);
  };

  const firstName = useSelector(lawyerSelectors.selectFirstName);
  const lastName = useSelector(lawyerSelectors.selectLastName);
  const uid = useSelector(lawyerSelectors.selectUid);
  const mail = useSelector(clientsSelectors.selectAll)[index].email;

  const { onSubmitAddFile, fileRef, isLoadingAddFile } = useAddFileToDB();
  const { onSubmitGetFiles, storageFiles } = useGetFilesFromDB();
  const { onSubmitAddUserDocuments } = useAddNewClientDocument();

  const { formsData } = useGetFormsData();

  const loadingDocsHandler = (files: FileList) => {
    setDroppedFiles(files);
  };

  const buttonCancelRef = useRef<HTMLButtonElement | null>(null);

  const { file64string, logImagesData } = useConvertFileTo64String();

  useEffect(() => {
    if (droppedFiles) {
      logImagesData(droppedFiles[0]);
    }
    if (selectedFile) {
      logImagesData(selectedFile);
    }
  }, [droppedFiles, selectedFile]);

  const { ocrGetImageData, ocrGetPDFData, isLoadingGetTextData, textFromDocs } =
    useDocumentAI();

  useEffect(() => {
    if (file64string) {
      const prepared = file64string.split(",");
      if (getMimeType(prepared[0]).includes("pdf")) {
        ocrGetPDFData(prepared[1], getMimeType(prepared[0]));
      } else {
        ocrGetImageData(prepared[1]);
      }
    }
  }, [file64string]);

  useEffect(() => {
    if (buttonCancelRef) {
      buttonCancelRef.current?.scrollIntoView(true);
    }
  }, [isLoadingDoc]);

  const docTitle = loadingDocTitle || loadingDocTitleInput;

  const uploadFileToDB = async () => {
    if (droppedFiles) {
      try {
        await onSubmitAddFile(
          droppedFiles[0],
          `${DATABASE.CLIENTS}/documents/${clientId}/${docTitle}/${droppedFiles[0].name}`
        );
        setDroppedFiles(null);
        await onSubmitGetFiles(
          `${DATABASE.CLIENTS}/documents/${clientId}/${docTitle}/`
        );
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoadingDoc(!isLoadingDoc);
      }
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const uploadImage = async () => {
    if (selectedFile) {
      try {
        await onSubmitAddFile(
          selectedFile,
          `${DATABASE.CLIENTS}/documents/${clientId}/${docTitle}/${selectedFile.name}`
        );
        setSelectedFile(null);
        await onSubmitGetFiles(
          `${DATABASE.CLIENTS}/documents/${clientId}/${docTitle}/`
        );
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoadingDoc(!isLoadingDoc);
      }
    }
  };

  const handleUploadFile = () => {
    if (droppedFiles && docTitle) {
      uploadFileToDB();
    }
    if (selectedFile && docTitle) {
      uploadImage();
    }
    if (!selectedFile && !droppedFiles) {
      fileRef.current?.click();
    }
  };

  const addUserDocuments = async (
    docNames: string,
    docUrls: string,
    text: string
  ) => {
    try {
      await onSubmitAddUserDocuments({
        docNames,
        docUrls,
        uid,
        firstName,
        lastName,
        docTitle,
        text
      });
      await onSubmitGetClientDocs();
    } catch (e) {
      console.error("Error add doc: ", e);
    }
  };

  useEffect(() => {
    if (storageFiles.length) {
      storageFiles.forEach((item) => {
        getDownloadURL(item).then((url) => {
          addUserDocuments(item.name, url, `${textFromDocs}`);
        });
      });
      setLoadingDocTitle("");
      setLoadingDocTitleInput("");
    }
  }, [storageFiles]);

  const { onSubmitDeleteFile } = useDeleteFileFromDB();
  const { onSubmitDeleteUserDocuments } = useDeleteClientDocument();
  const [documentToDelete, setDocumentToDelete] = useState<DataDocs | null>(
    null
  );
  const handleDeleteConfirm = async () => {
    if (documentToDelete) {
      try {
        await onSubmitDeleteFile(
          `${DATABASE.CLIENTS}/documents/${clientId}/${documentToDelete.documentTitle}/${documentToDelete.docNames}`
        );
        await onSubmitDeleteUserDocuments({
          firstName,
          lastName,
          uid,
          docTitle: documentToDelete.documentTitle!
        });
        await onSubmitGetClientDocs();
      } catch (e) {
        console.log(e);
      } finally {
        onClose();
        setDocumentToDelete(null);
      }
    }
  };

  const [questionnaireLetter, setQuestionnaireLetter] =
    useState<ExpertLetter | null>(null);
  const [secondStepData, setSecondStepData] = useState<SecondForm | null>(null);

  const questionnairesData = useMemo(() => {
    return [
      {
        title: FORMDATA.FORMS,
        date: secondStepData?.uploadDate,
        name: secondStepData?.uploadedByName
      },
      {
        title: FORMDATA.LETTER,
        date: questionnaireLetter?.uploadDate,
        name: questionnaireLetter?.uploadedByName
      }
    ];
  }, [secondStepData, questionnaireLetter]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (formsData) {
      setQuestionnaireLetter(formsData.expertLetter);
    }
    if (formsData && formsData.questionnaires?.secondStep) {
      setSecondStepData(formsData.questionnaires.secondStep);
    }
    if (formsData && formsData.expertLetter) {
      dispatch(
        updateQuestionnaireLetter({
          index,
          questionnaireLetter: formsData.expertLetter
        })
      );
    }
  }, [formsData]);

  return (
    <>
      <Card mb="15px">
        <Box py="15px" sx={clickedStyles}>
          <Heading size="md">{`${visaType} visa documents`}</Heading>
        </Box>

        <Box p="15px">
          <Box
            p="15px"
            width="100%"
            fontWeight="400"
            textAlign="left"
            bgColor="rgba(255, 251, 229, 0.67)"
            mb="10px"
            borderRadius="5px"
            border="1px solid #ECE4E4"
          >
            <Text fontSize="18px" fontStyle="italic">
              The following documents are required:
            </Text>

            <UnorderedList my="5px" fontWeight="400" fontSize="16px">
              <ListItem>Passport</ListItem>
              <ListItem>Current visa</ListItem>
              <ListItem>I-94</ListItem>
              <ListItem>Candidate resume</ListItem>
            </UnorderedList>
          </Box>

          {data.length && (
            <Card p="15px">
              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th />
                      <Th>Document</Th>
                      <Th>Date</Th>
                      <Th>Uploaded by</Th>
                      <Th textAlign="center">Required for forms</Th>
                      <Th textAlign="center">Required for drafting</Th>
                      <Th />
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data.map((doc, i) => (
                      <Tr key={i}>
                        <Td>
                          <Checkbox
                            isChecked={!!doc.uploadDate && !!doc.uploadBy}
                          />
                        </Td>
                        <Td>
                          {doc.docUrls ? (
                            <a
                              href={doc.docUrls}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {doc?.documentTitle ?? ""}
                            </a>
                          ) : (
                            doc?.documentTitle ?? ""
                          )}
                        </Td>
                        <Td>
                          {doc.uploadDate
                            ? dayjs(doc.uploadDate).format("MM/DD/YYYY")
                            : ""}
                        </Td>
                        <Td
                          color={
                            doc.uploadBy === "Not uploaded" ? "#F00" : "#000"
                          }
                        >
                          {doc.uploadBy}
                        </Td>
                        <Td textAlign="center">
                          {doc.isRequireForForms ? "Y" : "N"}
                        </Td>
                        <Td textAlign="center">
                          {doc.isRequireForDraft ? "Y" : "N"}
                        </Td>
                        <Td>
                          <Flex flexGrow="0" gap="15px">
                            {/* <EditIcon cursor="pointer" /> */}
                            <DeleteIcon
                              cursor="pointer"
                              onClick={() => {
                                if (doc.uploadDate && doc.docNames) {
                                  onOpen();
                                  setDocumentToDelete(doc);
                                }
                              }}
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Card>
          )}
          {!isLoadingDoc && (
            <Flex gap="25px" justifyContent="center" mt="40px">
              <Flex
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
                gap="5px"
              >
                <Image
                  boxSize="30px"
                  objectFit="contain"
                  src={images.emailClient}
                  alt="Email client"
                />
                <Button>
                  <a href={`mailto:${mail}`}>
                    Remind client to upload a document
                  </a>
                </Button>
              </Flex>
              <Flex
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
                gap="5px"
              >
                <Image
                  boxSize="30px"
                  objectFit="cover"
                  src={images.uploadDocument}
                  alt="Upload new document"
                />
                <Button
                  onClick={openLoadDocHandler}
                  isLoading={isLoadingGetTextData}
                >
                  Upload new document
                </Button>
              </Flex>
            </Flex>
          )}
          {isLoadingDoc && (
            <Card my="15px" w="50%" mx="auto">
              <Box py="15px" sx={clickedStyles}>
                <Heading size="md">Upload new document</Heading>
              </Box>
              <Select
                placeholder="Document type"
                m="15px"
                w="40%"
                onChange={(e) => setLoadingDocTitle(e.target.value)}
                value={loadingDocTitle}
                disabled={!!loadingDocTitleInput}
              >
                {data
                  .filter((doc) => !doc.uploadDate)
                  .map((doc, i) => (
                    <option value={doc?.documentTitle ?? ""} key={i}>
                      {doc?.documentTitle ?? ""}
                    </option>
                  ))}
              </Select>
              <InputGroup w="70%" m="15px">
                <InputLeftAddon>Other:</InputLeftAddon>
                <Input
                  placeholder="add document title ..."
                  value={loadingDocTitleInput}
                  onChange={(e) => setLoadingDocTitleInput(e.target.value)}
                  disabled={!!loadingDocTitle}
                />
              </InputGroup>
              <div>
                {!selectedFile && (
                  <DndProvider backend={HTML5Backend} key={7}>
                    <InputDND
                      loadFiles={loadingDocsHandler}
                      files={droppedFiles}
                    />
                  </DndProvider>
                )}
                {selectedFile && (
                  <List spacing={3} mt="10px">
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      {selectedFile.name}
                    </ListItem>
                  </List>
                )}
                <Flex flexDirection="column" mt="15px">
                  <Input
                    type="file"
                    ref={fileRef}
                    onChange={(e) => {
                      if (e.target.files) {
                        setSelectedFile(e.target.files[0]);
                      }
                    }}
                    accept={supportedExtensionsString}
                    opacity="0"
                    h="0"
                    w="0"
                    lineHeight="0"
                    overflow="hidden"
                    p="0"
                    m="0"
                  />
                  <Button
                    bg={docTitle ? "bgColor.300" : "#CBD5E0"}
                    color="text.100"
                    type="button"
                    mx="auto"
                    my="10px"
                    w="50%"
                    onClick={handleUploadFile}
                    disabled={!docTitle}
                    isLoading={isLoadingAddFile}
                  >
                    {!selectedFile && !droppedFiles ? "Upload file" : "Save"}
                  </Button>
                  <Button
                    onClick={openLoadDocHandler}
                    type="button"
                    mx="auto"
                    my="10px"
                    w="50%"
                    ref={buttonCancelRef}
                  >
                    Cancel
                  </Button>
                </Flex>
              </div>
            </Card>
          )}
        </Box>
      </Card>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalCloseButton
            onClick={() => {
              onClose();
              setDocumentToDelete(null);
            }}
          />
          <ModalBody pb={6}>
            Would you like to delete this document:{" "}
            <strong>{documentToDelete?.documentTitle}</strong>?
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={() => {
                onClose();
                setDocumentToDelete(null);
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={() => {
                handleDeleteConfirm();
              }}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
