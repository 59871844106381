import {
  Divider,
  Image,
  List,
  ListIcon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { MdCheckCircle, MdSettings } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import images from "../../assets/image-export-assets";
import { CustomButton } from "./CustomButton";

interface PremiumModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const PremiumModal = ({ isOpen, onClose }: PremiumModalProps) => {
  const navigate = useNavigate();
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          className="flex flex-col justify-center items-center min-w-min"
          pb={0}
        >
          <div className="flex justify-center items-center gap-2 relative  ">
            <Image src={images.premiumIcon} h={12} w={12} />
            <Text color="primary.darkBlue">Upgrade to Premium</Text>
          </div>
          <Divider my={2} color="#B8C0C9" bg="#B8C0C9" />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text className="text-[#4D5962] mb-4">
            Sign up to learn more and gain access to <br /> CaseBlink premium.
          </Text>
          {/* included */}
          <Text className="font-bold my-2" color="primary.darkBlue">
            What's included?
          </Text>
          <List spacing={2}>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="primary.red" />
              Unlimited expert letters
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="primary.red" />
              Legal brief
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="primary.red" />
              Exhibit list
            </ListItem>
          </List>
        </ModalBody>

        <ModalFooter
          alignItems="center"
          justifyItems="center"
          justifyContent="center"
        >
          <Link to="/account/1">
            <CustomButton
              bgColor="primary.darkBlue"
              color="white"
              type="standard"
              title="Sign Up Now"
              onClick={() => {}}
            />
          </Link>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
