import {
  ChakraProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select
} from "@chakra-ui/react";
import { FieldAttributes, FieldConfig, FormikConfig, useField } from "formik";

interface Props extends FieldConfig, ChakraProps {
  label: string;
  placeHolder: string;
  options: string[];
  isRequired?: boolean;
  formikHelpers?: any;
}

export const SelectField = ({
  label,
  options,
  placeHolder,
  formikHelpers,
  isRequired = false,
  ...props
}: Props) => {
  const [field, meta] = formikHelpers ?? useField(props.name);

  return (
    <FormControl
      {...(props as ChakraProps)}
      isRequired={isRequired}
      isInvalid={meta.touched && !!meta.error}
      id={props.name}
    >
      <FormLabel>{label}</FormLabel>
      <Select {...field} placeholder={placeHolder}>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Select>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
