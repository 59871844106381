import axiosInstance from "./axiosConfig";

interface ConversionResult {
  filename: string;
  data: ArrayBuffer;
}

export const convertFromHtml = async (
  html: string,
  format: string
): Promise<ConversionResult> => {
  try {
    const response = await axiosInstance.post(
      "/convert_from_html",
      { html, format },
      { responseType: "arraybuffer" }
    );

    // Extract Content-Disposition header
    const contentDisposition = response.headers["content-disposition"];
    console.log({ contentDisposition });
    let filename = "unknown";

    if (contentDisposition) {
      // Match the filename in the Content-Disposition header
      const filenameMatch = contentDisposition.match(/filename="?(.+?)"?$/);
      if (filenameMatch) {
        [, filename] = filenameMatch;
      }
    }

    const result = {
      filename,
      data: response.data as ArrayBuffer
    };
    return result;
  } catch (error) {
    console.error(`Error generating ${format} file:`, error);
    throw error;
  }
};
