import {
  Flex,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Card,
  CardHeader,
  Heading,
  CardBody,
  CardFooter,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  UnorderedList,
  ListItem,
  ModalFooter,
  useDisclosure,
  Avatar,
  Spinner
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDownloadURL } from "firebase/storage";
import { Header } from "../components/Header";
import { Sidebar } from "../components/Sidebar/Sidebar";
import { DATABASE, VISAVALUE } from "../types/tables-data";
import { clickedStyles } from "../styles/common-styles";
import { useGetActiveClientData } from "../hooks/useGetActiveClientData";
import {
  addClientLawyerData,
  setActiveClientData,
  updateActiveClientAvatar,
  updateActiveClientData
} from "../redux/client-case/activeClientSlice";
import { activeClientSelectors } from "../redux/client-case/selectors";
import { useUpdateActiveClient } from "../hooks/useUpdateActiveClient";
import { useGetLawyerData } from "../hooks/useGetLawyerData";
import { useAddFileToDB } from "../hooks/useAddFileToDB";
import { useGetFilesFromDB } from "../hooks/useGetFilesFromDB";
import { useDeleteFileFromDB } from "../hooks/useDeleteFileFromDB";
import HamburgerMenu from "../components/HamburgerMenu";
import { auth } from "../api/firebaseApi";
import { validateDate, maxInputDateValue } from "../helpers/date_helpers";
import { supportedExtensionsString } from "../constans";

interface UpdateActiveClient {
  userFirstName: string;
  userLastName: string;
  birthDay: string;
  visaType: VISAVALUE;
  citizenship: string;
  employer: string;
  address: string;
  email: string;
}

export const ClientPage = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { onSubmitGetClientsData, clientData, isLoadingGetActiveClient } =
    useGetActiveClientData();
  const mail = useSelector(activeClientSelectors.selectEmail);
  const firstName = useSelector(activeClientSelectors.selectFirstName);
  const lastName = useSelector(activeClientSelectors.selectLastName);
  const birthDay = useSelector(activeClientSelectors.selectBirthDay);
  const citizenship = useSelector(activeClientSelectors.selectCitizenship);
  const visaType = useSelector(activeClientSelectors.selectVisa);
  const employer = useSelector(activeClientSelectors.selectEmployer);
  const address = useSelector(activeClientSelectors.selectAddress);
  const uid = useSelector(activeClientSelectors.selectId);
  const lawyerId = useSelector(activeClientSelectors.selectLawyerId);
  const avatarSrc = useSelector(activeClientSelectors.selectAvatarSrc);
  const avatarName = useSelector(activeClientSelectors.selectAvatarName);

  const {
    onSubmitUpdateActiveClient,
    isLoadingUpdateActiveClient,
    onUpdateActiveClientAvatar
  } = useUpdateActiveClient();

  useEffect(() => {
    if (mail) {
      onSubmitGetClientsData(mail);
    }
  }, [mail]);

  const formik = useFormik({
    initialValues: {
      userFirstName: firstName || "",
      userLastName: lastName || "",
      birthDay: birthDay ? dayjs(birthDay).format("YYYY-MM-DD") : "",
      visaType: visaType || VISAVALUE.EMPTY,
      citizenship: citizenship || "",
      employer: employer || "",
      address: address || "",
      email: mail,
      lawyerName: "N/A"
    },
    validationSchema: Yup.object({
      userFirstName: Yup.string().required("Required"),
      userLastName: Yup.string().required("Required"),
      birthDay: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email address")
    }),
    onSubmit: () => {}
  });

  const { onSubmitGetLawyerData, lawyerData } = useGetLawyerData();

  const { onSubmitGetFiles, storageFiles } = useGetFilesFromDB();

  const getInfoLawyer = async () => {
    await onSubmitGetLawyerData(lawyerId);
    await onSubmitGetFiles(`${DATABASE.CLIENTS}/avatars/${uid}/`);
  };

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const { onSubmitAddFile, fileRef } = useAddFileToDB();

  const { onSubmitDeleteFile } = useDeleteFileFromDB();

  const getImagesFromBD = async () => {
    try {
      await onSubmitGetFiles(`${DATABASE.CLIENTS}/avatars/${uid}/`);
    } catch (e) {
      console.log(e);
      dispatch(updateActiveClientAvatar({ avatarSrc: "/", avatarName: "" }));
    }
  };

  const uploadImage = async () => {
    if (selectedFile) {
      try {
        await onSubmitAddFile(
          selectedFile,
          `${DATABASE.CLIENTS}/avatars/${uid}/${selectedFile.name}`
        );
        setSelectedFile(null);
        getImagesFromBD();
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    if (storageFiles.length) {
      storageFiles.forEach((item) => {
        getDownloadURL(item).then((url) => {
          dispatch(
            updateActiveClientAvatar({ avatarSrc: url, avatarName: item.name })
          );
          onUpdateActiveClientAvatar(uid, {
            avatarSrc: url,
            avatarName: item.name
          });
        });
      });
    }
    if (storageFiles.length === 0) {
      dispatch(updateActiveClientAvatar({ avatarSrc: "/", avatarName: "" }));
    }
  }, [storageFiles]);

  const deleteImage = async () => {
    if (avatarName) {
      try {
        await onSubmitDeleteFile(
          `${DATABASE.CLIENTS}/avatars/${uid}/${avatarName}`
        );
        await onUpdateActiveClientAvatar(uid, {
          avatarSrc: "",
          avatarName: ""
        });
        dispatch(updateActiveClientAvatar({ avatarSrc: "/", avatarName: "" }));
      } catch (e) {
        console.log(e);
      } finally {
        uploadImage();
      }
    }
  };

  useEffect(() => {
    if (selectedFile && avatarName) {
      deleteImage();
    } else if (selectedFile) {
      uploadImage();
    }
  }, [selectedFile]);

  const location = useLocation();

  useEffect(() => {
    if (lawyerData) {
      dispatch(
        addClientLawyerData({
          lawyerEmail: lawyerData.lawyerEmail,
          lawyerFirstName: lawyerData.lawyerFirstName,
          lawyerLastName: lawyerData.lawyerLastName
        })
      );
      formik.setFieldValue(
        "lawyerName",
        `${lawyerData.lawyerFirstName} ${lawyerData.lawyerLastName}`,
        false
      );
    }
    if (lawyerData && location.state === "login" && avatarSrc) {
      navigate(`/client-case/${uid}`);
    }
  }, [lawyerData, avatarSrc]);

  useEffect(() => {
    if (lawyerId) {
      getInfoLawyer();
    }
  }, [lawyerId]);

  useEffect(() => {
    if (clientData && !uid) {
      formik.setFieldValue("userFirstName", clientData.firstName, false);
      formik.setFieldValue("userLastName", clientData.lastName, false);
      formik.setFieldValue(
        "birthDay",
        dayjs(clientData.birthDay).format("YYYY-MM-DD"),
        false
      );
      formik.setFieldValue("visaType", clientData.visa, false);
      formik.setFieldValue("citizenship", clientData.citizenship, false);
      formik.setFieldValue("employer", clientData.employer, false);
      formik.setFieldValue("address", clientData.address, false);

      dispatch(setActiveClientData(clientData));
    }
  }, [clientData]);

  const changedClientData = () => {
    const changedValues: string[] = [];
    if (formik.values.userFirstName !== firstName) {
      changedValues.push("First name");
    }
    if (formik.values.userLastName !== lastName) {
      changedValues.push("Last name");
    }
    if (formik.values.birthDay !== dayjs(birthDay).format("YYYY-MM-DD")) {
      changedValues.push("Day of birth");
    }
    if (formik.values.visaType !== visaType) {
      changedValues.push("Visa type");
    }
    if (formik.values.citizenship !== citizenship) {
      changedValues.push("Citizenship");
    }
    if (formik.values.employer !== employer) {
      changedValues.push("Employer");
    }
    if (formik.values.address !== address) {
      changedValues.push("Address");
    }

    return changedValues;
  };

  const handleUpdateData = async (values: UpdateActiveClient) => {
    try {
      const updatedValues = {
        firstName: values.userFirstName,
        lastName: values.userLastName,
        birthDay: Date.parse(values.birthDay),
        citizenship: values.citizenship,
        employer: values.employer,
        address: values.address,
        visa: values.visaType,
        email: values.email
      };
      await onSubmitUpdateActiveClient(uid, updatedValues, lawyerId);

      dispatch(updateActiveClientData(updatedValues));

      if (lawyerData && lawyerData?.lawyerEmail) {
        navigate(`/client-case/${uid}`);
      }
    } catch (e) {
      console.error("Error is:", e);
    } finally {
      onClose();
    }
  };

  return (
    <>
      <Header />
      <Flex justifyContent="space-between" flexDirection="row" mt="30px">
        <HamburgerMenu />
        <Sidebar
          closeSidebar={() => setShowSidebar(false)}
          showSidebar={showSidebar}
          isLoading={isLoadingGetActiveClient}
        />
        <Flex alignItems="center" flexDirection="column" flex="1">
          {location.state === "login" || !clientData ? (
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          ) : (
            <Card align="center" width="90%">
              <CardHeader sx={clickedStyles} width="100%">
                <Heading size="md">Profile</Heading>
              </CardHeader>
              <>
                <CardBody width="50%">
                  <Avatar
                    name={`${formik.values.userFirstName} ${formik.values.userLastName}`}
                    src={avatarSrc}
                    marginBottom="11px"
                    width="6rem"
                    height="6rem"
                    cursor="pointer"
                    onClick={() => {
                      fileRef.current?.click();
                    }}
                  />
                  <Input
                    type="file"
                    ref={fileRef}
                    onChange={(e) => {
                      if (e.target.files) {
                        setSelectedFile(e.target.files[0]);
                      }
                    }}
                    accept={supportedExtensionsString}
                    opacity="0"
                    h="0"
                    w="0"
                    lineHeight="0"
                    overflow="hidden"
                    p="0"
                    m="0"
                  />
                  <FormControl
                    my="20px"
                    variant="floating"
                    id="userFirstName"
                    isRequired
                    isInvalid={
                      formik.touched.userFirstName &&
                      !!formik.errors.userFirstName
                    }
                  >
                    <Input
                      placeholder=" "
                      name="userFirstName"
                      value={formik.values.userFirstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <FormLabel>First name</FormLabel>
                    <FormErrorMessage>
                      Your First name is required
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    my="20px"
                    variant="floating"
                    id="userLastName"
                    isRequired
                    isInvalid={
                      formik.touched.userLastName &&
                      !!formik.errors.userLastName
                    }
                  >
                    <Input
                      placeholder=" "
                      name="userLastName"
                      value={formik.values.userLastName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <FormLabel>Last name</FormLabel>
                    <FormErrorMessage>
                      Your Last name is required
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    my="20px"
                    variant="floating"
                    id="birthDay"
                    isRequired
                    isInvalid={
                      formik.touched.birthDay &&
                      !validateDate(formik.values.birthDay)
                    }
                  >
                    <Input
                      placeholder=" "
                      type="date"
                      name="birthDay"
                      min="1900-01-01"
                      max={maxInputDateValue}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.birthDay}
                    />
                    <FormLabel>Date of birth</FormLabel>
                    <FormErrorMessage>
                      Your Date of birth is required
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl my="20px" variant="floating" id="visaType">
                    <Select
                      placeholder="Select VISA option"
                      onChange={formik.handleChange}
                      name="visaType"
                      value={formik.values.visaType}
                    >
                      {/* <option value={VISAVALUE.EB1}>{VISAVALUE.EB1}</option> */}
                      {/* <option value={VISAVALUE.EB2}>{VISAVALUE.EB2}</option> */}
                      {/* <option value={VISAVALUE.O1}>{VISAVALUE.O1}</option> */}
                      <option value={VISAVALUE.EB2NIW}>
                        {VISAVALUE.EB2NIW}
                      </option>
                    </Select>
                  </FormControl>
                  <FormControl my="20px" variant="floating" id="citizenship">
                    <Input
                      placeholder=" "
                      name="citizenship"
                      value={formik.values.citizenship}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <FormLabel>Citizenship</FormLabel>
                  </FormControl>
                  <FormControl my="20px" variant="floating" id="employer">
                    <Input
                      placeholder=" "
                      name="employer"
                      value={formik.values.employer}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <FormLabel>Employer</FormLabel>
                  </FormControl>
                  <FormControl my="20px" variant="floating" id="email">
                    <Input
                      placeholder=" "
                      name="email"
                      type="email"
                      value={formik.values.email}
                      readOnly
                    />
                    <FormLabel>Email</FormLabel>
                  </FormControl>

                  <FormControl my="20px" variant="floating" id="address">
                    <Input
                      placeholder=" "
                      name="address"
                      type="text"
                      value={formik.values.address}
                      onChange={formik.handleChange}
                    />
                    <FormLabel>Address</FormLabel>
                  </FormControl>

                  <FormControl my="20px" variant="floating" id="lawyerName">
                    <Input
                      placeholder=" "
                      name="lawyerName"
                      type="text"
                      value={formik.values.lawyerName}
                      readOnly
                    />
                    <FormLabel>Lawyer Name</FormLabel>
                  </FormControl>
                </CardBody>
                <CardFooter>
                  <Button
                    bg="bgColor.300"
                    color="text.100"
                    type="button"
                    onClick={() => navigate(-1)}
                    mx="15px"
                  >
                    Cancel
                  </Button>
                  <Button
                    bg="bgColor.300"
                    color="text.100"
                    mx="15px"
                    type="button"
                    onClick={() => {
                      if (changedClientData().length) {
                        onOpen();
                      } else {
                        navigate(`/client-case/${uid}`);
                      }
                    }}
                    isLoading={
                      isLoadingGetActiveClient || isLoadingUpdateActiveClient
                    }
                  >
                    Save
                  </Button>
                </CardFooter>
              </>
            </Card>
          )}
        </Flex>
      </Flex>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            You changed the following fields:{" "}
            <UnorderedList my="15px">
              {changedClientData().map((data, i) => (
                <ListItem key={i}>{data}</ListItem>
              ))}
            </UnorderedList>
            <br />
            <strong>Would you like to save it?</strong>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} mr={3}>
              Cancel
            </Button>

            <Button
              colorScheme="blue"
              onClick={() => handleUpdateData(formik.values)}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
