import { useEffect } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from "react-router-dom";
import "./App.css";
import { CaseResearch } from "./components/individualTabs/CaseResearch";
import { EditEvidenceDocument } from "./components/individualTabs/individualDocuments/EditEvidence";
import { EditStandardDocument } from "./components/individualTabs/individualDocuments/EditStandardDocument";
import { IndividualDrafts } from "./components/individualTabs/IndividualDrafts";
import { EditCoverLetterDraft } from "./components/individualTabs/individualDrafts/coverLetterDrafts/EditCoverLetterDraft";
import { EditExhibitList } from "./components/individualTabs/individualDrafts/exhibitListDraft/EditExhibitListDraft";
import { EditExpertLetterDraft } from "./components/individualTabs/individualDrafts/expertLetterDrafts/EditExpertLetterDraft";
import { EditSopDraft } from "./components/individualTabs/individualDrafts/sopDrafts/EditSopDraft";
import { IndividualOverview } from "./components/individualTabs/IndividualOverview";
import { EvidenceQuestions } from "./components/individualTabs/individualQuestionnaires/EvidenceQuestions";
import { AddEditExpertLetterInputs } from "./components/individualTabs/individualQuestionnaires/expertLetter/AddEditExpertInput";
import { I140Form } from "./components/individualTabs/individualQuestionnaires/immigrationForms/prefill-forms/I140Form";
import { Chatbot } from "./components/individualTabs/individualQuestionnaires/immigrationForms/visa-form/Chatbot";
import { IndividualTutorials } from "./components/individualTabs/IndividualTutorials";
import { LawFirmSettings } from "./components/LawFirmSettings";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { firmConfiguration } from "./constans";
import { setFavicon } from "./helpers/dom_helpers";
import { getSubdomain } from "./helpers/helpers";
import AcceptAgreement from "./pages/AcceptAgreement";
import { Account } from "./pages/Account";
import AdminUpdatesManager from "./pages/Admin/Updates/AdminUpdatesManager";
import AdminEditUser from "./pages/Admin/Users/AdminEditUser";
import AdminNewUser from "./pages/Admin/Users/AdminNewUser";
import { AdminUsers } from "./pages/Admin/Users/AdminUsers";
import { ArchivedCases } from "./pages/ArchivedCases";
import { ClientCase } from "./pages/ClientCase";
import { ClientExpertLetter } from "./pages/ClientExpertLetter";
import { ClientFormExpertLetter } from "./pages/ClientFormExpertLetter";
import { ClientForms } from "./pages/ClientForms";
import { ClientFormStepOne } from "./pages/ClientFormStepOne";
import { ClientFormStepThree } from "./pages/ClientFormStepThree";
import { ClientFormStepTwo } from "./pages/ClientFormStepTwo";
import { ClientPage } from "./pages/ClientPage";
import { CurrentCaseId } from "./pages/CurrentCaseId";
import { CurrentCases } from "./pages/CurrentCases";
import { ExpertsExtractedHighlights } from "./pages/ExpertsResumeHighlights";
import { ExtractedInfoPage } from "./pages/ExtractedInfoPage";
import IndividualCase from "./pages/IndividualCase";
import { IndividualExtractedCaseResearch } from "./pages/individualExtractedCaseResearch";
import { IndividualExtractedEvidence } from "./pages/individualExtractedEvidence";
import { IndividualExtractedInfo } from "./pages/IndividualExtractedInfo";
import { IndividualProfile } from "./pages/IndividualProfile";
import LawyerAccountPage from "./pages/LawyerAccountPage";
import { LawyerProfile } from "./pages/LawyerProfile";
import { Login } from "./pages/Login";
import { NewCase } from "./pages/NewCase";
import NotFound from "./pages/NotFound";
import { Payment } from "./pages/Payment";
import { PlanPage } from "./pages/PlanPage";
import { RedirectHandler } from "./pages/RedirectHandler";
import { ResetPassword } from "./pages/ResetPassword";

function App() {
  const subdomain = getSubdomain();

  // Updates the document title and favicon based on the current subdomain.
  // Defaults to "Caseblink" if no matching title or favicon is found.
  useEffect(() => {
    window.document.title =
      firmConfiguration[subdomain]?.tabTitle ?? "Caseblink";
    const faviconUrl = firmConfiguration[subdomain]?.favicon;
    if (faviconUrl) setFavicon(faviconUrl);
  }, []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<RedirectHandler />} />
        <Route path="login" element={<Login />} />
        <Route path="accept-agreement" element={<AcceptAgreement />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/account" element={<LawyerAccountPage />}>
            <Route path=":tabIndex" element={<LawyerProfile />} />
            <Route path=":tabIndex" element={<PlanPage />} />
          </Route>
          <Route path="/individual/:id">
            <Route index element={<IndividualCase />} />
            <Route path="account" element={<Account />}>
              <Route path="profile" element={<IndividualProfile />} />
              <Route path="payment" element={<Payment />} />
            </Route>
            <Route path=":visaType" element={<IndividualCase />}>
              <Route path="overview" element={<IndividualOverview />} />
              <Route path="tutorials" element={<IndividualTutorials />} />
              <Route path="documents" element={<IndividualCase />}>
                <Route path="standard-documents" element={<IndividualCase />} />
                <Route path="evidence-documents" element={<IndividualCase />} />
              </Route>
              <Route path="questionnaires" element={<EvidenceQuestions />} />
              <Route path="forms" />
              <Route path="case-research" element={<IndividualCase />}>
                <Route path="case-research" element={<CaseResearch />} />
              </Route>
              <Route path="drafts" element={<IndividualDrafts />} />
              <Route path="packet" />
            </Route>
          </Route>
          <Route path="/admin/users" element={<AdminUsers />} />
          <Route path="/admin/users/new" element={<AdminNewUser />} />
          <Route path="/admin/users/:uid/edit" element={<AdminEditUser />} />
          <Route path="/admin/updates" element={<AdminUpdatesManager />} />
          <Route
            path="/individual/:id/:visaType/documents/evidence-documents/:docId"
            element={<EditEvidenceDocument />}
          />
          <Route
            path="/individual/:id/:visaType/documents/standard-documents/:docId"
            element={<EditStandardDocument />}
          />
          <Route
            path="/individual/:id/:visaType/documents/standard-documents/extracted-information"
            element={<IndividualExtractedInfo />}
          />
          <Route
            path="/individual/:id/:visaType/documents/evidence-documents/extracted-information"
            element={<IndividualExtractedEvidence />}
          />
          <Route
            path="/individual/:id/:visaType/drafts/expert-letters/expert-letter-inputs/:expertId?"
            element={<AddEditExpertLetterInputs />}
          />
          <Route
            path="/individual/:id/:visaType/drafts/expert-letters/extracted-resume-highlights"
            element={<ExpertsExtractedHighlights />}
          />
          <Route
            path="/individual/:id/:visaType/drafts/expert-letters/:expertId/edit"
            element={<EditExpertLetterDraft />}
          />
          <Route
            path="/individual/:id/:visaType/drafts/cover-letter/edit"
            element={<EditCoverLetterDraft />}
          />
          <Route
            path="/individual/:id/:visaType/case-research/extracted-information"
            element={<IndividualExtractedCaseResearch />}
          />
          <Route
            path="/individual/:id/:visaType/drafts/statement-of-purpose/edit"
            element={<EditSopDraft />}
          />
          <Route
            path="/individual/:id/:visaType/drafts/exhibit-list/edit"
            element={<EditExhibitList />}
          />
          <Route path="/individual/lawfirm" element={<LawFirmSettings />} />
          <Route
            path="/individual/:id/:visaType/forms/chatbot"
            element={<Chatbot />}
          />
          <Route
            path="/individual/:id/:visaType/forms/i-140-form"
            element={<I140Form />}
          />
          <Route
            path="/individual/:id/:visaType/packet/signed-expert-letters/:docId"
            element={<EditEvidenceDocument />}
          />
          <Route path="/cases">
            <Route index element={<CurrentCases />} />
            <Route path=":caseID/overview" element={<CurrentCaseId />} />
            <Route path=":caseID/notes" element={<CurrentCaseId />} />
            <Route path=":caseID/documents" element={<CurrentCaseId />} />
            <Route path=":caseID/questionnaires" element={<CurrentCaseId />} />
            <Route
              path="/cases/:caseID/questionnaire/expert-letter"
              element={<ClientFormExpertLetter />}
            />
            <Route path=":caseID/packet" element={<CurrentCaseId />} />
            <Route path=":caseID/edit-case" element={<NewCase />} />
            <Route
              path=":caseID/extracted-information"
              element={<ExtractedInfoPage />}
            />
            <Route
              path=":caseID/expert-letter"
              element={<ClientExpertLetter />}
            />
          </Route>
          <Route path="/archived-cases" element={<ArchivedCases />} />
          <Route path="/new-case" element={<NewCase />} />
          <Route path="/client" element={<ClientPage />} />
          <Route path="/client-case/:caseID" element={<ClientCase />}>
            <Route path="overview" element={<ClientCase />} />
            <Route path="documents" element={<ClientCase />} />
            <Route path="questionnaires" element={<ClientCase />} />
            <Route path="packet" element={<ClientCase />} />
          </Route>
          <Route path="/questionnaire/:caseID" element={<ClientForms />} />
          <Route
            path="/questionnaire/:caseID/forms-step-1"
            element={<ClientFormStepOne />}
          />
          <Route
            path="/questionnaire/:caseID/forms-step-2"
            element={<ClientFormStepTwo />}
          />
          <Route
            path="/questionnaire/:caseID/forms-step-3"
            element={<ClientFormStepThree />}
          />
          <Route
            path="/questionnaire/:caseID/expert-letter"
            element={<ClientFormExpertLetter />}
          />
        </Route>
        <Route path="*" element={<NotFound />} />
      </>
    )
  );

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
