import { useState } from "react";
import {
  FirestoreError,
  FirestoreErrorCode,
  doc,
  updateDoc
} from "firebase/firestore";
import { db } from "../api/firebaseApi";
import { DATABASE } from "../types/tables-data";
import { UpdateActiveClient } from "../redux/client-case/activeClientSlice";

interface UpdateActiveClientAvatar {
  avatarSrc: string;
  avatarName: string;
}

export const useUpdateActiveClient = () => {
  const [isLoadingUpdateActiveClient, setIsLoadingUpdateActiveClient] =
    useState(false);
  const [errorUpdateActiveClient, setErrorUpdateActiveClient] =
    useState<FirestoreErrorCode | null>(null);

  const onSubmitUpdateActiveClient = async (
    id: string,
    values: UpdateActiveClient,
    lawyerId: string
  ) => {
    setIsLoadingUpdateActiveClient(true);
    try {
      const clientRef = doc(db, DATABASE.CLIENTS, `${id}`);

      await updateDoc(clientRef, {
        firstName: values.firstName,
        lastName: values.lastName,
        birthDay: values.birthDay,
        citizenship: values.citizenship,
        employer: values.employer,
        address: values.address,
        visa: values.visa,
        email: values.email,
        lawyerId
      });
    } catch (e: any) {
      const errorCode = e.code;
      setErrorUpdateActiveClient(errorCode);
    } finally {
      setIsLoadingUpdateActiveClient(false);
    }
  };
  const onUpdateActiveClientAvatar = async (
    id: string,
    values: UpdateActiveClientAvatar
  ) => {
    setIsLoadingUpdateActiveClient(true);
    try {
      const clientRef = doc(db, DATABASE.CLIENTS, `${id}`);

      await updateDoc(clientRef, {
        avatarName: values.avatarName,
        avatarSrc: values.avatarSrc
      });
    } catch (e) {
      if (e instanceof FirestoreError) {
        const errorCode = e.code;
        setErrorUpdateActiveClient(errorCode);
      }
    } finally {
      setIsLoadingUpdateActiveClient(false);
    }
  };

  return {
    isLoadingUpdateActiveClient,
    onSubmitUpdateActiveClient,
    errorUpdateActiveClient,
    onUpdateActiveClientAvatar
  };
};
