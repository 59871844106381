import { IconButton } from "@chakra-ui/react";
import React from "react";
import { IoArrowBackSharp, IoArrowForwardSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

interface Props {
  onClick?: () => void;
  disabled?: boolean;
  isNext?: boolean;
  ariaLabel?: string;
  isContainerVisible?: boolean;
}

const CustomBackButton: React.FC<Props> = ({
  onClick,
  disabled = false,
  isNext = false,
  ariaLabel = "",
  isContainerVisible = true
}) => {
  const navigate = useNavigate();

  if (isContainerVisible) {
    return (
      <IconButton
        aria-label={ariaLabel}
        disabled={disabled}
        m={0}
        textAlign="left"
        border="none"
        padding={0}
        icon={isNext ? <IoArrowForwardSharp /> : <IoArrowBackSharp />}
        bg="transparent"
        size="lg"
        borderRadius="0"
        rounded="full"
        onClick={onClick || (() => navigate(isNext ? 1 : -1))}
      />
    );
  }
  return <span>{isNext ? <IoArrowForwardSharp /> : <IoArrowBackSharp />}</span>;
};

export default CustomBackButton;
