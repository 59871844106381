import { WarningTwoIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
  useOutsideClick
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { MdAnnouncement, MdInsertDriveFile } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import useAdminUpdates from "../../hooks/admin/useManageAdminUpdates";
import { useSubscribeToJob } from "../../hooks/useSubscribeToJob";
import { AdminUpdate } from "../../pages/Admin/Updates/AdminUpdate";
import { clearAllExtractions } from "../../redux/extraction-jobs/extractionJobsSlice";

interface UpdateProps {
  updates: AdminUpdate[];
}

const getIcon = (type: string) => {
  if (type.toLowerCase().includes("extraction")) {
    return MdInsertDriveFile;
  }
  if (type.toLowerCase().includes("generation")) {
    return MdInsertDriveFile;
  }
  return MdInsertDriveFile;
};

const getPriorityColor = (priority: string) => {
  switch (priority) {
    case "low":
      return "green.100";
    case "medium":
      return "yellow.100";
    case "high":
      return "red.100";
    default:
      return "gray.100";
  }
};

const UpdatesList: React.FC<UpdateProps> = ({ updates }) => (
  <VStack alignItems="start" divider={<Divider />} spacing={4} p={4}>
    {updates.length === 0 && <Text>No updates here.</Text>}
    {updates.map((update) => (
      <Box
        borderRadius="12px"
        m={2}
        p={4}
        key={update.id}
        _hover={{ textDecoration: "none" }}
        bg={getPriorityColor(update.priority)}
        boxShadow="md"
      >
        <Flex alignItems="center">
          <Box
            boxSize="40px"
            borderRadius="full"
            display="flex"
            alignItems="center"
            justifyContent="center"
            mr={4}
            flexShrink={0}
          >
            <MdAnnouncement size={24} color="primary.darkBlue" />
          </Box>
          <Flex flexDirection="row" alignItems="center" flexWrap="wrap">
            <Text textAlign="left" fontWeight={500} mr={2} whiteSpace="nowrap">
              {update.title}:
            </Text>
            <Text textAlign="left">{update.description}</Text>
          </Flex>
        </Flex>
      </Box>
    ))}
  </VStack>
);

interface UpdatesBoxProps {
  updates: AdminUpdate[];
  closeUpdatesBox: () => void;
}

const UpdatesBox: React.FC<UpdatesBoxProps> = ({
  updates,
  closeUpdatesBox
}) => {
  const {
    isOpen: isMoreOpen,
    onOpen: onMoreOpen,
    onClose: onMoreClose
  } = useDisclosure();

  return (
    <Box
      transition="1s ease-in-out"
      py="4"
      bg="white"
      boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
      borderRadius="lg"
      position="absolute"
      top="50px"
      right="8px"
      zIndex="modal"
      minWidth="600px"
    >
      <Text textAlign="left" ml={4} fontSize={24} fontWeight={600}>
        App updates{" "}
      </Text>
      <UpdatesList updates={isMoreOpen ? updates : updates.slice(0, 5)} />
      {updates.length > 5 && !isMoreOpen && (
        <Button onClick={onMoreOpen} mt={4}>
          More
        </Button>
      )}
    </Box>
  );
};

const AdminUpdatesModal: React.FC = () => {
  const dispatch = useDispatch();
  const { fetchActiveJobs, subscribeToJob } = useSubscribeToJob();
  const { id, visaType } = useParams<{ id: string; visaType: string }>();
  const { adminUpdates, isLoading, error, fetchAdminUpdates } =
    useAdminUpdates();

  const [isBoxVisible, setIsBoxVisible] = useState(false);

  const toggleTasksBox = () => {
    setIsBoxVisible((prev) => !prev);
  };

  const closeUpdatesBox = () => setIsBoxVisible(false);

  useEffect(() => {
    fetchAdminUpdates();
  }, []);

  useEffect(() => {
    dispatch(clearAllExtractions());
    const initJobListening = async () => {
      if (id) {
        const activeJobs = await fetchActiveJobs(id);
        activeJobs.forEach((job) => subscribeToJob(job));
      }
    };
    initJobListening();
  }, [id]);

  const tooltipRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: tooltipRef,
    handler: closeUpdatesBox
  });

  useEffect(() => {
    console.log("admin-updates: ,", adminUpdates);
  }, [adminUpdates]);

  return (
    <Box>
      {adminUpdates && adminUpdates.length > 0 && (
        <Box zIndex="tooltip">
          <Box className="relative" ref={tooltipRef}>
            <Box display="flex" alignItems="center">
              <Tooltip placement="bottom-end" isOpen={isBoxVisible}>
                <Box
                  position="relative"
                  display="inline-block"
                  onClick={toggleTasksBox}
                >
                  <IconButton
                    icon={<WarningTwoIcon boxSize="20px" color="yellow.500" />}
                    aria-label="Updates"
                    backgroundColor="primary.background"
                  />
                </Box>
              </Tooltip>
            </Box>
            {isBoxVisible && (
              <UpdatesBox
                updates={adminUpdates}
                closeUpdatesBox={closeUpdatesBox}
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AdminUpdatesModal;
