import { useDispatch } from "react-redux";
import { filesHasUnsupportedExtension } from "../helpers/file_helpers";
import { VisaDocumentType } from "../redux/documents/types";
import {
  setError,
  setExpanded as setFileWindowExpanded,
  setFiles,
  setUploadConfiguration,
  setVisibilty
} from "../redux/file-upload/fileUploadSlice";
import { uploadFiles } from "../redux/file-upload/fileUploadThunk";

export type FileActionsProps = {
  files: File[];
  filePath: string;
  uid: string;
  mainDocumentId?: string;
  type?: VisaDocumentType;
};

const useFileActions = () => {
  const dispatch = useDispatch();

  const handleFileActions = ({
    files,
    uid,
    filePath,
    mainDocumentId,
    type = VisaDocumentType.Standard
  }: FileActionsProps) => {
    const filesAsArray = Array.from(files);
    const filesHasOtherExtension = filesHasUnsupportedExtension(filesAsArray);
    dispatch(setFiles(filesAsArray));
    dispatch(
      setUploadConfiguration({
        uid: uid ?? "",
        path: filePath,
        mainDocumentId,
        documentType: type
      })
    );
    if (!filesHasOtherExtension) {
      dispatch(setVisibilty(true));
      dispatch(setFileWindowExpanded(true));

      dispatch(uploadFiles() as any);
    } else {
      dispatch(setError("extension-error"));
    }
  };

  return {
    handleFileActions
  };
};

export default useFileActions;
