import { StorageReference, listAll, ref } from "firebase/storage";
import { useState } from "react";
import { storage } from "../api/firebaseApi";

export const useGetFilesFromDB = () => {
  const [isLoadingGetFiles, setIsLoadingGetFiles] = useState(false);
  const [storageFiles, setStorageFiles] = useState<StorageReference[]>([]);

  const onSubmitGetFiles = async (path: string) => {
    const imageListRef = ref(storage, path);
    setIsLoadingGetFiles(true);

    try {
      const images = await listAll(imageListRef);
      setStorageFiles(images.items);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingGetFiles(false);
    }
  };

  return {
    isLoadingGetFiles,
    storageFiles,
    onSubmitGetFiles
  };
};
